import {
  Badge,
  Box,
  Button,
  Chip,
  Divider,
  Stack,
  Typography,
  Paper,
  Pagination,
  PaginationItem,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { ArrowBack, ArrowForward } from "@mui/icons-material";
import { DatePicker } from "@mui/lab";
import { DateRangePicker } from "rsuite";
import { Link } from "react-router-dom";
import ProductCard from "../ProductCard/ProductCard";
import { useSelector } from "react-redux";
import TabsSorter from "../TabsSorter/TabsSorter";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import {
  getNotification,
  resetNotification,
} from "../StateSlices/notificationSlice";
import Spinner from "../Spinner/Spinner";
import { sortDataPurchaseOrder, toastOption } from "../../utils/utils";
import {
  changeOrderStatus,
  resetCancel,
} from "../StateSlices/orderCancelSlice";
import { toast } from "react-toastify";

const { beforeToday } = DateRangePicker;

const MyOrders = () => {
  const location = useLocation();
  const dispatch = useDispatch();

  const [page, setPage] = useState(1);
  const [pageChanged, setPageChanged] = useState(false);
  const [data, setData] = useState([]);
  const [spinner, setSpinner] = useState(false);
  const [sortType, setSortType] = useState("newest");
  const [orderStatus, setOrderStatus] = useState("pending");

  const { status, userInfo } = useSelector((state) => state.user);
  const { notificationMessage, notificationInfo } = useSelector(
    (state) => state.notification
  );
  const { cancelStatus, cancelMessage } = useSelector((state) => state.cancel);

  const handlePageChange = (e, value) => {
    if (value !== page) {
      setPageChanged(true);
      setPage(value);
    }
  };

  useEffect(() => {
    if (userInfo) {
      let token = localStorage.getItem("inktoken");
      dispatch(getNotification({ token, filter: "purchase" }));
      setSpinner(true);
    }
  }, [userInfo]);

  useEffect(() => {
    if (notificationInfo) {
      console.log("noti", notificationInfo);
      setData(notificationInfo);
      dispatch(resetNotification());
      setSpinner(false);
    }
  }, [notificationInfo]);

  useEffect(() => {
    setSpinner(true);
    const sortedData = sortDataPurchaseOrder(data, sortType, "both");
    if (sortedData) {
      setData(sortedData);
      setTimeout(() => {
        setSpinner(false);
      }, 2000);
    }
  }, [sortType]);

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    // Clean up the event listener
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleOrderStatus = (status, id, productId) => {
    let token = localStorage.getItem("inktoken");
    dispatch(
      changeOrderStatus({
        token,
        checkoutData: { id, productId, status, user: "buyer" },
      })
    );
    setSpinner(true);
  };

  useEffect(() => {
    if (cancelMessage == "Order Status Change") {
      toast.success("Order Status Updated successfully!", toastOption);
      dispatch(resetCancel());
      let token = localStorage.getItem("inktoken");
      dispatch(getNotification({ token, filter: "purchase" }));
    }
  }, [cancelMessage]);

  return (
    <>
      <Box component={"div"} marginTop={"2rem"}>
        <Box component={"div"} className="tabs-header">
          <Typography variant="h2" className="title mont-title">
            My Orders
          </Typography>
          <Stack direction={"row"}>
            <TabsSorter setSortType={setSortType} />
            <Button
              size="small"
              href="mailto:support@godizel.com"
              variant="contained"
              color="secondary"
              target="_blank"
            >
              Contact Us
            </Button>
          </Stack>
        </Box>
        {spinner ? (
          <Spinner height={"60vh"} />
        ) : data.length == 0 ? (
          <Stack
            direction={"column"}
            justifyContent={"center"}
            alignItems={"center"}
            minHeight={"60vh"}
            width={"100%"}
          >
            <Typography color={"var(--grey-color)"} variant="h3">
              No orders found!
            </Typography>
          </Stack>
        ) : (
          <>
            <Box
              component={"div"}
              margin={"1rem 0"}
              className="purchases-item-con"
            >
              {data.length > 0 &&
                data.map((dt, ind) => (
                  <Box
                    component={"div"}
                    marginTop={".5rem"}
                    marginBottom={"2rem"}
                  >
                    <Stack
                      direction={"row"}
                      columnGap={4}
                      justifyContent={
                        windowWidth < 820 ? "center" : "flex-start"
                      }
                      alignItems={"center"}
                      className="order-purchase-wrap-con"
                    >
                      <Box maxWidth={"400px"}>
                        <Stack
                          direction={"row"}
                          flex={"50%"}
                          justifyContent={"space-evenly"}
                          alignItems={"center"}
                          sx={{ marginBottom: "1rem" }}
                        >
                          {/* <Typography variant="h5" marginRight={"1rem"}>
                            <strong>Status: </strong>
                          </Typography> */}
                          <FormControl
                            variant="standard"
                            fullWidth
                            sx={{ padding: ".5rem" }}
                          >
                            <InputLabel
                              sx={{ padding: ".5rem" }}
                              id="orderStatusField"
                            >
                              Status
                            </InputLabel>
                            <Select
                              color="secondary"
                              labelId="orderStatusField"
                              id="demo-simple-select"
                              // value={orderStatus}
                              defaultValue={
                                dt.order.products.find(
                                  (pd) => pd.productId === dt.productId
                                ).buyerStatus
                                  ? dt.order.products.find(
                                      (pd) => pd.productId === dt.productId
                                    ).buyerStatus
                                  : "pending"
                              }
                              label="Status"
                              onChange={(e) => {
                                handleOrderStatus(
                                  e.target.value,
                                  dt.order._id,
                                  dt.productId
                                );
                              }}
                            >
                              <MenuItem color="secondary" value={"pending"}>
                                Pending
                              </MenuItem>
                              <MenuItem color="secondary" value={"complete"}>
                                Complete
                              </MenuItem>
                              <MenuItem color="secondary" value={"incomplete"}>
                                Incomplete
                              </MenuItem>
                            </Select>
                          </FormControl>
                        </Stack>
                        <ProductCard
                          hideLikeIcon={true}
                          shopPageCardWidth={
                            windowWidth < 1080 && windowWidth > 820
                              ? null
                              : windowWidth < 430
                              ? null
                              : "400px"
                          }
                          product={dt.product}
                          userInfo={userInfo}
                          buttons={["View Details"]}
                        />
                      </Box>
                      <Box
                        sx={{
                          display: windowWidth < 850 && "none",
                          // display: { xs: "none", md: "block" },
                          borderRight: "2px solid var(--black-color)",
                          height: "433px",
                          width: "5px",
                        }}
                      >
                        &nbsp;
                      </Box>
                      <Box
                        width={"400px"}
                        display={"flex"}
                        flexDirection={"column"}
                        justifyContent={"flex-start"}
                        alignItems={"flex-start"}
                      >
                        <Typography variant="h5" my={1}>
                          <strong>Order Id:</strong> {dt.orderId}
                        </Typography>
                        <Typography variant="h5" my={1}>
                          <strong>Order Date:</strong>{" "}
                          {new Date(dt.createdAt).toDateString()}
                        </Typography>
                        <br />
                        <Typography variant="h5" my={1}>
                          <strong>Amount Paid:</strong> $ {dt.pricePaid.toLocaleString("en-US")}
                        </Typography>
                        <Typography variant="h5" my={1}>
                          <strong>Rent Period:</strong>
                          <span style={{ textTransform: "capitalize" }}>
                            &nbsp;
                            {dt.order.products.find(
                              (pd) => pd.productId === dt.productId
                            ).rentalDateRange?.length > 0
                              ? new Date(
                                  dt.order.products.find(
                                    (pd) => pd.productId === dt.productId
                                  ).rentalDateRange[0]
                                ).toDateString() +
                                " - " +
                                new Date(
                                  dt.order.products.find(
                                    (pd) => pd.productId === dt.productId
                                  ).rentalDateRange[1]
                                ).toDateString()
                              : " - "}
                          </span>
                        </Typography>
                        <br />
                        <Typography variant="h5" my={1}>
                          <strong>Purchase type:</strong>
                          <span style={{ textTransform: "capitalize" }}>
                            &nbsp;
                            {dt.order.products.find(
                              (pd) => pd.productId === dt.productId
                            ).type
                              ? dt.order.products.find(
                                  (pd) => pd.productId === dt.productId
                                ).type
                              : "-"}
                          </span>
                        </Typography>
                        <br />
                        <br />
                        {/* <br /> */}
                        {/* <br /> */}
                        <a
                          href={`mailto:${dt.contact}`}
                          style={{ width: "100%" }}
                          target="_blank"
                        >
                          <Button
                            variant="contained"
                            sx={{
                              border: "1px solid var(--white-color)",
                              color: "var(--white-color)",
                              background: "var(--black-color)",
                              width: "100%",
                              "&:hover": {
                                border: "1px solid var(--grey-color)",
                                color: "var(--black-color)",
                                background: "var(--grey-color)",
                              },
                              marginTop: ".5rem",
                            }}
                          >
                            Contact Buyer
                          </Button>
                        </a>
                      </Box>
                    </Stack>
                  </Box>
                ))}
            </Box>
            <Box
              margin={"auto"}
              sx={{ display: "flex", justifyContent: "center" }}
            >
              {/* <Pagination
              count={Math.ceil(products.length / 10)}
              // variant="outlined"
              shape="rounded"
              // variant="text"
              page={page}
              onChange={handlePageChange}
              color="primary"
              sx={{
                padding: "1rem",
                borderRadius: "5px",
                border: "1px solid var(--primary-color)",
                marginTop: "1rem",
              }}
              renderItem={(item) => (
                <PaginationItem
                  slots={{ previous: ArrowBack, next: ArrowForward }}
                  {...item}
                />
              )}
            /> */}
            </Box>
          </>
        )}
      </Box>
    </>
  );
};

export default MyOrders;
