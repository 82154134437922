import React from "react";
import "./ConstructionInfo.css";
import ConstructionIcon from "@mui/icons-material/Construction";
import EngineeringIcon from "@mui/icons-material/Engineering";
import FoundationIcon from "@mui/icons-material/Foundation";
import LocationCityIcon from "@mui/icons-material/LocationCity";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Divider,
  Grid,
  Paper,
  Typography,
} from "@mui/material";
import { ArrowRightAlt, HearingTwoTone } from "@mui/icons-material";
const ConstructionInfo = () => {
  const data = [
    {
      icon: <EngineeringIcon style={{ fontSize: "38px" }} />,
      title: "Price Transparency",
      desc: "Easily compare rental rates and availability from multiple companies with our user-friendly platform. Our service streamlines the rental process, allowing you to quickly and efficiently find the best rental options for your needs. With just a few clicks, you can compare rates and availability from multiple rental companies, saving you time and money.",
      link: "",
    },
    {
      icon: <ConstructionIcon style={{ fontSize: "38px" }} />,
      title: "More Options",
      desc: "Discover an extensive range of equipment options from our rental partners across North America, including nationwide, regional, and local providers. With this diverse network of partners, you'll have access to more rental options than ever before, making it easier to find the equipment you need for your projects.",
      link: "",
    },
    {
      icon: <FoundationIcon style={{ fontSize: "38px" }} />,
      title: "Convenience",
      desc: "Manage your equipment rentals hassle-free with our online platform. Book, track, and manage your orders from any device, anywhere, anytime. Enjoy the flexibility and freedom to manage your rentals on-the-go.",
      link: "",
    },
    {
      icon: <LocationCityIcon style={{ fontSize: "38px" }} />,
      title: "List Your Own Equipment",
      desc: "Maximize the utilization of your equipment and earn extra revenue by renting out your idle machines to contractors in need. Our service allows you to list your equipment for rent risk-free, as we only take a commission when your gear is rented. By renting out your equipment, you'll be able to increase your revenue and reduce downtime, while helping contractors get the equipment they need for their projects.",
      link: "",
    },
  ];
  return (
    <section style={{ margin: "4rem auto" }}>
      <Typography
        className="title"
        variant="h1"
        textAlign={"center"}
        fontWeight={700}
      >
        Why Dizel for Contractors?
      </Typography>
      <Typography
        variant="h4"
        textAlign={"center"}
        color={"text.secondary"}
        sx={{ margin: ".5rem auto", mb: "2rem" }}
      >
        Get seamless advantages of the networking
      </Typography>
      <Grid container spacing={0} justifyContent="space-between" gap={4}>
        {data.map((item, ind) => (
          <Card
            elevation={0}
            sx={{
              width: "550px",
              minHeight: "350px",
              borderRadius: "20px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              padding: "1rem",
              paddingBottom: 0,
            }}
            className="contractor_card"
          >
            <CardContent className="construction-card-content">
              <Typography className="cons-icon" color="text.secondary">
                {item.icon}
              </Typography>
              <Typography
                variant="h4"
                component="div"
                margin={"0px 0px 4px 0px"}
                fontWeight={700}
              >
                {item.title}
              </Typography>
              <Typography variant="h6">{item.desc}</Typography>
            </CardContent>
            <CardActions className="cons-button">
              <Button size="large">
                <ArrowRightAlt
                  sx={{
                    fontSize: "50px",
                    textAlign: "right",
                    color: "#fff",
                  }}
                />
              </Button>
            </CardActions>
          </Card>
        ))}
      </Grid>
      <div className="repeat-icon">{/* <img src={repeatImg} /> */}</div>
    </section>
  );
};

export default ConstructionInfo;
