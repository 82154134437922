import React, { useEffect, useState } from "react";
import PageHeader from "../../components/PageHeader/PageHeader";
import {
  Box,
  Chip,
  Container,
  Grid,
  Pagination,
  PaginationItem,
  Stack,
  Tab,
  Tabs,
  Typography,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Backdrop,
  Button,
  Fade,
  Modal,
  ToggleButton,
  ToggleButtonGroup,
  useTheme,
  useMediaQuery,
  Card,
  Skeleton,
  CardContent,
  CardActions,
} from "@mui/material";
import Filter from "../../components/Filter/Filter";
import ProductCard from "../../components/ProductCard/ProductCard";
import {
  getallProducts,
  reset,
  resetError,
} from "../../components/StateSlices/getproductsSlice";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Spinner from "../../components/Spinner/Spinner";
import propTypes from "prop-types";
import {
  categoies,
  brands,
  transportationOptions,
  subCategories,
} from "../AddNewItem/data";
import "./Shop.css";
import {
  ArrowBack,
  ArrowDownward,
  ArrowDropDown,
  ArrowForward,
  Close,
} from "@mui/icons-material";
import { toast } from "react-toastify";
import {
  addtoCart,
  addtoCartForRental,
  resetCartMessage,
} from "../../components/StateSlices/addtocartSlice";
import { getuserInfo } from "../../components/StateSlices/getuserSlice";
import { DateRangePicker } from "rsuite";
// import '~rsuite/styles/index.less';
// import './rsuitcustom.less';
import "rsuite/dist/rsuite-no-reset.min.css";
import { categoryImagesOld, categoryTitleOld } from "../AddNewItem/data";

import CustomCategoryTab from "./CustomCategoryTab";

import {
  geocodeByAddress,
  getLatLng,
  geocodeByLatLng,
} from "react-google-places-autocomplete";
import { getUserLikes } from "../../components/StateSlices/userLikesSlice";
import { shouldDisableDatee } from "../../utils/utils";

import {
  getProductFields,
  resetFields,
} from "../../components/StateSlices/getProductFields";

// const catMappings = {
//  "65eaba8c9b109ef32be78564": {title: categoryTitleOld['65eaba8c9b109ef32be78564'], img: categoryImagesOld['65eaba8c9b109ef32be78564']},
//   "65eaba8c9b109ef32be7856d": dummy, //fuel tanks
//   "65eaba8c9b109ef32be78569": dummy, //heating
//   "65eaba8c9b109ef32be78565": skidSteers,
//   "65eaba8c9b109ef32be78563": dozers,
//   "65eaba8c9b109ef32be78566": tractors,
//   "65eaba8c9b109ef32be78567": dummy, //trenchers
//   "65eaba8c9b109ef32be78570": dummy, //lifting people
//   "65eaba8c9b109ef32be7856c": generators,
//   "65eaba8c9b109ef32be78568": cooling,
//   "65eaba8c9b109ef32be7856e": dummy, //accessories
//   "65eaba8c9b109ef32be7856a": dummy, //drying
//   "65eaba8c9b109ef32be7856b": dummy, //purifying
//   "65eaba8c9b109ef32be7856f": forklifts, //lifting material
//   "65f33fd67557aba7f912ee92": dummy, //loaders
// };

const categoryIds = [
  "65eaba8c9b109ef32be78564",
  "65eaba8c9b109ef32be7856d",
  "65eaba8c9b109ef32be78569",
  "65eaba8c9b109ef32be78565",
  "65eaba8c9b109ef32be78563",
  "65eaba8c9b109ef32be78566",
  "65eaba8c9b109ef32be78567",
  "65eaba8c9b109ef32be78570",
  "65eaba8c9b109ef32be7856c",
  "65eaba8c9b109ef32be78568",
  "65eaba8c9b109ef32be7856e",
  "65eaba8c9b109ef32be7856a",
  "65eaba8c9b109ef32be7856b",
  "65eaba8c9b109ef32be7856f",
  "65f33fd67557aba7f912ee92",
];

const chipStyle = {
  padding: "1.25rem 1.5rem",
  color: "var(--white-color)",
  background: "var(--black-color)",
  cursor: "pointer",
  transition: ".3s ease",
  borderRadius: "30px",
  marginTop: ".5rem",
  "&:hover": {
    background: "var(--dark-grey-color)",
    transform: "scale(1.05)",
    transition: ".3s ease",
  },
};

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: propTypes.node,
  index: propTypes.number.isRequired,
  value: propTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const Shop = ({ setAddedtoCart }) => {
  const { allowedRange, combine, beforeToday, after, before } = DateRangePicker;
  const navigate = useNavigate();
  const [rentPriceRange, setRentPriceRange] = useState([0, 25000]);
  const [buyPriceRange, setBuyPriceRange] = useState([0, 25000]);
  const [spinner, setSpinner] = useState(true);
  const [productInfo, setProductInfo] = useState([]);
  const [remainingProducts, setRemainingProducts] = useState([]);
  const [categories, setCategories] = useState([
    { id: 1, name: "Electronics", selected: false },
    { id: 2, name: "Clothing", selected: false },
    { id: 3, name: "Books", selected: false },
  ]);

  const location = useLocation();
  const [firstTime, setFirstTime] = useState(true);
  const [categoryFilter, setCategoryFilter] = useState([]);
  const [platforms, setPlatforms] = useState([]);
  const [subCategoryFilter, setSubCategoryFilter] = useState([]);
  const [categorySelected, setCategorySelected] = useState([]);
  const [subCategorySelected, setSubCategorySelected] = useState([]);
  const [brandSelected, setBrandSelected] = useState([]);
  const [brandFilter, setBrandFilter] = useState([]);
  const [page, setPage] = useState(1);
  const [pageChanged, setPageChanged] = useState(false);
  const [clear, setClear] = useState(false);
  const [dateFilter, setDateFilter] = useState([]);
  const [rentPriceFilter, setRentPriceFilter] = useState(false);
  const [buyPriceFilter, setBuyPriceFilter] = useState(false);
  const [valCategories, setValCategories] = useState([]);
  const [valSubCategories, setValSubCategories] = useState([]);
  const [search, setSearch] = useState("");
  const [tabValue, setTabValue] = React.useState(
    location && location.state
      ? location.state.tab === "buy"
        ? ["buy"]
        : ["rent"]
      : ["buy", "rent"]
  );
  const [priceByFilter, setPriceByFilter] = useState("itemDailyPrice");
  const [itemLocTemp, setItemLocTemp] = useState(null);
  const [langLong, setLangLong] = useState({});
  const [milesFilter, setMilesFilter] = useState("");
  const [transportationFilter, setTransportationFilter] = useState([]);
  const [sortFilter, setSortFilter] = useState("newest");
  const [firstLoad, setFirstLoad] = useState(true);

  const [loading, setLoading] = useState(true);
  const [productDetails, setProductDetails] = useState(null);
  const [expandedAccordion, setExpandedAccordion] = React.useState(false);
  const [openRentInfoModal, setOpenRentInfoModal] = useState(false);
  const [checkoutRentalPriceType, setCheckoutRentalPriceType] =
    useState("itemDailyPrice");
  const [checkoutRentalDateRange, setCheckoutRentalDateRange] = useState(null);
  const [PId, setPId] = useState(null);
  const [cartValue, setCartValue] = useState([]);
  const [chooseModel, setChooseModel] = useState(false);
  const { cstatus, message } = useSelector((state) => state.cart);
  const [currentId, setCurrentId] = useState("");
  const [shopLikes, setShopLikes] = useState([]);
  const [goalSelected, setGoalSelected] = useState([]);
  const [categoryNameSelected, setCategoryNameSelected] = useState([]);
  const [subCategoryNameSelected, setSubCategoryNameSelected] = useState([]);
  const [sizeSelected, setSetsizeSelected] = useState([]);
  const [sizeOption, setSetsizeOption] = useState([]);
  const [inputLocValue, setInputLocValue] = useState("");
  const [locationSet, setLocationSet] = useState(false);
  const [platformSelected, setPlatformSelected] = useState([]);
  const [expansions, setExpansions] = useState([]);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };
  const { userInfo } = useSelector((state) => state.user);

  const dispatch = useDispatch();

  const { status, products, error, productCount, filters, remainingItems } =
    useSelector((state) => state.product);

  const { userLikes } = useSelector((state) => state.userlikes);

  let toastOption = {
    position: "top-right",
    autoClose: 1500,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "dark",
  };

  const theme = useTheme();
  const doGridExpansion = useMediaQuery(theme.breakpoints.down("md"));

  // useEffect(()=>{
  //   let tab;
  //   if(location && location.state && location.state.tab==="rent") tab = "rent"
  //   else tab = "buy"
  //   dispatch(getallProducts({tab}));
  // },[location?.state?.tab])

  useEffect(() => {
    const token = localStorage.getItem("inktoken");
    if (token) {
      setSpinner(true);
      dispatch(getUserLikes({ token }));
    }
  }, []);

  const fetchFormFields = (goal, category, subCategory) => {
    // setLoadingForFields(true);
    dispatch(
      getProductFields({
        goal: goal,
        category: category,
        subCategory: subCategory,
      })
    );
  };

  const { fieldsInfo, fieldStatus } = useSelector(
    (state) => state.productfields
  );

  useEffect(() => {
    const queryP = new URLSearchParams(location.search);
    console.log("SetFilter",queryP.get("setfilter"))
    if(queryP.get("setfilter")=="false" || !queryP.get("setfilter")){
      console.log("SetFilter anadar")
      if (userInfo) {
        // console.log("USERINFO",userInfo);
  
        if (userInfo && userInfo.userDetailsId.userAddress) {
          setInputLocValue(userInfo.userDetailsId.userAddress);
          setLocationSet(true);
          setItemLocTemp(userInfo.userDetailsId.userAddress);
          geocodeByAddress(userInfo.userDetailsId.userAddress)
            .then((results) => {
              if (results.length > 0) {
                // console.log("USERINFO",results[0]);
                return getLatLng(results[0]);
              }
            })
            .then(({ lat, lng }) => {
              console.log("Successfully got latitude and longitude", {
                lat,
                lng,
              });
              setLangLong({ lng, lat });
              setMilesFilter("250");
            })
            .catch((error) => console.error(error));
        }
      } else {
        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition(
            (position) => {
              setLocationSet(true);
              const { latitude, longitude } = position.coords;
              console.log(
                "Successfully got latitude and longitude from browser",
                { latitude, longitude }
              );
              setLangLong({ lng: longitude, lat: latitude });
              setMilesFilter("250");
              geocodeByLatLng({ lat: latitude, lng: longitude })
                .then((results) => {
                  if (results.length > 0) {
                    const address = results[0].formatted_address;
                    setInputLocValue(address);
                    setItemLocTemp(address);
                  }
                })
                .catch((error) => console.error(error));
            },
            (error) => console.error(error)
          );
        } else {
          console.error("Geolocation is not supported by this browser.");
        }
      }
    }
    
  }, [userInfo]);

  useEffect(() => {
    if (fieldsInfo) {
      let sizeOption = fieldsInfo.fields.find((field) => field.name === "Size");
      if (sizeOption && sizeOption.options[0] !== "AO Enters") {
        setSetsizeOption(sizeOption.options);
      } else {
        setSetsizeOption([]);
      }
      //
    }
  }, [fieldsInfo]);

  useEffect(() => {
    // debugger;
    const queryParams = new URLSearchParams(location.search);

    const category = queryParams.get("category");
    const subcategory = queryParams.get("subcategory");

    const searchh = queryParams.get("search");

    const locationSearch = queryParams.get("location");

    let goal = queryParams.get("goal");
    const categoryName = queryParams.get("categoryName");
    const subCategoryName = queryParams.get("subCategoryName");
    const size = queryParams.get("size");

    let latLong = {};
    if (locationSearch) {
      geocodeByAddress(locationSearch)
        .then((results) => getLatLng(results[0]))
        .then(({ lat, lng }) => {
          console.log("Successfully got latitude and longitude", {
            lat,
            lng,
          });
          setSearch(searchh);
          setLangLong({ lng, lat });
          setMilesFilter(250);
        })
        .catch((error) => {
          setSearch(searchh);
          console.error("Error", error);
        });
    }

    if (category) {
      let selectctg = categoies.filter(
        (ctg) => ctg.value === category || ctg._id === category
      );
      if (selectctg.length > 0) {
        setCategorySelected([selectctg[0]._id]);
        setValCategories([selectctg[0]]);
      }
    }

    if (subcategory) {
      // debugger;
      let selectsubctg = subCategories.filter((ctg) => ctg._id === subcategory);
      if (selectsubctg.length > 0) {
        setSubCategorySelected([subcategory]);
        setValSubCategories([selectsubctg[0]]);
      }
    }

    if (searchh && !locationSearch) {
      setSearch(searchh);
    }

    if (location && location.state) {
      if (location.state.tab === "buy") {
        setTabValue(["buy"]);
      } else if (location.state.tab === "rent") {
        setTabValue(["rent"]);
      }
    } else {
      setTabValue(["buy", "rent"]);
    }
    if (goal) {
      if (goal === "Lifting People & Material")
        goal = "Lifting People and Material";
      setGoalSelected([goal]);
    }
    if (categoryName) {
      setCategoryNameSelected([categoryName]);
    }
    if (subCategoryName) {
      setSubCategoryNameSelected([subCategoryName]);
    }
    if (size) {
      setSetsizeSelected([size]);
    }
  }, [location]);

  useEffect(() => {
    setSpinner(true);
    if (userLikes && userLikes.length > 0) {
      setShopLikes(userLikes);
      setTimeout(() => {
        setSpinner(false);
      }, 2000);
    }
  }, [userLikes]);

  useEffect(() => {
    console.log("YES SIR I AM THE ONE",goalSelected)
    // debugger;
    if (clear) {
      setClear(false);
    } else {
      let tab;
      console.log("cacacat", categorySelected);

      if (tabValue.includes("buy") && tabValue.includes("rent")) tab = "both";
      else tab = tabValue[0];
      if (firstTime) {
        if (location && location.state && location.state.tab === "rent") {
          tab = "rent";
        } else if (location && location.state && location.state.tab === "buy")
          tab = "buy";
        else tab = "both";
      }

      if (!pageChanged) {
        setPage(1);
      }

      if (firstLoad) {
        setFirstLoad(false);
        const queryParams = new URLSearchParams(location.search);

        const category = queryParams.get("category");
        const subcategory = queryParams.get("category");

        const searchh = queryParams.get("search");
        if (searchh) {
          setSearch(searchh);
        }

        if (category) {
          let selectctg = categoies.filter(
            (ctg) => ctg.value === category || ctg._id === category
          );
          if (selectctg.length == 0) {
            dispatch(
              getallProducts({
                tab,
                categories: categorySelected.join(","),
                subcategories: subCategorySelected.join(","),
                brands: brandSelected.join(","),
                page: pageChanged ? page : 1,
                date: dateFilter.join(","),
                priceFilterType: priceByFilter,
                minRentPriceFilter: rentPriceRange[0],
                maxRentPriceFilter: rentPriceRange[1],
                minBuyPriceFilter: buyPriceRange[0],
                maxBuyPriceFilter: buyPriceRange[1],
                search: search,
                langLong,
                milesFilter,
                transportationFilter,
                sortFilter,
                goal: goalSelected,
                categoryName: categoryNameSelected,
                subCategory: subCategoryNameSelected,
                size: sizeSelected,
                platforms: platformSelected.join(","),
              })
            );
          }
        } else if (subcategory) {
          let selectsubctg = subCategories.filter(
            (ctg) => ctg.value === subcategory || ctg._id === subcategory
          );
          if (selectsubctg.length == 0) {
            dispatch(
              getallProducts({
                tab,
                categories: categorySelected.join(","),
                subcategories: subCategorySelected.join(","),
                brands: brandSelected.join(","),
                page: pageChanged ? page : 1,
                date: dateFilter.join(","),
                priceFilterType: priceByFilter,
                minRentPriceFilter: rentPriceRange[0],
                maxRentPriceFilter: rentPriceRange[1],
                minBuyPriceFilter: buyPriceRange[0],
                maxBuyPriceFilter: buyPriceRange[1],
                search: search,
                langLong,
                milesFilter,
                transportationFilter,
                sortFilter,
                goal: goalSelected,
                categoryName: categoryNameSelected,
                subCategory: subCategoryNameSelected,
                size: sizeSelected,
                platforms: platformSelected.join(","),
              })
            );
          }
        }
      } else {
        const queryParams = new URLSearchParams(location.search);
        const searchh = queryParams.get("search");
        if (searchh) {
          setSearch(searchh);
        }
        dispatch(
          getallProducts({
            tab,
            categories: categorySelected.join(","),
            subcategories: subCategorySelected.join(","),
            brands: brandSelected.join(","),
            page: pageChanged ? page : 1,
            date: dateFilter.join(","),
            priceFilterType: priceByFilter,
            minRentPriceFilter: rentPriceRange[0],
            maxRentPriceFilter: rentPriceRange[1],
            minBuyPriceFilter: buyPriceRange[0],
            maxBuyPriceFilter: buyPriceRange[1],
            langLong,
            search: search ? search : "",
            milesFilter,
            transportationFilter,
            sortFilter,
            goal: goalSelected,
            categoryName: categoryNameSelected,

            subCategory: subCategoryNameSelected,
            size: sizeSelected,
            platforms: platformSelected.join(","),
          })
        );
      }

      setSpinner(true);
      if (firstTime) {
        setFirstTime(false);
      }

      //expansions state
      const exp = ["panel0"];
      if (goalSelected && goalSelected.length > 0) {
        exp.push("panel1");
      }
      if (categoryNameSelected && categoryNameSelected.length > 0) {
        exp.push("panelCategory");
      }
      if (subCategoryNameSelected && subCategoryNameSelected.length > 0) {
        exp.push("panelSubCategory");
      }
      if (sizeSelected && sizeSelected.length > 0) {
        exp.push("panelSize");
      }
      if (brandSelected && brandSelected.length > 0) {
        exp.push("panel2");
      }
      if (platformSelected && platformSelected.length > 0) {
        exp.push("panel3");
      }
      if (dateFilter && dateFilter.length > 0) {
        exp.push("panel4");
      }
      if (milesFilter && milesFilter.length > 0) {
        exp.push("panel5");
      }
      setExpansions(exp);
    }
  }, [
    tabValue,
    categorySelected,
    subCategorySelected,
    brandSelected,
    pageChanged,
    dateFilter,
    rentPriceFilter,
    buyPriceFilter,
    milesFilter,
    transportationFilter,
    sortFilter,
    search,
    goalSelected,
    categoryNameSelected,
    subCategoryNameSelected,
    sizeSelected,
    platformSelected,
  ]);

  useEffect(() => {
    if (pageChanged) {
      setPageChanged(false);
      window.scrollTo(0, 0);
    }
  }, [clear]);

  useEffect(() => {
    if (products.length > 0 || status === "succeeded") {
      if (pageChanged) setClear(true);

      setProductInfo((prev) => [...products]);
      setRemainingProducts(remainingItems);
      let ctg = [];
      let subctg = [];
      let brand = [];
      let pltforms = ["Dizel"];
      // console.log("ketul filters ", filters);
      filters &&
        filters.map((product) => {
          const categoriesToAdd = categoies.filter(
            (cat) =>
              !ctg.find((ct) => ct._id === cat._id) &&
              product.itemCategory?.includes(cat._id)
          );
          ctg.push(...categoriesToAdd);

          const subCategoriesToAdd = subCategories.filter(
            (cat) =>
              !subctg.find((ct) => ct._id === cat._id) &&
              product.itemSubCategory?.includes(cat._id)
          );
          subctg.push(...subCategoriesToAdd);
          const brds = brands.find(
            (brd) =>
              !brand.find((bd) => {
                return bd._id == brd._id;
              }) && brd._id === product.itemBrand
          );
          if (brds) brand.push(brds);
          if (
            !pltforms.includes(product.itemPlatform) &&
            product.itemPlatform &&
            product.itemPlatform !== "Dizel"
          )
            pltforms.push(product.itemPlatform);
        });
      setPlatforms(pltforms);
      setCategoryFilter(ctg);
      setSubCategoryFilter(subctg);
      setBrandFilter(brand);
      dispatch(reset());
      setSpinner(false);
    }

    // dispatch(reset())
  }, [products]);

  useEffect(() => {
    if(productInfo && productInfo.length > 0){
      const queryP = new URLSearchParams(location.search);
      const firstP = productInfo[0]
      if(queryP.get("setfilter") && (goalSelected.length !== 1 || categoryNameSelected.length !==1 || subCategoryNameSelected.length !==1 || goalSelected[0] !== firstP.itemGoal || categoryNameSelected[0] !== firstP.itemCategoryName || subCategoryNameSelected[0] !== firstP.itemSubCategoryName)){
        
        if(firstP.itemGoal === "Lifting people and material"){
          setGoalSelected(["Lifting People & Material"])
        }else{
          setGoalSelected([firstP.itemGoal])
        }
        setCategoryNameSelected([firstP.itemCategoryName])
        setSubCategoryNameSelected([firstP.itemSubCategoryName])
      }
    }
  },[productInfo])

  if (error) {
    setSpinner(false);
    dispatch(resetError());
  }

  const minPriceDistance = 500;
  const handleRentPriceChange = (event, newValue, activeThumb) => {
    if (!Array.isArray(newValue)) {
      return;
    }

    if (activeThumb === 0) {
      setRentPriceRange([
        Math.min(newValue[0], rentPriceRange[1] - minPriceDistance),
        rentPriceRange[1],
      ]);
    } else {
      setRentPriceRange([
        rentPriceRange[0],
        Math.max(newValue[1], rentPriceRange[0] + minPriceDistance),
      ]);
    }
  };

  const handleBuyPriceChange = (event, newValue, activeThumb) => {
    if (!Array.isArray(newValue)) {
      return;
    }

    if (activeThumb === 0) {
      setBuyPriceRange([
        Math.min(newValue[0], buyPriceRange[1] - minPriceDistance),
        buyPriceRange[1],
      ]);
    } else {
      setBuyPriceRange([
        buyPriceRange[0],
        Math.max(newValue[1], buyPriceRange[0] + minPriceDistance),
      ]);
    }
  };

  const handleCategoryChange = (categoryId) => {
    setCategories((prevCategories) =>
      prevCategories.map((category) =>
        category.id === categoryId
          ? { ...category, selected: !category.selected }
          : category
      )
    );
  };

  const handlePageChange = (e, value) => {
    if (value !== page) {
      window.scrollTo(0, 0);
      setPageChanged(true);
      setPage(value);
    }
  };

  const handleSort = (sort) => {
    console.log(sort);
    setSortFilter(sort);
  };

  console.log(productInfo);

  const AddToCart = (id, productType) => {
    if (productType == "rent") {
      setOpenRentInfoModal(true);
      setPId(id);
      return;
    } else {
      //temporary basis LOCAL STORAGE
      let cartItems = localStorage.getItem("cartItems") || [];
      if (cartItems.length > 0) cartItems = JSON.parse(cartItems);
      cartItems.push({ id, itemType: "buy" });
      localStorage.setItem("cartItems", JSON.stringify(cartItems));
      ///////////////////////////////////////////////////////
      let token = localStorage.getItem("inktoken");
      setLoading(true);
      // let cart = [];
      // cart.push(id);
      // sessionStorage.setItem("closetcart", JSON.stringify(cart));
      setCartValue((prev) => [...prev, id]);
      if (userInfo) {
        dispatch(addtoCart({ token, id: { id, itemType: "buy" } }));
      } else {
        setLoading(false);
        setAddedtoCart(true);
        toast.success("Equipment added to cart successfully!", toastOption);
      }

      // setLoading(false);

      // toast.success("Coming Soon", toastOption);
    }
  };

  const handleCheckoutRentalInfo = () => {
    // temporary basis LOCAL STORAGE
    setOpenRentInfoModal(false);
    let cartItems = localStorage.getItem("cartItems") || [];
    if (cartItems.length > 0) cartItems = JSON.parse(cartItems);
    let prodInfo = productInfo[productInfo.findIndex((p) => p._id === PId)];
    if (prodInfo.buyRange) {
      let overlap = prodInfo.buyRange.some((ord) => {
        return (
          (new Date(checkoutRentalDateRange[0]).getTime() >
            new Date(ord.from).getTime() &&
            new Date(checkoutRentalDateRange[0]).getTime() <
              new Date(ord.to).getTime()) ||
          (new Date(checkoutRentalDateRange[1]).getTime() >
            new Date(ord.to).getTime() &&
            new Date(checkoutRentalDateRange[1]).getTime() <
              new Date(ord.to).getTime())
        );
      });
      if (overlap) {
        toast.error(
          "This product is already booked for the selected date range",
          toastOption
        );
        return;
      }
    }

    cartItems.push({
      id: PId,
      itemType: "rent",
      checkoutRentalDateRange,
    });
    localStorage.setItem("cartItems", JSON.stringify(cartItems));
    ///////////////////////////////////////////////////////
    let token = localStorage.getItem("inktoken");
    setLoading(true);
    // let cart = JSON.parse(sessionStorage.getItem("closetcart")) || [];
    // cart.push(PId);
    // sessionStorage.setItem("closetcart", JSON.stringify(cart));
    // setCartVal(cart);
    setCartValue((prev) => [...prev, PId]);
    const payload = { checkoutRentalDateRange, checkoutRentalPriceType };

    // dispatch(addtoCartForRental({ token, PId, payload }));
    if (userInfo) {
      dispatch(
        addtoCart({
          token,
          id: {
            id: PId,
            itemType: "rent",
            checkoutRentalDateRange,
          },
        })
      );
    } else {
      setLoading(false);
      setAddedtoCart(true);
      toast.success("Equipment added to cart successfully!", toastOption);
    }

    // setLoading(false);
    // toast.success("Equipment added to cart successfully!", toastOption);

    // toast.success("Coming Soon", toastOption);
  };

  useEffect(() => {
    if (message) {
      setLoading(false);
      dispatch(resetCartMessage());
      toast.success("Equipment added to cart successfully!", toastOption);
      let token = localStorage.getItem("inktoken");
      setTimeout(() => {
        if (token) {
          dispatch(getuserInfo({ token: token }));
        }
      }, 1000);
    }
  }, [message]);

  const allowedDatesRange = (start, end, buyRange) => {
    if (buyRange) {
      // console.log(combine(start,end))
    }
    return allowedRange(start, end);
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "60%",
    maxWidth: "80%",
    bgcolor: "background.paper",
    boxShadow: 24,
    borderRadius: 2,
    p: 4,
    zIndex: 1,
  };

  return (
    <Container
      maxWidth={"xl"}
      data-aos="fade-in"
      data-aos-duration="1000"
      sx={{ marginTop: { xs: "1rem", md: "2rem" } }}
    >
      {chooseModel && (
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={chooseModel}
          onClose={() => setChooseModel(false)}
          closeAfterTransition
          slots={{ backdrop: Backdrop }}
          slotProps={{
            backdrop: {
              timeout: 500,
            },
          }}
        >
          <Fade in={chooseModel}>
            <Box sx={style} className="checkout-rental-info-modal">
              <Typography
                id="transition-modal-title"
                variant="h3"
                fontWeight={700}
                align="center"
              >
                Choose One Option
              </Typography>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "1rem",
                  justifyContent: "center",
                }}
              >
                <Button
                  type="submit"
                  sx={{ margin: "2rem 0" }}
                  variant="contained"
                  onClick={() => {
                    AddToCart(currentId, "rent");
                    setChooseModel(false);
                  }}
                >
                  Rent this Item
                </Button>
                <Typography
                  variant="h4"
                  fontWeight={"700"}
                  textAlign={"center"}
                  color={"var(--grey-color)"}
                >
                  OR
                </Typography>
                <Button
                  type="submit"
                  sx={{ margin: "2rem 0" }}
                  variant="contained"
                  onClick={() => {
                    AddToCart(currentId, "buy");
                    setChooseModel(false);
                  }}
                >
                  Buy this Item
                </Button>
              </div>
            </Box>
          </Fade>
        </Modal>
      )}

      {openRentInfoModal && (
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={openRentInfoModal}
          onClose={() => setOpenRentInfoModal(false)}
          closeAfterTransition
          slots={{ backdrop: Backdrop }}
          slotProps={{
            backdrop: {
              timeout: 500,
            },
          }}
        >
          <Fade in={openRentInfoModal}>
            <Box sx={style} className="checkout-rental-info-modal">
              <Typography
                id="transition-modal-title"
                variant="h3"
                fontWeight={700}
              >
                Provide Rental Details
              </Typography>
              <Typography id="transition-modal-description" variant="h6">
                Please provide rental information for your selected item for the
                checkout
              </Typography>
              <br />

              <Typography variant="h6" margin={".5rem 0"}>
                Rental Period
              </Typography>
              <DateRangePicker
                defaultOpen
                shouldDisableDate={(date) =>
                  productInfo[productInfo.findIndex((p) => p._id === PId)]
                    .itemAvailability &&
                  shouldDisableDatee(
                    date,
                    productInfo[productInfo.findIndex((p) => p._id === PId)]
                      .itemLeaseFields,
                    productInfo[productInfo.findIndex((p) => p._id === PId)]
                      .buyRange
                  )
                }
                style={{ width: "100%" }}
                onOk={(newValue) => setCheckoutRentalDateRange(newValue)}
              />
              <br />
              <Button
                type="submit"
                sx={{ margin: "2rem 0" }}
                variant="contained"
                onClick={handleCheckoutRentalInfo}
              >
                Add to Cart
              </Button>
            </Box>
          </Fade>
        </Modal>
      )}
      <Grid container>
        <Grid
          md={3}
          lg={2.5}
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          {/* {search && search !== "" && (
            <>
              <Typography variant="h5" margin={"0 .5rem"}>
                <strong>Search for:&nbsp;</strong>
                <i>{search}</i>
              </Typography>

              <Chip
                onClick={() => {
                  setSearch("");
                  navigate(window.location.pathname);
                }}
                sx={{ cursor: "pointer" }}
                label="clear"
              />
            </>
          )} */}
        </Grid>

        <Grid
          container
          md={9}
          lg={9.5}
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
          position={"relative"}
          sx={{ padding: { xs: "0", md: "0 2rem" } }}
        >
          <PageHeader
            title={"All Equipment"}
            desc={`Showing ${(page - 1) * 12 + 1} - ${
              (page - 1) * 12 + productInfo.length
            } equipment out of ${productCount}`}
          />
          <div class="menu-wrap">
            <ul class="menu">
              <li class="menu-item">
                <a href="#">
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <div>
                      <Typography variant="p">Sort by</Typography>
                    </div>
                    <div style={{ lineHeight: 0 }}>
                      <ArrowDropDown />
                    </div>
                  </div>
                </a>
                <ul class="drop-menu">
                  <li
                    class="drop-menu-item"
                    onClick={() => handleSort("newest")}
                    style={
                      sortFilter === "newest"
                        ? { background: "rgba(243, 169, 80, 0.08)" }
                        : {}
                    }
                  >
                    <a
                      href="#"
                      style={
                        sortFilter === "newest"
                          ? { color: "var(--primary-color)" }
                          : {}
                      }
                    >
                      Year: newest to oldest
                    </a>
                  </li>
                  <li
                    class="drop-menu-item"
                    onClick={() => handleSort("oldest")}
                    style={
                      sortFilter === "oldest"
                        ? { background: "rgba(243, 169, 80, 0.08)" }
                        : {}
                    }
                  >
                    <a
                      href="#"
                      style={
                        sortFilter === "oldest"
                          ? { color: "var(--primary-color)" }
                          : {}
                      }
                    >
                      Year: oldest to newest
                    </a>
                  </li>
                  <li
                    class="drop-menu-item"
                    onClick={() => handleSort("recent")}
                    style={
                      sortFilter === "recent"
                        ? { background: "rgba(243, 169, 80, 0.08)" }
                        : {}
                    }
                  >
                    <a
                      href="#"
                      style={
                        sortFilter === "recent"
                          ? { color: "var(--primary-color)" }
                          : {}
                      }
                    >
                      Recently added
                    </a>
                  </li>
                  <li
                    class="drop-menu-item"
                    onClick={() => handleSort("price")}
                    style={
                      sortFilter === "price"
                        ? { background: "rgba(243, 169, 80, 0.08)" }
                        : {}
                    }
                  >
                    <a
                      href="#"
                      style={
                        sortFilter === "price"
                          ? { color: "var(--primary-color)" }
                          : {}
                      }
                    >
                      Price: low to high
                    </a>
                  </li>
                  <li
                    class="drop-menu-item"
                    onClick={() => handleSort("price-desc")}
                    style={
                      sortFilter === "price-desc"
                        ? { background: "rgba(243, 169, 80, 0.08)" }
                        : {}
                    }
                  >
                    <a
                      href="#"
                      style={
                        sortFilter === "price-desc"
                          ? { color: "var(--primary-color)" }
                          : {}
                      }
                    >
                      Price: high to low
                    </a>
                  </li>
                  <li
                    class="drop-menu-item"
                    onClick={() => {
                      if (Object.keys(langLong).length > 0) {
                        handleSort("distance");
                      }
                    }}
                    // style={
                    //   sortFilter === "distance"
                    //     ? { background: "rgba(243, 169, 80, 0.08)" }
                    //     : {}
                    // }
                    style={
                      Object.keys(langLong).length > 0
                        ? sortFilter === "price-desc"
                          ? { color: "var(--primary-color)" }
                          : {}
                        : {
                            background: "rgb(206 202 202)",
                            cursor: "not-allowed",
                          }
                    }
                    title={
                      Object.keys(langLong).length > 0
                        ? ""
                        : "Please Select the Location"
                    }
                  >
                    <a
                      href="#"
                      style={
                        Object.keys(langLong).length > 0
                          ? sortFilter === "distance"
                            ? { color: "var(--primary-color)" }
                            : {}
                          : { cursor: "not-allowed" }
                      }
                    >
                      Sort by Distance
                    </a>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </Grid>
      </Grid>

      <Grid
        container
        maxWidth={"100%"}
        minHeight={`calc(100vh - 69px)`}
        marginTop={".05rem"}
        paddingBottom={"2rem"}
        justifyContent={"center"}
      >
        <Grid
          xs={12}
          md={2.5}
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          <Filter
            categories={categories}
            rentPriceRange={rentPriceRange}
            setRentPriceRange={setRentPriceRange}
            buyPriceRange={buyPriceRange}
            setBuyPriceRange={setBuyPriceRange}
            handleRentPriceChange={handleRentPriceChange}
            handleBuyPriceChange={handleBuyPriceChange}
            handleCategoryChange={handleCategoryChange}
            setTabValue={setTabValue}
            tabValue={tabValue}
            categoryFilter={categoryFilter}
            subCategoryFilter={subCategoryFilter}
            brandFilter={brandFilter}
            setCategorySelected={setCategorySelected}
            setSubCategorySelected={setSubCategorySelected}
            brandSelected={brandSelected}
            setBrandSelected={setBrandSelected}
            dateFilter={dateFilter}
            setDateFilter={setDateFilter}
            setPriceByFilter={setPriceByFilter}
            priceByFilter={priceByFilter}
            setRentPriceFilter={setRentPriceFilter}
            setBuyPriceFilter={setBuyPriceFilter}
            categorySelected={categorySelected}
            subCategorySelected={subCategorySelected}
            setValCategories={setValCategories}
            valCategories={valCategories}
            valSubCategories={valSubCategories}
            setValSubCategories={setValSubCategories}
            setLangLong={setLangLong}
            itemLocTemp={itemLocTemp}
            setItemLocTemp={setItemLocTemp}
            milesFilter={milesFilter}
            setMilesFilter={setMilesFilter}
            langLong={langLong}
            transportationFilter={transportationFilter}
            setTransportationFilter={setTransportationFilter}
            transportationOptions={transportationOptions}
            search={search}
            setSearch={setSearch}
            goalSelected={goalSelected}
            setGoalSelected={setGoalSelected}
            categoryNameSelected={categoryNameSelected}
            setCategoryNameSelected={setCategoryNameSelected}
            subCategoryNameSelected={subCategoryNameSelected}
            setSubCategoryNameSelected={setSubCategoryNameSelected}
            fetchFormFields={fetchFormFields}
            sizeOption={sizeOption}
            setSetsizeSelected={setSetsizeSelected}
            sizeSelected={sizeSelected}
            setInputLocValue={setInputLocValue}
            inputLocValue={inputLocValue}
            userInfo={userInfo}
            locationSet={locationSet}
            platforms={platforms}
            platformSelected={platformSelected}
            setPlatformSelected={setPlatformSelected}
            expansions={expansions}
          />
        </Grid>
        <Grid md={9.5} xs={12}>
          <Container disableGutters>
            <Grid
              container
              sx={{
                margin: "2.5rem 0",
                justifyContent: {
                  xs: "center",
                  sm: "space-evenly",
                  md: "space-evenly",
                  lg: "space-evenly",
                },
              }}
              style={{ position: "relative" }}
              columnGap={2}
              rowGap={3}
              data-aos="fade-up"
              data-aos-duration="500"
            >
              {spinner ? (
                // <div style={{ minHeight: "80vh" }}>
                // <Spinner />
                <Grid
                  container
                  sx={{
                    // margin: "2.5rem 0",
                    justifyContent: {
                      xs: "center",
                      sm: "space-evenly",
                      md: "space-evenly",
                      lg: "space-evenly",
                    },
                  }}
                  style={{ position: "relative" }}
                  columnGap={2}
                  rowGap={3}
                >
                  {Array.from(new Array(20)).map((item, ind) => (
                    <Card
                      key={ind}
                      sx={{ width: 350, border: "1px solid #f1f1f1" }}
                      elevation={0}
                    >
                      <Skeleton
                        variant="rectangular"
                        width="100%"
                        height={200}
                        animation={"wave"}
                      />
                      <CardContent sx={{ marginTop: ".5rem" }}>
                        <Skeleton
                          variant="text"
                          width="80%"
                          height={32}
                          animation={"wave"}
                        />
                        <Skeleton
                          variant="text"
                          width="40%"
                          height={24}
                          animation={"wave"}
                        />
                      </CardContent>
                      <CardActions>
                        <Skeleton
                          animation={"wave"}
                          variant="rectangular"
                          width={"100%"}
                          sx={{ margin: "0 8px", borderRadius: 1 }}
                          height={36}
                        />
                      </CardActions>
                    </Card>
                  ))}
                </Grid>
              ) : (
                // </div>
                // </div>
                ""
              )}

              {!spinner && productInfo.length > 0 ? (
                <>
                  {categorySelected.length > 1 && (
                    <Stack
                      component={"div"}
                      width={"100%"}
                      direction={"row"}
                      alignItems={"flex-start"}
                      justifyContent={"flex-start"}
                      data-aos="fade-in"
                      flexWrap={"wrap"}
                      sx={{ gap: { xs: "12px", md: "20px" } }}
                      margin={"0 .5rem"}
                    >
                      <CustomCategoryTab
                        categorySelected={categorySelected}
                        productsInfo={productInfo}
                        // catMappings={{
                        //   categorySelected: {
                        //     title: categoryTitleOld[categorySelected],
                        //     img: categoryImagesOld[categorySelected],
                        //   },
                        // }}
                      />
                    </Stack>
                  )}
                  {categorySelected.length === 1 && (
                    <Stack
                      component={"div"}
                      width={"100%"}
                      direction={"row"}
                      alignItems={"flex-start"}
                      justifyContent={"space-between"}
                      data-aos="fade-in"
                      gap={"30px"}
                      margin={"1rem"}
                      sx={{ flexWrap: { xs: "wrap", md: "nowrap" } }}
                    >
                      <Box
                        component={"div"}
                        maxWidth={"250px"}
                        className="category-image-card"
                        marginTop={0}
                        maxHeight={"250px"}
                        margin={0}
                      >
                        <img
                          src={categoryImagesOld[categorySelected]}
                          alt="category"
                          width={"100%"}
                          height={"100%"}
                          style={{ borderRadius: "20px" }}
                        />
                      </Box>
                      <Box component={"div"} flex={"70%"}>
                        <Typography variant="h4" fontWeight={700}>
                          Equipment from $1000 to $50000
                        </Typography>
                        <Box
                          component={"div"}
                          marginTop={"2rem"}
                          className="other-products"
                        >
                          {productInfo.slice(0, 5).map((item, ind) => (
                            <Stack
                              direction={"row"}
                              justifyContent={"space-between"}
                              margin={"1rem auto"}
                              flexWrap={"wrap"}
                            >
                              <Typography
                                variant="h5"
                                marginTop={".5rem"}
                                width={"375px"}
                              >
                                {item.itemName}
                              </Typography>
                              <Box component={"div"}>
                                <Chip
                                  sx={{
                                    ...chipStyle,
                                    marginRight: "1rem",
                                    "&:hover": {
                                      background: "var(--black-color)",
                                      transform: "none",
                                      cursor: "default",
                                    },
                                    minWidth: "250px",
                                  }}
                                  label={
                                    item.itemToSell
                                      ? item.itemAmount.toLocaleString("en-US")
                                      : item.itemWeeklyPrice &&
                                        item.itemWeeklyPrice !== 0
                                      ? "Rent for $" +
                                        item.itemWeeklyPrice.toLocaleString(
                                          "en-US"
                                        ) +
                                        " per week"
                                      : item.itemDailyPrice &&
                                        item.itemDailyPrice !== 0
                                      ? "Rent for $" +
                                        item.itemDailyPrice.toLocaleString(
                                          "en-US"
                                        ) +
                                        " per day"
                                      : "Rent for $" + item.itemMonthlyPrice &&
                                        item.itemMonthlyPrice.toLocaleString(
                                          "en-US"
                                        ) + " per month"
                                  }
                                />
                                <Link
                                  to={"/product/" + item._id}
                                  state={{ id: item._id }}
                                >
                                  <Chip sx={chipStyle} label={"View"} />
                                </Link>
                              </Box>
                            </Stack>
                          ))}
                          <Link
                            to={
                              "/category/" +
                              categoryTitleOld[categorySelected[0]].replace(
                                " ",
                                "-"
                              )
                            }
                            state={{
                              products: productInfo,
                              catImg: categoryImagesOld[categorySelected[0]],
                              catTitle: categoryTitleOld[categorySelected[0]],
                            }}
                          >
                            <Chip
                              sx={{ ...chipStyle, textTransform: "capitalize" }}
                              label={`View More ${
                                categoryTitleOld[categorySelected[0]] || "Other"
                              } Equipment`}
                            />
                          </Link>
                        </Box>
                      </Box>
                    </Stack>
                  )}
                  {productInfo.map((product) => (
                    <Box
                      data-aos="fade-in"
                      data-aos-duration="500"
                      key={product._id}
                    >
                      <ProductCard
                        shopLikes={shopLikes}
                        setShopLikes={setShopLikes}
                        shopPageCardWidth={"350px"}
                        shopPageCardHeight={"200px"}
                        product={product}
                        userInfo={userInfo}
                        AddToCart={AddToCart}
                        setChooseModel={setChooseModel}
                        setCurrentId={setCurrentId}
                        priceByFilter={priceByFilter}
                        // buttons={
                        //   product.merchantFeed
                        //     ? ["View Details", "Add to Cart Merchant"]
                        //     : ["View Details", "Add to Cart"]
                        // }
                      />
                    </Box>
                  ))}
                </>
              ) : (
                <Box
                  minHeight={"30vh"}
                  display={"flex"}
                  justifyContent={"center"}
                  alignItems={"center"}
                >
                  <Typography
                    variant="h4"
                    fontWeight={700}
                    color={"var(--black-color)"}
                  >
                    No Equipment Found{" "}
                    {Object.keys(langLong).length > 0 &&
                      milesFilter &&
                      "Nearby"}
                  </Typography>
                </Box>
              )}
              {/* {productInfo.length < 0 && (
                  <Box
                    minHeight={"80vh"}
                    display={"flex"}
                    justifyContent={"center"}
                    alignItems={"center"}
                  >
                    <Typography
                      variant="h3"
                      fontWeight={700}
                      color={"var(--black-color)"}
                    >
                      End of Equipment Stock
                    </Typography>
                  </Box>
                )} */}
            </Grid>
            {remainingProducts.length > 0 && (
              <>
                <Box padding={"0 2rem"}>
                  <PageHeader title={"Similar Items"} />
                </Box>
                <Grid
                  container
                  sx={{
                    margin: "2.5rem 0",
                    justifyContent: {
                      xs: "center",
                      sm: "space-evenly",
                      md: "space-evenly",
                      lg: "space-evenly",
                    },
                  }}
                  style={{ position: "relative" }}
                  columnGap={2}
                  rowGap={3}
                  data-aos="fade-up"
                  data-aos-duration="500"
                >
                  {spinner ? (
                    // <div style={{ minHeight: "80vh" }}>
                    // <Spinner />
                    <Grid
                      container
                      sx={{
                        // margin: "2.5rem 0",
                        justifyContent: {
                          xs: "center",
                          sm: "space-evenly",
                          md: "space-evenly",
                          lg: "space-evenly",
                        },
                      }}
                      style={{ position: "relative" }}
                      columnGap={2}
                      rowGap={3}
                    >
                      {Array.from(new Array(20)).map((item, ind) => (
                        <Card
                          key={ind}
                          sx={{ width: 350, border: "1px solid #f1f1f1" }}
                          elevation={0}
                        >
                          <Skeleton
                            variant="rectangular"
                            width="100%"
                            height={200}
                            animation={"wave"}
                          />
                          <CardContent sx={{ marginTop: ".5rem" }}>
                            <Skeleton
                              variant="text"
                              width="80%"
                              height={32}
                              animation={"wave"}
                            />
                            <Skeleton
                              variant="text"
                              width="40%"
                              height={24}
                              animation={"wave"}
                            />
                          </CardContent>
                          <CardActions>
                            <Skeleton
                              animation={"wave"}
                              variant="rectangular"
                              width={"100%"}
                              sx={{ margin: "0 8px", borderRadius: 1 }}
                              height={36}
                            />
                          </CardActions>
                        </Card>
                      ))}
                    </Grid>
                  ) : (
                    // </div>
                    // </div>
                    ""
                  )}

                  {!spinner && remainingProducts.length > 0 ? (
                    <>
                      {categorySelected.length > 1 && (
                        <Stack
                          component={"div"}
                          width={"100%"}
                          direction={"row"}
                          alignItems={"flex-start"}
                          justifyContent={"flex-start"}
                          data-aos="fade-in"
                          flexWrap={"wrap"}
                          sx={{ gap: { xs: "12px", md: "20px" } }}
                          margin={"0 .5rem"}
                        >
                          <CustomCategoryTab
                            categorySelected={categorySelected}
                            productsInfo={remainingProducts}
                            // catMappings={{
                            //   categorySelected: {
                            //     title: categoryTitleOld[categorySelected],
                            //     img: categoryImagesOld[categorySelected],
                            //   },
                            // }}
                          />
                        </Stack>
                      )}
                      {categorySelected.length === 1 && (
                        <Stack
                          component={"div"}
                          width={"100%"}
                          direction={"row"}
                          alignItems={"flex-start"}
                          justifyContent={"space-between"}
                          data-aos="fade-in"
                          gap={"30px"}
                          margin={"1rem"}
                          sx={{ flexWrap: { xs: "wrap", md: "nowrap" } }}
                        >
                          <Box
                            component={"div"}
                            maxWidth={"250px"}
                            className="category-image-card"
                            marginTop={0}
                            maxHeight={"250px"}
                            margin={0}
                          >
                            <img
                              src={categoryImagesOld[categorySelected]}
                              alt="category"
                              width={"100%"}
                              height={"100%"}
                              style={{ borderRadius: "20px" }}
                            />
                          </Box>
                          <Box component={"div"} flex={"70%"}>
                            <Typography variant="h4" fontWeight={700}>
                              Equipment from $1000 to $50000
                            </Typography>
                            <Box
                              component={"div"}
                              marginTop={"2rem"}
                              className="other-products"
                            >
                              {remainingProducts
                                .slice(0, 5)
                                .map((item, ind) => (
                                  <Stack
                                    direction={"row"}
                                    justifyContent={"space-between"}
                                    margin={"1rem auto"}
                                    flexWrap={"wrap"}
                                  >
                                    <Typography
                                      variant="h5"
                                      marginTop={".5rem"}
                                      width={"375px"}
                                    >
                                      {item.itemName}
                                    </Typography>
                                    <Box component={"div"}>
                                      <Chip
                                        sx={{
                                          ...chipStyle,
                                          marginRight: "1rem",
                                          "&:hover": {
                                            background: "var(--black-color)",
                                            transform: "none",
                                            cursor: "default",
                                          },
                                          minWidth: "250px",
                                        }}
                                        label={
                                          item.itemToSell
                                            ? item.itemAmount.toLocaleString(
                                                "en-US"
                                              )
                                            : item.itemWeeklyPrice &&
                                              item.itemWeeklyPrice !== 0
                                            ? "Rent for $" +
                                              item.itemWeeklyPrice.toLocaleString(
                                                "en-US"
                                              ) +
                                              " per week"
                                            : item.itemDailyPrice &&
                                              item.itemDailyPrice !== 0
                                            ? "Rent for $" +
                                              item.itemDailyPrice.toLocaleString(
                                                "en-US"
                                              ) +
                                              " per day"
                                            : "Rent for $" +
                                                item.itemMonthlyPrice &&
                                              item.itemMonthlyPrice.toLocaleString(
                                                "en-US"
                                              ) + " per month"
                                        }
                                      />
                                      <Link
                                        to={"/product/" + item._id}
                                        state={{ id: item._id }}
                                      >
                                        <Chip sx={chipStyle} label={"View"} />
                                      </Link>
                                    </Box>
                                  </Stack>
                                ))}
                              <Link
                                to={
                                  "/category/" +
                                  categoryTitleOld[categorySelected[0]].replace(
                                    " ",
                                    "-"
                                  )
                                }
                                state={{
                                  products: productInfo,
                                  catImg:
                                    categoryImagesOld[categorySelected[0]],
                                  catTitle:
                                    categoryTitleOld[categorySelected[0]],
                                }}
                              >
                                <Chip
                                  sx={{
                                    ...chipStyle,
                                    textTransform: "capitalize",
                                  }}
                                  label={`View More ${
                                    categoryTitleOld[categorySelected[0]] ||
                                    "Other"
                                  } Equipment`}
                                />
                              </Link>
                            </Box>
                          </Box>
                        </Stack>
                      )}
                      {remainingProducts.map((product) => (
                        <Box
                          data-aos="fade-in"
                          data-aos-duration="500"
                          key={product._id}
                        >
                          <ProductCard
                            shopLikes={shopLikes}
                            setShopLikes={setShopLikes}
                            shopPageCardWidth={"350px"}
                            shopPageCardHeight={"200px"}
                            product={product}
                            userInfo={userInfo}
                            AddToCart={AddToCart}
                            setChooseModel={setChooseModel}
                            setCurrentId={setCurrentId}
                            priceByFilter={priceByFilter}
                            // buttons={
                            //   product.merchantFeed
                            //     ? ["View Details", "Add to Cart Merchant"]
                            //     : ["View Details", "Add to Cart"]
                            // }
                          />
                        </Box>
                      ))}
                    </>
                  ) : (
                    <Box
                      minHeight={"75vh"}
                      display={"flex"}
                      justifyContent={"center"}
                      alignItems={"center"}
                    >
                      <Typography
                        variant="h3"
                        fontWeight={700}
                        color={"var(--black-color)"}
                      >
                        No Equipment Found
                      </Typography>
                    </Box>
                  )}
                  {/* {productInfo.length < 0 && (
                        <Box
                          minHeight={"80vh"}
                          display={"flex"}
                          justifyContent={"center"}
                          alignItems={"center"}
                        >
                          <Typography
                            variant="h3"
                            fontWeight={700}
                            color={"var(--black-color)"}
                          >
                            End of Equipment Stock
                          </Typography>
                        </Box>
                      )} */}
                </Grid>
              </>
            )}

            {spinner ? (
              ""
            ) : (
              <>
                {productCount > 5 && (
                  <Stack
                    spacing={2}
                    direction={"row"}
                    justifyContent={"center"}
                    sx={{ margin: { xs: "1rem 0", md: "2rem 1rem" } }}
                  >
                    <Pagination
                      count={Math.ceil(productCount / 12)}
                      // variant="outlined"
                      shape="rounded"
                      // variant="text"
                      page={page}
                      onChange={handlePageChange}
                      color="secondary"
                      siblingCount={1}
                      sx={{
                        padding: "1rem 0rem",
                        borderRadius: "5px",
                        border: "1px solid var(--black-color)",
                      }}
                      renderItem={(item) => (
                        <PaginationItem
                          slots={{ previous: ArrowBack, next: ArrowForward }}
                          {...item}
                        />
                      )}
                    />
                  </Stack>
                )}
              </>
            )}
          </Container>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Shop;
