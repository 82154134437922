import React, { useState, useEffect, useRef } from "react";
import { useFormik, Formik } from "formik";
import Founder from "../components/Founder/Founder";
import MembershipSelect from "../components/MembershipSelect/MembershipSelect";
import LoginForm from "../components/LoginForm/LoginForm";
import { useDispatch, useSelector } from "react-redux";
import { loginUser, reset } from "../components/StateSlices/loginSlice";
import { reset as userInfoRest } from "../components/StateSlices/getuserSlice";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import Spinner from "../components/Spinner/Spinner";
import {
  addtoCart,
  resetCartMessage,
} from "../components/StateSlices/addtocartSlice";
import {
  getuserInfo,
  resetStatus,
} from "../components/StateSlices/getuserSlice";

import "react-toastify/dist/ReactToastify.css";

const Login = () => {
  const [membership, setMembership] = useState("free");
  const [tc, setTc] = useState(false);
  const [spinner, setSpinner] = useState(false);
  const [provider, setProvider] = useState("email");
  const [pEmail, setPEmail] = useState("");
  const [LoggedIn, setLoggedIn] = useState(false);
  const formRef = useRef();

  let navigate = useNavigate();
  const dispatch = useDispatch();
  const { status, userLogedIn, error, userFullInfo } = useSelector(
    (state) => state.login
  );
  const { message } = useSelector((state) => state.cart);
  const { userInfo, profile } = useSelector((state) => state.user);

  let toastOption = {
    position: "top-right",
    autoClose: 1500,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "dark",
  };
  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    onSubmit: (values) => {
      console.log(values);
      let payload = {};
      if (provider === "email") {
        payload = { ...values, email: values.email.toLowerCase(), provider };
      } else {
        payload = { email: pEmail.toLowerCase(), provider };
      }
      console.log(payload);
      setSpinner(true);
      dispatch(loginUser(payload));
    },
  });

  // if (userLogedIn) {
  //   let sessionCart = JSON.parse(sessionStorage.getItem("closetcart"));
  //   if (sessionCart) {
  //     dispatch(
  //       addtoCart({
  //         id: sessionCart,
  //         token: localStorage.getItem("inktoken"),
  //         arr: true,
  //       })
  //     );
  //   } else {
  //     dispatch(
  //       addtoCart({
  //         id: [],
  //         token: localStorage.getItem("inktoken"),
  //         arr: true,
  //       })
  //     );
  //     sessionStorage.setItem("closetcart", JSON.stringify([]));
  //   }
  //   dispatch(reset());
  // }

  if (userLogedIn) {
    toast.success("User Succesfully LoggedIn", toastOption);
    setProvider("email");
    setPEmail("");

    let token = localStorage.getItem("inktoken");
    // console.log(userFullInfo)
    dispatch(getuserInfo({ token: token }));
    dispatch(reset());
    // setCartValue(cartItems.map((item) => item.id));
    // navigate("/");
    // setTimeout(() => {

    // }, 5000);
  }

  useEffect(() => {
    if (userInfo) {
      console.log("ECECUTINNG");
      console.log(userInfo);
      let cartItems = localStorage.getItem("cartItems") || [];
      if (cartItems.length > 0) cartItems = JSON.parse(cartItems);

      let userDBCart =
        userInfo && userInfo.userProductsInfo
          ? userInfo.userProductsInfo.cartId
          : [];

      // check the values of both cartItems and userDBCart and if they are different, update the cartItems ans push it into localstorage both is array of object
      // console.log(userDBCart,userInfo)
      let userDBCartIds = userDBCart.map((item) => item.id);
      console.log("USER DB", userDBCartIds);

      // Filter out items present in cartItems but not in userDBCart
      let itemsOnlyInCartItems = cartItems.filter((item) => {
        return (
          !userDBCartIds.includes(item.id) &&
          !userInfo.userProducts.some((a) => a._id === item.id)
        );
      });

      console.log("ONLY CART", itemsOnlyInCartItems);
      if (itemsOnlyInCartItems.length > 0) {
        let token = localStorage.getItem("inktoken");
        cartItems = [...userDBCart, ...itemsOnlyInCartItems];
        localStorage.setItem("cartItems", JSON.stringify(cartItems));
        dispatch(addtoCart({ token, id: itemsOnlyInCartItems, arr: true }));
      } else if (itemsOnlyInCartItems.length == 0 && userDBCartIds.length > 0) {
        cartItems = userDBCart;
        localStorage.setItem("cartItems", JSON.stringify(cartItems));
        dispatch(resetCartMessage());
        navigate("/");
        // let token = localStorage.getItem("inktoken");
        // dispatch(addtoCart({ token, id: userDBCartIds, arr: true }));
      } else {
        cartItems = [];
        localStorage.setItem("cartItems", JSON.stringify(cartItems));
        dispatch(resetCartMessage());
        navigate("/");
      }
    }
  }, [userInfo]);

  useEffect(() => {
    if (message) {
      dispatch(resetCartMessage());
      // toast.success("Equipment added to cart successfully!", toastOption);
      let token = localStorage.getItem("inktoken");
      if (token) {
        dispatch(getuserInfo({ token: token }));
      }
      navigate("/");
    }
  }, [message]);

  if (error) {
    console.log(error);
    if (error.indexOf("Banned") > -1 || error.indexOf("Paused") > -1) {
      toastOption = {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      };
      // let z = document.createElement('div')
      // z.innerHTML = error
      toast.error(
        ({ closeToast }) => <div dangerouslySetInnerHTML={{ __html: error }} />,
        toastOption
      );
    } else {
      toast.error(
        error.slice(
          0,
          error.indexOf("?") === -1 ? error.length : error.indexOf("?")
        ),
        toastOption
      );
    }

    setProvider("email");
    setPEmail("");
    dispatch(reset());
    setSpinner(false);
    if (error.includes("Password Reset Required")) {
      setTimeout(() => {
        navigate(`/resetpassword/${error.slice(error.indexOf("=") + 1)}`);
      }, 2000);
    }
  }

  // useEffect(() => {
  //   return () => {
  //     formik.setFieldValue(
  //       'membership',membership
  //     );

  //     setTimeout(() => formik.setFieldTouched(membership, true));
  //   }
  // }, [membership])

  return (
    <div
      className="page-container"
      style={{ position: "relative", padding: "2rem auto" }}
    >
      {spinner ? <Spinner /> : ""}
      <div className="signup">
        {/* <ToastContainer
          position="bottom-left"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        /> */}
        {/* <div style={{ width: "30%" }}>
          <Founder />
        </div> */}
        <div className="signup_right">
          {/* <MembershipSelect membership={membership} formik={formik} setMembership={setMembership}/> */}
          <Formik
            initialValues={{
              email: "",
              password: "",
            }}
            onSubmit={(values, actions) => {
              console.log(values);
              dispatch(userInfoRest());
              let payload = {};
              if (provider === "email") {
                payload = {
                  ...values,
                  email: values.email.toLowerCase(),
                  provider,
                };
              } else {
                payload = { email: pEmail.toLowerCase(), provider };
              }

              setSpinner(true);
              dispatch(loginUser(payload));
            }}
            innerRef={formRef}
            render={(props) => (
              <LoginForm
                setTc={setTc}
                status={status}
                setProvider={setProvider}
                {...props}
                setPEmail={setPEmail}
                setSpinner={setSpinner}
              />
            )}
          />
        </div>
      </div>
    </div>
  );
};

export default Login;
