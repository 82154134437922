import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
  orderstatus: "idle",
  orderInfo: [],
  error: null,
  orderHistoryStatusMessage:null
};

export const getSoldProducts = createAsyncThunk(
  "product/getsoldorders",
  async ({ token }, { rejectWithValue }) => {
    try {
      const { data } = await axios.get(`/api/checkout/getsoldproducts`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const seenSoldOrders = createAsyncThunk(
  "product/seenSoldOrders",
  async ({ token,ordersId }, { rejectWithValue }) => {
    try {
      const { data } = await axios.post(`/api/checkout/seensoldorders`,{
        ordersId
      }, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const soldOrdersSlice = createSlice({
  name: "soldorders",
  initialState,
  reducers: {
    reseto(state, action) {
      // state.orderInfo = [];
      // state.orderstatus = "idle";
      state.orderHistoryStatusMessage=null;
    },
    resetSold(state,action){
      state.orderstatus = "idle"
      state.orderInfo = []
      state.error = null
    }
  },
  extraReducers: {
    [getSoldProducts.pending]: (state, action) => {
      state.orderstatus = "loading";
    },
    [getSoldProducts.fulfilled]: (state, action) => {
      state.orderstatus = "succeeded";
      state.orderInfo = action.payload.sellingInfo;
    },
    [getSoldProducts.rejected]: (state, action) => {
      state.orderstatus = "failed";
      state.error = action.payload.message;
    },
    [seenSoldOrders.pending]: (state, action) => {
      state.orderstatus = "loading";
    },
    [seenSoldOrders.fulfilled]: (state, action) => {
      state.orderstatus = "succeeded";
      state.orderHistoryStatusMessage = action.payload.message
      // state.orderInfo = action.payload.sellingInfo;
    },
    [seenSoldOrders.rejected]: (state, action) => {
      state.orderstatus = "failed";
      state.error = action.payload.message;
    },
  },
});

export const { reseto,resetSold } = soldOrdersSlice.actions;
export default soldOrdersSlice.reducer;
