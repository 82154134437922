import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
  ratingStatus: "idle",
  ratingInfo: null,
  ratingError: null,
};

export const closetrating = createAsyncThunk(
  "product/rating/closet",
  async ({ id, token, rating }, { rejectWithValue }) => {
    try {
      const { data } = await axios.get(
        `/api/product/rating/closet/${id}/${rating}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);
export const closetratingSlice = createSlice({
  name: "closetRating",
  initialState,
  reducers: {
    resetItem(state, action) {
      state.ratingInfo = null;
      state.ratingStatus = "idle";
      state.ratingError = null;
    },
  },
  extraReducers: {
    [closetrating.pending]: (state, action) => {
      state.ratingStatus = "loading";
    },
    [closetrating.fulfilled]: (state, action) => {
      state.ratingStatus = "succeeded";
      state.ratingInfo = action.payload.ratingInfo;
    },
    [closetrating.rejected]: (state, action) => {
      state.ratingStatus = "failed";
      state.ratingError = action.payload.message;
    },
  },
});

export const { resetItem } = closetratingSlice.actions;
export default closetratingSlice.reducer;
