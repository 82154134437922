import React, { useRef, useState } from "react";
import {
  Container,
  Typography,
  InputBase,
  Grid,
  Card,
  CardContent,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
  Paper,
  CardActions,
  Button,
  Tabs,
  Tab,
  styled,
  TextField,
  InputAdornment,
  Stack,
} from "@mui/material";
import {
  Search as SearchIcon,
  ExpandMore as ExpandMoreIcon,
  Search,
  SearchRounded,
} from "@mui/icons-material";
import propTypes from "prop-types";

import ConstructionIcon from "@mui/icons-material/Construction";
import EngineeringIcon from "@mui/icons-material/Engineering";
import FoundationIcon from "@mui/icons-material/Foundation";
import { Link, useNavigate } from "react-router-dom";
import faqIcon1 from "../../assets/faq-icon-1.png";
import faqIcon2 from "../../assets/faq-icon-2.png";
import faqIcon3 from "../../assets/faq-icon-3.png";
import faqBg from "../../assets/landing-bg-2.png";
import "./FAQ.css";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box
          sx={{ p: 3 }}
          maxWidth={"100%"}
          display={"block"}
          minHeight={"350px"}
        >
          <Typography fontWeight={700} mb={2} variant="h3">
            {children}
          </Typography>
          {index === 0 && (
            <>
              <Typography textAlign={"justify"}>
                To start renting equipment, create an account and validate email
                for approval. Once approved, you can search for equipment for
                either rent or purchase. You can search by price, category,
                brand, transportation, date range and location. Add machines to
                your cart, select attachments, and specify rental dates. You can
                modify rental dates and transportation information later during
                checkout. During checkout, you can review rental terms and add
                transportation details. Payment methods require a credit card. A
                commercial liability insurance policy is required to be
                uploaded, and rental confirmation typically takes a few hours.
                You'll receive email notifications during the transport of your
                equipment.
              </Typography>
              <Box component={"div"} margin={"1rem 0"}>
                {/* <Button variant="outlined">Link 1</Button> */}
                <Link to={"/shop"} state={{ tab: "rent" }}>
                  <Button variant="outlined">Rent Equipment</Button>
                </Link>
                <Link to={"/shop"} state={{ tab: "buy" }}>
                  <Button variant="outlined" sx={{ marginLeft: ".5rem" }}>
                    Buy Equipment
                  </Button>
                </Link>
              </Box>
            </>
          )}
          {index === 1 && (
            <>
              <Typography textAlign={"justify"}>
                To list your equipment for sale or rent, create a free account
                and go to your owner dashboard. You have complete control over
                your rental rates and availability and there are no minimums.
                Specify the category, make, and model, and provide details such
                as year, hours, and location. You can attach photographs and
                update your rental rates and availability as needed. Once
                approved, transportation will be coordinated for pickup. Notify
                DIZEL immediately if you are unable to fill the order. You will
                receive payment via ACH deposit account, with the first payment
                made 45 days after the first day on rent. Any mechanical repairs
                not caused by the renter will be deducted from your earnings
                payment.
              </Typography>
              {/* <Box component={"div"} margin={"1rem 0"}>
                <Link to={"/shop"} state={{ tab: "rent" }}>
                  <Button variant="outlined">Rent Equipment</Button>
                </Link>
                <Link to={"/shop"} state={{ tab: "buy" }}>
                  <Button variant="outlined" sx={{ marginLeft: ".5rem" }}>
                    Buy Equipment
                  </Button>
                </Link>
              </Box> */}
            </>
          )}
          {index === 2 && (
            <>
              <Typography textAlign={"justify"}>Coming Soon!</Typography>
              {/* <Box component={"div"} margin={"1rem 0"}>
                <Link to={"/shop"} state={{ tab: "rent" }}>
                  <Button variant="outlined">Rent Equipment</Button>
                </Link>
                <Link to={"/shop"} state={{ tab: "buy" }}>
                  <Button variant="outlined" sx={{ marginLeft: ".5rem" }}>
                    Buy Equipment
                  </Button>
                </Link>
              </Box> */}
            </>
          )}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: propTypes.node,
  index: propTypes.number.isRequired,
  value: propTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

function HorizontalTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }} maxWidth={"100%"} display={"block"}>
          <Typography fontWeight={700} mb={2} variant="h3">
            {children}
          </Typography>
          {index === 0 && (
            <>
              <Typography variant="p">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor
                in reprehenderit in voluptate velit esse cillum dolore eu fugiat
                nulla pariatur. Excepteur sint occaecat cupidatat non proident,
                sunt in culpa qui officia deserunt mollit anim id est
                laborum.Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                sed do eiusmod tempor incididunt ut labore et dolore magna
                aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis
                aute irure dolor in reprehenderit in voluptate velit esse cillum
                dolore eu fugiat nulla pariatur. Excepteur sint occaecat
                cupidatat non proident, sunt in culpa qui officia deserunt
                mollit anim id est laborum.
              </Typography>
              <Box component={"div"} margin={"1rem 0"}>
                <Button variant="outlined">Link 1</Button>
                <Button sx={{ marginLeft: "1rem" }} variant="outlined">
                  Link 2
                </Button>
              </Box>
            </>
          )}
        </Box>
      )}
    </div>
  );
}

HorizontalTabPanel.propTypes = {
  children: propTypes.node,
  index: propTypes.number.isRequired,
  value: propTypes.number.isRequired,
};

function a22yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const HorizontalTabs = () => {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box
      sx={{
        width: "100%",
        display: { xs: "block", md: "none" },
        margin: "4rem 0",
      }}
    >
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          <Tab label="Renters / Buyers" {...a22yProps(0)} />
          <Tab label="Owners" {...a22yProps(1)} />
          <Tab label="Terms and Conditions" {...a22yProps(2)} />
        </Tabs>
      </Box>
      <HorizontalTabPanel value={value} index={0}>
        Renters / Buyers
      </HorizontalTabPanel>
      <HorizontalTabPanel value={value} index={1}>
        Owners
      </HorizontalTabPanel>
      <HorizontalTabPanel value={value} index={1}>
        Terms and Conditions
      </HorizontalTabPanel>
    </Box>
  );
};

const VerticalTabs = () => {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box
      sx={{
        flexGrow: 1,
        bgcolor: "background.paper",
        display: { xs: "none", md: "flex" },
        height: "auto",
        margin: "4rem 0",
      }}
    >
      <Tabs
        orientation="vertical"
        variant="scrollable"
        value={value}
        onChange={handleChange}
        aria-label="Vertical tabs example"
        sx={{ borderRight: 0.5, borderColor: "divider", minWidth: "200px" }}
      >
        <Tab label="Renters / Buyers" {...a11yProps(0)} />
        <Tab label="Owners" {...a11yProps(1)} />
        <Tab label="Terms and Conditions" {...a11yProps(2)} />
      </Tabs>
      <TabPanel value={value} index={0}>
        Renters / Buyers
      </TabPanel>
      <TabPanel value={value} index={1}>
        Owners
      </TabPanel>
      <TabPanel value={value} index={2}>
        Terms and Conditions
      </TabPanel>
    </Box>
  );
};

const FAQCard = ({ icon, title, description }) => (
  <Card>
    <CardContent>
      <div style={{ display: "flex", alignItems: "center", marginBottom: 16 }}>
        {icon}
        <Typography variant="h6" sx={{ ml: 2 }}>
          {title}
        </Typography>
      </div>
      <Typography>{description}</Typography>
    </CardContent>
  </Card>
);

const FAQ = () => {
  const navigate = useNavigate();

  const [search, setSearch] = useState("");

  const submitSearchValue = (e) => {
    const searchString = "/shop?search=" + search;
    navigate(searchString);
  };
  const Search = styled("div")(({ theme }) => ({
    position: "relative",
    // borderRadius: theme.shape.borderRadius,
    backgroundColor: "var(--white-color)",
    color: "var(--black-color)",
    marginLeft: 0,
    width: "100%",
    margin: "1rem 0",
    [theme.breakpoints.up("sm")]: {
      width: "100%",
      // marginTop: "1.8rem",
    },
  }));

  const SearchIconWrapper = styled("div")(({ theme }) => ({
    padding: theme.spacing(0, 0),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  }));

  const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: "inherit",
    width: "100%",
    "& .MuiInputBase-input": {
      padding: theme.spacing(0.5, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(2)})`,
      borderBottom: "2px solid var(--grey-color)",
      transition: theme.transitions.create("width"),
      [theme.breakpoints.up("sm")]: {
        width: "100%",
        "&:focus": {
          width: "100%",
        },
      },
    },
  }));
  const faqs = [
    {
      title:
        "What are the advantages of listing your rental equipment on DIZEL?",
      answer:
        "By listing your equipment on DIZEL Marketplace, you can reach thousands of contractors who are searching for rental equipment for their next job. Let us help your drive more profit for your business. Attract new customers, increase your online visibility, and build brand awareness. It’s free to list!",
    },
    {
      title: "What is the cost of listing equipment on DIZEL?",
      answer:
        "You can list equipment on DIZEL Marketplace for free. Fees are only applied if the equipment is rented or purchased. Start making revenue now!",
    },
    {
      title: "Why rent equipment from DIZEL?",
      answer:
        "Rent equipment hassle-free. Find, compare prices, and book your rental with just a few clicks. Say goodbye to traditional equipment rental and experience the convenience of DIZEL.",
    },
    {
      title: "How many pieces of equipment can I list on DIZEL?",
      answer: "As many as you would like. You can list your entire fleet.",
    },
    {
      title: "How do I start renting from DIZEL?",
      answer: "Create an account today and begin your search in minutes.",
      linkContent: "About Page Renters",
      linkHref: "/user/equipment",
    },
    {
      title: "How to I List my equipment from DIZEL?",
      answer:
        "Listing equipment is easy. Creating an account with DIZEL take only a few minutes. Once you validate your email address you have full access to our platform and can list immediately.",
      linkContent: "About Page Owners",
      linkHref: "/user/equipment",
    },
    {
      title: "Can I buy equipment from DIZEL?",
      answer:
        "Yes, you can leverage the marketplace to rent or purchase equipment. Select the ‘Buy Equipment ’ tab when searching for equipment available for purchase.",
    },
    {
      title: "How often should I update my listings on DIZEL?",
      answer:
        "We recommend that you review your listings as often as possible to ensure that your information is up-to-date with current pricing and availability.",
    },
  ];
  const data = [
    {
      icon: faqIcon1,
      title: "Boost the utilization and efficiency of your equipment.",
    },
    {
      icon: faqIcon2,
      title: "Maximize revenue potential by renting out your idle equipment.",
    },
    {
      icon: faqIcon3,
      title:
        "Instantly access availability and pricing from nearby rental companies.",
    },
  ];
  return (
    <>
      <Box className="faq-con" component={"div"}>
        <Stack
          maxWidth={"lg"}
          margin={"auto"}
          height={"calc(100vh - 60px)"}
          display={"flex"}
          direction={"column"}
          // alignItems={"center"}
          justifyContent={"center"}
        >
          <Typography
            variant="h1"
            sx={{ fontSize: { xs: 32, md: 48 } }}
            align="center"
            color={"var(--white-color)"}
            className="mont-title"
          >
            How to Get Started
            <Typography variant="h4" marginTop={1} color={"var(--grey-color)"}>
              The Power of Dizel
            </Typography>
          </Typography>

          <Paper elevation={1} sx={{ mt: 4, borderRadius: 2 }}>
            <div style={{ position: "relative" }}>
              <InputBase
                placeholder="Search FAQs"
                endAdornment={
                  <SearchIcon
                    sx={{
                      ml: 1,
                      color: "text.secondary",
                    }}
                  />
                }
                sx={{
                  width: "100%",
                  padding: 2,
                }}
              />
            </div>
          </Paper>
        </Stack>
      </Box>
      <Container maxWidth="lg">
        <VerticalTabs />
        <HorizontalTabs />
        <Grid sx={{ mt: 0 }}>
          <Box
            className=""
            component={"div"}
            sx={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: { xs: "center", md: "space-between" },
              width: "100%",
            }}
          >
            {data.map((item, ind) => (
              <Card
                elevation={0}
                sx={{
                  border: "1px solid #f1f1f1",
                  maxWidth: 350,
                  minHeight: 320,
                  margin: ".5rem",
                  borderRadius: "10px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  "&:hover": {
                    transition: ".5s ease",
                    boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                  },
                }}
              >
                <CardContent>
                  <Typography
                    color="text.secondary"
                    textAlign={"center"}
                    gutterBottom
                  >
                    <img src={item.icon} width={"200px"} />
                  </Typography>
                  <Typography variant="h4" textAlign={"center"} component="div">
                    {item.title}
                  </Typography>
                </CardContent>
                {/* <CardActions>
                <Button size="small">More Info</Button>
              </CardActions> */}
              </Card>
            ))}
          </Box>
        </Grid>
        <Box marginTop={4}></Box>
        <Typography
          variant="h3"
          textAlign={"center"}
          fontWeight={700}
          margin={"2rem auto"}
          marginTop={"4rem"}
        >
          Frequently Asked Questions
        </Typography>
        {faqs.map((item, ind) => (
          <Accordion
            sx={{
              boxShadow: "none",
              padding: ".5rem 0",
              borderBottom: "1px solid var(--grey-color)",
            }}
            disableGutters
          >
            <AccordionSummary
              sx={{ border: "none", boxShadow: "none" }}
              expandIcon={<ExpandMoreIcon />}
            >
              <Typography fontWeight={700}>{item.title}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <ul>
                <li>{item.answer}</li>
                {item.linkHref && (
                  <Typography
                    style={{
                      fontWeight: 700,
                      color: "var(--dark-grey-color)",
                    }}
                  >
                    <Link to={item.linkHref}>
                      <span
                        style={{ borderBottom: "2px solid var(--grey-color)" }}
                      >
                        {item.linkContent}
                      </span>
                    </Link>
                  </Typography>
                )}
                {ind === 6 && (
                  <form class="search" onSubmit={(e) => submitSearchValue(e)}>
                    <TextField
                      sx={{ marginTop: "1rem" }}
                      value={search}
                      onChange={(e) => setSearch(e.target.value)}
                      id="input-with-icon-textfield"
                      label=""
                      placeholder="Search equipment..."
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <SearchIcon />
                          </InputAdornment>
                        ),
                      }}
                      variant="standard"
                    />
                    <Button type="submit" sx={{ display: "none" }}>
                      Submit{" "}
                    </Button>
                  </form>
                )}
              </ul>
            </AccordionDetails>
          </Accordion>
        ))}
        <br />
        <br />
        <br />
        {/* <Box sx={{ mt: 4 }}>
        <Typography variant="h1" mt={"4rem"} mb={"2rem"} textAlign={"center"}>
          How-tos
        </Typography>
        <Grid container spacing={3}>
          {[1, 2, 3, 4, 5, 6].map((item) => (
            <Grid item xs={12} sm={6} md={4}>
              <div>
                <Typography variant="h6">How-to Video {item}</Typography>
                <iframe
                  style={{ borderRadius: "5px" }}
                  width="100%"
                  height="250"
                  src="https://www.youtube.com/embed/OXQDsSctP1M?si=5hqhLpcwG8V9-UYS"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen
                ></iframe>
              </div>
            </Grid>
          ))}
        </Grid>
        <Box mb={10}></Box>
      </Box> */}
      </Container>
    </>
  );
};

export default FAQ;
