import { ArrowDropDown } from "@mui/icons-material";
import { Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

const TabsSorter = ({ setSortType }) => {
  const [sortFilter, setSortFilter] = useState("newest");
  const dispatch = useDispatch();
  const { products, productCount, filters } = useSelector(
    (state) => state.product
  );

  const handleSort = (sort) => {
    setSortType(sort);
    console.log(sort);
    setSortFilter(sort);
  };

  return (
    <Box>
      <ul class="menu">
        <li class="menu-item">
          <a href="#">
            <div style={{ display: "flex", alignItems: "center" }}>
              <div>
                <Typography variant="p">Sort by</Typography>
              </div>
              <div style={{ display: "flex" }}>
                <ArrowDropDown />
              </div>
            </div>
          </a>
          <ul class="drop-menu">
            <li
              class="drop-menu-item"
              onClick={() => handleSort("newest")}
              style={
                sortFilter === "newest"
                  ? {
                      background: "rgba(244, 67, 54, 0.06)",
                      borderRadius: "5px",
                    }
                  : {}
              }
            >
              <a
                href="#"
                style={
                  sortFilter === "newest"
                    ? { color: "var(--secondary-color)" }
                    : {}
                }
              >
                Year: newest to oldest
              </a>
            </li>
            <li
              class="drop-menu-item"
              onClick={() => handleSort("oldest")}
              style={
                sortFilter === "oldest"
                  ? {
                      background: "rgba(244, 67, 54, 0.06)",
                      borderRadius: "5px",
                    }
                  : {}
              }
            >
              <a
                href="#"
                style={
                  sortFilter === "oldest"
                    ? { color: "var(--secondary-color)" }
                    : {}
                }
              >
                Year: oldest to newest
              </a>
            </li>
            <li
              class="drop-menu-item"
              onClick={() => handleSort("recent")}
              style={
                sortFilter === "recent"
                  ? {
                      background: "rgba(244, 67, 54, 0.06)",
                      borderRadius: "5px",
                    }
                  : {}
              }
            >
              <a
                href="#"
                style={
                  sortFilter === "recent"
                    ? { color: "var(--secondary-color)" }
                    : {}
                }
              >
                Recently added
              </a>
            </li>
            <li
              class="drop-menu-item"
              onClick={() => handleSort("price")}
              style={
                sortFilter === "price"
                  ? {
                      background: "rgba(244, 67, 54, 0.06)",
                      borderRadius: "5px",
                    }
                  : {}
              }
            >
              <a
                href="#"
                style={
                  sortFilter === "price"
                    ? { color: "var(--secondary-color)" }
                    : {}
                }
              >
                Price: low to high
              </a>
            </li>
            <li
              class="drop-menu-item"
              onClick={() => handleSort("price-desc")}
              style={
                sortFilter === "price-desc"
                  ? {
                      borderRadius: "5px",
                      background: "rgba(244, 67, 54, 0.06)",
                    }
                  : {}
              }
            >
              <a
                href="#"
                style={
                  sortFilter === "price-desc"
                    ? { color: "var(--secondary-color)" }
                    : {}
                }
              >
                Price: high to low
              </a>
            </li>
          </ul>
        </li>
      </ul>
    </Box>
  );
};

export default TabsSorter;
