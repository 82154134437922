import {
  CardElement,
  CardNumberElement,
  CardCvcElement,
  CardExpiryElement,
} from "@stripe/react-stripe-js";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import React from "react";

const CARD_ELEMENT_OPTIONS = {
  iconStyle: "solid",
  hidePostalCode: true,
  style: {
    base: {
      iconColor: "rgb(240, 57, 122)",
      color: "rgb(0, 0, 0)",
      fontSize: "14px",
      fontFamily: "inherit",
      fontSmoothing: "antialiased",
      padding: "8px",
      border: "none",
      borderBottom: "1px solid",
      "::placeholder": {
        color: "#CFD7DF",
      },
    },
    invalid: {
      color: "#e5424d",
      ":focus": {
        color: "#303238",
      },
    },
  },
};

export default function CardSection({setSaveCard,checkBoxStatus}) {
  const [focus, setFocused] = React.useState(false);
  const [hasValue, setHasValue] = React.useState(false);
  const onFocus = () => setFocused(true);
  const onBlur = () => setFocused(false);

  return (
    <>
      <div className="input_container" style={{ margin: "15px 0px" }}>
        <label
          className="card_label"
          for="card_number"
          style={{ fontSize: "13px" }}
        >
          Card Number *
        </label>
        <CardNumberElement options={CARD_ELEMENT_OPTIONS} />
      </div>
      {/* <div style={{ background: "#e1e2e5", textAlign: "center" }}>
        <img
          src={stripebadge2}
          alt="stripe badge cards"
          width={"65%"}
          style={{ borderRadius: "5px" }}
        />
      </div> */}
      <div className="input_container" style={{ margin: "15px 0px" }}>
        <label className="card_label" for="cvc" style={{ fontSize: "13px" }}>
          CVC *
        </label>
        <CardCvcElement options={CARD_ELEMENT_OPTIONS} />
      </div>
      <div className="input_container" style={{ margin: "15px 0px" }}>
        <label
          className="card_label"
          for="card_number"
          style={{ fontSize: "13px" }}
        >
          Expiry Date *
        </label>
        <CardExpiryElement options={CARD_ELEMENT_OPTIONS} />
      </div>
      {/* <Grid item xs={12} md={6}>
          <TextField
            required
            id="cardName"
            label="Name on card"
            fullWidth
            autoComplete="cc-name"
            variant="standard"
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            required
            id="cardNumber"
            label="Card number"
            fullWidth
            autoComplete="cc-number"
            variant="standard"
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            required
            id="expDate"
            label="Expiry date"
            fullWidth
            autoComplete="cc-exp"
            variant="standard"
            onFocus={onFocus}
            onBlur={onBlur}
            InputProps={{
              classes: {
                input: "CustomTextField",
              },
            }}
            onChange={(e) => {
              if (e.target.value) setHasValue(true);
              else setHasValue(false);
            }}
            type={hasValue || focus ? "date" : "text"}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            required
            id="cvv"
            label="CVV"
            helperText="Last three digits on signature strip"
            fullWidth
            autoComplete="cc-csc"
            variant="standard"
            type="number"
          />
        </Grid> */}
      <Grid item xs={12}>
        <FormControlLabel
          control={<Checkbox color="secondary" name="saveCard" value="yes" defaultChecked={checkBoxStatus} onChange={()=>setSaveCard(prev=>!prev)}/>}
          label="Save credit card info for easy checkout next time"
        />
      </Grid>
    </>
  );
}
