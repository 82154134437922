import React, { useEffect, useState } from "react";
import shortid from "shortid";
import "./FileUploadDragAndDrop.css";
import { Backdrop, Box, Button, Fade, Modal, Typography } from "@mui/material";
import swal from "sweetalert";
import { FaFileAlt } from "react-icons/fa";

const FileUploadDragAndDrop = ({
  Files,
  SetFiles,
  filePreviewModal,
  setFilePreviewModal,
  setFileAgreement,
  setByown,
  setData,
  userInfo,
  docusignDone,
  setDocusignDone,
  setDocuSignByOwn
}) => {
  const [selectedfile, SetSelectedFile] = useState("");

  const filesizes = (bytes, decimals = 2) => {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  };

  const InputChange = (e) => {
    // --For Single File Input
    setByown(true)
    // setDocusignDone(false)
    // setDocuSignByOwn(false)
    // setData("")
    let reader = new FileReader();
    let file = e.target.files[0];

    reader.onloadend = () => {
      SetFiles([
        {
          id: shortid.generate(),
          filename: e.target.files[0].name,
          filetype: e.target.files[0].type,
          file: reader.result,
          filesize: filesizes(e.target.files[0].size),
        },
      ]);
    };
    if (e.target.files[0]) {
      // console.log(file);
      reader.readAsDataURL(file);
    }
    setFilePreviewModal(true);
  };

  const DeleteSelectFile = (id) => {
    if (swal.confirm("Are you sure you want to delete this Image?")) {
      const result = Files.filter((data) => data.id !== id);
      SetFiles(result);
    } else {
      // alert('No');
    }
  };

  const DeleteFile = async (id) => {
    swal({
      title: "Are you sure?",
      text: "This file will be removed",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        const result = Files.filter((data) => data.id !== id);
        SetFiles(result);
        setByown(false)
        setFileAgreement(null);
        localStorage.removeItem('localUpload');
        if (userInfo && userInfo.userDetailsId.docusignAgreement && !docusignDone) {
          // setSavedType("docusign")
          // dispatch(
          //   getdocuSign({ token, id: userInfo.userDetailsId.docusignAgreement })
          // );
          // setSpinner(false);
          console.log("GH");
          // setSpinner(true);
        } else if (userInfo && userInfo.userDetailsId.agreement && !docusignDone) {
          // setData(userInfo.userDetailsId.agreement.replace("http","https"))
          let agreement = userInfo.userDetailsId.agreement;
          if (agreement.startsWith("http://")) {
            agreement = agreement.replace("http://", "https://");
          } else if (!agreement.startsWith("https://")) {
            // If the URL doesn't start with http:// or https://, assume it's relative and prepend https://
            agreement = "https://" + agreement;
          }
          // setSavedType("file")
          // setFileSet(true);
          setData(agreement);
          // if(!fileAgreement) setFilePreviewModal(true);
          // setSpinner(false);
        } else {
          console.log("HH");
          // setSpinner(false);
        }
      } else {
        // alert('No');
      }
    });
  };
  return (
    <>
      <div className="fileupload-view">
        <div className="row justify-content-center m-0">
          <div className="col-md-6" style={{ width: "100%" }}>
            <div className="file-upload-card mt-5">
              <div className="file-upload-card-body">
                <div className="kb-data-box">
                  <div className="kb-modal-data-title">
                    <div className="kb-data-title"></div>
                  </div>
                  <form>
                    <div className="kb-file-upload">
                      <div className="file-upload-box">
                        <input
                          type="file"
                          id="fileupload"
                          className="file-upload-input"
                          onChange={InputChange}
                        />
                        <span>
                          Drag and drop or{" "}
                          <span className="file-link">Choose your file</span>
                        </span>
                      </div>
                    </div>
                  </form>

                  {Files && Files.length > 0 && (
                    <div className="kb-attach-box">
                      <hr />
                      {Files.map((data, index) => {
                        const { id, filename, fileimage, datetime, filesize } =
                          data;
                        return (
                          <div className="file-atc-box" key={index}>
                            <div className="file-image">
                              <FaFileAlt />
                            </div>
                            <div className="file-detail">
                              <h6>{filename?filename:data}</h6>
                              <p>
                                {
                                  filesize && (
                                    <span>Size : {filesize}</span>
                                  )
                                }
                              </p>
                              <div className="file-actions">
                                <button
                                  className="file-action-btn"
                                  onClick={() => DeleteFile(id)}
                                >
                                  Delete
                                </button>
                                <button
                                  href={fileimage}
                                  className="file-action-btn"
                                  onClick={() => setFilePreviewModal(true)}
                                >
                                  Preview
                                </button>
                                <a
                                  href={fileimage}
                                  className="file-action-btn"
                                  download={filename}
                                >
                                  Download
                                </a>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FileUploadDragAndDrop;
