import {
    Box,
    Button,
    Divider,
    Modal,
    TextField,
    Typography,
  } from "@mui/material";
  import React, { useEffect } from "react";
  import verifyIllustrator from "../../assets/Pictures/verify-mail-illustrate.svg";
  import { useState } from "react";
  import { useLocation, useNavigate, useParams } from "react-router-dom";
  import { useDispatch, useSelector } from "react-redux";
  import { ToastContainer, toast } from "react-toastify";
  import Spinner from "../../components/Spinner/Spinner";
  import { resetPassword,reset } from "../../components/StateSlices/resetpasswordSlice";
  
  const ResetPassword = () => {
    const style = {
    //   position: "absolute",
    //   top: "50%",
    //   left: "50%",
    //   transform: "translate(-50%, -50%)",
    //   width: 400,
      bgcolor: "background.paper",
    //   boxShadow: 24,
      p: 4,
      borderRadius: "10px",
    };
    const [openModal, setOpenModal] = useState(false);
    const [newEmail, setNewEmail] = useState("");
    let location = useLocation();
    let navigate = useNavigate();
    const [spinner, setSpinner] = useState(false);
    const dispatch = useDispatch();
    // const [message, setMessage] = useState("");
    const [oldEmail, setOldEmail] = useState("");
    const [sent, setSent] = useState(false)
    const [password, setPassword] = useState("")
    const [confirmPassword, setConfirmPassword] = useState("")
    const { status, message, error } = useSelector((state) => state.reset);
  
    const userDetailsState = location.state;

    const { id } = useParams();
  
    const toastOption = {
      position: "top-right",
      autoClose: 1500,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    };
  
    useEffect(() => {
    //   if (!userDetailsState || userDetailsState === null) {
    //     navigate("/signup");
    //   }
    //   let search = location.search;
    //   if (search) {
    //     let qry = new URLSearchParams(search);
    //     let error = qry.get("error");
    //     if (error === "AccountNotVerified") {
    //       setMessage("Account Not Verified");
    //     }
    //   }
    //   console.log(userDetailsState);
    //   if (userDetailsState) {
    //     setNewEmail(userDetailsState.userEmail);
    //     setOldEmail(userDetailsState.userEmail);
    //   }
    }, []);
  
    const handleSubmit = (e) => {
      e.preventDefault();
    //   password: Yup.string("Enter your Password")
    //         .min(8, "Password should be of minimum 8 characters length")
    //         .required("Please enter your Password"),
    // Please have this validation also
        if(password.trim().length < 8){
            toast.error("Password should be of minimum 8 characters length", toastOption);
            return;
        }
        if(password.trim() !== confirmPassword.trim()){
            toast.error("Passwords do not match", toastOption);
            return;
        }
      dispatch(resetPassword({ password: password.trim(), token: id }));
      setSpinner(true);
    };
  
    if (message) {
      dispatch(reset());
      toast.success("Password Reset Successfully", toastOption);
      setSpinner(false);
      setOpenModal(false);
      setSent(true)
      navigate("/login")
    }
  
    if (error) {
      toast.error(error, toastOption);
      dispatch(reset());
      setSpinner(false);
    }
  
    return (
      <>
        {/* <Modal
          open={openModal}
          onClose={() => setOpenModal(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        > */}
          
        {/* </Modal> */}
        <Box component={"div"} minHeight={"100vh"} minWidth={"100%"}>
          {spinner ? <Spinner /> : ""}
          <Box
            component={"div"}
            padding={".5rem 2rem"}
            textAlign={"center"}
            marginTop={"4rem"}
          >
            {/* {message ? (
              <p className="paragraph_title" style={{ color: "red" }}>
                {message}
              </p>
            ) : (
              ""
            )} */}
            <Typography variant="h1 mont-title" fontWeight={700}>
              Reset Password
            </Typography>
            <Box sx={style}>
            <form onSubmit={(e) => handleSubmit(e)}>
              <Typography
                id="modal-modal-title"
                variant="h4"
                component="h2"
                fontWeight={700}
                sx={{textAlign: "left"}}
              >
                New Password
              </Typography>
              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                <TextField
                  fullWidth
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  placeholder="New Password"
                ></TextField>
              </Typography>
              <br/>
              <Typography
                id="modal-modal-title"
                variant="h4"
                component="h2"
                fontWeight={700}
                sx={{textAlign: "left"}}
              >
                Retype Password
              </Typography>
              {/* Retype Password Input */}
              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                <TextField
                  fullWidth
                  type="password"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  placeholder="Retype Password"
                ></TextField>
              </Typography>
                
              <Button
                type="submit"
                variant="contained"
                sx={{ marginTop: "1rem", float: "right" }}
              >
                Reset Password
              </Button>
            </form>
          </Box>
            {/* {
                sent ?(
                    <>
                        
                        <Typography variant="h4" color={"text.secondary"}>
                        Check your email and click the link to reset your password{" "}
                        </Typography>
                        <img
                        src={verifyIllustrator}
                        style={{ width: "100%", maxWidth: "300px", margin: "2rem auto" }}
                        alt="verify-mail-illustrate"
                        />
                    </>
                ):(
                    <>
                    <Typography variant="h4" color={"text.secondary"}>
                        Enter your email address to reset your password
                        </Typography>
                    </>
                )
            } */}
            
            {/* <Box component={"div"}>
              <Button
                variant="contained"
                sx={{ margin: ".5rem", color: "var(--white-color)" }}
                onClick={() => setOpenModal(true)}
                color="primary"
              >
                Send Reset Link
              </Button>
              <Button variant="outlined" sx={{ margin: ".5rem" }}>
                <a href="mailto:support@godizel.com" target="_blank">Contact Support</a>
              </Button>
            </Box> */}
          </Box>
        </Box>
      </>
    );
  };
  
  export default ResetPassword;
  