import { ArrowDropDown } from "@mui/icons-material";
import { Box, Typography } from "@mui/material";
import React, { useState } from "react";

const EquipmentTypeSorter = ({
  setLoading,
  userProducts,
  setUserProducts,
  setProductType,
}) => {
  const [sortFilter, setSortFilter] = useState("both");

  const handleSort = (sort) => {
    setProductType(sort);
    console.log(sort, userProducts);

    setSortFilter(sort);
  };
  return (
    <Box>
      <ul class="menu">
        <li class="menu-item">
          <a href="#">
            <div style={{ display: "flex", alignItems: "center" }}>
              <div>
                <Typography variant="p">Equipment type</Typography>
              </div>
              <div style={{ display: "flex" }}>
                <ArrowDropDown />
              </div>
            </div>
          </a>
          <ul class="drop-menu" style={{ minWidth: "220px" }}>
            <li
              class="drop-menu-item"
              onClick={() => handleSort("both")}
              style={
                sortFilter === "both"
                  ? {
                      background: "rgba(244, 67, 54, 0.06)",
                      borderRadius: "5px",
                    }
                  : {}
              }
            >
              <a
                href="javascript:void(0)"
                style={
                  sortFilter === "both"
                    ? { color: "var(--secondary-color)" }
                    : {}
                }
              >
                Buy & Rent Equipment
              </a>
            </li>
            <li
              class="drop-menu-item"
              onClick={() => handleSort("buy")}
              style={
                sortFilter === "newest"
                  ? {
                      background: "rgba(244, 67, 54, 0.06)",
                      borderRadius: "5px",
                    }
                  : {}
              }
            >
              <a
                href="javascript:void(0)"
                style={
                  sortFilter === "buy"
                    ? { color: "var(--secondary-color)" }
                    : {}
                }
              >
                Buy Equipment
              </a>
            </li>
            <li
              class="drop-menu-item"
              onClick={() => handleSort("rent")}
              style={
                sortFilter === "rent"
                  ? {
                      background: "rgba(244, 67, 54, 0.06)",
                      borderRadius: "5px",
                    }
                  : {}
              }
            >
              <a
                href="javascript:void(0)"
                style={
                  sortFilter === "rent"
                    ? { color: "var(--secondary-color)" }
                    : {}
                }
              >
                Rent Equipment
              </a>
            </li>
          </ul>
        </li>
      </ul>
    </Box>
  );
};

export default EquipmentTypeSorter;
