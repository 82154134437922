// darkTheme.js
import { createTheme } from "@mui/material/styles";

const darkTheme = createTheme({
  palette: {
    mode: "dark",
    // primary: {
    //   main: "#183d3d",
    // },
    // secondary: {
    //   main: "#93B1A6",
    // },
    // background: {
    //   default: "#040D12",
    //   paper: "#212121",
    // },
    // text: {
    //   primary: "#93B1A6",
    //   secondary: "#5C8374",
    //   hint: "#183D3D",
    // },
  },
  typography: {
    // fontFamily: "Montserrat",
    h1: {
      fontSize: "32px",
      fontWeight: 700,
    },
    h2: {
      fontSize: "28px",
    },
    h3: {
      fontSize: "24px",
    },
    h4: {
      fontSize: "20px",
    },
    h5: {
      fontSize: "16px",
    },
    h6: {
      fontSize: "14px",
    },
  },
});

export default darkTheme;
