import React, { useState } from "react";
import "./AdminTabs.css";
const AdminTabs = ({ tabs, removeMargins = false }) => {
  const [activeTab, setActiveTab] = useState(0);
  return (
    <>
      <div className={tabs[0].label === "M" ? "tabbed-container" : ""}>
        {tabs.length > 1 && (
          <ul className="tab-list">
            {tabs.map((tab, index) => (
              <li
                key={index}
                style={{ margin: removeMargins && "0 0.6rem" }}
                className={index === activeTab ? "active" : ""}
                onClick={() => setActiveTab(index)}
              >
                {tab.label}
              </li>
            ))}
          </ul>
        )}
        <div className="tab-content">{tabs[activeTab].content}</div>
      </div>
    </>
  );
};

export default AdminTabs;
