import { Box, Container, Divider, Grid, Typography } from "@mui/material";
import React from "react";
import "./ConstructionInfoNew.css";

const ConstructionInfoNew = () => {
  const data = [
    {
      //   icon: <EngineeringIcon style={{ fontSize: "38px" }} />,
      title: "Price <br/> Transparency",
      desc: "Easily compare rental rates and availability from multiple companies with our user-friendly platform. Our service streamlines the rental process, allowing you to quickly and efficiently find the best rental options for your needs. With just a few clicks, you can compare rates and availability from multiple rental companies, saving you time and money.",
      link: "",
    },
    {
      //   icon: <ConstructionIcon style={{ fontSize: "38px" }} />,
      title: "More <br/>  Options",
      desc: "Discover an extensive range of equipment options from our rental partners across North America, including nationwide, regional, and local providers. With this diverse network of partners, you'll have access to more rental options than ever before, making it easier to find the equipment you need for your projects.",
      link: "",
    },
    {
      //   icon: <FoundationIcon style={{ fontSize: "38px" }} />,
      title: "Convenience",
      desc: "Manage your equipment rentals hassle-free with our online platform. Book, track, and manage your orders from any device, anywhere, anytime. Enjoy the flexibility and freedom to manage your rentals on-the-go.",
      link: "",
    },
    {
      //   icon: <LocationCityIcon style={{ fontSize: "38px" }} />,
      title: "List Your <br/> Own Equipment",
      desc: "Maximize the utilization of your equipment and earn extra revenue by renting out your idle machines to contractors in need. Our service allows you to list your equipment for rent risk-free, as we only take a commission when your gear is rented. By renting out your equipment, you'll be able to increase your revenue and reduce downtime, while helping contractors get the equipment they need for their projects.",
      link: "",
    },
  ];
  return (
    <section
      style={{
        backgroundSize: "cover",
        margin: "2rem auto",
        marginBottom: "2rem",
      }}
      data-aos-animation="fade-up"
    >
      <Typography
        className="title mont-title"
        variant="h1"
        textAlign={"center"}
        fontWeight={700}
      >
        Why Dizel for Contractors?
      </Typography>
      <Typography
        variant="h4"
        textAlign={"center"}
        color={"text.secondary"}
        sx={{ margin: ".5rem auto", mb: "2rem" }}
      >
        Get seamless advantages of the networking
      </Typography>
      <Grid
        container
        gap={4}
        sx={{ justifyContent: { xs: "center", lg: "space-between" } }}
        // justifyContent={"space-between"}
        margin={"2rem 0"}
        marginBottom={"4rem"}
      >
        {data.map((item, ind) => {
          return (
            <>
              <Box component={"div"} className="cons-info-card">
                {/* <Box component={"div"} className="horizontal divider"></Box> */}
                <Divider
                  sx={{
                    border: "1px solid var(--secondary-color)",
                    borderWidth: "3px",
                    borderRadius: "5px",
                    marginBottom: "1rem",
                  }}
                />
                <Typography
                  variant="h3"
                  fontWeight={700}
                  minHeight={"55px"}
                  margin={"1.5rem 0"}
                  dangerouslySetInnerHTML={{
                    __html: item.title,
                  }}
                />
                <Typography variant="h6" my={1}>
                  {item.desc}
                </Typography>
              </Box>
            </>
          );
        })}
      </Grid>
    </section>
  );
};

export default ConstructionInfoNew;
