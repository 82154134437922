import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import axios from "axios"

const initialState = {
  chatHistoryStatus: "idle",
  chatHistoryInfo: null,
  chatHisitoryError: null,
}

export const addChatHistory = createAsyncThunk(
  "chat/addChatHistory",
  async ({token,values}, { rejectWithValue }) => {
    try {
      const { data } = await axios.post(
        `/api/users/addchathistory`,values,
        {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        }
      )
      return data
    } catch (err) {
      return rejectWithValue(err.response.data)
    }
  }
)

export const deleteChatHistory = createAsyncThunk(
    "chat/deleteChatHistory",
    async ({token,values}, { rejectWithValue }) => {
      try {
        const { data } = await axios.post(
          `/api/users/removechathistory`,values,
          {
              headers: {
                  Authorization: `Bearer ${token}`,
              },
          }
        )
        return data
      } catch (err) {
        return rejectWithValue(err.response.data)
      }
    }
  )

export const chatHistorySlice = createSlice({
  name: "chathistory",
  initialState,
  reducers: {
    resetChatHistory(state,action){
      state.chatHistoryStatus = "idle"
      state.chatHistoryInfo=null
      state.chatHisitoryError= null
    }
  },
  extraReducers: {
    [addChatHistory.pending]: (state, action) => {
      state.chatHistoryStatus = "loading"
    },
    [addChatHistory.fulfilled]: (state, action) => {
      state.chatHistoryStatus = "succeeded"
      state.chatHistoryInfo = action.payload
    },
    [addChatHistory.rejected]: (state, action) => {
      state.chatHistoryStatus = "failed";
      state.chatHisitoryError = action.payload
    },
    [deleteChatHistory.pending]: (state, action) => {
      state.chatHistoryStatus = "loading"
    },
    [deleteChatHistory.fulfilled]: (state, action) => {
      state.commentStatus = "succeeded"
      state.chatHistoryInfo = action.payload.message
    },
    [deleteChatHistory.rejected]: (state, action) => {
      state.chatHistoryStatus = "failed";
      state.chatHisitoryError = action.payload
    },
  },
})

export const { resetChatHistory } = chatHistorySlice.actions
export default chatHistorySlice.reducer 