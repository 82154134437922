import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import axios from "axios"

const initialState = {
  reactivateStatus: "idle",
  reactivateInfo: null,
  reactivateError: null,
}

export const reactivateItem = createAsyncThunk(
  "reactivate/reactivateItem",
  async ({token,id}, { rejectWithValue }) => {
    try {
      const { data } = await axios.post(
        `/api/product/reactivate/${id}`,
        {},
        {
        headers: {
                Authorization: `Bearer ${token}`,
            },
        }
      )
      return data
    } catch (err) {
      return rejectWithValue(err.response.data)
    }
  }
)
export const reactivateitemSlice = createSlice({
  name: "reactivate",
  initialState,
  reducers: {
    resetReactivate(state,action){
        state.reactivateInfo = null
        state.reactivateStatus="idle"
      }
  },
  extraReducers: {
    [reactivateItem.pending]: (state, action) => {
      state.reactivateStatus = "loading"
    },
    [reactivateItem.fulfilled]: (state, action) => {
      state.reactivateStatus = "succeeded"
      state.reactivateInfo = action.payload.message
    },
    [reactivateItem.rejected]: (state, action) => {
      state.reactivateStatus = "failed";
      state.reactivateError = action.payload
    },
  },
})

export const { resetReactivate } = reactivateitemSlice.actions
export default reactivateitemSlice.reducer 