import { Box, Container, Grid, Typography, Stack } from "@mui/material";
import React, { useEffect, useState } from "react";
import ProductCard from "../ProductCard/ProductCard";
import "./Favorites.css";
import TabsSorter from "../TabsSorter/TabsSorter";
import { useDispatch, useSelector } from "react-redux";
import { getallProducts } from "../StateSlices/getproductsSlice";
import { getUserLikes } from "../StateSlices/userLikesSlice";
import { getUserFavorites } from "../StateSlices/favoritesSlice";
import Spinner from "../Spinner/Spinner";
import noitem from "../../assets/no-item.png"

const Favorites = () => {
  const [spinner, setSpinner] = useState(true);
  const [uLikes, setULikes] = useState([]);
  const [productInfo, setProductInfo] = useState([]);
  const dispatch = useDispatch();
  const { userFavorites, userFavIds } = useSelector(
    (state) => state.userfavorites
  );

  useEffect(() => {
    const temp = productInfo.filter((prev) => uLikes.indexOf(prev._id) !== -1);
    setProductInfo(temp);
  }, [uLikes]);

  useEffect(() => {
    const token = localStorage.getItem("inktoken");
    if (token) {
      setSpinner(true);
      dispatch(getUserFavorites({ token }));
    }
  }, []);

  useEffect(() => {
    if (userFavorites) {
      setProductInfo(userFavorites);
      setULikes(userFavIds);
      setTimeout(() => {
        setSpinner(false);
      }, 2000);
    }
  }, [userFavorites]);

  return (
    <Container
      maxWidth="xl"
      className="favorites-prod-grid"
      sx={{ minHeight: "85vh", padding: 0 }}
    >
      <Box component={"div"} marginTop={"2rem"}>
        <Box component={"div"} className="tabs-header">
          <Typography variant="h2" mb={"1rem"} className="title mont-title">
            My Favorites
          </Typography>
          <TabsSorter />
        </Box>
        <Grid
          container
          // spacing={2}
          sx={{
            margin: "0",
            marginBottom: "2rem",
            justifyContent: { xs: "center", md: "space-between" },
          }}
          style={{ position: "relative" }}
        >
          {spinner ? (
            <Spinner height={"60vh"} />
          ) : (
            <>
              {productInfo.length > 0 ? (
                <>
                  {productInfo.map((product) => (
                    <Grid
                      sx={{
                        flexWrap: "wrap",
                        justifyContent: "space-between",
                      }}
                      key={product._id}
                      margin={"1rem 0"}
                      // spacing={2}
                      // xs={12}
                      // sm={6}
                      // md={4}
                      // lg={3}
                    >
                      <ProductCard
                        shopLikes={uLikes}
                        setShopLikes={setULikes}
                        shopPageCardWidth={"400px"}
                        product={product}
                        removeMargins={true}
                        buttons={["view details", "add to cart"]}
                      />
                    </Grid>
                  ))}
                </>
              ) : (
                <Stack
                  minHeight={"60vh"}
                  display={"flex"}
                  justifyContent={"center"}
                  alignItems={"center"}
                  width={"100%"}
                  sx={{
                    background:`url(${noitem})`,
                    backgroundSize:"cover",
                  }}
                >
                  <Typography variant="h3" color={"var(--black-color)"}>
                    No Favorites added.
                  </Typography>
                </Stack>
              )}
            </>
          )}
          {/* {productInfo.length < 0 && (
                  <Box
                    minHeight={"80vh"}
                    display={"flex"}
                    justifyContent={"center"}
                    alignItems={"center"}
                  >
                    <Typography
                      variant="h3"
                      fontWeight={700}
                      color={"var(--black-color)"}
                    >
                      End of Equipment Stock
                    </Typography>
                  </Box>
                )} */}
        </Grid>
      </Box>
    </Container>
  );
};

export default Favorites;
