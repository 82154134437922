import React from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { reset } from "../StateSlices/getuserSlice";
import { Collapse } from "@mui/material";
import "./Header.css";

const Dropdown = ({ isLoggedIn, handleLinkClick }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const logout = () => {
    localStorage.removeItem("cartItems");
    localStorage.removeItem("inktoken");
    dispatch(reset());
    // navigate("/");
    window.location.href = "/";
  };

  return (
    // <Collapse in={Boolean(anchorElUser) ? true : false} timeout={300}>
    <section
      className="footer-con"
      style={{
        borderTop: "1px solid var(--grey-color)",
        zIndex: 9999,
        width: "100%",
        // maxWidth: "1536px",
      }}
    >
      <div
        className="footer-wrapper"
        style={{
          paddingTop: "0",
          paddingBottom: "0",
          maxWidth: "1536px",
          margin: "auto",
        }}
      >
        <div className="footer-row-2">
          <div className="col">
            <h6>Dizel</h6>
            <ul>
              <li>
                <Link to={"/faqs"}>How To Guide</Link>
              </li>
              <li>
                <Link
                  to={"/about-dizel"}
                  onClick={() => {
                    window.scrollTo(0, 0);
                  }}
                >
                  About Dizel
                </Link>
              </li>
              <li>
                <Link
                  to={"/categories"}
                  onClick={() => {
                    window.scrollTo(0, 0);
                  }}
                >
                  Categories
                </Link>
              </li>
            </ul>
          </div>
          <div className="vertical-divider">&nbsp;</div>
          <div className="col">
            <h6>Rent / Buy</h6>

            <ul>
              <li>
                <Link
                  to={"/shop"}
                  state={{ tab: "rent" }}
                  onClick={() => {
                    window.scrollTo(0, 0);
                  }}
                >
                  Rent Equipment
                </Link>
              </li>
              <li>
                <Link
                  to={"/shop"}
                  state={{ tab: "buy" }}
                  onClick={() => {
                    window.scrollTo(0, 0);
                  }}
                >
                  Buy Equipment
                </Link>
              </li>
              <li>
                <Link
                  // state={{ tab: "purchases" }}
                  to={"/user/transactions"}
                  onClick={() => {
                    window.scrollTo(0, 0);
                  }}
                >
                  Transactions
                </Link>
              </li>
            </ul>
          </div>
          <div className="vertical-divider">&nbsp;</div>

          <div className="col">
            <h6>Account</h6>

            <ul>
              <li>
                <Link
                  to={"/user/equipment"}
                  // state={{ tab: "my equipment" }}
                  onClick={() => {
                    window.scrollTo(0, 0);
                  }}
                >
                  My Equipment
                </Link>
              </li>
              <li>
                <Link
                  to={"/user/profile"}
                  onClick={() => {
                    window.scrollTo(0, 0);
                  }}
                >
                  My Profile
                </Link>
              </li>
              <li>
                {isLoggedIn ? (
                  <Link to={"#"} onClick={() => logout()}>
                    Log Out
                  </Link>
                ) : (
                  <>
                    <Link to={"/signup"}>Sign Up </Link>|{" "}
                    <Link to={"/login"}>Log In</Link>
                  </>
                )}
              </li>
            </ul>
          </div>
        </div>
        {/* <div className="footer-row-3">
              <div>
                <small> © 2024, All Rights Reserved.</small>
              </div>
              <div className="social-media-icons">
                

                <div>
                  <a href="https://facebook.com/" style={{ color: "#999" }}>
                    <FaFacebook
                      size={"1.4em"}
                      style={{ margin: "0 1rem" }}
                    ></FaFacebook>
                  </a>
                  <a
                    href="https://twitter.com/GoDizel"
                    style={{ color: "#999" }}
                  >
                    <FaXTwitter size={"1.2em"} style={{ margin: "0 1rem" }} />
                  </a>
                  <a
                    href="https://www.linkedin.com/company/dizel/"
                    style={{ color: "#999" }}
                  >
                    <FaLinkedin size={"1.4em"} style={{ margin: "0 1rem" }} />
                  </a>
                </div>
              </div>
            </div> */}
      </div>
    </section>
    // </Collapse>
  );
};

export default Dropdown;
