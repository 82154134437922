import React from "react";
import {
  Container,
  Typography,
  Box,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";

const data = [
  {
    title: "Information You Provide to Us",
    content: [
      {
        type: "p",
        text: "The information we collect on or through our Website may include:",
      },
      {
        type: "b",
        text: [
          "Information that you provide by filling in forms on our Website. This includes information provided at the time of subscribing to our service or requesting further services. We may also ask you for information when you enter a contest or promotion sponsored by us, and when you report a problem with our Website.",
          "Records and copies of your correspondence (including email addresses) if you contact us.",
          "Your responses to surveys that we might ask you to complete for research purposes.",
          "Details of transactions you carry out through our Website and of the fulfillment of your orders. You may be required to provide financial information before placing an order through our Website; however, all payments and order processing are handled by a third-party payment processor.",
          "Your search queries on the Website.",
        ],
      },
    ],
  },
];

const PrivacyPolicy = () => {
  return (
    <Container
      maxWidth="md"
      sx={{ textAlign: "justify", marginBottom: "5rem" }}
    >
      <Box data-aos="fade-in" data-aos-duration={500}>
        <Typography
          variant="h1"
          textAlign={"center"}
          fontWeight={700}
          component="h1"
          gutterBottom
          marginTop={"2rem"}
        >
          Dizel, Inc. Privacy Policy
        </Typography>
        <Typography
          variant="body2"
          color="textSecondary"
          margin={"1rem 0"}
          gutterBottom
        >
          Last modified: June 1, 2024
        </Typography>

        <Typography variant="h4" component="h2" fontWeight={700} gutterBottom>
          <u>Introduction</u>
        </Typography>
        <Typography variant="body1" paragraph>
          Dizel, Inc. (<strong>“Company”</strong> or <strong>“we”</strong>)
          respects your privacy and is committed to protecting it through our
          compliance with this privacy policy (<strong>“Policy”</strong>).
        </Typography>
        <Typography variant="body1" paragraph>
          This Policy describes the types of information we may collect from you
          or that you may provide when you visit the website www.godizel.com
          (our <strong>“Website”</strong>) and our practices for collecting,
          using, maintaining, protecting, and disclosing that information.
        </Typography>
        <Typography variant="body1" paragraph>
          This Policy applies to information we collect:
        </Typography>
        <ul>
          <li>On this Website.</li>
          <li>
            In email, text, and other electronic messages between you and this
            Website.
          </li>
          <li>
            Through mobile and desktop applications you download from this
            Website, which provide dedicated non-browser-based interaction
            between you and this Website.
          </li>
          <li>
            When you interact with our advertising and applications on
            third-party websites and services, if those applications or
            advertising include links to this Policy.
          </li>
          <li>It does not apply to information collected by:</li>
          <li>
            Us offline or through any other means, including on any other
            website operated by Company or any third party; or
          </li>
          <li>
            Any third party, including through any application or content
            (including advertising) that may link to or be accessible from or on
            the Website.
          </li>
        </ul>
        <br />

        <Typography variant="body1" paragraph>
          Please read this Policy carefully to understand our policies and
          practices regarding your information and how we will treat it. If you
          do not agree with our policies and practices, your choice is not to
          use our Website. By accessing or using this Website, you agree to this
          Policy. This Policy may change from time to time (see Changes to Our
          Privacy Policy). Your continued use of this Website after we make
          changes is deemed to be acceptance of those changes, so please check
          the Policy periodically for updates.
        </Typography>

        <Typography variant="h4" component="h2" fontWeight={700} gutterBottom>
          <u>Persons Under the Age of 16</u>
        </Typography>
        <Typography variant="body1" paragraph>
          Our Website is not intended for persons under 18 years of age. No one
          under age 18 may provide any information to or on the Website. We do
          not knowingly collect personal information from persons under 18. If
          you are under 18, do not use or provide any information on this
          Website or on or through any of its features. If we learn we have
          collected or received personal information from a person under 18
          without verification of parental consent, we will delete that
          information. If you believe we might have any information from or
          about a person under 16, please contact us at: [
          <a
            href="mailto:support@godizel.com"
            target="_blank"
            style={{ color: "blue", borderBottom: "1px solid blue" }}
          >
            support@godizel.com
          </a>
          ].
        </Typography>
        <Typography variant="body1" paragraph>
          California residents under 16 years of age may have additional rights
          regarding the collection and sale of their personal information.
          Please see Your California Privacy Rights for more information.
        </Typography>

        <Typography variant="h4" component="h2" fontWeight={700} gutterBottom>
          <u>Information We Collect About You and How We Collect It</u>
        </Typography>
        <Typography variant="body1" paragraph>
          We collect several types of information from and about users of our
          Website, including information:
        </Typography>
        <ul>
          <li>
            By which you may be personally identified, such as name, postal
            address, e-mail address, telephone number, or any other identifier
            by which you may be contacted online or offline (
            <strong>“personal information”</strong>);
          </li>
          <li>
            That is about you but individually does not identify you, such as
            such as payment information (including, last four digits of your
            credit card and its expiration date); and/or
          </li>
          <li>
            About your internet connection, uniform resource locator, the
            equipment you use to access our Website, and usage details.
          </li>
        </ul>

        <Typography variant="body1" paragraph>
          We collect this information:
        </Typography>
        <ul>
          <li>Directly from you when you provide it to us.</li>
          <li>
            Automatically as you navigate through the site. Information
            collected automatically may include usage details, IP addresses, and
            information collected through cookies, web beacons, and other
            tracking technologies.
          </li>
          <li>From third parties, for example, our business partners.</li>
        </ul>

        <Typography variant="h4" component="h2" fontWeight={700} gutterBottom>
          <u>Information You Provide to Us.</u>
        </Typography>
        <Typography variant="body1" paragraph>
          The information we collect on or through our Website may include:
        </Typography>
        <ul>
          <li>
            Information that you provide by filling in forms on our Website.
            This includes information provided at the time of subscribing to our
            service or requesting further services. We may also ask you for
            information when you enter a contest or promotion sponsored by us,
            and when you report a problem with our Website.
          </li>
          <li>
            Records and copies of your correspondence (including email
            addresses) if you contact us.
          </li>
          <li>
            Your responses to surveys that we might ask you to complete for
            research purposes.
          </li>
          <li>
            Details of transactions you carry out through our Website and of the
            fulfillment of your orders. You may be required to provide financial
            information before placing an order through our Website; however,
            all payments and order processing are handled by a third-party
            payment processor.
          </li>
          <li>Your search queries on the Website.</li>
        </ul>
        <br />

        <Typography variant="h4" component="h2" fontWeight={700} gutterBottom>
          <u>
            Information We Collect Through Automatic Data Collection
            Technologies.
          </u>
        </Typography>
        <Typography variant="body1" paragraph>
          As you navigate through and interact with our Website, we may use
          automatic data collection technologies to collect certain information
          about your equipment, browsing actions, and patterns, including:
        </Typography>
        <ul>
          <li>
            Details of your visits to our Website, including traffic data,
            location data, logs, and other communication data and the resources
            that you access and use on the Website.
          </li>
          <li>
            Information about your computer and internet connection, including
            your IP address, operating system, and browser type. The information
            we collect automatically may include personal information, or we may
            maintain it or associate it with personal information we collect in
            other ways or receive from third parties. It helps us to improve our
            Website and to deliver a better and more personalized service,
            including by enabling us to:
          </li>
          <li>Estimate our audience size and usage patterns</li>
          <li>
            Store information about your preferences, allowing us to customize
            our Website according to your individual interests.
          </li>
          <li>Speed up your searches.</li>
          <li>Recognize you when you return to our Website.</li>
        </ul>
        <br />

        <Typography variant="body1" paragraph>
          The technologies we use for this automatic data collection may
          include:
        </Typography>
        <ul>
          <li>
            <strong>Cookies (or browser cookies).</strong> A cookie is a small
            file placed on the hard drive of your computer. You may refuse to
            accept browser cookies by activating the appropriate setting on your
            browser. However, if you select this setting, you may be unable to
            access certain parts of our Website. Unless you have adjusted your
            browser setting so that it will refuse cookies, our system will
            issue cookies when you direct your browser to our Website.
          </li>
          <li>
            <strong>Flash Cookies.</strong> Certain features of our Website may
            use local stored objects (or Flash cookies) to collect and store
            information about your preferences and navigation to, from, and on
            our Website. Flash cookies are not managed by the same browser
            settings as are used for browser cookies. For information about
            managing your privacy and security settings for Flash cookies, see
            Choices About How We Use and Disclose Your Information.
          </li>
          <li>
            <strong>Web Beacons.</strong> Pages of our Website and our emails
            may contain small electronic files known as web beacons (also
            referred to as clear gifs, pixel tags, and single-pixel gifs) that
            permit the Company, for example, to count users who have visited
            those pages or opened an email and for other related website
            statistics (for example, recording the popularity of certain website
            content and verifying system and server integrity).
          </li>
          <li>
            <strong>Analytics.</strong> Analytics are tools we use, such as
            Google Analytics, to help provide us with information about traffic
            to and use if our Website, which Google may share with other
            services and websites who use the collected data to contextualize
            and personalize the ads of its own advertising network.
          </li>
          <li>
            <strong>Mobile Application Technologies. </strong> If you access our
            Website through a mobile device, we may automatically collect
            information about your device, your phone number, and your physical
            location.
          </li>
          <li>
            <strong>
              Behavioral Targeting/Re-Targeting/Intelligent Advertising.
            </strong>{" "}
            We partner with a third-party ad network to either display
            advertising on Website or to manage our advertising on other sites.
            We may also use technologies to send you follow up communications
            related to products you may have viewed or added to your cart but
            not purchased. Our ad network partner uses cookies and web beacons
            to collect information about your activities on this and other
            websites and social media platforms to provide you targeted
            advertising based upon your interests, available information and
            online activities.
          </li>
        </ul>
        <br />

        <Typography variant="h4" component="h2" fontWeight={700} gutterBottom>
          <u>
            Third-Party Use of Cookies and Other Tracking Technologies; Linkes
            to other Websites
          </u>
        </Typography>
        <Typography variant="body1" paragraph>
          Some content or applications, including advertisements, on the Website
          are served by third-parties, including advertisers, ad networks and
          servers, content providers, and application providers. These third
          parties may use cookies alone or in conjunction with web beacons or
          other tracking technologies to collect information about you when you
          use our website. The information they collect may be associated with
          your personal information or they may collect information, including
          personal information, about your online activities over time and
          across different websites and other online services. They may use this
          information to provide you with interest-based (behavioral)
          advertising or other targeted content.
        </Typography>
        <Typography variant="body1" paragraph>
          We encourage you to be aware when you leave our Website to read the
          privacy policies of other sites that may collect your information to
          learn about their use of cookies and other technology. We do not
          control these third parties’ tracking technologies or how they may be
          used. If you have any questions about an advertisement or other
          targeted content, you should contact the responsible provider
          directly. For information about how you can opt out of receiving
          targeted advertising from many providers, see Choices About How We Use
          and Disclose Your Information.
        </Typography>
        <Typography variant="body1" paragraph>
          The information we collect is covered by this Policy, and the
          information the third parties or social media platforms collect is
          subject to the third-party website or platform’s privacy practices.
          Our Website may contain links to other websites or services that are
          not owned or controlled by us, including links to social media
          platforms such as Facebook, Instagram, Twitter and TikTok, or may
          redirect you off our website away from our Website to other websites
          for information, other services, or to receive special offers,
          contests, games, sweepstakes, or for transactions or purchases.
        </Typography>

        <Typography variant="h4" component="h2" fontWeight={700} gutterBottom>
          <u>How We Use Your Information</u>
        </Typography>

        <Typography variant="body1" paragraph>
          We use information that we collect about you or that you provide to
          us, including any personal information:
        </Typography>
        <ul>
          <li>To present our Website and its contents to you.</li>
          <li>
            To provide you with information, products, or services that you
            request from us.
          </li>
          <li>
            To fulfill any other purpose for which you provide it, including,
            without limitation, processing of orders and payment.
          </li>
          <li>
            To carry out our obligations and enforce our rights arising from any
            contracts entered into between you and us, including for billing and
            collection.
          </li>
          <li>
            To notify you about changes to our Website or any products or
            services we offer or provide though it.
          </li>
          <li>
            To allow you to participate in interactive features on our Website.
          </li>
          <li>
            In any other way we may describe when you provide the information.
          </li>
          <li>For any other purpose with your consent.</li>
        </ul>
        <br />
        <Typography variant="body1" paragraph>
          We may also use your information to contact you about our own and
          third-parties’ goods and services that may be of interest to you. If
          you do not want us to use your information in this way, please check
          the relevant box located on the form on which we collect your data
          (the order form). For more information, see Choices About How We Use
          and Disclose Your Information.
        </Typography>
        <Typography variant="body1" paragraph>
          We may use the information we have collected from you to enable us to
          display advertisements to our advertisers’ target audiences. Even
          though we do not disclose your personal information for these purposes
          without your consent, if you click on or otherwise interact with an
          advertisement, the advertiser may assume that you meet its target
          criteria.
        </Typography>

        <Typography variant="h4" component="h2" fontWeight={700} gutterBottom>
          <u>Disclosure of Your Information</u>
        </Typography>
        <Typography variant="body1" paragraph>
          We may disclose aggregated information about our users, and
          information that does not identify any individual, without
          restriction.
        </Typography>
        <Typography variant="body1" paragraph>
          We may disclose personal information that we collect or you provide as
          described in this Policy:
        </Typography>

        <ul>
          <li>To our subsidiaries and affiliates.</li>
          <li>
            To contractors, service providers, and other third parties we use to
            support our business, including, without limitation, third-party
            payment processors.
          </li>
          <li>
            To a buyer or other successor in the event of a merger, divestiture,
            restructuring, reorganization, dissolution, or other sale or
            transfer of some or all of Dizel, Inc.’s assets, whether as a going
            concern or as part of bankruptcy, liquidation, or similar
            proceeding, in which personal information held by Dizel, Inc. about
            our Website users is among the assets transferred.
          </li>
          <li>
            To third parties to market their products or services to you if you
            have consented to these disclosures. For more information, see
            Choices About How We Use and Disclose Your Information.
          </li>
          <li>To fulfill the purpose for which you provide it.</li>
          <li>
            For any other purpose disclosed by us when you provide the
            information.
          </li>
          <li>With your consent.</li>
          <li>We may also disclose your personal information:</li>
          <li>
            To comply with any court order, law, or legal process, including to
            respond to any government or regulatory request.
          </li>
          <li>
            To enforce or apply our Terms and Conditions and other agreements,
            including for billing and collection purposes.
          </li>
          <li>
            If we believe disclosure is necessary or appropriate to protect the
            rights, property, or safety of Dizel, Inc., our customers, or
            others. This includes exchanging information with other companies
            and organizations for the purposes of fraud protection and credit
            risk reduction.
          </li>
        </ul>
        <br />

        <Typography variant="h4" component="h2" fontWeight={700} gutterBottom>
          <u>Choices About How We Use and Disclose Your Information</u>
        </Typography>
        <Typography variant="body1" paragraph>
          We strive to provide you with choices regarding the personal
          information you provide to us. We have created mechanisms to provide
          you with the following control over your information:
        </Typography>
        <ul>
          <li>
            <strong>Tracking Technologies and Advertising.</strong> You can set
            your browser to refuse all or some browser cookies, or to alert you
            when cookies are being sent. To learn how you can manage your Flash
            cookie settings, visit the Flash player settings page on Adobe’s
            website. If you disable or refuse cookies, please note that some
            parts of this site may then be inaccessible or not function
            properly.
          </li>
          <li>
            <strong>
              Disclosure of Your Information for Third-Party Advertising.
            </strong>{" "}
            If you do not want us to share your personal information with
            unaffiliated or non-agent third parties for promotional purposes,
            you can opt out by sending us an email stating your request to
            [EMAIL ADDRESS].
          </li>
          <li>
            <strong>Promotional Offers from the Company.</strong> If you do not
            wish to have your email address used by the Company to promote our
            own or third parties’ products or services, you can opt out by
            sending us an email stating your request to{" "}
            <a
              href="mailto:support@godizel.com"
              target="_blank"
              style={{ color: "blue", borderBottom: "1px solid blue" }}
            >
              support@godizel.com
            </a>
            . If we have sent you a promotional email, you may send us a return
            email asking to be omitted from future email distributions
          </li>
          <li>
            <strong>Targeted Advertising.</strong> If you do not want us to use
            information that we collect or that you provide to us to deliver
            advertisements according to our advertisers’ target-audience
            preferences, you can opt out by sending us an email stating your
            request to{" "}
            <a
              href="mailto:support@godizel.com"
              target="_blank"
              style={{ color: "blue", borderBottom: "1px solid blue" }}
            >
              support@godizel.com
            </a>
            . For this opt-out to function, you must have your browser set to
            accept all browser cookies.
          </li>
        </ul>
        <br />
        <Typography variant="body1" paragraph>
          We do not control third parties’ collection or use of your information
          to serve interest-based advertising. However, these third parties may
          provide you with ways to choose not to have your information collected
          or used in this way. You can opt out of receiving targeted ads from
          members of the Network Advertising Initiative (<strong>“NAI”</strong>)
          on the NAI’s website.
        </Typography>

        <Typography variant="h4" component="h2" fontWeight={700} gutterBottom>
          <u>Notice to Certain Residents</u>
        </Typography>
        <Typography variant="body1" paragraph>
          <i>Persons in the European Union or European Economic Area</i>
        </Typography>

        <Typography variant="body1" paragraph>
          To the extent any European data protection laws apply, and we hold any
          of your “personal data” as defined in such laws (which may include
          some or all of your information as defined in this Policy), and we
          hold such personal data in our capacity as a data controller as
          defined under those laws, you may request that we:
        </Typography>
        <ul>
          <li>
            Restrict the way that we process and share your personal data;
          </li>
          <li>Transfer your personal data to a third party;</li>
          <li>Revoke your consent for processing of your personal data;</li>
          <li>Provide you with access to your personal data;</li>
          <li>
            Remove your personal data if no longer necessary for the purposes
            collected;
          </li>
          <li>
            Update your personal data so it is correct and not out of date;
            and/or
          </li>
          <li>Object to our processing of your personal data.</li>
        </ul>
        <br />
        <Typography variant="body1" paragraph>
          If you wish to object to the use and processing of your personal data
          or withdraw consent to this Policy, you can contact us at &nbsp;
          <a
            href="mailto:support@godizel.com"
            target="_blank"
            style={{ color: "blue", borderBottom: "1px solid blue" }}
          >
            support@godizel.com
          </a>
          .
        </Typography>
        <Typography variant="body1" paragraph>
          The requests above will be considered and responded to in the
          time-period stated by applicable law. Note, certain information may be
          exempt from such requests. We may require additional information from
          you to confirm your identity in responding to such requests. You have
          the right to lodge a complaint with the supervisory authorities
          applicable to you and your situation, although we invite you to
          contact us with any concern as we would be happy to try and resolve it
          directly. Please contact us at{" "}
          <a
            href="mailto:support@godizel.com"
            target="_blank"
            style={{ color: "blue", borderBottom: "1px solid blue" }}
          >
            support@godizel.com
          </a>
          .
        </Typography>
        <Typography variant="body1" paragraph>
          As described throughout this Policy, we believe the foregoing handling
          of your information, which may include your personal data, furthers
          our legitimate interests in commercial activities that are not
          overridden by the interest or fundamental rights and freedoms of the
          individuals at issue. Depending on what personal data we collect from
          you and how we collect it, we rely on various grounds for processing
          your personal data, including the following reasons:
        </Typography>
        <ul>
          <li>
            To administer our contractual relationship, including setting up any
            services you may request;
          </li>
          <li>
            Because it is in our legitimate interest to operate our business
            effectively and efficiently, and provide you with the services
            offered through the Website and other useful content and for other
            marketing, design, and advertising purposes;
          </li>
          <li>
            To process employee data, prevent against fraud, provide technology
            security, and other necessary Company operational matters;
          </li>
          <li>
            In order to fulfill any legal obligations, we may have to collect
            this information from you; and/or
          </li>
          <li>Because you have provided your consent for us to do so.</li>
        </ul>
        <Typography variant="body1" paragraph>
          Our Website is operated in the United States. Please be aware that
          information, including personal data, that we collect will be
          transferred to, stored, and processed in the United States, a
          jurisdiction in which the privacy laws may not be as comprehensive as
          those in the country where you reside and/or are a citizen. We collect
          and transfer to the U.S. only information, including personal data:
          with your consent; to perform a contract with you; or to fulfill a
          compelling legitimate interest of Dizel, Inc. in a manner that does
          not outweigh your rights and freedoms. We endeavor to apply suitable
          safeguards to protect the privacy and security of your personal data
          and to use it only consistent with your relationship with us and the
          practices described in this Policy. We also enter into data processing
          agreements and model clauses with vendors whenever feasible and
          appropriate.
        </Typography>

        <Typography variant="h5" component="h2" fontWeight={700} gutterBottom>
          <i>California Residents </i>
        </Typography>

        <Typography variant="body1" paragraph>
          To the extent any California data privacy law applies, we will provide
          California residents with certain information upon request (
          <strong>“Data Subject Request”</strong>). This Policy outlines how
          California residents can receive the information and what you can
          receive.
        </Typography>
        <Typography variant="body1" paragraph>
          If you would like to submit a Data Subject Request, please email us at{" "}
          <a
            href="mailto:support@godizel.com"
            target="_blank"
            style={{ color: "blue", borderBottom: "1px solid blue" }}
          >
            support@godizel.com
          </a>
          . If you choose to submit a Data Subject Request, you must provide us
          with enough information to identify you and enough specificity on the
          requested data. We will only use the information we receive for
          verification. We will not be able to disclose information if it cannot
          verify that the person making the Data Subject Request is the person
          about whom we collected information, or someone authorized to act on
          such person’s behalf.
        </Typography>
        <Typography variant="body1" paragraph>
          Please note that the categories of personal information and sources
          will not exceed what is contained in this Policy. Additionally, we are
          not required to retain any information about you if it is only used
          for a one-time transaction and would not be maintained in the ordinary
          course of business. We are also not required to reidentify personal
          information if it is not stored in that manner already, nor is it
          required to provide the personal information to you more than twice in
          a twelve-month period.
        </Typography>
        <Typography variant="body1" paragraph>
          You may request that we delete your personal information. Your
          deletion request should be submitted through a Data Subject Request.
          Subject to certain exceptions set out below we will, on receipt of a
          verifiable Data Subject Request, delete your personal information from
          our records and direct any service providers to do the same.
        </Typography>
        <Typography variant="body1" paragraph>
          Please note that we may not delete your personal information if it is
          necessary to:
        </Typography>
        <ul>
          <li>
            complete the transaction for which the personal information was
            collected;
          </li>
          <li>
            provide a good or service requested by you, or reasonably
            anticipated within the context of our ongoing business relationship
            with you, or otherwise perform a contract between you and us;
          </li>
          <li>
            detect security incidents, protect against malicious, deceptive
            activity, and take all necessary and appropriate steps to mitigate
            current and future risk;
          </li>
          <li>
            debug and repair internal information technology as necessary;
          </li>
          <li>
            undertake internal research for technological development and
            demonstration;
          </li>
          <li>
            exercise free speech, ensure the right of another consumer to
            exercise his or her right of free speech, or exercise another right
            provided for by law;
          </li>
          <li>
            comply with the California Electronic Communications Privacy Act;
          </li>
          <li>
            engage in public or peer-reviewed scientific, historical, or
            statistical research in the public interest that adheres to all
            other applicable ethics and privacy laws, when our deletion of the
            information is likely to render impossible or seriously impair the
            achievement of such research, provided we have obtained your
            informed consent;
          </li>
          <li>
            enable solely internal uses that are reasonably aligned with your
            expectations based on your relationship with us;
          </li>
          <li>comply with an existing legal obligation; or</li>
          <li>
            otherwise use your personal information, internally, in a lawful
            manner that is compatible with the context in which you provided the
            information.
          </li>
        </ul>
        <br />
        <Typography variant="body1" paragraph>
          We may not, and will not, treat you differently because of your Data
          Subject Request activity. As a result of your Data Subject Request
          activity, we may not and will not deny goods or services to you,
          charge different rates for goods or services, provide a different
          level quality of goods or services, or suggest any of the preceding
          will occur. However, we can and may charge you a different rate, or
          provide a different level of quality, if the difference is reasonably
          related to the value provided by your personal information.
        </Typography>
        <Typography variant="body1" paragraph>
          In addition, if you are a California resident under the age of 18 and
          are a registered user, you may request us to remove content or
          information posted on our websites or stored on our servers by (a)
          submitting a request in writing to{" "}
          <a
            href="mailto:support@godizel.com"
            target="_blank"
            style={{ color: "blue", borderBottom: "1px solid blue" }}
          >
            support@godizel.com
          </a>
          , and (b) clearly identifying the content or information you wish to
          have removed and providing sufficient information to allow us to
          locate the content or information to be removed. However, please note
          that we are not required to erase or otherwise eliminate content or
          information if (i) other state or federal laws require us or a third
          party to maintain the content or information; (ii) the content or
          information was posted, stored, or republished by another user; (iii)
          the content or information is anonymized so that the minor cannot be
          individually identified; (iv) the minor does not follow the
          instructions posted in this Policy on how to request removal of such
          content or information; and (v) the minor has received compensation or
          other consideration for providing the content. Further, nothing in
          this provision shall be construed to limit the authority of a law
          enforcement agency to obtain such content or information.
        </Typography>

        <Typography variant="h5" component="h2" fontWeight={700} gutterBottom>
          <i>Colorado, Connecticut, Virginia, and Utah Residents</i>
        </Typography>

        <Typography variant="body1" paragraph>
          Colorado, Connecticut, Virginia, and Utah each provide their state
          residents with rights to:
        </Typography>
        <ul>
          <li>Confirm whether we process their personal information.</li>
          <li>Access and delete certain personal information.</li>
          <li>Data portability.</li>
          <li>
            Opt-out of personal data processing for targeted advertising and
            sales.
          </li>
        </ul>
        <br />
        <Typography variant="body1" paragraph>
          Colorado, Connecticut, and Virginia also provide their state residents
          with rights to:
        </Typography>
        <ul>
          <li>
            Correct inaccuracies in their personal information, taking into
            account the information’s nature processing purpose.
          </li>
          <li>
            Opt-out of profiling in furtherance of decisions that produce legal
            or similarly significant effects.
          </li>
        </ul>
        <Typography variant="body1" paragraph>
          To exercise any of these rights please contact us at{" "}
          <a
            href="mailto:support@godizel.com"
            target="_blank"
            style={{ color: "blue", borderBottom: "1px solid blue" }}
          >
            support@godizel.com
          </a>
          .
        </Typography>

        <Typography variant="h5" component="h2" fontWeight={700} gutterBottom>
          <i>Nevada Residents</i>
        </Typography>
        <Typography variant="body1" paragraph>
          Nevada provides its residents with a limited right to opt-out of
          certain personal information sales. Residents who wish to exercise
          this sale opt-out rights may submit to{" "}
          <a
            href="mailto:support@godizel.com"
            target="_blank"
            style={{ color: "blue", borderBottom: "1px solid blue" }}
          >
            support@godizel.com
          </a>
          .
        </Typography>

        <Typography variant="h4" component="h2" fontWeight={700} gutterBottom>
          <u>Data Security</u>
        </Typography>
        <Typography variant="body1" paragraph>
          We have implemented measures designed to secure your personal
          information from accidental loss and from unauthorized access, use,
          alteration, and disclosure. Any payment transactions will be
          encrypted.
        </Typography>
        <Typography variant="body1" paragraph>
          The safety and security of your information also depends on you. Where
          we have given you (or where you have chosen) a password for access to
          certain parts of our Website, you are responsible for keeping this
          password confidential. We ask you not to share your password with
          anyone.
        </Typography>
        <Typography variant="body1" paragraph>
          Unfortunately, the transmission of information via the internet is not
          completely secure. Although we do our best to protect your personal
          information, we cannot guarantee the security of your personal
          information transmitted to our Website. Any transmission of personal
          information is at your own risk. We are not responsible for
          circumvention of any privacy settings or security measures contained
          on the Website.
        </Typography>

        <Typography variant="h4" component="h2" fontWeight={700} gutterBottom>
          <u>Changes to Our Privacy Policy</u>
        </Typography>
        <Typography variant="body1" paragraph>
          It is our policy to post any changes we make to this Policy on this
          page. If we make material changes to how we treat our users’ personal
          information, we will notify you by posting a notice on the homepage of
          the Website. The date the Policy was last revised is identified at the
          top of the page. You are responsible for periodically visiting our
          Website and this Policy to check for any changes. Your continued use
          of this Website constitutes your acceptance of any updates to this
          Policy.
        </Typography>

        <Typography variant="h4" component="h2" fontWeight={700} gutterBottom>
          <u>Contact Information</u>
        </Typography>
        <Typography variant="body1" paragraph>
          To ask questions or comment about this Policy and our privacy
          practices, contact us at:
        </Typography>
        <Typography variant="body1" paragraph>
        4801 Linton Blvd #11A PMB# 444, <br />
          Delray Beach, FL 33445
          <br />
          <a
            href="mailto:support@godizel.com"
            target="_blank"
            style={{ color: "blue", borderBottom: "1px solid blue" }}
          >
            support@godizel.com
          </a>
        </Typography>
      </Box>
    </Container>
  );
};

export default PrivacyPolicy;
