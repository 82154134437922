import { Box, Button, Typography, Stack } from "@mui/material";
import React, { useEffect } from "react";
import "./Notifications.css";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  notificationSeen,
  resetNotification,
} from "../StateSlices/notificationSlice";
import { getuserInfo } from "../StateSlices/getuserSlice";
import noitem from "../../assets/no-item.png"

const Notifications = () => {
  const dispatch = useDispatch();

  const { status, userInfo, profile } = useSelector((state) => state.user);
  const { notificationMessage } = useSelector((state) => state.notification);

  useEffect(() => {
    if (
      userInfo && 
      userInfo.userNotification &&
      userInfo.userNotification.notification.length > 0
    ) {
      const countSeenFalse = userInfo.userNotification.notification.filter(
        (item) => item.seen === false
      ).length;
      if (countSeenFalse > 0) {
        let token = localStorage.getItem("inktoken");
        dispatch(notificationSeen({ token }));
      }
    }
  }, []);

  useEffect(() => {
    if (notificationMessage) {
      dispatch(resetNotification());
      let token = localStorage.getItem("inktoken");
      dispatch(getuserInfo({ token }));
    }
  }, [notificationMessage]);

  const notifications = [1, 2, 3, 4];
  return (
    <Box component={"div"}>
      <Box component={"div"} marginTop={"2rem"}>
        <Typography
          variant="h2"
          mt={"4rem"}
          mb={"1rem"}
          className="title mont-title"
        >
          My Notifications
        </Typography>
        <Box marginTop={"2rem"}>
          {userInfo &&
          userInfo.userNotification &&
          userInfo.userNotification.notification.length > 0 ? (
            <>
              {userInfo.userNotification.notification.map((notif) => (
                <Box
                  component={"div"}
                  key={notif}
                  className="notif-card"
                  gap={2}
                  mt={"1rem"}
                >
                  <Box component={"div"} className="notif-line-divider">
                    &nbsp;
                  </Box>
                  <Box component={"div"}>
                    <Typography variant="h3" my={1}>
                      {`Equipment ${notif.eventName}`}
                    </Typography>
                    <Typography variant="h6" my={1} color={"var(--grey-color)"}>
                      <strong style={{ color: "var(--white-color)" }}>
                        Name:{" "}
                      </strong>
                      {notif.productName}
                    </Typography>
                    <Typography variant="h6" my={1} color={"var(--grey-color)"}>
                      <strong style={{ color: "var(--white-color)" }}>
                        Date:{" "}
                      </strong>
                      {new Date(notif.createdAt).toLocaleDateString()}
                    </Typography>
                    <Typography variant="h6" my={1} color={"var(--grey-color)"}>
                      <strong style={{ color: "var(--white-color)" }}>
                        Price {notif.eventName=="purchase"?"Earned":"Paid"}:{" "}
                      </strong>
                      $ {notif.pricePaid.toLocaleString("en-US") }
                    </Typography>
                    <Typography variant="h6" my={1} color={"var(--grey-color)"}>
                      <strong style={{ color: "var(--white-color)" }}>
                        Address:{" "}
                      </strong>
                      {notif.address}
                    </Typography>
                  </Box>
                  <Box component={"div"} className="notif-btns">
                    {/* <Link
                      to={"/product/" + notif.productId}
                      state={{ id: notif.productId }}
                      style={{
                        textDecoration: "none",
                      }}
                    > */}
                    <Link to={`/order-details/${notif.orderId}/${notif.productId}`}>
                      <Button
                        variant="contained"
                        fullWidth
                        sx={{
                          color: "var(--black-color)",
                          background: "var(--white-color)",
                          my: 1,
                          fontSize: "16px",
                          border: "none",
                          "&:hover": { background: "var(--grey-color)" },
                        }}
                      >
                        View Details
                      </Button>
                    </Link>
                    <Button
                      // href={`mailto:${notif.contact}`}
                      onClick={(e)=>{
                        e.preventDefault();
                        
                        window.open(
                          `mailto:${notif.contact}`,
                          "_blank"
                        )
                      }}
                      variant="contained"
                      fullWidth
                      sx={{
                        color: "var(--black-color)",
                        fontSize: "16px",
                        background: "var(--white-color)",
                        my: 1,
                        border: "none",
                        "&:hover": { background: "var(--grey-color)" },
                      }}
                      // target="_blank"
                    >
                      Contact{" "}
                      {notif.eventName === "ordered" ? "Asset Owner" : "Buyer"}
                    </Button>
                  </Box>
                </Box>
              ))}
            </>
          ) : (
            <Stack
              direction={"column"}
              justifyContent={"center"}
              alignItems={"center"}
              minHeight={"60vh"}
              width={"100%"}
              sx={{
                background:`url(${noitem})`,
                backgroundSize:"cover",
              }}
            >
              <Typography color={"var(--black-color)"} variant="h3">
                No new notifications found!
              </Typography>
            </Stack>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default Notifications;
