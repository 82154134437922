import React, { useEffect, useState } from "react";
import {
  Container,
  Typography,
  Checkbox,
  Button,
  FormGroup,
  FormControlLabel,
  Box,
  Backdrop,
  Fade,
  Modal,
} from "@mui/material";
import { styled } from "@mui/system";
import { Link } from "react-router-dom";
import FileUploadDragAndDrop from "./FileUploadDragAndDrop";
import { toast } from "react-toastify";
import DocViewer, { DocViewerRenderers } from "react-doc-viewer";
import { useSelector } from "react-redux";
import { FaFileAlt } from "react-icons/fa";

// const AgreementContainer = styled(Container)(({ theme }) => ({
//   display: "flex",
//   flexDirection: "column",
//   //   alignItems: "center",
//   padding: theme.spacing(3),
//   maxWidth: "600px",
//   margin: "auto",
// }));

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "75%",
  height: "75vh",
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: "10px",
};

let toastOption = {
  position: "top-right",
  autoClose: 1500,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: "dark",
};
const CheckoutUploadInsurance = ({ handleNext, fileAgreement, setFileAgreement,   setSaveInsurance, docuSignByOwn,   docusignDone,
  setDocusignDone,
  setDocuSignByOwn, }) => {
  const [data, setData] = useState("");
  const [filePreviewModal, setFilePreviewModal] = useState(false);
  const [byown, setByown] = useState(false);
  const [Files, SetFiles] = useState(fileAgreement ? [fileAgreement] : []);

  const { userInfo, error, profile } = useSelector((state) => state.user);
  
  useEffect(() => {
    if(userInfo && userInfo.userDetailsId.agreement){
      setFileAgreement(userInfo.userDetailsId.agreement);
      SetFiles([userInfo.userDetailsId.agreement])
      localStorage.setItem("localUpload",
        userInfo.userDetailsId.agreement
      )
    }
  }, [userInfo]);


  //   useEffect(() => {
  //     let token = localStorage.getItem("inktoken");
  //     if (token) {
  //       setSpinner(true);
  //       dispatch(getuserInfo({ token: token }));
  //       window.scrollTo(0, 0);
  //     } else {
  //       navigate("/login");
  //     }
  // }, []);

  const uploadFileAgreement = () => {
    window.scrollTo(0, 0);
    setFileAgreement(Files[0] ? Files[0] : data);
    localStorage.setItem("localUpload",
      JSON.stringify(Files[0] ? Files[0] : data)
    )
    toast.success("File Saved Successfully", toastOption);
    setFilePreviewModal(false);
    // setActiveStep((prev) => prev + 1);
  };

  useEffect(() => {
    console.log("FILE",Files)
    if(Files.length > 0){
      setByown(true);
    }
  }, [Files]);

  return (
    <Container
      maxWidth="lg"
      sx={{ margin: "2rem auto", paddingLeft: 0, paddingRight: 0 }}
      data-aos="fade-in"
      data-aos-duration="1000"
    >
      <Box component={"section"} className="upload-rental-ins-con">
                <Box marginBottom={".5rem"}>
                  <Typography
                    variant="h3"
                    margin={"0 0 2rem 0"}
                    textAlign={"center"}
                    fontWeight={700}
                  >
                    Upload Rental Insurance (Optional)
                  </Typography>
                </Box>

                <div className="checkout-file-upload-con">
                  <FileUploadDragAndDrop
                    SetFiles={SetFiles}
                    Files={Files}
                    filePreviewModal={filePreviewModal}
                    setFilePreviewModal={setFilePreviewModal}
                    setFileAgreement={setFileAgreement}
                    setByown={setByown}
                    userInfo={userInfo}
                    setData={setData}
                    docusignDone={docusignDone}
                    setDocusignDone={setDocusignDone}
                    setDocuSignByOwn={setDocuSignByOwn}
                  />
                </div>

                {byown && (
                  <Box textAlign={"center"}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          color="secondary"
                          name="saveDocument"
                          value="yes"
                          defaultChecked={
                            userInfo.userDetailsId.insuranceCheckBox !==
                            undefined
                              ? userInfo.userDetailsId.insuranceCheckBox
                              : true
                          }
                          onChange={() => setSaveInsurance((prev) => !prev)}
                        />
                      }
                      label="Save insurance document for easy checkout next time"
                    />
                  </Box>
                )}
              </Box>

              <div className="checkout-file-upload-con">
                      {data && (
                        <div className="kb-attach-box">
                          <hr />

                          <div
                            className="file-atc-box"
                            style={{ marginBottom: "1.5rem" }}
                          >
                            <div className="file-image">
                              <FaFileAlt />
                            </div>
                            <div className="file-detail">
                              <h6>File: {data}</h6>

                              <div className="file-actions">
                                <button
                                  href={data}
                                  className="file-action-btn"
                                  onClick={() => setFilePreviewModal(true)}
                                >
                                  Preview
                                </button>
                                <a
                                  href={data}
                                  className="file-action-btn"
                                  download={data}
                                >
                                  Download
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}

                      {/* {!docuSignByOwn && savedType === "docusign" && (
                        <Typography
                          color={"green"}
                          variant="body2"
                          textAlign={"center"}
                        >
                          Docusign Document Signed Successfully!
                          <br />
                          You'll receive an email once checkout completes.
                        </Typography>
                      )} */}
                    </div>


    
      <Box textAlign="center">
        <Button
          variant="contained"
          color="primary"
          sx={{
            marginTop: "1rem",
            background: "var(--secondary-color)",
            "&: hover": {
              background: "var(--grey-color)",
              color: "var(--black-color)",
            },
          }}
          // disabled={!areAllAgreementsChecked()}
          onClick={() => {
            if(data){
              if (data) {
                uploadFileAgreement();
              }
            }
            handleNext()}}
        >
          Next
        </Button>
      </Box>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={filePreviewModal}
        onClose={() => setFilePreviewModal(false)}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={filePreviewModal}>
          <Box sx={modalStyle}>
            {Files.length > 0 || data ? (
              <>
                <DocViewer
                  config={{
                    header: {
                      disableHeader: false,
                      disableFileName: false,
                       retainURLParams: false
                    },
                  }}
                  style={{ height: "94%", borderRadius: "10px" }}
                  pluginRenderers={DocViewerRenderers}
                  documents={[
                    // {
                    //   uri: 'https://upload.wikimedia.org/wikipedia/commons/thumb/8/87/PDF_file_icon.svg/534px-PDF_file_icon.svg.png'
                    // }
                    {
                      uri:
                        (Files[0] && typeof Files[0] != "string") ||
                        (byown && typeof Files[0] != "string")
                          ? Files[0].file
                          : data,
                    },
                  ]}
                />
                <Button
                  variant="contained"
                  sx={{ my: "1rem", background: "var(--secondary-color)" }}
                  onClick={uploadFileAgreement}
                  fullWidth
                >
                  Upload
                </Button>
              </>
            ) : (
              <Box
                display={"flex"}
                alignItems={"center"}
                height={"100%"}
                justifyContent={"center"}
                border={"1px solid #ddd"}
                borderRadius={"5px"}
              >
                <Typography
                  variant="h4"
                  fontWeight={700}
                  color={"text.secondary"}
                >
                  Please Upload File for Preview
                </Typography>
              </Box>
            )}
          </Box>
        </Fade>
      </Modal>
    </Container>
  );
};

export default CheckoutUploadInsurance;
