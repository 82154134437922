import React, { useState } from "react";
import {
  Container,
  Typography,
  Box,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import { Link } from "react-router-dom";
import DocViewer, { DocViewerRenderers } from "react-doc-viewer";
import BlogBanner from "../components/BlogBanner/BlogBanner";

const Article1 = () => {
//   const [data, setData] = useState(
//     `${process.env.PUBLIC_URL}/assets/rental-terms.pdf`
//   );
  return (
    <>
    <BlogBanner/>
    <Container
      maxWidth="lg"
      sx={{ textAlign: "justify", marginBottom: "5rem" }}
    >
      <Box data-aos="fade-in" data-aos-duration={500}>
        <Typography
          variant="h1"
          textAlign={"left"}
          fontWeight={700}
          component="h1"
          gutterBottom
          marginTop={"2rem"}
        >
          Ever Thought About the Cost of Unused Construction Equipment? Here's Why Mobilizing Your Assets Could Save Millions
        </Typography>
        <>
        <div>
  <p style={{ lineHeight: '1.38', marginBottom: '12pt', marginTop: '12pt' }} dir="ltr">
    <span style={{ backgroundColor: 'transparent', color: '#000000',  }}>
      <span style={{ fontStyle: 'normal', fontVariant: 'normal', fontWeight: 400, textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>
        Imagine you have a toy that you were really excited about when you first got it, but now it just sits in your room, collecting dust. It’s still a great toy, but you don’t play with it anymore. Wouldn't it be smart to sell it or lend it to a friend who would use it? Well, something similar happens with construction equipment, like big machines and tools used to build roads, bridges, and buildings. When these machines sit unused, they can cost a company a lot of money. Let’s look at why keeping them moving can save millions of dollars.
      </span>
    </span>
  </p>
  
  <h2 style={{ lineHeight: '1.38', marginBottom: '6pt', marginTop: '14pt' }} dir="ltr">
    <span style={{ backgroundColor: 'transparent', color: '#000000',  }}>
      <span style={{ fontStyle: 'normal', fontVariant: 'normal', fontWeight: 400, textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>
        What Is Construction Equipment?
      </span>
    </span>
  </h2>
  
  <p style={{ lineHeight: '1.38', marginBottom: '12pt', marginTop: '12pt' }} dir="ltr">
    <span style={{ backgroundColor: 'transparent', color: '#000000',  }}>
      <span style={{ fontStyle: 'normal', fontVariant: 'normal', fontWeight: 400, textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>
        Construction equipment includes all the big machines and tools that help build things. Some examples are bulldozers, cranes, dump trucks, and excavators. They help make jobs faster and easier, but these machines also cost a lot to buy, repair, and maintain.
      </span>
    </span>
  </p>
  
  <h2 style={{ lineHeight: '1.38', marginBottom: '6pt', marginTop: '14pt' }} dir="ltr">
    <span style={{ backgroundColor: 'transparent', color: '#000000',  }}>
      <span style={{ fontStyle: 'normal', fontVariant: 'normal', fontWeight: 400, textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>
        The Problem With Unused Equipment
      </span>
    </span>
  </h2>
  
  <p style={{ lineHeight: '1.38', marginBottom: '12pt', marginTop: '12pt' }} dir="ltr">
    <span style={{ backgroundColor: 'transparent', color: '#000000',  }}>
      <span style={{ fontStyle: 'normal', fontVariant: 'normal', fontWeight: 400, textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>
        Imagine a construction company buys a big crane for $500,000 to help build a bridge. But once the bridge is done, the crane just sits there for months without being used. The company still has to pay for things like insurance, storage, and maintenance even though the crane is doing nothing. It’s like owning a pet and still having to buy food and take it to the vet, even if you never play with it! Over time, these unused machines can end up costing the company a lot of money.
      </span>
    </span>
  </p>
  
  <p style={{ lineHeight: '1.38', marginBottom: '12pt', marginTop: '12pt' }} dir="ltr">
    <span style={{ backgroundColor: 'transparent', color: '#000000',  }}>
      <span style={{ fontStyle: 'normal', fontVariant: 'normal', fontWeight: 400, textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>
        Not only is there the cost of taking care of the equipment, but machines that sit around unused also lose value over time. This is called "depreciation." For example, a car that sits in your driveway for years without being driven will be worth less and less every year. The same thing happens with construction equipment. The longer it sits, the less it’s worth.
      </span>
    </span>
  </p>
  
  <h2 style={{ lineHeight: '1.38', marginBottom: '6pt', marginTop: '14pt' }} dir="ltr">
    <span style={{ backgroundColor: 'transparent', color: '#000000',  }}>
      <span style={{ fontStyle: 'normal', fontVariant: 'normal', fontWeight: 400, textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>
        How Mobilizing Equipment Saves Money
      </span>
    </span>
  </h2>
  
  <p style={{ lineHeight: '1.38', marginBottom: '12pt', marginTop: '12pt' }} dir="ltr">
    <span style={{ backgroundColor: 'transparent', color: '#000000',  }}>
      <span style={{ fontStyle: 'normal', fontVariant: 'normal', fontWeight: 400, textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>
        Now, what if the company used that crane on another project, instead of letting it sit idle? Or what if they rented it out to another company that needs it? This way, the crane would stay in use, and the company could avoid paying for something that's just sitting around. By moving equipment around from one job to another, or renting it out, companies can save a lot of money.
      </span>
    </span>
  </p>
  
  <p style={{ lineHeight: '1.38', marginBottom: '12pt', marginTop: '12pt' }} dir="ltr">
    <span style={{ backgroundColor: 'transparent', color: '#000000',  }}>
      <span style={{ fontStyle: 'normal', fontVariant: 'normal', fontWeight: 400, textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>
        Think of it like this: If you let a friend borrow your bike while you're not using it, you can avoid spending money to fix it up later, and your friend might even give you a little money in return. The same goes for construction equipment. By keeping the machines in action, a company doesn’t waste money and may even earn extra by renting them out.
      </span>
    </span>
  </p>
  
  <h2 style={{ lineHeight: '1.38', marginBottom: '6pt', marginTop: '14pt' }} dir="ltr">
    <span style={{ backgroundColor: 'transparent', color: '#000000',  }}>
      <span style={{ fontStyle: 'normal', fontVariant: 'normal', fontWeight: 400, textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>
        Tracking Equipment
      </span>
    </span>
  </h2>
  
  <p style={{ lineHeight: '1.38', marginBottom: '12pt', marginTop: '12pt' }} dir="ltr">
    <span style={{ backgroundColor: 'transparent', color: '#000000',  }}>
      <span style={{ fontStyle: 'normal', fontVariant: 'normal', fontWeight: 400, textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>
        Some companies use special computer programs to track where their equipment is and how often it’s being used. These programs help them figure out which machines are sitting idle and which ones are working hard. This way, they can make smart choices about where to send their equipment next. It’s like keeping track of all your toys so you know which ones you don’t play with anymore and can lend to friends.
      </span>
    </span>
  </p>
  
  <h2 style={{ lineHeight: '1.38', marginBottom: '6pt', marginTop: '14pt' }} dir="ltr">
    <span style={{ backgroundColor: 'transparent', color: '#000000',  }}>
      <span style={{ fontStyle: 'normal', fontVariant: 'normal', fontWeight: 400, textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>
        Conclusion
      </span>
    </span>
  </h2>
  
  <p style={{ lineHeight: '1.38', marginBottom: '12pt', marginTop: '12pt' }} dir="ltr">
    <span style={{ backgroundColor: 'transparent', color: '#000000',  }}>
      <span style={{ fontStyle: 'normal', fontVariant: 'normal', fontWeight: 400, textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap' }}>
        Construction equipment can cost a lot of money, but companies can save millions by keeping their machines in use. Whether by moving them from job to job or renting them out, keeping the machines working instead of sitting idle helps prevent extra costs. Smart companies even use computer programs to track their machines and make sure they’re always on the move. So, just like lending your unused toys to a friend, construction companies can save a lot by putting their equipment to good use!
      </span>
    </span>
  </p>
</div>

        </>

      </Box>
    </Container>
    </>
  );
};

export default Article1;
