import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import axios from "axios"

const initialState = {
  userListStatus: "idle",
  userListError: null,
  userList:[]
}

export const getUserList = createAsyncThunk(
  "product/getUserList",
  async ({token}, { rejectWithValue }) => {
    try {
      const { data } = await axios.get(
        `/api/product/getuserlist`,
        {
          headers: {
              Authorization: `Bearer ${token}`,
          },
        }
      )
      return data
    } catch (err) {
      return rejectWithValue(err.response.data)
    }
  }
)
export const UserListSlice = createSlice({
  name: "userlist",
  initialState,
  reducers: {
    resetUserList(state,action){
        state.userList = []
        state.userListStatus="idle"
        state.userListError=null
      }
  },
  extraReducers: {
    [getUserList.pending]: (state, action) => {
      state.userListStatus = "loading"
    },
    [getUserList.fulfilled]: (state, action) => {
      state.userListStatus = "succeeded"
      state.userList = action.payload.userList;
    },
    [getUserList.rejected]: (state, action) => {
      state.userListStatus = "failed"
      state.userListError = action.payload.message
    },
  },
})

export const { resetUserList } = UserListSlice.actions
export default UserListSlice.reducer 