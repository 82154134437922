import React, { useState, useMemo, useEffect } from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-material.css";
import { ordersData } from "../dummyData";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getCheckoutOrders } from "../../StateSlices/adminSlice";
import "../Users/Users.css";
import AnalyticsHeader from "../AnalyticsHeader/AnalyticsHeader";
import { Pagination, PaginationItem, Stack } from "@mui/material";
import { ArrowBack, ArrowForward } from "@mui/icons-material";

const Orders = ({ tab, type }) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [spinner, setSpinner] = useState(false);
  const [pageChanged, setPageChanged] = useState(false);
  const [pageNumber, setPageNumber] = useState(
    location.search.slice(-1)
      ? location.search.slice(location.search.indexOf("=") + 1) - 1
      : 1
  );
  const handlePageChange = (e, value) => {
    if (value !== pageNumber) {
      window.scrollTo(0, 0);
      setPageChanged(true);
      setPageNumber(value);
    }
  };

  const agGridHeight = window.innerHeight - 150;

  const { allOrdersStatus, allOrdersInfo, allOrdersError, totalCount } =
    useSelector((state) => state.admin);

  const [rowData, setRowData] = useState(ordersData);
  const getDate = (params) => {
    const date = new Date(params.value);
    return (
      (date.getMonth() + 1).toString().padStart(2, "0") +
      "-" +
      date.getDate().toString().padStart(2, "0") +
      "-" +
      date.getFullYear()
    );
  };
  const defaultColDef = useMemo(() => ({
    filter: true,
    cellStyle: { fontFamily: "Helvetica, Arial, sans-serif" },
  }));
  const [colDefs, setColDefs] = useState([
    { headerName: "OrderId", field: "orderId" },
    { headerName: "Buyer Email", field: "buyerInfo.userEmail" },
    { headerName: "Asset Owner Email", field: "sellerInfo.userEmail" },
    { headerName: "Order Type", field: "products.type" },
    { headerName: "Order Status", 
      field: "products",
      valueFormatter: (params) => {
        // console.log("HHHHEEE",params)
        if(!params.value) return "-"
        let {sellerStatus, buyerStatus} = params.value
        if (buyerStatus == "complete" && sellerStatus == "complete"){
          return "Both Completed"
        }
        else if(buyerStatus == "complete"){
          return "Buyer Completed"
        }else if(sellerStatus == "complete"){
          return "Seller Completed"
        }else{
          return "Both Pending"
        }
      }
     },
    { headerName: "Product", field: "productInfo.itemName" },
    { headerName: "Shipping Address", field: "shippingAddress" },
    { headerName: "Price Paid", field: "products.price" },
    { headerName: "Fees", field: "fees" },
    { headerName: "Tax", field: "tax" },
    {
      headerName: "Checkout Date",
      field: "checkoutTime",
      valueFormatter: (params) => getDate(params),
    },
  ]);

  useEffect(() => {
    let token = localStorage.getItem("inktoken");
    if (token) {
      // console.log(pageNumber);
      setSpinner(true);
      dispatch(
        getCheckoutOrders({
          token,
          p: location?.state?.searchQuery ? 0 : pageNumber - 1,
          search: location?.state?.searchQuery || "",
          // p: location.search.slice(location.search.indexOf("=") + 1) - 1,
          // search: orderSearchVal,
        })
      );
      window.scrollTo(0, 0);
    } else {
      navigate("/404", { replace: true });
    }
  }, [pageNumber, location?.search?.slice(-1), tab, type]);

  useEffect(() => {
    if (allOrdersInfo && allOrdersInfo.length > 0) {
      setSpinner(false);
      setRowData(allOrdersInfo);
    }
  }, [allOrdersInfo]);
  return (
    <>
      <AnalyticsHeader
        placeholder="Asset Owner email"
        label={"orders"}
        searchQuery={location?.state?.searchQuery || ""}
      />
      <div className="ag-theme-material" style={{ height: agGridHeight }}>
        <AgGridReact
          defaultColDef={defaultColDef}
          rowData={rowData}
          columnDefs={colDefs}
        />
      </div>
      {totalCount && totalCount > 20 && (
        <Stack
          spacing={2}
          direction={"row"}
          justifyContent={"center"}
          sx={{ margin: "1rem", paddingBottom: "1rem" }}
        >
          <Pagination
            count={Math.ceil(totalCount / 20)}
            // variant="outlined"
            shape="rounded"
            // variant="text"
            page={pageNumber === 0 ? 1 : pageNumber}
            onChange={handlePageChange}
            color="secondary"
            sx={{
              padding: ".5rem",
              borderRadius: "5px",
              border: "1px solid var(--black-color)",
            }}
            renderItem={(item) => (
              <PaginationItem
                slots={{ previous: ArrowBack, next: ArrowForward }}
                {...item}
              />
            )}
          />
        </Stack>
      )}
    </>
  );
};

export default Orders;
