import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import axios from "axios"

const initialState = {
  newsStatus: "idle",
  newsInfo: null,
  newsError: null,
}

export const newsSubscribe = createAsyncThunk(
  "add/newsletter",
  async (values, { rejectWithValue }) => {
    try {
      const { data } = await axios.post(
        "/api/users/newsletter",{
            email: values
        }
      )
      return data
    } catch (err) {
      return rejectWithValue(err.response.data)
    }
  }
)
export const newsletterSlice = createSlice({
  name: "newsletter",
  initialState,
  reducers: {
    resetNews(state,action){
      state.newsInfo = null
      state.newsStatus="idle"
      state.newsError= null
    }
  },
  extraReducers: {
    [newsSubscribe.pending]: (state, action) => {
      state.newsStatus = "loading"
    },
    [newsSubscribe.fulfilled]: (state, action) => {
      state.newsStatus = "succeeded"
      state.newsInfo = action.payload.message
    },
    [newsSubscribe.rejected]: (state, action) => {
      state.newsStatus = "failed";
      state.newsError = action.payload
    },
  },
})

export const { resetNews } = newsletterSlice.actions
export default newsletterSlice.reducer 