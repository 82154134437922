import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
  searchstatus: "idle",
  searchinfo: {},
  error: null,
};

export const getSearchResult = createAsyncThunk(
  "product/getSearchedProduct",
  async (searchQuery, { rejectWithValue }) => {
    console.log(searchQuery);
    try {
      const { data } = await axios.get(`/api/product/search/${searchQuery}`);
      return data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);
export const SearchedSlice = createSlice({
  name: "search",
  initialState,
  reducers: {
    resetp(state, action) {
      state.searchinfo = {};
      state.searchstatus = "idle";
    },
  },
  extraReducers: {
    [getSearchResult.pending]: (state, action) => {
      state.searchstatus = "loading";
    },
    [getSearchResult.fulfilled]: (state, action) => {
      state.searchstatus = "succeeded";
      state.searchinfo = action.payload.info;
    },
    [getSearchResult.rejected]: (state, action) => {
      state.searchstatus = "failed";
      state.error = action.payload.message;
    },
  },
});

export const { resetp } = SearchedSlice.actions;
export default SearchedSlice.reducer;
