import React from "react";
import HeroSection from "../../components/HeroSection/HeroSection";
import ConstructionInfo from "../../components/ConstructionInfo/ConstructionInfo";
import BrandsMasonary from "../../components/BrandsMasonary/BrandsMasonary";
import PricingTable from "../../components/PricingTable/PricingTable";
import Testimonials from "../../components/Testimonials/Testimonials";
import CategorySection from "../../components/CategorySection/CategorySection";
import GoDizelInfo from "../../components/GoDizelInfo/GoDizelInfo";
import Marquee from "../../components/Marquee/Marquee";
import LandingSection from "../../components/LandingSection/LandingSection";
import { Container, Grid } from "@mui/material";
import ConstructionInfoNew from "../../components/ConstructionInfo/ConstructionInfoNew";

const LogoutHome = () => {
  return (
    <>
      <div
        data-aos="fade-in"
        data-aos-duration="1000"
        style={{ width: "100%" }}
      >
        <HeroSection />
      </div>
      <Container>
        <div data-aos="fade-up" data-aos-duration="100">
          <GoDizelInfo />
        </div>
        <div data-aos="fade-up" data-aos-duration="1000">
          <LandingSection />
        </div>
        <div data-aos="fade-up" data-aos-duration="1000">
          <BrandsMasonary />
        </div>

        <Marquee />

        {/* <ConstructionInfo /> */}
        <ConstructionInfoNew />
      </Container>
    </>
  );
};

export default LogoutHome;
