import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import axios from "axios"

const initialState = {
  purchasestatus: "idle",
  purchaseInfo: {},
  error: null,
}

export const getPurchasedProduct = createAsyncThunk(
  "product/getpurchasedproduct",
  async ({id,token}, { rejectWithValue }) => {
    try {
      const { data } = await axios.get(
        `/api/checkout/getproduct/${id}`,
        {
            headers: {
                Authorization: `Bearer ${token}`,
            },
          }
      )
      return data
    } catch (err) {
      return rejectWithValue(err.response.data)
    }
  }
)
export const PurchasedSlice = createSlice({
  name: "purchased",
  initialState,
  reducers: {
    resetp(state,action){
        state.purchaseInfo = {}
        state.purchasestatus="idle"
    }
  },
  extraReducers: {
    [getPurchasedProduct.pending]: (state, action) => {
      state.purchasestatus = "loading"
    },
    [getPurchasedProduct.fulfilled]: (state, action) => {
      state.purchasestatus = "succeeded"
      state.purchaseInfo = action.payload.checkoutInfo[0]
    },
    [getPurchasedProduct.rejected]: (state, action) => {
      state.purchasestatus = "failed"
      state.error = action.payload.message
    },
  },
})

export const { resetp } = PurchasedSlice.actions
export default PurchasedSlice.reducer 