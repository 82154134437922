import EquipmentTab from "../../MyEquipments/EquipmentTab";
import Equipments from "../Equipments/Equipments";
import Orders from "../Orders/Orders";
import Users from "../Users/Users";

export const usersTab = [
  {
    label: "All Users",
    content: <Users tab="active" />,
    title: "Manage Users",
  },
  // {
  //   label: "New Users",
  //   content: <Users tab="ban" />,
  //   title: "Manage Users",
  // },
];
export const equipmentsTab = [
  {
    label: "All Equipment",
    content: <Equipments tab="active" />,
    title: "Manage Equipment",
  },
  // {
  //   label: "Sold Equipments",
  //   content: <Equipments tab="ban" />,
  //   title: "Manage Equipments",
  // },
  // {
  //   label: "Other Equipments",
  //   content: <Equipments tab="ban" />,
  //   title: "Manage Equipments",
  // },
];

export const ordersTab = [
  {
    label: "All Orders",
    content: <Orders tab="active" />,
    title: "Manage Orders",
  },
  // {
  //   label: "Rental Orders",
  //   content: <Orders tab="ban" />,
  //   title: "Manage Orders",
  // },
];

export const myEquipmentTab = [
  { label: "Buy Equipment", content: <EquipmentTab type="buy" /> },
  {
    label: "Rent Equipment",
    content: <EquipmentTab type="rent" />,
  },
  {
    label: "Hold Equipment",
    content: <EquipmentTab type="hold" />,
  },
  {
    label: "Draft Equipment",
    content: <EquipmentTab type="draft" />,
  },
  {
    label: "Delisted Equipment",
    content: <EquipmentTab type="delisted" />,
  },
  {
    label: "Buy and Rent",
    content: <EquipmentTab type="both" />,
  },
];
