import {
  Autocomplete as Mautocomplete,
  Box,
  Divider,
  FilledInput,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  TextField,
  Typography,
  Input,
  Button,
  FormControlLabel,
  Checkbox,
  FormHelperText,
  Container,
} from "@mui/material";
import React, { useState, useEffect, useRef } from "react";
import "../AddNewItem/AddNewItem.css";
import {
  AddAPhoto,
  Camera,
  CameraFrontTwoTone,
  Clear,
} from "@mui/icons-material";
import {
  Formik,
  useFormik,
  Field,
  Form,
  ErrorMessage,
  FieldArray,
  useFormikContext,
} from "formik";
import { DateRangePicker } from "rsuite";
import "rsuite/dist/rsuite-no-reset.min.css";
// import { DateRangePicker } from "@mui/x-date-pickers-pro/DateRangePicker";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs, { Dayjs } from "dayjs";
import {
  categoies,
  subCategories,
  brands,
  conditions,
  years,
  deliveryOptions,
  transportationOptions,
} from "../AddNewItem/data";
import { useDispatch, useSelector } from "react-redux";
import Spinner from "../../components/Spinner/Spinner";
import { useNavigate, useLocation } from "react-router-dom";
import * as Yup from "yup";
import CryptoJS from "crypto-js";
import { toast } from "react-toastify";
import axios from "axios";
import { getuserInfo } from "../../components/StateSlices/getuserSlice";
import {
  getProduct,
  reset,
} from "../../components/StateSlices/getproductSlice";
import {
  updateItem,
  resetItem,
} from "../../components/StateSlices/updateitemSlice";
import GooglePlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-google-places-autocomplete";
import moment from "moment/moment";

export const getFieldErrorNames = (formikErrors) => {
  const transformObjectToDotNotation = (obj, prefix = "", result = []) => {
    Object.keys(obj).forEach((key) => {
      const value = obj[key];
      if (!value) return;

      const nextKey = prefix ? `${prefix}.${key}` : key;
      if (typeof value === "object") {
        transformObjectToDotNotation(value, nextKey, result);
      } else {
        result.push(nextKey);
      }
    });

    return result;
  };

  return transformObjectToDotNotation(formikErrors);
};

export const ScrollToFieldError = ({
  scrollBehavior = { behavior: "smooth", block: "center" },
}) => {
  const { submitCount, isValid, errors } = useFormikContext();

  useEffect(() => {
    if (isValid) return;

    const fieldErrorNames = getFieldErrorNames(errors);
    if (fieldErrorNames.length <= 0) return;

    const element = document.querySelector(
      `input[name='${fieldErrorNames[0]}']`
    );
    if (!element) return;

    // Scroll to first known error into view
    element.scrollIntoView(scrollBehavior);

    // Formik doesn't (yet) provide a callback for a client-failed submission,
    // thus why this is implemented through a hook that listens to changes on
    // the submit count.
  }, [submitCount]); // eslint-disable-line react-hooks/exhaustive-deps

  return null;
};

const EditItem = () => {
  const location = useLocation();
  const [eimages, setEImages] = useState([]);
  const [images, setImages] = useState([]);
  const [feature, setFeature] = useState(0);
  const [dragActive, setDragActive] = useState(false);
  const [spinner, setSpinner] = useState(true);
  const [value, setValue] = useState();
  const [country, setCountry] = useState("us");
  const [productDetail, setProductDetail] = useState(null);
  const formRef = useRef();
  const [dateError, setDateError] = useState("");
  const [itemLeaseFields, setItemLeaseFields] = useState([
    {
      initVal: { from: "", to: "" },
    },
  ]);

  const [leaseCounts, setLeaseCounts] = useState(1);
  const [langLong, setLangLong] = useState({});

  const handleAddField = () => {
    setLeaseCounts(leaseCounts + 1);
    // Add a new empty field
    setItemLeaseFields([...itemLeaseFields, { initVal: { from: "", to: "" } }]);
    // console.log("Updated Fields after add ", itemLeaseFields);
  };

  const handleDateRangeSelectt = (index, newValue) => {
    const updatedFields = [...itemLeaseFields];
    updatedFields[index].initVal = newValue;
    setItemLeaseFields(updatedFields);
  };

  const handleRemoveField = (index) => {
    const updatedFields = [...itemLeaseFields];
    updatedFields.splice(index, 1);
    setItemLeaseFields(updatedFields);
    // console.log("Updated Fields after remove ", updatedFields);
  };

  const today = dayjs();
  const { beforeToday } = DateRangePicker;

  const { itemStatus, itemInfo, itemError } = useSelector(
    (state) => state.update
  );
  const { status, userInfo } = useSelector((state) => state.user);
  const { pstatus, productInfo, closetInfo, error } = useSelector(
    (state) => state.getproduct
  );

  let toastOption = {
    position: "top-right",
    autoClose: 1500,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "dark",
  };

  const dispatch = useDispatch();
  let navigate = useNavigate();

  const removeImg = (id) => {
    if (feature === id) setFeature(0);
    let updatedImg = eimages.filter((img, index) => index !== id);
    setEImages([...updatedImg]);
  };

  const handleDrag = function (e) {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };

  const handleDrop = function (e) {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      setEImages([...e.dataTransfer.files]);
    }
  };

  if (itemInfo) {
    toast.success("Equipment Updated Successfully", toastOption);
    // let hang = itemInfo.itemInfo.productHanger;
    // let productStock = itemInfo.itemInfo.productStock
    // toast.success(`+${productStock * hang} Hanger Added Successfully`, toastOption);
    dispatch(resetItem());
    navigate(`/product/${itemInfo.itemName}`, {
      state: { id: location.state.id },
    });
    // setTimeout(() => {
    //   navigate(0);
    // }, 5000);
  }

  useEffect(() => {
    let token = localStorage.getItem("inktoken");
    if (token && !userInfo) {
      dispatch(getuserInfo({ token }));
    } else if (!token) {
      navigate("/signup", { replace: true });
    } else {
      if (
        userInfo.userProducts.findIndex(
          (prod) => prod._id === location.state.id
        ) > -1 ||
        userInfo.role === "admin"
      ) {
        dispatch(getProduct(location.state.id));

        // if (location.state && location.state.from) {
        //   setFrom("admin");
        // }
        // dispatch(
        //   addBrand({ token, values: { brandName }, requestType: "get" })
        // );
      } else {
        navigate("/user/equipment", { replace: true });
      }
    }
  }, [userInfo]);

  useEffect(() => {
    if (productInfo && pstatus == "succeeded") {
      setProductDetail(productInfo);
      console.log(productInfo);
      formRef.current.setFieldValue("itemName", productInfo.itemName);
      formRef.current.setFieldValue("itemType", productInfo.itemType);
      formRef.current.setFieldValue(
        "itemDescription",
        productInfo.itemDescription
      );
      formRef.current.setFieldValue(
        "itemCondition",
        productInfo.itemCondition.charAt(0).toUpperCase() +
          productInfo.itemCondition.slice(1)
      );
      formRef.current.setFieldValue("itemBrand", productInfo.itemBrand);
      // formRef.current.setFieldValue("itemModel", productInfo.itemModel);
      formRef.current.setFieldValue(
        "itemYearManufacture",
        productInfo.itemYearManufacture
      );
      formRef.current.setFieldValue(
        "itemAmount",
        productInfo.itemAmount ? productInfo.itemAmount : 0
      );
      formRef.current.setFieldValue(
        "itemDeposit",
        productInfo.itemDeposit ? productInfo.itemDeposit : ""
      );
      formRef.current.setFieldValue("itemLocation", {
        label: productInfo.itemLocation,
        value: { description: productInfo.itemLocation },
      });
      // formRef.current.setFieldValue(
      //   "itemDeliveryOption",
      //   productInfo.itemDeliveryOption[0]
      // );
      // console.log([new Date(productInfo.itemLeaseStart),new Date(productInfo.itemLeaseEnd)])
      // setValue([
      //   new Date(productInfo.itemLeaseStart),
      //   new Date(productInfo.itemLeaseEnd),
      // ]);
      // formRef.current.setFieldValue(
      //   "itemLeaseStart",
      //   new Date(productInfo.itemLeaseStart)
      // );
      // formRef.current.setFieldValue(
      //   "itemLeaseEnd",
      //   new Date(productInfo.itemLeaseEnd)
      // );
      formRef.current.setFieldValue(
        "itemMonthlyPrice",
        productInfo.itemMonthlyPrice !== null
          ? productInfo.itemMonthlyPrice
          : ""
      );
      console.log("ITEMMONTHLY", productInfo.itemMonthlyPrice);
      formRef.current.setFieldValue(
        "itemWeeklyPrice",
        productInfo.itemWeeklyPrice !== null ? productInfo.itemWeeklyPrice : ""
      );
      formRef.current.setFieldValue(
        "itemDailyPrice",
        productInfo.itemDailyPrice !== null ? productInfo.itemDailyPrice : ""
      );

      formRef.current.setFieldValue(
        "itemCleaningPrice",
        productInfo.itemCleaningPrice !== null
          ? productInfo.itemCleaningPrice
          : ""
      );

      formRef.current.setFieldValue(
        "itemFuelPrice",
        productInfo.itemFuelPrice !== null ? productInfo.itemFuelPrice : ""
      );

      formRef.current.setFieldValue(
        "itemMaintenancePrice",
        productInfo.itemMaintenancePrice !== null
          ? productInfo.itemMaintenancePrice
          : ""
      );

      formRef.current.setFieldValue(
        "itemForRent",
        JSON.parse(productInfo.itemForRent)
      );
      formRef.current.setFieldValue("itemToSell", productInfo.itemToSell);
      let updatedFields = productInfo.itemLeaseFields.map((dateObject) => {
        const fromDate = moment(dateObject.from).toDate();
        const toDate = moment(dateObject.to).toDate();
        return {
          initVal: [fromDate, toDate],
        };
      });
      setItemLeaseFields(updatedFields);
      formRef.current.setFieldValue("itemCategory", productInfo.itemCategory);
      formRef.current.setFieldValue(
        "itemSubCategory",
        productInfo.itemSubCategory
      );
      formRef.current.setFieldValue("itemToSell", productInfo.itemToSell);
      formRef.current.setFieldValue("itemForRent", productInfo.itemForRent);

      formRef.current.setFieldValue(
        "itemMonthlyPrice",
        productInfo.itemMonthlyPrice ? productInfo.itemMonthlyPrice : ""
      );
      console.log("ITEMMONTHLY", productInfo.itemMonthlyPrice);
      formRef.current.setFieldValue(
        "itemWeeklyPrice",
        productInfo.itemWeeklyPrice ? productInfo.itemWeeklyPrice : ""
      );
      formRef.current.setFieldValue(
        "itemDailyPrice",
        productInfo.itemDailyPrice ? productInfo.itemDailyPrice : ""
      );
      if (productInfo.transportation && productInfo.transportation !== "") {
        const transportationLabel = transportationOptions.filter(
          (item) => item.value === productInfo.transportation
        );

        formRef.current.setFieldValue(
          "transportation",
          transportationLabel[0].value
        );
      }
      setEImages(productInfo.itemImages);
      let leasePeriod = productInfo.itemLeaseFields.map((lease, index) => {
        return { initVal: [new Date(lease.from), new Date(lease.to)] };
      });
      console.log(leasePeriod);
      setItemLeaseFields(leasePeriod);
      setLangLong({
        lng: productInfo.location.coordinates[0],
        lat: productInfo.location.coordinates[1],
      });

      setSpinner(false);
      dispatch(reset());
    }
  }, [productInfo]);

  useEffect(() => {
    if (itemError) {
      toast.error("Something Went Wrong Please Try Again", toastOption);
      dispatch(resetItem());
      setSpinner(false);
    }
  }, [itemError]);

  return (
    <div className="page-container" style={{ position: "relative" }}>
      {spinner ? <Spinner /> : ""}
      <Formik
        initialValues={{
          itemName: "",
          itemType: "",
          itemDescription: "",
          itemCategory: [],
          itemSubCategory: [],
          itemCondition: "",
          itemBrand: "",
          // itemModel: "",
          itemYearManufacture: "",
          itemAmount: "",
          itemDeposit: "",
          itemLocation: "",
          // itemDeliveryOption: "",
          itemLease: [],
          itemToSell: false,
          itemForRent: false,
          itemDailyPrice: "",
          itemWeeklyPrice: "",
          itemMonthlyPrice: "",
          transportation: "",
          itemCleaningPrice: "",
          itemFuelPrice: "",
          itemMaintenancePrice: "",
        }}
        initialErrors={{
          itemName: "Please enter Equipment Name",
          itemType: "Please enter Equipment Type",
          itemDescription: "Please enter your ItEquipmentem Description",
          itemCategory: "Please select Equipment Category",
          itemSubCategory: "Please select Equipment Subcategory",
          itemCondition: "Please select Equipment Condition",
          itemBrand: "Please select Equipment Brand",
          // itemModel: "Please enter Equipment Model",
          itemYearManufacture: "Please enter Equipment Manufacture",
          itemLocation: "Please enter Equipment Location",
          // itemDeliveryOption: "Please select Equipment Delivery Option",
          transportation: "Please select delivery",
        }}
        validationSchema={Yup.object().shape({
          itemName: Yup.string().required("Please enter Equipment Name"),
          itemType: Yup.string().required("Please enter Equipment Type"),
          itemDescription: Yup.string().required(
            "Please enter your Equipment Description"
          ),
          itemCategory: Yup.array()
            .of(Yup.string())
            .min(1, "Please select at least one category")
            .required("Please select at least one category"),
          itemSubCategory: Yup.array()
            .of(Yup.string())
            .min(1, "Please select at least one Subcategory")
            .required("Please select at least one Subcategory"),
          itemCondition: Yup.string().required(
            "Please select Equipment Condition"
          ),
          itemBrand: Yup.string().required("Please select Equipment Brand"),
          // itemModel: Yup.string().required("Please enter Equipment Model"),
          itemYearManufacture: Yup.string()
            .matches(/^(19|20)\d{2}$/, "Please enter a valid year (e.g., 2023)")
            .required("Please select Equipment Manufacture (year)"),
          itemAmount: Yup.number()
            .typeError("Amount must be a number")
            .when("itemToSell", {
              is: true,
              then: (schema) => schema.required("Amount is required"),
              // otherwise: (schema) => schema.number(),
            }),
          itemDeposit: Yup.number()
            .typeError("Security Deposit must be a number")
            .when("itemForRent", {
              is: true,
              then: (schema) => schema.required("Security Deposit is required"),
              // otherwise: (schema) => schema.number(),
            }),
          // itemLocation: Yup.required("Please enter Item Location"),
          itemLocation: Yup.object().shape({
            label: Yup.string().required('Please enter Equipment Location"'),
          }),
          // itemDeliveryOption: Yup.string().required(
          //   "Please select Equipment Delivery Option"
          // ),
          itemDailyPrice: Yup.number().typeError(
            "Equipment Daily Price must be a number"
          ),
          itemWeeklyPrice: Yup.number().typeError(
            "Equipment Weekly Price must be a number"
          ),
          itemMonthlyPrice: Yup.number().typeError(
            "Equipment Monthly Price must be a number"
          ),
          itemToSell: Yup.boolean().test(
            "itemSelection",
            'Please select either "Equipment to Sell" or "Equipment for Rent"',
            function (value) {
              return value === true || this.parent.itemForRent === true;
            }
          ),
          // itemForRent: Yup.boolean().test(
          //   'itemSelection',
          //   'Please select either "Item to Sell" or "Item for Rent"',
          //   function(value) {
          //     return value === true || this.parent.itemToSell === true;
          //   },
          // ),
          itemForRent: Yup.boolean().test(
            "atLeastOnePriceRequired",
            "At least one of the rental prices is required",
            function (value) {
              if (value) {
                const { itemDailyPrice, itemWeeklyPrice, itemMonthlyPrice } =
                  this.parent;
                if (!itemDailyPrice && !itemWeeklyPrice && !itemMonthlyPrice) {
                  return this.createError({
                    message: "At least one of the rental prices is required",
                    path: "itemRent",
                  });
                }
              }
              return true;
            }
          ),
          transportation: Yup.string().required("Please enter delivery"),
        })}
        onSubmit={(values, actions) => {
          console.log("SUbmitting", values);
          if (!itemLeaseFields[0].initVal[0]) {
            toast.error("Please Select Lease Date", toastOption);
            return;
          }
          if (Object.keys(langLong).length === 0) {
            toast.error("Please Select Correct Location !!", toastOption);
            formRef.current.setFieldValue("itemLocation", null);
            return;
          }
          let payload = { ...values, images: [] };

          console.log(process.env.REACT_APP_CLOUDINARY_API_SECRET);
          console.log(images);
          if (eimages.length > 0) {
            setSpinner(true);
            const timestamp = Math.round(new Date().getTime() / 1000);
            const params = {
              timestamp: timestamp,
              transformation: "f_webp,q_70,c_scale,w_1000",
              upload_preset: "closet-closest",
              // add any additional parameters here, such as transformation options
            };
            const signature = CryptoJS.SHA1(
              `timestamp=${params.timestamp}&transformation=${params.transformation}&upload_preset=${params.upload_preset}${process.env.REACT_APP_CLOUDINARY_API_SECRET}`
            ).toString();

            const uploaders = eimages.map(async (file, index) => {
              // Initial FormData
              if (typeof file !== "string") {
                const formData = new FormData();
                formData.append("file", file);
                formData.append("upload_preset", "closet-closest");
                formData.append("cloud_name", "closet-closest");
                formData.append("transformation", "f_webp,q_70,c_scale,w_1000");
                formData.append("signature", signature);
                formData.append("timestamp", timestamp);
                formData.append(
                  "api_key",
                  process.env.REACT_APP_CLOUDINARY_API_TOKEN
                );

                return axios
                  .post(process.env.REACT_APP_CLOUDINARY_API_URL, formData, {
                    headers: { "X-Requested-With": "XMLHttpRequest" },
                  })
                  .then((response) => {
                    const data = response.data;
                    const fileURL = data.url; // You should store this URL for future references in your app
                    if (index === feature)
                      payload = {
                        ...values,
                        images: [fileURL, ...payload.images],
                      };
                    else
                      payload = {
                        ...values,
                        images: [...payload.images, fileURL],
                      };
                  })
                  .catch((e) => {
                    console.log(e);
                    throw new Error("Something went Wrong!!!!");
                  });
              } else {
                if (index === feature)
                  payload = { ...values, images: [file, ...payload.images] };
                else payload = { ...values, images: [...payload.images, file] };
              }
            });

            axios
              .all(uploaders)
              .then(() => {
                // setValues(payload);
                // payload.color = colors;
                let token = localStorage.getItem("inktoken");
                if (token) {
                  console.log(payload);
                  payload = { ...payload, itemLeaseFields, langLong };
                  dispatch(
                    updateItem({
                      token,
                      values: payload,
                      id: location.state.id,
                    })
                  );
                } else {
                  navigate("/signup");
                }

                // setSubmitting(false);
              })
              .catch((e) => {
                toast.error(
                  "Something Went Wrong Please Try Again",
                  toastOption
                );
                // dispatch(addBrand({ token, values: { brandName }, requestType: "get" }));
              });
          } else {
            toast.error("Please upload Atlease one Image", toastOption);
          }

          // setSpinner(true);
        }}
        innerRef={formRef}
        render={(props) => {
          let {
            setFieldValue,
            values,
            handleBlur,
            handleChange,
            touched,
            errors,
            handleSubmit,
            setErrors,
            setFieldTouched,
            setFieldError,
          } = props;

          return (
            <form onSubmit={handleSubmit}>
              {/* <div className="add-new-item-con">
                <div className="add-new-item-wrapper"> */}
              <Container maxWidth="lg" sx={{ margin: "2rem auto" }}>
                <Grid container>
                  <Grid item xs={12}>
                    <Typography variant="h1" className="mont-title">
                      Edit Equipment
                    </Typography>
                    <Divider sx={{ marginBottom: "2rem" }} />
                    {eimages.length > 0 ? (
                      <div className="preview_image">
                        <div className="item_title">
                          Image Preview - Please Select the Feature Photo
                        </div>
                        <div className="preview_image_container">
                          <div
                            className="img_name_cont"
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              flexWrap: "wrap",
                              margin: "10px 0px",
                            }}
                          >
                            {eimages.map((image, index) => {
                              return (
                                <div className={`img_icon_cont`} key={index}>
                                  <div className="img_name">
                                    <div
                                      className="cross"
                                      onClick={() => removeImg(index)}
                                    >
                                      X
                                    </div>
                                    <img
                                      className={`${
                                        feature === index ? "feature" : ""
                                      }`}
                                      onClick={() => setFeature(index)}
                                      src={
                                        typeof image === "string"
                                          ? image
                                          : URL.createObjectURL(image)
                                      }
                                      alt={image.name}
                                    />
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                    <div className="img_upload_container">
                      <div className="file_icon_container">
                        <div className="item_title">Take or Choose Photos</div>
                        <label
                          htmlFor="closet_icon"
                          className={`file_upload ${
                            dragActive ? "drag-active" : ""
                          }`}
                          onDragEnter={handleDrag}
                          onDragLeave={handleDrag}
                          onDragOver={handleDrag}
                          onDrop={handleDrop}
                        >
                          <div className="icon_container add_icon_container">
                            <AddAPhoto color="primary" />
                            <p className="item_title" style={{}}>
                              {" "}
                              or drag them in
                            </p>
                          </div>
                        </label>
                        <input
                          type="file"
                          multiple={true}
                          id="closet_icon"
                          hidden
                          onChange={(e) => {
                            console.log(URL.createObjectURL(e.target.files[0]));
                            setEImages((prevImg) => [
                              ...prevImg,
                              ...e.target.files,
                            ]);
                          }}
                          style={{ borderRadius: "10px" }}
                        />
                      </div>
                    </div>
                  </Grid>
                </Grid>
                <Typography
                  variant="h3"
                  fontWeight={700}
                  marginTop={"2rem"}
                  className="mont-title"
                >
                  General Information
                </Typography>
                <Grid
                  container
                  spacing={4}
                  sx={{ flexGrow: 1 }}
                  marginTop={"0rem"}
                >
                  <Grid item xs={6}>
                    <TextField
                      error={touched.itemName && Boolean(errors.itemName)}
                      required
                      name="itemName"
                      id="name"
                      label="Name"
                      defaultValue=""
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.itemName}
                      helperText={touched.itemName && errors.itemName} //required in case of error msg
                      sx={{ width: "100%" }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      error={touched.itemType && Boolean(errors.itemType)}
                      required
                      name="itemType" //true if error exists
                      id="type"
                      label="Type"
                      defaultValue=""
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.itemType}
                      helperText={touched.itemType && errors.itemType}
                      sx={{ width: "100%" }}
                    />
                  </Grid>
                </Grid>

                <TextField
                  required
                  fullWidth
                  error={
                    touched.itemDescription && Boolean(errors.itemDescription)
                  }
                  name="itemDescription" //true if error exists
                  id="description"
                  label="Description"
                  multiline
                  minRows={5}
                  placeholder="Enter Description"
                  defaultValue=""
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.itemDescription}
                  helperText={touched.itemDescription && errors.itemDescription}
                  sx={{ width: "100%", margin: "1rem auto" }}
                />

                {productDetail?.itemCategory.length > 0 ||
                  (productDetail?.itemSubCategory.length > 0 && (
                    <Grid
                      container
                      spacing={4}
                      sx={{ flexGrow: 1 }}
                      marginBottom={"1rem"}
                    >
                      <Grid item xs={6}>
                        <Mautocomplete
                          onOpen={handleBlur}
                          multiple
                          id="combo-box-category"
                          options={categoies.map((category) => category._id)}
                          getOptionLabel={(string) => {
                            return (
                              categoies.find(
                                (category) => category._id === string
                              )?.label ?? ""
                            );
                          }}
                          value={values.itemCategory}
                          sx={{ width: "100%" }}
                          onChange={(e, value) => {
                            const selectedIds =
                              value.length > 0
                                ? value.map((option) => option)
                                : [];
                            setFieldValue("itemCategory", selectedIds);
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              name="itemCategory"
                              label="Category"
                              error={Boolean(
                                touched.itemCategory && errors.itemCategory
                              )}
                              helperText={
                                touched.itemCategory && errors.itemCategory
                              }
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <Mautocomplete
                          onOpen={handleBlur}
                          multiple
                          id="combo-box-sub-category"
                          options={subCategories.map((sub) => sub._id)}
                          getOptionLabel={(string) => {
                            return (
                              subCategories.find((sub) => sub._id === string)
                                ?.label ?? ""
                            );
                          }}
                          value={values.itemSubCategory}
                          sx={{ width: "100%" }}
                          onChange={(e, value) => {
                            const selectedIds =
                              value.length > 0
                                ? value.map((option) => option)
                                : [];
                            setFieldValue("itemSubCategory", selectedIds);
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Sub Category"
                              name="itemSubCategory"
                              error={Boolean(
                                touched.itemSubCategory &&
                                  errors.itemSubCategory
                              )}
                              helperText={
                                touched.itemSubCategory &&
                                errors.itemSubCategory
                              }
                            />
                          )}
                        />
                      </Grid>
                    </Grid>
                  ))}
                <Grid
                  container
                  spacing={4}
                  sx={{ flexGrow: 1, marginBottom: "1rem" }}
                >
                  <Grid item xs={6}>
                    <Mautocomplete
                      onOpen={handleBlur}
                      disablePortal
                      fullWidth
                      id="combo-box-conditions"
                      options={conditions.map((condition) => condition.label)}
                      value={values.itemCondition}
                      onChange={(e, value) =>
                        setFieldValue("itemCondition", value)
                      }
                      sx={{ width: "100%" }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Condition"
                          name="itemCondition"
                          error={Boolean(
                            touched.itemCondition && errors.itemCondition
                          )}
                          helperText={
                            touched.itemCondition && errors.itemCondition
                          }
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Mautocomplete
                      onOpen={handleBlur}
                      disablePortal
                      fullWidth
                      id="combo-box-brands"
                      options={brands.map((brand) => brand._id)}
                      getOptionLabel={(string) => {
                        return (
                          brands.find((brand) => brand._id === string)?.label ??
                          ""
                        );
                      }}
                      value={values.itemBrand}
                      onChange={(e, value) => setFieldValue("itemBrand", value)}
                      sx={{ width: "100%" }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Brand"
                          name="itemBrand"
                          error={Boolean(touched.itemBrand && errors.itemBrand)}
                          helperText={touched.itemBrand && errors.itemBrand}
                        />
                      )}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={4} sx={{ flexGrow: 1 }}>
                  {/* <Grid item xs={6}>
                    <TextField
                      required
                      name="itemModel"
                      error={touched.itemModel && Boolean(errors.itemModel)}
                      helperText={touched.itemModel && errors.itemModel}
                      id="model"
                      label="Model"
                      defaultValue=""
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.itemModel}
                      sx={{ width: "100%" }}
                    />
                  </Grid> */}
                  <Grid item xs={12}>
                    <Mautocomplete
                      onOpen={handleBlur}
                      disablePortal
                      fullWidth
                      id="combo-box-year"
                      options={years.map((year) => year.value)}
                      value={values.itemYearManufacture}
                      onChange={(e, value) =>
                        setFieldValue("itemYearManufacture", value)
                      }
                      sx={{ width: "100%" }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Year Manufactured"
                          name="itemYearManufacture"
                          error={Boolean(
                            touched.itemYearManufacture &&
                              errors.itemYearManufacture
                          )}
                          helperText={
                            touched.itemYearManufacture &&
                            errors.itemYearManufacture
                          }
                        />
                      )}
                    />
                  </Grid>
                </Grid>
                <Typography
                  variant="h3"
                  fontWeight={700}
                  marginTop={"2rem"}
                  className="mont-title"
                >
                  Pricing and Availability
                </Typography>
                <Grid
                  container
                  spacing={4}
                  marginTop={"5px"}
                  marginBottom={"1rem"}
                  paddingTop={"10px"}
                >
                  <Grid item xs={6}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          onChange={(e) => {
                            handleChange(e);
                            setFieldValue("itemAmount", "");
                          }}
                          onBlur={handleBlur}
                          value={values.itemToSell}
                          name="itemToSell"
                          checked={values.itemToSell}
                        />
                      }
                      label="Equipment to Sell?"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          onChange={(e) => {
                            handleChange(e);
                            setFieldValue("itemDeposit", "");
                            setFieldValue("itemDailyPrice", "");
                            setFieldValue("itemWeeklyPrice", "");
                            setFieldValue("itemMonthlyPrice", "");
                            setFieldValue("itemCleaningPrice", "");
                            setFieldValue("itemFuelPrice", "");
                            setFieldValue("itemMaintenancePrice", "");
                          }}
                          onBlur={handleBlur}
                          value={values.itemForRent}
                          name="itemForRent"
                          checked={values.itemForRent}
                        />
                      }
                      label="Item for Rent?"
                    />
                    {((errors.itemToSell && touched.itemToSell) ||
                      (errors.itemForRent && touched.itemForRent)) && (
                      <FormHelperText style={{ color: "red" }}>
                        {errors.itemToSell}
                      </FormHelperText>
                    )}
                  </Grid>

                  <Grid item xs={6}>
                    {values.itemForRent && (
                      <TextField
                        required={values.itemForRent ? true : false}
                        name="itemDeposit"
                        error={
                          touched.itemDeposit && Boolean(errors.itemDeposit)
                        }
                        helperText={touched.itemDeposit && errors.itemDeposit}
                        id="security_deposit"
                        label="Security Deposit"
                        defaultValue=""
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">$</InputAdornment>
                          ),
                        }}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.itemDeposit}
                        sx={{ width: "100%" }}
                      />
                    )}

                    {values.itemToSell && (
                      <TextField
                        required={values.itemToSell ? true : false}
                        name="itemAmount"
                        error={touched.itemAmount && Boolean(errors.itemAmount)}
                        helperText={touched.itemAmount && errors.itemAmount}
                        id="amount"
                        label="Amount"
                        defaultValue=""
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">$</InputAdornment>
                          ),
                        }}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.itemAmount}
                        sx={{
                          width: "100%",
                          marginTop: values.itemForRent ? "1rem" : 0,
                        }}
                      />
                    )}
                  </Grid>
                </Grid>
                {values.itemForRent && (
                  <>
                    <Grid
                      container
                      spacing={4}
                      marginTop={".5rem"}
                      marginBottom={"1rem"}
                    >
                      <Grid item xs={4}>
                        <TextField
                          name="itemDailyPrice"
                          error={
                            (touched.itemDailyPrice &&
                              Boolean(errors.itemDailyPrice)) ||
                            Boolean(errors.itemRent)
                          }
                          helperText={
                            (touched.itemDailyPrice && errors.itemDailyPrice) ||
                            errors.itemRent
                          }
                          id="dailyPrice"
                          label="Daily Price"
                          defaultValue=""
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                $
                              </InputAdornment>
                            ),
                          }}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.itemDailyPrice}
                          sx={{ width: "100%" }}
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <TextField
                          name="itemWeeklyPrice"
                          error={
                            (touched.itemWeeklyPrice &&
                              Boolean(errors.itemWeeklyPrice)) ||
                            Boolean(errors.itemRent)
                          }
                          helperText={
                            (touched.itemWeeklyPrice &&
                              errors.itemWeeklyPrice) ||
                            Boolean(errors.itemRent)
                          }
                          id="itemWeeklyPrice"
                          label="Weekly Price"
                          defaultValue=""
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                $
                              </InputAdornment>
                            ),
                          }}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.itemWeeklyPrice}
                          sx={{ width: "100%" }}
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <TextField
                          name="itemMonthlyPrice"
                          error={
                            (touched.itemMonthlyPrice &&
                              Boolean(errors.itemMonthlyPrice)) ||
                            Boolean(errors.itemRent)
                          }
                          helperText={
                            (touched.itemMonthlyPrice &&
                              errors.itemMonthlyPrice) ||
                            Boolean(errors.itemRent)
                          }
                          id="itemMonthlyPrice"
                          label="Monthly Price"
                          defaultValue=""
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                $
                              </InputAdornment>
                            ),
                          }}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.itemMonthlyPrice}
                          sx={{ width: "100%" }}
                        />
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      spacing={4}
                      marginTop={".5rem"}
                      marginBottom={"1rem"}
                    >
                      <Grid item xs={4}>
                        <TextField
                          name="itemCleaningPrice"
                          error={
                            touched.itemCleaningPrice &&
                            Boolean(errors.itemCleaningPrice)
                          }
                          helperText={
                            touched.itemCleaningPrice &&
                            errors.itemCleaningPrice
                          }
                          id="itemCleaningPrice"
                          label="Cleaning Fees"
                          defaultValue=""
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                $
                              </InputAdornment>
                            ),
                          }}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.itemCleaningPrice}
                          sx={{ width: "100%" }}
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <TextField
                          name="itemFuelPrice"
                          error={
                            touched.itemFuelPrice &&
                            Boolean(errors.itemFuelPrice)
                          }
                          helperText={
                            touched.itemFuelPrice && errors.itemFuelPrice
                          }
                          id="itemFuelPrice"
                          label="Fuel Replacement Price"
                          defaultValue=""
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                $
                              </InputAdornment>
                            ),
                          }}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.itemFuelPrice}
                          sx={{ width: "100%" }}
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <TextField
                          name="itemMaintenancePrice"
                          error={
                            touched.itemMaintenancePrice &&
                            Boolean(errors.itemMaintenancePrice)
                          }
                          helperText={
                            touched.itemMaintenancePrice &&
                            errors.itemMaintenancePrice
                          }
                          id="itemMaintenancePrice"
                          label="Maintenance Price"
                          defaultValue=""
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                $
                              </InputAdornment>
                            ),
                          }}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.itemMaintenancePrice}
                          sx={{ width: "100%" }}
                        />
                      </Grid>
                    </Grid>
                  </>
                )}
                <Grid container spacing={4}>
                  <Grid item xs={6}>
                    <GooglePlacesAutocomplete
                      selectProps={{
                        value: values.itemLocation,
                        onChange: (val) => {
                          console.log(val);
                          geocodeByAddress(val.label)
                            .then((results) => getLatLng(results[0]))
                            .then(({ lat, lng }) => {
                              console.log(
                                "Successfully got latitude and longitude",
                                { lat, lng }
                              );
                              setLangLong({ lng, lat });
                            });
                          setFieldValue("itemLocation", val);
                        },
                        styles: {
                          input: (provided) => ({
                            ...provided,
                            padding: 10,
                            border: "none",
                          }),
                          option: (provided) => ({
                            ...provided,
                            zIndex: 999,
                          }),
                          singleValue: (provided) => ({
                            ...provided,
                          }),
                          indicatorSeparator: (provided) => ({
                            ...provided,
                            display: "none",
                          }),
                        },
                        placeholder: "Enter Location",
                      }}
                      apiKey="AIzaSyAw_j2KY4CDtksQcd6JexS3J1xt4BdbnGQ"
                      apiOptions={{ region: "US" }}
                    />
                    {errors.itemLocation && touched.itemLocation && (
                      <FormHelperText style={{ color: "red" }}>
                        {errors.itemLocation.label}
                      </FormHelperText>
                    )}
                  </Grid>
                  <Grid item xs={6}>
                    {/* <Mautocomplete
                        onOpen={handleBlur}
                        disablePortal
                        fullWidth
                        id="combo-box-delivery-options"
                        options={deliveryOptions.map((brand) => brand._id)}
                        getOptionLabel={(string) => {
                          return (
                            deliveryOptions.find(
                              (brand) => brand._id === string
                            )?.label ?? ""
                          );
                        }}
                        onChange={(e, value) =>
                          setFieldValue("itemDeliveryOption", value)
                        }
                        value={values.itemDeliveryOption}
                        sx={{ width: "100%" }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Delivery Options"
                            name="itemDeliveryOption"
                            error={Boolean(
                              touched.itemDeliveryOption &&
                                errors.itemDeliveryOption
                            )}
                            helperText={
                              touched.itemDeliveryOption &&
                              errors.itemDeliveryOption
                            }
                          />
                        )}
                      /> */}
                    <Mautocomplete
                      onOpen={handleBlur}
                      disablePortal
                      fullWidth
                      id="combo-box-year"
                      value={values.transportation}
                      options={transportationOptions.map((item) => item.value)}
                      getOptionLabel={(string) => {
                        return (
                          transportationOptions.find(
                            (item) => item.value === string
                          )?.label ?? ""
                        );
                      }}
                      onChange={(e, value) =>
                        setFieldValue("transportation", value)
                      }
                      sx={{ width: "100%" }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Delivery"
                          name="transportation"
                          error={Boolean(
                            touched.transportation && errors.transportation
                          )}
                          helperText={
                            touched.transportation && errors.transportation
                          }
                        />
                      )}
                    />
                  </Grid>
                </Grid>

                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginTop: "2rem",
                  }}
                >
                  <Typography
                    variant="h3"
                    fontWeight={700}
                    className="mont-title"
                  >
                    Lease Information &nbsp;&nbsp;&nbsp;
                  </Typography>
                  <Box>
                    <Button
                      // onClick={() => {setLeaseCounts((prev) => prev + 1); setValue([...value, { initVal: [null, null] }]);}}
                      onClick={handleAddField}
                    >
                      Add More
                    </Button>
                  </Box>
                </Box>
                {/* {Array.from({ length: leaseCounts }, (_, index) => ( */}
                {itemLeaseFields.map((field, index) => (
                  <Box
                    key={index}
                    margin={"1rem auto"}
                    display={"flex"}
                    alignItems={"center"}
                    justifyContent={"space-between"}
                  >
                    <DateRangePicker
                      style={{ width: index > 0 ? "96%" : "100%" }}
                      size="large"
                      placeholder="Select Date Range : From ~ To"
                      disabledDate={beforeToday()}
                      // onOk={(newValue) => {
                      //   setValue(newValue);
                      //   setFieldValue("itemLease", [
                      //     ...values.itemLease,
                      //     {
                      //       from: newValue[0],
                      //       to: newValue[1],
                      //     },
                      //   ]);
                      // }}
                      onOk={(newValue) =>
                        handleDateRangeSelectt(index, newValue)
                      }
                      value={field.initVal}
                      // value={value}
                    />
                    {index > 0 && (
                      <span style={{ marginTop: "5px", cursor: "pointer" }}>
                        <Clear
                          // onClick={() => {
                          //   const updatedLease = [...values.itemLease];
                          //   updatedLease.splice(index, 1);
                          //   setFieldValue("itemLease", updatedLease);
                          //   console.log("valuessss", values.itemLease);
                          // }}
                          onClick={() => handleRemoveField(index)}
                        />
                      </span>
                    )}
                  </Box>
                ))}
                <Box
                  margin={"2rem auto"}
                  display={"flex"}
                  justifyContent={"flex-end"}
                >
                  {/* <Button variant="outlined" >Save as Draft</Button> */}
                  <Button
                    variant="contained"
                    sx={{ marginLeft: "1rem" }}
                    type="submit"
                    disabled={itemStatus === "loading" ? true : false}
                  >
                    {itemStatus === "loading"
                      ? "Please Wait"
                      : "ADD TO INVENTORY"}
                  </Button>
                </Box>
              </Container>
              {/* </div>
              </div> */}
            </form>
          );
        }}
      />
    </div>
  );
};

export default EditItem;
