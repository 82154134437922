import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
  fieldStatus: "idle",
  fieldsInfo: null,
  fieldsError: null,
};

export const getProductFields = createAsyncThunk(
  "product/getproductfields",
  async ({ category, subCategory, type }, { rejectWithValue }) => {
    try {
      const { data } = await axios.get(
        `/api/product/getProductFields?category=${category}&subCategory=${subCategory}&type=${type}`
      );
      return data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);
export const ProductFieldsSlice = createSlice({
  name: "productfields",
  initialState,
  reducers: {
    resetFields(state, action) {
      state.fieldsInfo = null;
      state.fieldStatus = "idle";
      state.fieldsError = null;
    },
  },
  extraReducers: {
    [getProductFields.pending]: (state, action) => {
      state.fieldStatus = "loading";
    },
    [getProductFields.fulfilled]: (state, action) => {
      state.fieldStatus = "succeeded";
      state.fieldsInfo = action.payload.productFields;
    },
    [getProductFields.rejected]: (state, action) => {
      state.fieldStatus = "failed";
      state.fieldsError = action.payload.message;
    },
  },
});

export const { resetFields } = ProductFieldsSlice.actions;
export default ProductFieldsSlice.reducer;
