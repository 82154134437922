import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import axios from "axios"

const initialState = {
  tarnsferStatus: "idle",
  transferMessage: null,
  transferError: null
}

export const transferProduct = createAsyncThunk(
  "transfer/transferproduct",
  async ({token,value}, { rejectWithValue }) => {
    try {
      const { data } = await axios.post(
        `/api/product/transferproduct`,
        value,
        {
          headers: {
              Authorization: `Bearer ${token}`,
          },
        }
      )
      return data
    } catch (err) {
      return rejectWithValue(err.response.data)
    }
  }
)
export const transferProductSlice = createSlice({
  name: "transferproduct",
  initialState,
  reducers: {
    resettransfer(state,action){
        state.transferMessage = null
        state.tarnsferStatus="idle"
        state.transferError = null
      }
  },
  extraReducers: {
    [transferProduct.pending]: (state, action) => {
      state.tarnsferStatus = "loading"
    },
    [transferProduct.fulfilled]: (state, action) => {
      state.tarnsferStatus = "succeeded"
      state.transferMessage = action.payload.message
    },
    [transferProduct.rejected]: (state, action) => {
      state.tarnsferStatus = "failed";
      state.transferError = action.payload
    },
  },
})

export const { resettransfer } = transferProductSlice.actions
export default transferProductSlice.reducer 