import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import axios from "axios"

const initialState = {
  status: "idle",
  message: null,
  error: null,
}
export const resetPassword = createAsyncThunk(
  "reset/password",
  async (payload, { rejectWithValue }) => {
    try {
      const { data } = await axios.post(
        "/api/users/resetpassword",
        payload
      )
      return data
    } catch (err) {
      return rejectWithValue(err.response.data)
    }
  }
)
export const resetPasswordSlice = createSlice({
  name: "reset",
  initialState,
  reducers: {
    reset(state,action){
      state.error=null
      state.message=null
    }
  },
  extraReducers: {
    [resetPassword.pending]: (state, action) => {
      state.status = "loading"
    },
    [resetPassword.fulfilled]: (state, action) => {
      state.status = "succeeded"
      state.message = action.payload.message
    },
    [resetPassword.rejected]: (state, action) => {
      state.status = "failed"
      state.error = action.payload.message || "Something Went Wrong. Try Again Later!!"
    },
  },
})

export const { reset } = resetPasswordSlice.actions

export default resetPasswordSlice.reducer 