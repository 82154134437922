import { Box, Container, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import "./SearchResults.css";
import { getSearchResult } from "../StateSlices/searchSlice";
import { useDispatch, useSelector } from "react-redux";
import Spinner from "../Spinner/Spinner";
import ProductCard from "../ProductCard/ProductCard";

const SearchResults = () => {
  const [loading, setLoading] = useState(false);
  const { query } = useParams();
  const dispatch = useDispatch();
  const { searchinfo } = useSelector((state) => state.search);
  useEffect(() => {
    setLoading(true);
    dispatch(getSearchResult(query));
  }, [query]);

  useEffect(() => {
    if (searchinfo && searchinfo.products) {
      setTimeout(() => setLoading(false), 1500);
      //consolele.log(searchinfo);

      //   const uniqueNames = searchinfo.products.reduce((uniqueSet, item) => {
      //     if (
      //       !uniqueSet.some((uniqueItem) => uniqueItem.masterId === item.masterId)
      //     ) {
      //       uniqueSet.push({
      //         masterId: item.masterId,
      //         closetName: item.closetName,
      //         bannerImage: item.bannerImage,
      //       });
      //     }
      //     return uniqueSet;
      //   }, []);
      //   setClosets(uniqueNames);
    }
  }, [searchinfo]);
  return (
    <>
      <Box component={"section"} className="parent-page-con">
        <Typography
          variant="h1"
          textAlign={"center"}
          fontWeight={"700"}
          margin={"1rem auto"}
        >
          Search Results
        </Typography>
        <Typography variant="h5" color={"text.secondary"} marginBottom={"1rem"}>
          Search for : <i>{query}</i>
        </Typography>
        {loading ? (
          <Spinner pos={"relative"} />
        ) : (
          <>
            {searchinfo &&
            searchinfo.products &&
            searchinfo.products.length > 0 ? (
              <>
                <Box
                  sx={{
                    display: "flex",
                    flexWrap: "wrap",
                    justifyContent: "space-evenly",
                  }}
                >
                  {searchinfo.products.map((product, index) => (
                    <ProductCard key={index} product={product} />
                  ))}
                </Box>
              </>
            ) : (
              <Typography
                variant="h3"
                textAlign={"center"}
                margin={"2rem auto"}
                fontWeight={700}
              >
                No Equipment Found
              </Typography>
            )}
          </>
        )}
      </Box>
    </>
  );
};

export default SearchResults;
