import React, { useState } from "react";
import "./AdminPanel.css";
import Sidebar from "../../components/AdminComponents/Sidebar/Sidebar";
import RightSection from "../../components/AdminComponents/RightSection/RightSection";
import AdminHeader from "../../components/AdminComponents/AdminHeader/AdminHeader";

function AdminPanel() {
  const [isSidebarOpen, setIsSidebarOpen] = useState(
    window.innerWidth < 767 ? false : true
  );
  const [currentTab, setCurrentTab] = useState("users");

  const handleToggle = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  return (
    <div
      className="admin-panel-test"
      // data-aos="fade-in"
      // data-aos-duration="500"
    >
      <AdminHeader handleToggle={handleToggle} />
      <div className="admin-body-test">
        <aside className={`sidebar ${isSidebarOpen ? "open" : ""}`}>
          <Sidebar
            isOpen={isSidebarOpen}
            setCurrentTab={setCurrentTab}
            setIsOpen={setIsSidebarOpen}
          />
        </aside>
        <main className={`main-content ${isSidebarOpen ? "shifted" : ""}`}>
          <RightSection isOpen={isSidebarOpen} currentTab={currentTab} />
        </main>
      </div>
    </div>
  );
}

export default AdminPanel;
