import React, { useState, useEffect } from "react";
import Lightbox from "yet-another-react-lightbox";
import Inline from "yet-another-react-lightbox/plugins/inline";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import "yet-another-react-lightbox/styles.css";
// import slides from "./slides";

const Gallery = ({ product }) => {
  const [open, setOpen] = useState(false);
  const [index, setIndex] = useState(0);
  const [slides, setSlides] = useState([]);

  let slideData = [];
  useEffect(() => {
    console.log("k2l ", product.itemImages);
    if (product.itemImages && product.itemImages.length > 0) {
      product.itemImages.map((img) => slideData.push({ src: img }));
    }
    if (slideData.length > 0) {
      setSlides(slideData);
      console.log("k2l slide data ", slideData);
    }
  }, []);
  console.log("jk", slides);
  const toggleOpen = (state) => () => setOpen(state);
  const zoomRef = React.useRef(null);
  const updateIndex = ({ index: current }) => setIndex(current);

  return (
    <>
      {/* <section className="prod-gallery-con"> */}
      <Lightbox
        index={index}
        slides={slides}
        counter={{ container: { style: { top: "unset", bottom: 0 } } }}
        plugins={[Inline]}
        // zoom={{ ref: zoomRef }}
        on={{
          view: updateIndex,
          click: toggleOpen(true),
        }}
        carousel={{
          padding: 0,
          spacing: 0,
          imageFit: "contain",
          width: "100%",
          backgroundColor: "#fff",
          background: "#fff",
          margin: 0,
        }}
        inline={{
          style: {
            width: "100%",
            maxWidth: "400px",
            aspectRatio: "3 / 2",
            margin: "0 auto",
            background: "#fff",
            backgroundColor: "#fff",
            // border: "1px solid var(--grey-color)",
            // boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
            // borderRadius: "10px",
            border: "none",
          },
        }}
      />
      {/* <button type="button" onClick={() => zoomRef.current?.zoomIn()}>
        Zoom In
      </button>

      <button type="button" onClick={() => zoomRef.current?.zoomOut()}>
        Zoom Out
      </button> */}
      <Lightbox
        open={open}
        close={toggleOpen(false)}
        index={index}
        slides={slides}
        on={{ view: updateIndex }}
        animation={{ fade: 0.3 }}
        controller={{ closeOnPullDown: true, closeOnBackdropClick: true }}
      />
      {/* </section> */}
    </>
  );
};

export default Gallery;
