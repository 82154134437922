import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepButton from "@mui/material/StepButton";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Checkout from "./Checkout";
import {
  Container,
  Stack,
  StepConnector,
  StepLabel,
  Tooltip,
  keyframes,
  stepConnectorClasses,
  styled,
  tooltipClasses,
} from "@mui/material";
import CheckoutRentalAgreement from "./CheckoutRentalAgreement";
import { fetchProducts } from "../StateSlices/getCheckoutProductsSlice";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import Spinner from "../Spinner/Spinner";
import CheckoutShipping from "./CheckoutShipping";
import CheckoutPayment from "./CheckoutPayment";
import "./Checkout.css";
import { checkout, resetCheckout } from "../StateSlices/checkoutSlice";
import { useNavigate } from "react-router-dom";
import { getShippingPrice, taxReset } from "../StateSlices/getShippingSlice";
import {
  AccountCircle,
  CardTravel,
  Handshake,
  LocalShipping,
  Payment,
  ShoppingCartCheckout,
  UploadFile,
} from "@mui/icons-material";
import PropTypes from "prop-types";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import CheckoutUploadInsurance from "./CheckoutUploadInsurance";
import axios from "axios";
import CryptoJS from "crypto-js";
import { getuserInfo } from "../StateSlices/getuserSlice";

export default function CheckoutStepper({ setCart }) {
  const DarkToolTip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: "var(--black-color)",
      borderRadius: 0,
    },
    [`& .${tooltipClasses.tooltip}`]: {
      borderRadius: 30,
      padding: 10,
      backgroundColor: "var(--black-color)",
      border: "2px solid var(--black-color)",
      textAlign: "center",
    },
  }));
  const navigate = useNavigate();
  // let steps = ["Cart", "Shipping", "Payment"];
  const [steps, setSteps] = useState([
    ["Equipment Cart", "Checkout", "Rental Agreement "],
  ]);
  const [stepsTooltip, setStepsTooltip] = useState([
    "Selected equipment are added for rent or purchase",
    "Agree to the terms and conditions related to the rental or purchase of the equipment",
    "Complete your transaction by providing payment and shipping details to finalize your order",
  ]);
  const [activeStep, setActiveStep] = React.useState(0);
  const [completed, setCompleted] = React.useState({});
  const dispatch = useDispatch();
  const { userInfo, error, profile } = useSelector((state) => state.user);
  const { cherror, message, checkoutHistory } = useSelector(
    (state) => state.checkout
  );
  const { cproducts } = useSelector((state) => state.checkoutproducts);
  const {
    shippingStatus,
    shippingInfo,
    shippingError,
    salesTax,
    cardDetails,
    transportCost,
  } = useSelector((state) => state.shippingprice);

  const [bbuy, setBbuy] = React.useState([]);
  const [rrent, setRrent] = React.useState([]);
  const [spinner1, setSpinner1] = React.useState(false);
  const [loaderBtnSpinner, setLoaderBtnSpinner] = useState(false);
  const [tempTotal, setTempTotal] = useState(0);
  const [token, setToken] = useState(null);
  const [tax, setTax] = useState(0);
  const [fees, setFees] = useState(100);
  const [transportation, setTransportation] = useState(0);
  const [discount, setDiscount] = useState(0);
  const [taxCalculated, setTaxCalculated] = useState(false);
  const [fileAgreement, setFileAgreement] = useState(null);
  const [docuSignAgreement, setDocuSignAgreement] = useState(null);
  const [docusignDone, setDocusignDone] = useState(false);
  const [totalAmount, setTotalAmount] = useState(0);
  const [rentTotal, setRentTotal] = useState(0);
  const [subTotal, setSubTotal] = useState(0);
  const [buyTotal, setBuyTotal] = useState(0);
  const [cardSavedDetails, setCardSavedDetails] = useState(null);
  const [saveCard, setSaveCard] = useState(true);
  const [saveInsurance, setSaveInsurance] = useState(true);
  const [saveDocuSignInsurance, setSaveDocuSignInsurance] = useState(true);
  const [saveAddress, setSaveAddress] = useState(true);

  //Shipping Details Field
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [Address, setAddress] = useState("");
  const [State, setState] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [city, setCity] = useState("");
  const [country, setCountry] = useState("USA");
  const [rentEstimates, setRentEstimates] = useState([]);

  const [cardNumber, setCardNumber] = useState("");
  const [cardExpiry, setCardExpiry] = useState("");
  const [cardCvc, setCardCvc] = useState("");
  const [docuSignByOwn, setDocuSignByOwn] = useState(false);

  let toastOption = {
    position: "top-right",
    autoClose: 1500,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "dark",
  };

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const dsSuccessParam = urlSearchParams.get("event");
    if (dsSuccessParam === "signing_complete") {
      setActiveStep(2);
      console.log(userInfo);
      setDocusignDone(true);
      setDocuSignByOwn(true);
    }
    if(localStorage.getItem('localUpload')){
      let localData = JSON.parse(localStorage.getItem('localUpload'))
      setFileAgreement(localData);
    }
  }, []);

  useEffect(() => {
    // dispatch(reset());
    if (userInfo) {
      if (userInfo.userDetailsId.addressCheckBox !== undefined) {
        setSaveAddress(userInfo.userDetailsId.addressCheckBox);
      }
      if (userInfo.userDetailsId.cardCheckBox !== undefined) {
        setSaveCard(userInfo.userDetailsId.cardCheckBox);
      }
      if (userInfo.userDetailsId.insuranceCheckBox !== undefined) {
        setSaveInsurance(userInfo.userDetailsId.insuranceCheckBox);
      }

      if (userInfo.userDetailsId.saveDocuSignInsurance !== undefined) {
        setSaveDocuSignInsurance(userInfo.userDetailsId.saveDocuSignInsurance);
      }

      if (userInfo.userDetailsId.referCount) {
        setDiscount(50);
      }
      setFirstName(userInfo.userDetailsId.userFName);
      setLastName(userInfo.userDetailsId.userLName);
      setAddress(userInfo.userDetailsId.userAddress);
      setState(userInfo.userDetailsId.userState);
      setCity(userInfo.userDetailsId.userCity);
      setZipCode(userInfo.userDetailsId.userZipCode);
      let cart = JSON.parse(localStorage.getItem("cartItems")) || [];
      if (cart && cart.length > 0) {
        setSpinner1(true);
        dispatch(
          fetchProducts({
            token: localStorage.getItem("inktoken"),
            userId: userInfo._id,
          })
        );
      } else {
        setBbuy([]);
        setRrent([]);
      }
    }
  }, [userInfo]);

  useEffect(() => {
    if (cherror) {
      toast.error(cherror, toastOption);
      dispatch(resetCheckout());
      setSpinner1(false);
    }
    if (message) {
      toast.success("Product Sucessfully Purchased", toastOption);
      sessionStorage.clear("closetcart");
      localStorage.removeItem("cartItems");
      localStorage.removeItem('localUpload');
      dispatch(resetCheckout());
      let token = localStorage.getItem("inktoken");
      if (token) {
        dispatch(getuserInfo({ token: token }));
      }
      // console.log("King what happened", buyTotal, rentTotal);
      navigate("/order-confirmation", {
        state: {
          fees: fees,
          buyProducts: bbuy,
          rentProducts: rrent,
          discount: discount,
          rentEstimates: rentEstimates,
          tax: tax,
          checkoutHistory: checkoutHistory,
          buyTotal: buyTotal,
          rentTotal: rentTotal,
          subTotal: subTotal,
          transportation,
        },
      });
      setSpinner1(false);
    }
  }, [cherror, message]);

  useEffect(() => {
    if (cproducts) {
      if (cproducts.length > 0) {
        console.log("CPRODUCTS", cproducts);
        let cartItems = JSON.parse(localStorage.getItem("cartItems"));
        setBbuy(
          cproducts.filter(
            (item) =>
              item.itemToSell &&
              cartItems &&
              cartItems.length > 0 &&
              cartItems.find((ct) => ct.id === item._id)?.itemType === "buy"
          )
        );
        setRrent(
          cproducts.filter(
            (item) =>
              item.itemForRent &&
              cartItems &&
              cartItems.length > 0 &&
              cartItems.find((ct) => ct.id === item._id)?.itemType === "rent"
          )
        );
        setSpinner1(false);
      }
    }
  }, [cproducts]);

  useEffect(() => {
    if (rrent.length > 0) {
      if (steps.indexOf("Rental Insurance") === -1) {
        // steps.splice(1, 0, "Information");
        setSteps([
          "Equipment Cart",
          "Rental Insurance",
          "Rental Agreement",
          "Checkout",
        ]);

        setStepsTooltip([
          "Selected equipment are added for rent or purchase",
          "Review and select insurance coverage for your rented equipment",
          "Agree to the terms and conditions related to the rental or purchase of the equipment",
          "Complete your transaction by providing payment and shipping details to finalize your order",
        ]);
      }
    } else {
      setSteps(["Equipment Cart", "Checkout"]);
    }
  }, [rrent]);

  const totalSteps = () => {
    return steps.length;
  };

  const completedSteps = () => {
    return Object.keys(completed).length;
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps();
  };

  const calculateDateDifference = (startDateString, endDateString) => {
    const startDate = new Date(startDateString);
    const endDate = new Date(endDateString);

    // Calculate the difference in milliseconds
    const timeDifference = endDate - startDate;

    // Convert the time difference to days
    const daysDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24));

    // Calculate months, weeks, and additional days
    // const months = Math.floor(daysDifference / 30);
    // const remainingDays = daysDifference % 30;
    // const weeks = Math.floor(remainingDays / 7);
    // const additionalDays = remainingDays % 7;

    // return { days: additionalDays, weeks, months };
    return daysDifference + 1;
  };

  const getTaxRates = () => {
    let toAddress = {
      street1: Address,
      city,
      zip: zipCode,
      state: State,
    };
    let prodDetails = {};
    let rentalItem = [];
    let cartItems = JSON.parse(localStorage.getItem("cartItems"));
    let cart = JSON.parse(sessionStorage.getItem("closetcart")) || [];
    console.log(cartItems);
    if (cartItems && cartItems.length > 0) {
      // setLoaderBtnSpinner(true);
      for (let i = 0; i < cartItems.length; i++) {
        let item = cartItems[i];
        if (item.itemType == "buy" && !prodDetails[item.id]) {
          prodDetails[item.id] = {
            days: "buy",
          };
        } else if (item.itemType == "rent" && !prodDetails[item.id]) {
          let rentPeriodInDays = calculateDateDifference(
            item.checkoutRentalDateRange[0],
            item.checkoutRentalDateRange[1]
          );
          console.log("DAY DIFF", rentPeriodInDays);
          prodDetails[item.id] = {
            days: rentPeriodInDays,
          };
        }
      }
    }
    let token = localStorage.getItem("inktoken");
    dispatch(
      getShippingPrice({
        shippingData: { toAddress, prodDetails, refer: discount },
        token,
      })
    );
  };

  const handleSetToken = (token) => {
    setToken(token);
    handleSubmit(token);
  };

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      window.scrollTo(0, 0);
    }, 1200);

    return () => {
      // Cleanup function
      clearTimeout(timeoutId);
    };
  }, []);

  useEffect(() => {
    console.log(salesTax);
    if (salesTax != null) {
      console.log(salesTax);
      setTax(salesTax);
      setCardSavedDetails(cardDetails);
      console.log("k2l saved card", cardDetails);
      setTransportation(transportCost);
      // setTempTotal(tempTotal+transportCost)
      // calculateTotal();
      dispatch(taxReset());
      setTaxCalculated(true);
      setLoaderBtnSpinner(false);
    }
  }, [salesTax, transportCost]);

  const validateShipping = (newActiveStep) => {
    if (
      firstName == "" ||
      lastName == "" ||
      Address == "" ||
      State == "" ||
      zipCode == "" ||
      city == "" ||
      country == ""
    ) {
      toast.error("Please Fill Out all the Details", toastOption);
    } else {
      setLoaderBtnSpinner(true);
      getTaxRates();
      //setActiveStep(newActiveStep);
    }
  };

  const validateRentAgreement = (newActiveStep) => {
    if (!docusignDone) {
      toast.error("Please complete the rental agreement", toastOption);
    } else {
      setActiveStep(newActiveStep);
    }
  };

  const handleNext = () => {
    window.scrollTo(0, 0);
    // let cart = JSON.parse(sessionStorage.getItem("closetcart")) || [];
    // if (cart.length === 0) {
    //   return false;
    // }
    const newActiveStep =
      isLastStep() && !allStepsCompleted()
        ? // It's the last step, but not all steps have been completed,
          // find the first step that has been completed
          steps.findIndex((step, i) => !(i in completed))
        : activeStep + 1;
    if (steps.includes("Rental Insurance") && activeStep === 3) {
      validateShipping(newActiveStep);
    } else if (!steps.includes("Rental Insurance") && activeStep === 2) {
      validateShipping(newActiveStep);
    } else {
      setActiveStep(newActiveStep);
    }
  };

  const handleBack = () => {
    // let cart = JSON.parse(sessionStorage.getItem("closetcart")) || [];
    // if (cart.length === 0) {
    //   return false;
    // }
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStep = (stepp) => () => {
    window.scrollTo(0, 0);
    let cart = JSON.parse(localStorage.getItem("cartItems")) || [];
    // if (cart.length === 0) {
    //   return false;
    // }
    if (cart.length > 0) {
      // debugger
      if (steps.includes("Rental Insurance") && stepp === 3) {
        validateRentAgreement(stepp);
      } else if (
        steps.includes("Rental Insurance") &&
        activeStep === 3 &&
        stepp > activeStep
      ) {
        validateShipping(stepp);
      } else if (
        !steps.includes("Rental Insurance") &&
        activeStep === 2 &&
        stepp > activeStep
      ) {
        validateShipping(stepp);
      } else {
        setActiveStep(stepp);
      }
    } else {
      toast.info("Please add equipment to proceed further", toastOption);
    }
  };

  const handleComplete = () => {
    window.scrollTo(0, 0);
    const newCompleted = completed;
    newCompleted[activeStep] = true;
    setCompleted(newCompleted);
    handleNext();
  };

  const handleReset = () => {
    setActiveStep(0);
    setCompleted({});
  };

  const handleSubmit = (token) => {
    setSpinner1(true);
    console.log(fileAgreement);
    if (fileAgreement && fileAgreement.file) {
      const timestamp = Math.round(new Date().getTime() / 1000);
      const params = {
        timestamp: timestamp,
        upload_preset: "closet-closest",
        // add any additional parameters here, such as transformation options
      };

      const signature = CryptoJS.SHA1(
        `timestamp=${params.timestamp}&upload_preset=${params.upload_preset}${process.env.REACT_APP_CLOUDINARY_API_SECRET}`
      ).toString();

      const formData = new FormData();
      formData.append("file", fileAgreement.file);
      formData.append("upload_preset", "closet-closest");
      formData.append("cloud_name", "closet-closest");
      formData.append("signature", signature);
      formData.append("timestamp", timestamp);
      formData.append("api_key", process.env.REACT_APP_CLOUDINARY_API_TOKEN);

      return axios
        .post(process.env.REACT_APP_CLOUDINARY_API_URL, formData, {
          headers: { "X-Requested-With": "XMLHttpRequest" },
        })
        .then((response) => {
          const data = response.data;
          const fileURL = data.url; // You should store this URL for future references in your app
          const payload = {
            token: token,
            tax: tax,
            firstName: firstName,
            lastName: lastName,
            Address: Address,
            State: State,
            zipCode: zipCode,
            city: city,
            country: country,
            products: cproducts,
            fileAgreement: fileURL,
            docuSignAgreement:docuSignAgreement?docuSignAgreement:"",
            saveAddress: saveAddress,
            saveCard: saveCard,
            saveInsurance: saveInsurance,
            saveDocuSignInsurance,
            fees: fees,
            transportation,
            total:
              tempTotal +
              (tax.tax_amount_exclusive ? tax.tax_amount_exclusive / 100 : 0),
          };
        
          dispatch(
            checkout({
              checkoutData: payload,
              token: localStorage.getItem("inktoken"),
            })
          );
        })
        .catch((e) => {
          console.log(e);
          throw new Error("Something went Wrong!!!!");
        });
    } else{
      const payload = {
        token: token,
        tax: tax,
        firstName: firstName,
        lastName: lastName,
        Address: Address,
        State: State,
        zipCode: zipCode,
        city: city,
        country: country,
        products: cproducts,
        fileAgreement: fileAgreement?fileAgreement:"",
        docuSignAgreement:docuSignAgreement?docuSignAgreement:"",
        fees: fees,
        saveAddress: saveAddress,
        saveCard: saveCard,
        transportation,
        saveInsurance: saveInsurance,
        saveDocuSignInsurance,
        total:
          tempTotal +
          (tax.tax_amount_exclusive ? tax.tax_amount_exclusive / 100 : 0),
      };

      dispatch(
        checkout({
          checkoutData: payload,
          token: localStorage.getItem("inktoken"),
        })
      );
    }
  };

  useEffect(() => {
    calculateTotal();
  }, [bbuy, rrent, transportation]);

  useEffect(() => {
    //console.log("rent estimates", rentEstimates);
    if (rentEstimates && rentEstimates.length > 0) calculateTotal();
  }, [rentEstimates]);

  useEffect(() => {
    console.log(rrent);
    if (rrent) {
      let rentalItem = [];
      let cartItems = JSON.parse(localStorage.getItem("cartItems"));
      if (cartItems && cartItems.length > 0) {
        rentalItem = cartItems.filter((item) => item.itemType === "rent");
      }
      rrent.map((item, ind) => {
        rentalItem = rentalItem.filter((itemm) => itemm.id === item._id);
        if (rentalItem.length > 0) {
          let rentPeriodInDays = calculateDateDifference(
            rentalItem[0].checkoutRentalDateRange[0],
            rentalItem[0].checkoutRentalDateRange[1]
          );
          console.log("DAY FIFF", rentPeriodInDays);
          let totalRentPrice = 0;
          console.log(rentPeriodInDays);
          if (rentPeriodInDays > 0) {
            const dailyPrice = parseInt(item.itemDailyPrice) || 0;
            const weeklyPrice = parseInt(item.itemWeeklyPrice) || 0;
            const monthlyPrice = parseInt(item.itemMonthlyPrice) || 0;

            const weeks = Math.floor(rentPeriodInDays / 7);
            const remainingDays = rentPeriodInDays % 7;

            let semirent = rentPeriodInDays

            while (semirent > 0) {
                if (semirent > 17) {  // More than 2.5 weeks
                    totalRentPrice += monthlyPrice;
                    semirent -= 28;
                } else if (semirent > 3) {  // More than 3 days
                    totalRentPrice += weeklyPrice;
                    semirent -= 7;
                } else {
                    totalRentPrice += dailyPrice * semirent;
                    semirent = 0;
                }
            }

            if (item.itemFuelPrice) {
              totalRentPrice += item.itemFuelPrice;
            }
            if (item.itemMaintenancePrice) {
              const hoursPerDay = 24; // Number of hours in a day
              const baseMaintenancePrice = item.itemMaintenancePrice; // The base maintenance price for 250 hours

              // Calculate the total rental period in hours
              const totalRentPeriodInHours = rentPeriodInDays * hoursPerDay;

              // Calculate the number of 250-hour intervals
              const intervals = Math.ceil(totalRentPeriodInHours / 250);

              // Calculate the total maintenance cost
              const maintenanceCost = intervals * baseMaintenancePrice;

              // Add to the total rent price
              totalRentPrice += maintenanceCost;
              console.log(`Total Maintenance Cost: ${totalRentPeriodInHours}`);
            }
            if (item.itemCleaningPrice) {
              totalRentPrice += item.itemCleaningPrice;
            }

            setRentEstimates((prev) => {
              // Check if [item._id] is already present in the state
              if (prev) {
                const isItemPresent = prev.some((rentEstimate) =>
                  rentEstimate.hasOwnProperty(item._id)
                );

                //console.log("debugger", isItemPresent);

                // If [item._id] is not present, add it
                if (!isItemPresent) {
                  return [
                    ...prev,
                    {
                      [item._id]: {
                        rentPeriodInDays: rentPeriodInDays,
                        totalRentPrice: totalRentPrice,
                      },
                    },
                  ];
                }

                // If [item._id] is already present, return the unchanged state
                return prev;
              } else {
                // //console.log(
                //   "hello first",
                //   rentPeriodInDays,
                //   totalRentPrice
                // );
                return [
                  ...prev,
                  {
                    [item._id]: {
                      rentPeriodInDays: rentPeriodInDays,
                      totalRentPrice: totalRentPrice,
                    },
                  },
                ];
              }
            });
          }
        }
      });
    }
  }, [rrent]);

  // const calculateTotal = () => {
  //   console.log(rentEstimates);
  //   let subTotal =
  //     rentEstimates &&
  //     rentEstimates.length > 0 &&
  //     rentEstimates.reduce((sum, rentObj) => {
  //       const rentValues = Object.values(rentObj)[0]; // Extracting values from the object
  //       console.log(rentValues);
  //       return sum + rentValues.totalRentPrice;
  //     }, 0);
  //   console.log("SUBTOTAL", subTotal);
  //   // setRentTotal(subTotal);

  //   let buyT =
  //     bbuy &&
  //     bbuy.reduce((total, product) => total + Number(product.itemAmount), 0);
  //   console.log(bbuy);
  //   let buyTotal = buyT;
  //   // setBuyTotal(buyTotal);
  //   subTotal = subTotal ? subTotal + buyT : buyT;
  //   // setSubTotal(subTotal);

  //   // const tax = 0.1 * subTotal; // 10% tax (you can adjust this)
  //   setTax(0);

  //   const totalAmount = subTotal + tax;
  //   if (discount > 0) {
  //     setTotalAmount(totalAmount + (fees * discount) / 100);
  //     console.log(totalAmount);
  //     setTempTotal(totalAmount + (fees * discount) / 100);
  //   } else {
  //     setTotalAmount(totalAmount + fees);
  //     console.log(totalAmount);
  //     setTempTotal(totalAmount + fees);
  //   }

  //   // setTotalAmount(totalAmount+(fees*discount/100));
  //   // console.log(totalAmount);
  //   // setTempTotal(totalAmount+(fees*discount/100));

  //   return { subTotal, tax, totalAmount };
  // };

  const calculateTotal = () => {
    console.log("idhar raaja", rentEstimates);
    let subTotal =
      rentEstimates &&
      rentEstimates.length > 0 &&
      rentEstimates.reduce((sum, rentObj) => {
        const rentValues = Object.values(rentObj)[0]; // Extracting values from the object
        console.log(rentValues);
        return sum + rentValues.totalRentPrice;
      }, 0);
    console.log("SUBTOTAL", subTotal);
    setRentTotal(subTotal);

    let saleTax = 0;

    let buyT =
      bbuy &&
      bbuy.reduce((total, product) => {
        saleTax += Number(((product.itemAmount * 4.5) / 100).toFixed(2));
        return total + Number(product.itemAmount);
      }, 0);
    console.log(bbuy);
    let buyTotal = buyT;
    setBuyTotal(buyTotal);
    subTotal += buyT;
    setSubTotal(subTotal);

    // const tax = 0.1 * subTotal; // 10% tax (you can adjust this)
    setTax(0);

    let totalAmount = subTotal + tax;
    let checkForFees =
      cproducts &&
      cproducts.every((product) => product.chargesIncurredBySeller);
    // let transportFees = cproducts?cproducts.reduce((total, product) => {
    //   let multiple = 2;
    //   if(bbuy.find(buy=>buy._id == product._id)) multiple = 1
    //   return total + multiple * (product.itemTransportationPrice ? product.itemTransportationPrice : 0)
    // },0):0;

    // setTransportation(0);
    totalAmount += transportation;

    // console.log("TRANSPORT",bbuy,cproducts)

    let feesLocal = 0;
    if (!checkForFees) {
      feesLocal = Number((((subTotal - buyT) * 11.5) / 100).toFixed(2));
      console.log("FEE LOCAL", feesLocal, subTotal, buyT);
      feesLocal += saleTax;
      console.log("FEE LOCAL", feesLocal, subTotal, buyT);
      setFees(feesLocal);
    } else {
      setFees(0);
    }

    if (discount > 0) {
      setTotalAmount(totalAmount + (feesLocal * discount) / 100);
      console.log(totalAmount);
      setTempTotal(totalAmount + (feesLocal * discount) / 100);
    } else {
      setTotalAmount(totalAmount + feesLocal);
      console.log(totalAmount);
      setTempTotal(totalAmount + feesLocal);
    }

    return { subTotal, tax, totalAmount };
  };

  // CUSTOM STEPPER
  const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
      top: 27,
    },
    [`&.${stepConnectorClasses.active}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        backgroundImage:
          "linear-gradient( 95deg,var(--black-color) 0%,var(--black-color),var(--black-color) 100%)",
      },
    },
    [`&.${stepConnectorClasses.completed}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        backgroundImage:
          "linear-gradient( 95deg,var(--black-color) 0%,var(--black-color),var(--black-color) 100%)",
      },
    },
    [`& .${stepConnectorClasses.line}`]: {
      height: 3,
      border: 0,
      backgroundColor:
        theme.palette.mode === "dark"
          ? theme.palette.grey[500]
          : "var(--black-color)",
      borderRadius: 1,
    },
  }));

  const fadeIn = keyframes`
  from {
    width: 60px;
  }
  to {
    width: 100%;
  }
`;

  const ColorlibStepIconRoot = React.memo(
    styled("div")(({ theme, ownerState }) => ({
      backgroundColor:
        theme.palette.mode === "dark"
          ? theme.palette.grey[700]
          : "var(--secondary-color)",
      zIndex: 1,
      color: "var(--white-color)",
      padding: "1rem",
      display: "flex",
      borderRadius: !ownerState.active ? "50%" : "30px",
      justifyContent: "center",
      alignItems: "center",
      ...(ownerState.active && {
        background: "var(--secondary-color)",
        // boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
      }),
      ...(ownerState.completed && {
        backgroundImage:
          "linear-gradient( 95deg,var(--secondary-color) 0%,var(--secondary-color),var(--secondary-color) 100%)",
      }),
      cursor: "pointer",
      // animation: ownerState.active ? `${fadeIn} 0.3s ease-in-out` : "none",
      width: "fit-content",
      height: "60px",
    }))
  );

  function ColorlibStepIcon(props) {
    const { active, completed, className } = props;
    let icons = null;
    if (rrent.length > 0) {
      icons = {
        1: <ShoppingCartIcon />,
        2: <UploadFile />,
        3: <Handshake />,
        4: <LocalShipping />,
      };
    } else {
      icons = {
        1: <ShoppingCartIcon />,
        2: <Handshake />,
        3: <LocalShipping />,
      };
    }

    return (
      <ColorlibStepIconRoot
        ownerState={{ completed, active }}
        className={className}
      >
        {icons[String(props.icon)]}
        {active && (
          <Typography marginLeft={".5rem"}>{steps[props.icon - 1]}</Typography>
        )}
      </ColorlibStepIconRoot>
    );
  }

  ColorlibStepIcon.propTypes = {
    /**
     * Whether this step is active.
     * @default false
     */
    active: PropTypes.bool,
    className: PropTypes.string,
    /**
     * Mark the step as completed. Is passed to child components.
     * @default false
     */
    completed: PropTypes.bool,
    /**
     * The label displayed in the step icon.
     */
    icon: PropTypes.node,
    index: PropTypes.any,
  };

  // const steps = ['Select campaign settings', 'Create an ad group', 'Create an ad'];
  // END CUSTOM STEPPER

  return (
    <Container
      // maxWidth={"lg"}
      sx={{ margin: "2rem auto" }}
      data-aos="fade-in"
      data-aos-duration="1000"
    >
      <Stack
        sx={{ width: "100%" }}
        data-aos="fade-down"
        data-aos-duration="1200"
      >
        <Stepper
          activeStep={activeStep}
          alternativeLabel={true}
          connector={<ColorlibConnector />}
          // style={{ marginBottom: "2rem" }}
        >
          {steps.map((label, index) => (
            <DarkToolTip
              arrow
              className="gd-tooltip"
              placement="bottom"
              title={stepsTooltip[index]}
            >
              <Step
                key={label}
                onClick={handleStep(index)}
                completed={completed[index]}
              >
                <StepLabel StepIconComponent={ColorlibStepIcon}></StepLabel>
              </Step>
            </DarkToolTip>
          ))}
        </Stepper>
      </Stack>
      <div>
        <>
          {spinner1 && <Spinner pos={"relative"} />}
          {allStepsCompleted() ? (
            <React.Fragment>
              <Typography sx={{ mt: 2, mb: 1 }}>
                All steps completed - you&apos;re finished
              </Typography>
              <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                <Box sx={{ flex: "1 1 auto" }} />
                <Button onClick={handleReset}>Reset</Button>
              </Box>
            </React.Fragment>
          ) : (
            <React.Fragment>
              {/* <Typography sx={{ mt: 2, mb: 1, py: 1 }}>
              Step {activeStep + 1}
            </Typography> */}
              <Box
                sx={{
                  minHeight: "70vh",
                  // display: "flex",
                  // justifyContent: "center",
                  // alignItems: "center",
                }}
                style={spinner1 ? { display: "none" } : {}}
              >
                {activeStep + 1 === 1 && (
                  <Checkout
                    activeStep={activeStep}
                    handleBack={handleBack}
                    handleNext={handleNext}
                    steps={steps}
                    setTempTotal={setTempTotal}
                    setCart={setCart}
                    fees={fees}
                    discount={discount}
                    spinner1={spinner1}
                    transportFees={transportation}
                  />
                )}
                {steps.includes("Rental Insurance") ? (
                  <>
                    {activeStep + 1 === 2 && (
                      <CheckoutUploadInsurance
                      fileAgreement={fileAgreement}
                      setFileAgreement={setFileAgreement}
                        handleSubmit={handleSubmit}
                        handleNext={handleNext}
                        rentTotal={rentTotal}
                        setSaveInsurance={setSaveInsurance}
                        docuSignByOwn={docuSignByOwn}
                        docusignDone={docusignDone}
                        setDocusignDone={setDocusignDone}
                        setDocuSignByOwn={setDocuSignByOwn}
                      />
                      // <CheckoutRentalAgreement //RENTAL AGREEMENT
                      //   rrent={rrent}
                      //   fileAgreement={fileAgreement}
                      //   setFileAgreement={setFileAgreement}
                      //   setActiveStep={setActiveStep}
                      //   docusignDone={docusignDone}
                      //   setDocusignDone={setDocusignDone}
                      //   setSaveInsurance={setSaveInsurance}
                      //   saveInsurance={saveInsurance}
                      //   setDocuSignByOwn={setDocuSignByOwn}
                      //   docuSignByOwn={docuSignByOwn}
                      // />
                    )}
                    {activeStep + 1 === 4 && (
                      <CheckoutShipping
                        firstName={firstName}
                        setFirstName={setFirstName}
                        lastName={lastName}
                        setLastName={setLastName}
                        Address={Address}
                        setAddress={setAddress}
                        State={State}
                        setState={setState}
                        zipCode={zipCode}
                        setZipCode={setZipCode}
                        city={city}
                        setCity={setCity}
                        country={country}
                        setCountry={setCountry}
                        //CHeckout Payment Props
                        tempTotal={tempTotal}
                        handleSubmit={handleSubmit}
                        spinner1={setSpinner1}
                        setSpinner1={setSpinner1}
                        tax={tax}
                        validateShipping={validateShipping}
                        taxCalculated={taxCalculated}
                        setToken={handleSetToken}
                        loaderBtnSpinner={loaderBtnSpinner}
                        setCardNumber={setCardNumber}
                        setCardExpiry={setCardExpiry}
                        setCardCvc={setCardCvc}
                        cardNumber={cardNumber}
                        cardExpiry={cardExpiry}
                        cardCvc={cardCvc}
                        cardDetails={cardSavedDetails}
                        setSaveCard={setSaveCard}
                        setSaveAddress={setSaveAddress}
                        fees={fees}
                        discount={discount}
                        transportFees={transportation}
                      />
                    )}
                    {activeStep + 1 === 3 && (
                       <CheckoutRentalAgreement //RENTAL AGREEMENT
                       rrent={rrent}
                       fileAgreement={fileAgreement}
                       setFileAgreement={setFileAgreement}
                       setActiveStep={setActiveStep}
                       docusignDone={docusignDone}
                       setDocusignDone={setDocusignDone}
                       setSaveInsurance={setSaveInsurance}
                       saveInsurance={saveInsurance}
                       saveDocuSignInsurance={saveDocuSignInsurance}
                       setSaveDocuSignInsurance={setSaveDocuSignInsurance}
                       setDocuSignByOwn={setDocuSignByOwn}
                       docuSignByOwn={docuSignByOwn}
                       docuSignAgreement={docuSignAgreement}
                        setDocuSignAgreement={setDocuSignAgreement}
                     />
                      // <CheckoutUploadInsurance
                      // fileAgreement={fileAgreement}
                      // setFileAgreement={setFileAgreement}
                      //   handleSubmit={handleSubmit}
                      //   handleNext={handleNext}
                      //   rentTotal={rentTotal}
                      //   setSaveInsurance={setSaveInsurance}
                      //   docuSignByOwn={docuSignByOwn}
                      //   docusignDone={docusignDone}
                      //   setDocusignDone={setDocusignDone}
                      //   setDocuSignByOwn={setDocuSignByOwn}
                      // />
                    )}
                    {activeStep + 1 === 5 && (
                      <Typography
                        variant="h1"
                        fontWeight={700}
                        fontSize={"52px"}
                        textAlign={"center"}
                        marginTop={"5rem"}
                      >
                        Coming Soon!
                      </Typography>
                    )}
                  </>
                ) : (
                  <>
                    {/* {activeStep + 1 === 2 && (
                        <CheckoutRentalAgreement rrent={rrent} />
                      )} */}
                    {activeStep + 1 === 2 && (
                      <CheckoutShipping
                        firstName={firstName}
                        setFirstName={setFirstName}
                        lastName={lastName}
                        setLastName={setLastName}
                        Address={Address}
                        setAddress={setAddress}
                        State={State}
                        setState={setState}
                        zipCode={zipCode}
                        setZipCode={setZipCode}
                        city={city}
                        setCity={setCity}
                        country={country}
                        setCountry={setCountry}
                        //CHeckout Payment Props
                        tempTotal={tempTotal}
                        handleSubmit={handleSubmit}
                        spinner1={setSpinner1}
                        setSpinner1={setSpinner1}
                        tax={tax}
                        validateShipping={validateShipping}
                        taxCalculated={taxCalculated}
                        setToken={handleSetToken}
                        setCardNumber={setCardNumber}
                        setCardExpiry={setCardExpiry}
                        setCardCvc={setCardCvc}
                        cardNumber={cardNumber}
                        cardExpiry={cardExpiry}
                        cardCvc={cardCvc}
                        cardDetails={cardSavedDetails}
                        setSaveCard={setSaveCard}
                        setSaveAddress={setSaveAddress}
                        fees={fees}
                        discount={discount}
                        transportFees={transportation}
                      />
                    )}
                    {/* {activeStep + 1 === 2 && (
                      <CheckoutUploadInsurance
                        handleSubmit={handleSubmit}
                        handleNext={handleNext}
                      />
                    )} */}
                    {activeStep + 1 === 4 && (
                      <Typography
                        variant="h1"
                        fontWeight={700}
                        fontSize={"52px"}
                        textAlign={"center"}
                        marginTop={"5rem"}
                      >
                        Coming Soon!
                      </Typography>
                    )}
                  </>
                )}
                {activeStep !== 0 &&
                  activeStep !== steps.indexOf("Checkout") && (
                    // <Box
                    //   sx={{
                    //     display: "flex",
                    //     flexDirection: "row",
                    //     pt: 2,
                    //     justifyContent: "center",
                    //   }}
                    // >
                    //   <Button
                    //     variant="outlined"
                    //     disabled={activeStep === 0}
                    //     onClick={() => handleBack()}
                    //     sx={{ mr: 1, color: "#999" }}
                    //   >
                    //     Back
                    //   </Button>
                    //   {/* <Box sx={{ flex: "1 1 auto" }} /> */}
                    //   <Button
                    //     variant="contained"
                    //     onClick={() => handleNext()}
                    //     disabled={
                    //       !steps.includes("Rental Insurance")
                    //         ? activeStep === 2
                    //         : activeStep === 3
                    //     }
                    //     sx={{ mr: 1 }}
                    //   >
                    //     Next
                    //   </Button>
                    //   {/* {activeStep !== steps.length &&
                    // (completed[activeStep] ? (
                    //   <Typography
                    //     variant="caption"
                    //     sx={{ display: "inline-block" }}
                    //   >
                    //     Step {activeStep + 1} already completed
                    //   </Typography>
                    // ) : (
                    //   <Button onClick={handleComplete}>
                    //     {completedSteps() === totalSteps() - 1
                    //       ? "Finish"
                    //       : "Complete Step"}{" "}
                    //   </Button>
                    // ))} */}
                    // </Box>
                    <></>
                  )}
              </Box>
            </React.Fragment>
          )}
        </>
      </div>
    </Container>
  );
}
