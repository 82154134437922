import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import axios from "axios"

const initialState = {
  setupStatus: "idle",
  userFullInfo: null,
  setupError: null,
}

export const closetSetup = createAsyncThunk(
  "closet/closetSetup",
  async ({token,values}, { rejectWithValue }) => {
    try {
      const { data } = await axios.put(
        "/api/users/closetsetup",values,
        {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        }
      )
      return data
    } catch (err) {
      return rejectWithValue(err.response.data)
    }
  }
)
export const closetsetupSlice = createSlice({
  name: "closet",
  initialState,
  reducers: {
    resetSetup(state,action){
      state.userFullInfo = null
      state.setupStatus="idle"
      state.setupError= null
    }
  },
  extraReducers: {
    [closetSetup.pending]: (state, action) => {
      state.setupStatus = "loading"
    },
    [closetSetup.fulfilled]: (state, action) => {
      state.setupStatus = "succeeded"
      state.userFullInfo = action.payload
    },
    [closetSetup.rejected]: (state, action) => {
      state.setupStatus = "failed";
      console.log(action);
      state.setupError = action.payload
    },
  },
})

export const { resetSetup } = closetsetupSlice.actions

export default closetsetupSlice.reducer 