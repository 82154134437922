import React, { useState } from "react";
import "./Tabs.css";
import {
  Container,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { useEffect } from "react";
import EquipmentTab from "../../MyEquipments/EquipmentTab";
const Tabs = ({
  tabs,
  removeMargins = false,
  productType,
  sortType = "newest",
}) => {
  const [activeTab, setActiveTab] = useState(null);

  useEffect(() => {
    // console.log("PRODUCTTYPE", productType);
    if (productType === "buy") {
      setActiveTab(0);
    } else if (productType === "rent") {
      setActiveTab(1);
    } else if (productType === "hold") {
      setActiveTab(2);
    } else if (productType === "draft") {
      setActiveTab(3);
    } else if (productType === "delisted") {
      setActiveTab(4);
    } else {
      setActiveTab(5);
    }
  }, [productType]);

  return (
    <div>
      {activeTab >= 0 && (
        <>
          {/* <Typography variant="h3" my={"1rem"} className="mont-title">
            {tabs[0].title}
          </Typography> */}
          {/* <ul className="tab-list">
              {tabs.map((tab, index) => (
                <li
                  key={index}
                  style={{
                    marginRight: index === 0 && tabs.length === 2 && "1rem",
                    marginLeft:
                      index === tabs.length - 1 && tabs.length === 2 && "1rem",
                    margin: index !== 0 && index !== tabs.length - 1 && "0 1rem",
                  }}
                  className={index === activeTab ? "active" : ""}
                  onClick={() => setActiveTab(index)}
                >
                  {tab.label}
                </li>
              ))}
            </ul> */}
          {/* <FormControl color="secondary" fullWidth>
              <InputLabel id="demo-simple-select-label">
                Select By Equipment Type
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                color="secondary"
                id="demo-simple-select"
                value={activeTab}
                label="Select By Equipment Type"
                onChange={(e) => setActiveTab(e.target.value)}
              >
                {tabs.map((tab, ind) => (
                  <MenuItem color="secondary" key={ind} value={ind}>
                    {tab.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl> */}
          {/* <div className="tab-content">{tabs[activeTab].content}</div> */}
          <div className="tab-content">
            {<EquipmentTab type={productType} sort={sortType} />}
          </div>
        </>
      )}
    </div>
  );
};

export default Tabs;
