import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
  homeStatus: "idle",
  homeInfo: null,
  homeError: null,
};

export const gethomeInfo = createAsyncThunk(
  "home/getHomeInfo",
  async ({ token, p=0 }, { rejectWithValue }) => {
    try {
      console.log(token)
      const { data } = await axios.get(`/api/static/homeinfo`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);
export const homeInfoSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    resetHome(state, action) {
      state.homeStatus = "idle";
      state.homeInfo = null;
      state.homeError = null;
    },
  },
  extraReducers: {
    [gethomeInfo.pending]: (state, action) => {
      state.homeStatus = "loading";
    },
    [gethomeInfo.fulfilled]: (state, action) => {
      state.homeStatus = "succeeded";
      state.homeInfo = action.payload.info;
    },
    [gethomeInfo.rejected]: (state, action) => {
      state.homeStatus = "failed";
      state.homeError = action.payload.message;
    },
  },
});

export const { resetHome } = homeInfoSlice.actions;
export default homeInfoSlice.reducer;
