import { Box, Grid, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import ProductCard from "../ProductCard/ProductCard";
import { useDispatch, useSelector } from "react-redux";
import Spinner from "../Spinner/Spinner";
import TabsSorter from "../TabsSorter/TabsSorter";
import Tabs from "../AdminComponents/Tabs/Tabs";
import { myEquipmentTab } from "../AdminComponents/Tabs/TabsContent";
import EquipmentTypeSorter from "../TabsSorter/EquipmentTypeSorter";
import EquipmentAvailSorter from "../TabsSorter/EquipmentAvailSorter";
import { getuserInfo } from "../StateSlices/getuserSlice";
import { useLocation } from "react-router-dom";
import Confetti from "react-confetti";

const MyEquipments = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { userInfo } = useSelector((state) => state.user);
  const [userProducts, setUserProducts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [productType, setProductType] = useState("both");
  const [sortType, setSortType] = useState("newest");
  const [showConfetti, setShowConfetti] = useState(false);
  // const [inital, setInital] = useState(false)
  // useEffect(() => {
  //   if (userInfo && userInfo.userProducts) {
  //     setUserProducts(userInfo?.userProducts);
  //     setLoading(false);
  //     //   setSpinner(false);
  //   }
  // }, [userInfo]);

  useEffect(() => {
    // if(!inital){
    let token = localStorage.getItem("inktoken");
    // setInital(true)
    dispatch(getuserInfo({ token }));
    // }
  }, []);

  useEffect(() => {
    console.log("FROM MAIN");
  }, [productType]);

  useEffect(() => {
    if (location?.state?.showConfetti) {
      if (userInfo?.userProducts) {
        setShowConfetti(true);
        setTimeout(() => {
          setShowConfetti(false);
        }, 6000);
      }
    }
  }, [location.state]);

  return (
    <>
      <div className={`confetti-container ${showConfetti ? "show" : "hide"}`}>
        <Confetti
          wind={0.01}
          gravity={0.15}
          numberOfPieces={400}
          width={window.innerWidth - 30}
          height={window.innerHeight + 120}
          colors={["#161b21", "#f05023", "#f3a950", "#f1f1f1"]}
          recycle={showConfetti}
        />
      </div>
      <Box component={"div"} className="tabs-header">
        <Typography variant="h2" className="title mont-title">
          My Equipment
        </Typography>
        <Stack direction={"row"} alignItems={"center"}>
          <EquipmentAvailSorter
            setLoading={setLoading}
            userProducts={userProducts || userInfo?.userProducts}
            setUserProducts={setUserProducts}
            setProductType={setProductType}
            productType={productType}
          />
          <EquipmentTypeSorter
            setLoading={setLoading}
            userProducts={userProducts || userInfo?.userProducts}
            setUserProducts={setUserProducts}
            setProductType={setProductType}
            productType={productType}
          />
          <TabsSorter
            setLoading={setLoading}
            data={userProducts || userInfo?.userProducts}
            setData={setUserProducts}
            setType={setProductType}
            type={productType}
            setSortType={setSortType}
          />
        </Stack>
      </Box>
      <Tabs
        tabs={myEquipmentTab}
        productType={productType}
        sortType={sortType}
      />
    </>
  );
};

export default MyEquipments;
