export const usersData = [
  {
    userFName: "JohnS",
    userLName: "DoeS",
    userEmail: "tester@gmail.com",
    userAddress: "2061 TREXLELR CT",
    userPhone: "91995594586",
    userCity: "Denton",
    userState: "Texas",
    userZipCode: "76056",
    profileStatus: "complete",
    verified: true,
    membershipExpiration: "2023-12-29T08:34:23.000Z",
    createdAt: "2022-12-25T17:45:47.466Z",
    updatedAt: "2024-02-16T14:34:42.977Z",
    companyName: "Dizel",
    companyDesc:
      "Dizel Marketplace is an amazing rental ecommerce store for constructors and realtors",
    companyDesgn: "Chief Evangelist Officer",
    referId: "66067e",
    status: "Active",
    referCount: 0,
    agreement:
      "https://res.cloudinary.com/closet-closest/image/upload/v1708055702/xysmoewcdmbwornv5ple.pdf",
    docusignAgreement: "",
  },
  {
    userFName: "JohnS",
    userLName: "DoeS",
    userEmail: "tester@gmail.com",
    userAddress: "2061 TREXLELR CT",
    userPhone: "91995594586",
    userCity: "Denton",
    userState: "Texas",
    userZipCode: "76056",
    profileStatus: "complete",
    verified: true,
    membershipExpiration: "2023-12-29T08:34:23.000Z",
    createdAt: "2022-12-25T17:45:47.466Z",
    updatedAt: "2024-02-16T14:34:42.977Z",
    companyName: "Dizel",
    companyDesc:
      "Dizel Marketplace is an amazing rental ecommerce store for constructors and realtors",
    companyDesgn: "Chief Evangelist Officer",
    referId: "66067e",
    status: "Active",
    referCount: 0,
    agreement:
      "https://res.cloudinary.com/closet-closest/image/upload/v1708055702/xysmoewcdmbwornv5ple.pdf",
    docusignAgreement: "",
  },
  {
    userFName: "JohnS",
    userLName: "DoeS",
    userEmail: "tester@gmail.com",
    userAddress: "2061 TREXLELR CT",
    userPhone: "91995594586",
    userCity: "Denton",
    userState: "Texas",
    userZipCode: "76056",
    profileStatus: "complete",
    verified: true,
    membershipExpiration: "2023-12-29T08:34:23.000Z",
    createdAt: "2022-12-25T17:45:47.466Z",
    updatedAt: "2024-02-16T14:34:42.977Z",
    companyName: "Dizel",
    companyDesc:
      "Dizel Marketplace is an amazing rental ecommerce store for constructors and realtors",
    companyDesgn: "Chief Evangelist Officer",
    referId: "66067e",
    status: "Active",
    referCount: 0,
    agreement:
      "https://res.cloudinary.com/closet-closest/image/upload/v1708055702/xysmoewcdmbwornv5ple.pdf",
    docusignAgreement: "",
  },
  {
    userFName: "JohnS",
    userLName: "DoeS",
    userEmail: "tester@gmail.com",
    userAddress: "2061 TREXLELR CT",
    userPhone: "91995594586",
    userCity: "Denton",
    userState: "Texas",
    userZipCode: "76056",
    profileStatus: "complete",
    verified: true,
    membershipExpiration: "2023-12-29T08:34:23.000Z",
    createdAt: "2022-12-25T17:45:47.466Z",
    updatedAt: "2024-02-16T14:34:42.977Z",
    companyName: "Dizel",
    companyDesc:
      "Dizel Marketplace is an amazing rental ecommerce store for constructors and realtors",
    companyDesgn: "Chief Evangelist Officer",
    referId: "66067e",
    status: "Active",
    referCount: 0,
    agreement:
      "https://res.cloudinary.com/closet-closest/image/upload/v1708055702/xysmoewcdmbwornv5ple.pdf",
    docusignAgreement: "",
  },
  {
    userFName: "JohnS",
    userLName: "DoeS",
    userEmail: "tester@gmail.com",
    userAddress: "2061 TREXLELR CT",
    userPhone: "91995594586",
    userCity: "Denton",
    userState: "Texas",
    userZipCode: "76056",
    profileStatus: "complete",
    verified: true,
    membershipExpiration: "2023-12-29T08:34:23.000Z",
    createdAt: "2022-12-25T17:45:47.466Z",
    updatedAt: "2024-02-16T14:34:42.977Z",
    companyName: "Dizel",
    companyDesc:
      "Dizel Marketplace is an amazing rental ecommerce store for constructors and realtors",
    companyDesgn: "Chief Evangelist Officer",
    referId: "66067e",
    status: "Active",
    referCount: 0,
    agreement:
      "https://res.cloudinary.com/closet-closest/image/upload/v1708055702/xysmoewcdmbwornv5ple.pdf",
    docusignAgreement: "",
  },
  {
    userFName: "JohnS",
    userLName: "DoeS",
    userEmail: "tester@gmail.com",
    userAddress: "2061 TREXLELR CT",
    userPhone: "91995594586",
    userCity: "Denton",
    userState: "Texas",
    userZipCode: "76056",
    profileStatus: "complete",
    verified: true,
    membershipExpiration: "2023-12-29T08:34:23.000Z",
    createdAt: "2022-12-25T17:45:47.466Z",
    updatedAt: "2024-02-16T14:34:42.977Z",
    companyName: "Dizel",
    companyDesc:
      "Dizel Marketplace is an amazing rental ecommerce store for constructors and realtors",
    companyDesgn: "Chief Evangelist Officer",
    referId: "66067e",
    status: "Active",
    referCount: 0,
    agreement:
      "https://res.cloudinary.com/closet-closest/image/upload/v1708055702/xysmoewcdmbwornv5ple.pdf",
    docusignAgreement: "",
  },
  {
    userFName: "JohnS",
    userLName: "DoeS",
    userEmail: "tester@gmail.com",
    userAddress: "2061 TREXLELR CT",
    userPhone: "91995594586",
    userCity: "Denton",
    userState: "Texas",
    userZipCode: "76056",
    profileStatus: "complete",
    verified: true,
    membershipExpiration: "2023-12-29T08:34:23.000Z",
    createdAt: "2022-12-25T17:45:47.466Z",
    updatedAt: "2024-02-16T14:34:42.977Z",
    companyName: "Dizel",
    companyDesc:
      "Dizel Marketplace is an amazing rental ecommerce store for constructors and realtors",
    companyDesgn: "Chief Evangelist Officer",
    referId: "66067e",
    status: "Active",
    referCount: 0,
    agreement:
      "https://res.cloudinary.com/closet-closest/image/upload/v1708055702/xysmoewcdmbwornv5ple.pdf",
    docusignAgreement: "",
  },
  {
    userFName: "JohnS",
    userLName: "DoeS",
    userEmail: "tester@gmail.com",
    userAddress: "2061 TREXLELR CT",
    userPhone: "91995594586",
    userCity: "Denton",
    userState: "Texas",
    userZipCode: "76056",
    profileStatus: "complete",
    verified: true,
    membershipExpiration: "2023-12-29T08:34:23.000Z",
    createdAt: "2022-12-25T17:45:47.466Z",
    updatedAt: "2024-02-16T14:34:42.977Z",
    companyName: "Dizel",
    companyDesc:
      "Dizel Marketplace is an amazing rental ecommerce store for constructors and realtors",
    companyDesgn: "Chief Evangelist Officer",
    referId: "66067e",
    status: "Active",
    referCount: 0,
    agreement:
      "https://res.cloudinary.com/closet-closest/image/upload/v1708055702/xysmoewcdmbwornv5ple.pdf",
    docusignAgreement: "",
  },
  {
    userFName: "JohnS",
    userLName: "DoeS",
    userEmail: "tester@gmail.com",
    userAddress: "2061 TREXLELR CT",
    userPhone: "91995594586",
    userCity: "Denton",
    userState: "Texas",
    userZipCode: "76056",
    profileStatus: "complete",
    verified: true,
    membershipExpiration: "2023-12-29T08:34:23.000Z",
    createdAt: "2022-12-25T17:45:47.466Z",
    updatedAt: "2024-02-16T14:34:42.977Z",
    companyName: "Dizel",
    companyDesc:
      "Dizel Marketplace is an amazing rental ecommerce store for constructors and realtors",
    companyDesgn: "Chief Evangelist Officer",
    referId: "66067e",
    status: "Active",
    referCount: 0,
    agreement:
      "https://res.cloudinary.com/closet-closest/image/upload/v1708055702/xysmoewcdmbwornv5ple.pdf",
    docusignAgreement: "",
  },
  {
    userFName: "JohnS",
    userLName: "DoeS",
    userEmail: "tester@gmail.com",
    userAddress: "2061 TREXLELR CT",
    userPhone: "91995594586",
    userCity: "Denton",
    userState: "Texas",
    userZipCode: "76056",
    profileStatus: "complete",
    verified: true,
    membershipExpiration: "2023-12-29T08:34:23.000Z",
    createdAt: "2022-12-25T17:45:47.466Z",
    updatedAt: "2024-02-16T14:34:42.977Z",
    companyName: "Dizel",
    companyDesc:
      "Dizel Marketplace is an amazing rental ecommerce store for constructors and realtors",
    companyDesgn: "Chief Evangelist Officer",
    referId: "66067e",
    status: "Active",
    referCount: 0,
    agreement:
      "https://res.cloudinary.com/closet-closest/image/upload/v1708055702/xysmoewcdmbwornv5ple.pdf",
    docusignAgreement: "",
  },
];

export const equipmentData = [
  {
    itemName: "Dump Truck, 3 cubic yds., Single Axle, Diesel Powered",
    itemDescription:
      "Haul soil, sand, gravel and more with this 3 cubic yard dump truck. Its steel bed and side walls can handle the toughest materials. This truck would be ideal for smaller construction sites or DIY earthmoving jobs. We have 2-wheel drive and 4-wheel drive options.",
    itemCategory: ["65ceaaa51e4d043671bba76d"],
    itemSubCategory: ["65ceab339ddad92c5b9899a2"],
    itemBrand: "65ceab790a582b9f43f4a880",
    itemCondition: "Used",
    itemYearManufacture: "2002",
    itemAmount: 0,
    itemDeposit: 5,
    itemWeeklyPrice: 865,
    itemMonthlyPrice: 1735,
    itemDailyPrice: 350,
    itemLocation: "Raleigh, North Carolina",
    itemModel: "None",
    itemLeaseStart: {
      $date: "2024-02-22T14:08:31.381Z",
    },
    itemLeaseEnd: {
      $date: "2024-03-23T14:08:31.381Z",
    },
    itemLeaseFields: [
      {
        from: "1970-01-01T00:00:45.082Z",

        to: "1970-01-01T00:00:45.111Z",
      },
    ],
    itemToSell: false,
    itemForRent: true,
    createdAt: "2024-02-16T00:33:46.186Z",
    updatedAt: "2024-02-22T14:08:31.381Z",
    link: "https://www.unitedrentals.com/marketplace/equipment/trucks-trailers/trucks/dump-truck-3-cubic-yds-single-axle-diesel-powered",
    merchantFeed: true,
  },
];

export const ordersData = [
  {
    userId: "657b91385e41891f78021718",
    shippingAddress: "2061 Trexler Ct, Raleigh, NC 27606",
    shippingCity: "Raleigh",
    shippingState: "Gujarat",
    shippingZipCode: "27606",
    phone: "9876543141",
    name: "Jay Patel",
    pricePaid: "9188.7",
    orderdBy: "user",
    checkoutTime: "2024-01-12T00:02:19.628Z",
  },
];

export const referralData = [
  { referredTo: "ketul.chhaya@gmail.com", referredBy: "jay.patel@gmail.com" },
  { referredTo: "ketul.chhaya@gmail.com", referredBy: "jay.patel@gmail.com" },
  { referredTo: "ketul.chhaya@gmail.com", referredBy: "jay.patel@gmail.com" },
  { referredTo: "ketul.chhaya@gmail.com", referredBy: "jay.patel@gmail.com" },
  { referredTo: "ketul.chhaya@gmail.com", referredBy: "jay.patel@gmail.com" },
  { referredTo: "ketul.chhaya@gmail.com", referredBy: "jay.patel@gmail.com" },
];
