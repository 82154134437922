import React, { useEffect, useMemo, useState } from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-material.css";
import { equipmentData } from "../dummyData";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteProduct,
  getProducts,
  resetUpdateStatus,
  unbanProduct,
} from "../../StateSlices/adminSlice";
import {
  categoies,
  brands,
  transportationOptions,
} from "../../../pages/AddNewItem/data";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import swal from "sweetalert";
import UnarchiveIcon from "@mui/icons-material/Unarchive";
import { toast } from "react-toastify";
import "../Users/Users.css";
import AnalyticsHeader from "../AnalyticsHeader/AnalyticsHeader";
import Spinner from "../../Spinner/Spinner";
import { Pagination, PaginationItem, Stack } from "@mui/material";
import { ArrowBack, ArrowForward } from "@mui/icons-material";
import { FaPen, FaTrash } from "react-icons/fa6";

const toastOption = {
  position: "top-right",
  autoClose: 1500,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: "dark",
};

const Equipments = ({ tab, type }) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [rowData, setRowData] = useState();
  const [spinner, setSpinner] = useState(false);
  const [pageChanged, setPageChanged] = useState(false);
  const [pageNumber, setPageNumber] = useState(
    location.search.slice(-1)
      ? location.search.slice(location.search.indexOf("=") + 1) - 1
      : 1
  );

  const agGridHeight = window.innerHeight - 150;

  const {
    allUserStatus,
    allProductsInfo,
    allUserError,
    totalCount,
    updateUserStatus,
  } = useSelector((state) => state.admin);

  const handlePageChange = (e, value) => {
    if (value !== pageNumber) {
      window.scrollTo(0, 0);
      setPageChanged(true);
      setPageNumber(value);
    }
  };

  const actionButton = (props) => {
    // const dispatch = useDispatch();
    const cellValue = props.valueFormatted ? props.valueFormatted : props.value;

    const deleteUserWar = (id) => {
      swal({
        title: "Delete Product",
        text: "Are you sure you want to delete and remove this item from the site?",
        icon: "warning",
        buttons: true,
        dangerMode: true,
        // content: {
        //   element: "input",
        //   attributes: {
        //     placeholder: "Reason for Ban",
        //     type: "text",
        //     // require:true
        //   },
        // },
        inputValidator: (value) => {
          return new Promise((resolve) => {
            if (value.trim() !== "") {
              resolve();
            } else {
              resolve("Please enter a reason for banning the product");
            }
          });
        },
      }).then((willReactive) => {
        // console.log(willReactive);
        if (willReactive !== null) {
          let token = localStorage.getItem("inktoken");
          if (token) {
            setSpinner(true);
            dispatch(
              deleteProduct({
                token,
                id,
              })
            );
          } else {
            // navigate("/404", { replace: true });
          }
        }
      });
    };

    const unbanProductWar = (id) => {
      swal({
        title: "Unban Product",
        text: "Once Unbanned, Product will be available for users",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then((willReactive) => {
        // console.log(willReactive);
        if (willReactive) {
          let token = localStorage.getItem("inktoken");
          if (token) {
            setSpinner(true);
            dispatch(
              unbanProduct({
                token,
                id,
                // p: pageNumber,
                // search: productSearchVal,
                // status: tab === "ban" ? true : false,
                // sort: sorting,
                // report: tab === "report" ? true : false,
                // purchased: tab === "purchased" ? true : false,
              })
            );
          } else {
            navigate("/404", { replace: true });
          }
        }
      });
    };

    return (
      <span>
        {props.data.itemBan ? (
          <button
            class="btn-sm"
            onClick={() => unbanProductWar(props.data._id)}
          >
            <UnarchiveIcon />
          </button>
        ) : (
          <button class="btn-sm" onClick={() => deleteUserWar(props.data._id)}>
            <FaTrash
              cursor={"pointer"}
              color="var(--grey-color)"
              size={"1em"}
            />
          </button>
        )}

        <button
          onClick={() => {
            let brand = brands.find(
              (brand) => brand._id === props.data.itemBrand
            ).label;

            navigate(
              `/editproduct/${brand}-${encodeURIComponent(
                props.data.itemName
              )}`,
              {
                state: { id: props.data._id },
              }
            );
          }}
        >
          <FaPen cursor={"pointer"} color="var(--grey-color)" size={"1em"} />
        </button>
      </span>
    );
  };

  const getDate = (params) => {
    const date = new Date(params.value);
    return (
      (date.getMonth() + 1).toString().padStart(2, "0") +
      "-" +
      date.getDate().toString().padStart(2, "0") +
      "-" +
      date.getFullYear()
    );
  };
  const defaultColDef = useMemo(() => ({
    filter: true,
    cellStyle: { fontFamily: "Helvetica, Arial, sans-serif" },
  }));
  const [colDefs, setColDefs] = useState([
    // {
    //   headerName: "Action",
    //   width: "130px",
    //   field: "",
    //   cellRenderer: actionButton,
    // },
    { headerName: "Name", field: "itemName" },
    {
      headerName: "Type",
      field: "itemModel",
      valueFormatter: (params) => {
        // console.log(params)
        if (params.data.itemForRent === true) {
          return "Rent";
        } else {
          return "Sell";
        }
      },
    },
    { headerName: "Merchant Feed", field: "merchantFeed" },
    { headerName: "Description", field: "itemDescription" },
    {
      headerName: "Category",
      field: "itemGoal",
    },
    { headerName: "Sub Category", field: "itemCategoryName" },
    { headerName: "Type", field: "itemSubCategoryName" },
    { headerName: "Condition", field: "itemCondition" },
    { headerName: "Year Manufactured", field: "itemYearManufacture" },
    { headerName: "Amount", field: "itemAmount" },
    { headerName: "Deposit", field: "itemDeposit" },
    { headerName: "Daily Rent", field: "itemDailyPrice" },
    { headerName: "Weekly Rent", field: "itemWeeklyPrice" },
    { headerName: "Monthly Rent", field: "itemMonthlyPrice" },
    { headerName: "Location", field: "itemLocation" },
    {
      headerName: "Delivery Option",
      field: "transportation",
      valueFormatter: (params) =>
        params && params.value
          ? transportationOptions.filter((s) => s.value === params.value)[0]
              ?.label
          : "",
    },
    {
      headerName: "Lease Start",
      field: "itemLeaseStart",
      valueFormatter: (params) => getDate(params),
    },
    {
      headerName: "Lease End",
      field: "itemLeaseEnd",
      valueFormatter: (params) => getDate(params),
    },
    {
      headerName: "Published At",
      field: "createdAt",
      valueFormatter: (params) => getDate(params),
    },
    {
      headerName: "Updated At",
      field: "updatedAt",
      valueFormatter: (params) => getDate(params),
    },
    {
      headerName: "Brand",
      field: "itemBrand",
      valueFormatter: (params) => {
        let findBrand = brands.find((brand) => brand._id === params.value);
        return findBrand ? findBrand.label : "";
      },
    },
    { headerName: "Model", field: "itemModel" },
    { headerName: "Published By", field: "userEmail" },
    { headerName: "item Banned", field: "itemBan" },
    // { headerName: "item Archive", field: "itemArchive" },
  ]);

  useEffect(() => {
    let token = localStorage.getItem("inktoken");
    if (token) {
      if (location.search.slice(-1)) {
        if (
          location.search.slice(location.search.indexOf("=") + 1) - 1 ===
          pageNumber
        ) {
          setSpinner(true);
          dispatch(
            getProducts({
              token,
              p: location.search.slice(location.search.indexOf("=") + 1) - 1,
              search: location?.state?.searchQuery || "",
              // status: tab === "ban" ? true : false,
              // sort: sorting,
              // report: tab === "report" ? true : false,
              // purchased: tab === "purchased" ? true : false,
            })
          );
          window.scrollTo(0, 0);
        } else {
          setPageNumber(
            location.search.slice(location.search.indexOf("=") + 1) - 1
          );
        }
      } else {
        // console.log("This");
        dispatch(
          getProducts({
            token,
            p: pageNumber - 1,
            search: location?.state?.searchQuery || "",
            // status:  tab === "ban" ? true : false,,
            // type,
          })
        );
        window.scrollTo(0, 0);
      }
    } else {
      navigate("/404", { replace: true });
    }
  }, [pageNumber, location?.search?.slice(-1), tab, type]);

  useEffect(() => {
    if (allProductsInfo) {
      setRowData([...allProductsInfo]);
      setSpinner(false);
    }
  }, [allProductsInfo]);

  if (updateUserStatus === "succeeded") {
    setSpinner(false);
    toast.success("Product Updated Successfully", toastOption);
    dispatch(resetUpdateStatus());
  }

  return (
    <>
      {spinner ? (
        <Spinner />
      ) : (
        <>
          <AnalyticsHeader
            placeholder="equipment"
            searchQuery={location?.state?.searchQuery || ""}
            label={"equipment"}
          />
          <div className="ag-theme-material" style={{ height: agGridHeight }}>
            <AgGridReact
              loading={spinner}
              loadingOverlayComponent={Spinner}
              // loadingOverlayComponentParams={loadingOverlayComponentParams}
              defaultColDef={defaultColDef}
              rowData={rowData}
              columnDefs={colDefs}
            />
          </div>
          {totalCount && totalCount > 20 && (
            <Stack
              spacing={2}
              direction={"row"}
              justifyContent={"center"}
              sx={{ margin: "1rem", paddingBottom: "1rem" }}
            >
              <Pagination
                count={Math.ceil(totalCount / 20) - 1}
                // variant="outlined"
                shape="rounded"
                // variant="text"
                page={pageNumber === 0 ? 1 : pageNumber}
                onChange={handlePageChange}
                color="secondary"
                sx={{
                  padding: ".5rem",
                  borderRadius: "5px",
                  border: "1px solid var(--black-color)",
                }}
                renderItem={(item) => (
                  <PaginationItem
                    slots={{ previous: ArrowBack, next: ArrowForward }}
                    {...item}
                  />
                )}
              />
            </Stack>
          )}
        </>
      )}
    </>
  );
};

export default Equipments;
