import {
  Badge,
  Box,
  Button,
  Chip,
  Divider,
  Stack,
  Typography,
  Paper,
  Pagination,
  PaginationItem,
  Modal,
  Fade,
  Backdrop,
  Grid,
  TextField,
  Input,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import "./MyPurchases.css";
import { ArrowBack, ArrowForward, Close, Save } from "@mui/icons-material";
import { DatePicker, LoadingButton } from "@mui/lab";
import { DateRangePicker } from "rsuite";
import { Link } from "react-router-dom";
import ProductCard from "../ProductCard/ProductCard";
import { useSelector } from "react-redux";
import TabsSorter from "../TabsSorter/TabsSorter";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import {
  getNotification,
  resetNotification,
} from "../StateSlices/notificationSlice";
import Spinner from "../Spinner/Spinner";
import { sortDataPurchaseOrder, toastOption } from "../../utils/utils";
import {
  cancelOrder,
  changeOrderStatus,
  extendOrder,
  resetCancel,
} from "../StateSlices/orderCancelSlice";
import { toast } from "react-toastify";
import swal from "sweetalert";

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 750,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: "10px",
};

const { beforeToday } = DateRangePicker;

const MyPurchases = () => {
  const location = useLocation();
  const dispatch = useDispatch();

  const [page, setPage] = useState(1);
  const [pageChanged, setPageChanged] = useState(false);
  const [data, setData] = useState([]);
  const [spinner, setSpinner] = useState(false);
  const [sortType, setSortType] = useState("newest");
  const [rentalExtReqModal, setRentalExtReqModal] = useState(false);
  const rentalExtMsgRef = useRef();
  const [purchaseStatus, setPurchaseStatus] = useState("pending");
  const [orderExtension, setOrderExtension] = useState("");

  const { status, userInfo } = useSelector((state) => state.user);
  const { notificationMessage, notificationInfo } = useSelector(
    (state) => state.notification
  );

  const { cancelStatus, cancelMessage } = useSelector((state) => state.cancel);

  const handleCancelDispatch = (orderId, productId) => {
    swal({
      title: "Cancel Order",
      text: "Once Cancelled, Order cannot be reactivated",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willReactive) => {
      // console.log(willReactive);
      if (willReactive) {
        let token = localStorage.getItem("inktoken");
        if (token) {
          dispatch(
            cancelOrder({ token, checkoutData: { orderId, productId } })
          );
          setSpinner(true);
        }
      }
    });
  };

  const handleRentalExtRequest = () => {
    if (!rentalExtMsgRef.current.value) {
      toast.error("Please write the Reason for Extension", toastOption);
      return false;
    }
    let token = localStorage.getItem("inktoken");
    dispatch(
      extendOrder({
        extendData: {
          orderId: orderExtension,
          message: rentalExtMsgRef.current.value,
        },
        token,
      })
    );
  };

  useEffect(() => {
    if (cancelMessage == "Order Status Change") {
      toast.success("Order Status Updated successfully!", toastOption);
      dispatch(resetCancel());
      dispatch(
        getNotification({
          token: localStorage.getItem("inktoken"),
          filter: "ordered",
        })
      );
    } else if (cancelMessage == "Order Extend") {
      toast.success("Order Extend Request Sent Successfully!", toastOption);
      dispatch(resetCancel());
      setRentalExtReqModal(false);
      rentalExtMsgRef.current.value = "";
      setOrderExtension("");
      setSpinner(false);
    } else if (cancelMessage) {
      dispatch(
        getNotification({
          token: localStorage.getItem("inktoken"),
          filter: "ordered",
        })
      );
      toast.success("Order cancelled successfully!", toastOption);
      dispatch(resetCancel());
      // setSpinner(false);
    }
  }, [cancelMessage]);

  const handlePageChange = (e, value) => {
    if (value !== page) {
      setPageChanged(true);
      setPage(value);
    }
  };

  const isLessThan72Hours = (endDateStr) => {
    const rentalEndDate = new Date(endDateStr);
    const now = new Date();

    const timeDifference = rentalEndDate - now;
    const hoursDifference = timeDifference / (1000 * 60 * 60);

    console.log(hoursDifference);
    return hoursDifference > 72;
  };

  useEffect(() => {
    if (userInfo) {
      let token = localStorage.getItem("inktoken");
      dispatch(getNotification({ token, filter: "ordered" }));
      setSpinner(true);
    }
  }, [userInfo]);

  useEffect(() => {
    if (notificationInfo) {
      console.log(notificationInfo);
      setData(notificationInfo);
      dispatch(resetNotification());
      setSpinner(false);
    }
  }, [notificationInfo]);

  useEffect(() => {
    setSpinner(true);
    const sortedData = sortDataPurchaseOrder(data, sortType, "both");
    if (sortedData) {
      setData(sortedData);
      setTimeout(() => {
        setSpinner(false);
      }, 2000);
    }
  }, [sortType]);

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    // Clean up the event listener
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleOrderStatus = (status, id, productId) => {
    let token = localStorage.getItem("inktoken");
    dispatch(
      changeOrderStatus({
        token,
        checkoutData: { id, productId, status, user: "seller" },
      })
    );
    setSpinner(true);
  };

  return (
    <>
      <Box component={"div"} marginTop={"2rem"}>
        <Box component={"div"} className="tabs-header">
          <Typography variant="h2" className="title mont-title">
            My Purchases
          </Typography>
          <Stack direction={"row"}>
            <TabsSorter setSortType={setSortType} />
            <Button
              size="small"
              href="mailto:support@godizel.com"
              variant="contained"
              color="secondary"
            >
              Contact Us
            </Button>
          </Stack>
          {/* <TabsSorter setSortType={setSortType} /> */}
        </Box>
        {spinner ? (
          <Spinner height={"60vh"} />
        ) : data.length == 0 ? (
          <Stack
            direction={"column"}
            justifyContent={"center"}
            alignItems={"center"}
            minHeight={"60vh"}
            width={"100%"}
          >
            <Typography color={"var(--grey-color)"} variant="h3">
              No purchases found!
            </Typography>
          </Stack>
        ) : (
          <>
            <Box
              component={"div"}
              className="purchases-item-con"
              margin={"1rem 0"}
            >
              {data.length > 0 &&
                data.map((dt, ind) => (
                  <Box
                    component={"div"}
                    marginTop={".5rem"}
                    marginBottom={"2rem"}
                  >
                    <Stack
                      direction={"row"}
                      columnGap={4}
                      justifyContent={
                        windowWidth < 820 ? "center" : "flex-start"
                      }
                      alignItems={"center"}
                      className="order-purchase-wrap-con"
                    >
                      <Box maxWidth={"400px"}>
                        <Stack
                          direction={"row"}
                          flex={"50%"}
                          justifyContent={"space-evenly"}
                          alignItems={"center"}
                          sx={{ marginBottom: "1rem" }}
                        >
                          <Typography variant="h5" marginRight={"1rem"}>
                            <strong>Status: </strong>
                          </Typography>
                          <FormControl variant="standard" fullWidth>
                            {/* <InputLabel id="demo-simple-select-label"></InputLabel> */}
                            <Select
                              color="secondary"
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              // value={purchaseStatus}
                              defaultValue={
                                dt.order.products.find(
                                  (pd) => pd.productId === dt.productId
                                ).sellerStatus
                                  ? dt.order.products.find(
                                      (pd) => pd.productId === dt.productId
                                    ).sellerStatus
                                  : "pending"
                              }
                              label=""
                              onChange={
                                (e) => {
                                  handleOrderStatus(
                                    e.target.value,
                                    dt.order._id,
                                    dt.productId
                                  );
                                }
                                // setPurchaseStatus(e.target.value)
                              }
                            >
                              <MenuItem color="secondary" value={"pending"}>
                                Pending
                              </MenuItem>
                              <MenuItem color="secondary" value={"complete"}>
                                Complete
                              </MenuItem>
                              <MenuItem color="secondary" value={"incomplete"}>
                                Incomplete
                              </MenuItem>
                            </Select>
                          </FormControl>
                        </Stack>
                        <ProductCard
                          hideLikeIcon={true}
                          shopPageCardWidth={
                            windowWidth < 1080 && windowWidth > 820
                              ? null
                              : windowWidth < 430
                              ? null
                              : "400px"
                          }
                          product={dt.product}
                          userInfo={userInfo}
                          purchase={dt}
                          buttons={["View Details", "Cancel Order"]}
                          handleCancelDispatch={handleCancelDispatch}
                        />
                      </Box>
                      <Box
                        sx={{
                          display: windowWidth < 850 && "none",
                          // display: { xs: "none", md: "block" },
                          borderRight: "2px solid var(--black-color)",
                          height: "433px",
                          width: "5px",
                        }}
                      >
                        &nbsp;
                      </Box>
                      <Box
                        width={"400px"}
                        display={"flex"}
                        flexDirection={"column"}
                        justifyContent={"flex-start"}
                        alignItems={"flex-start"}
                      >
                        <Typography variant="h5" my={1}>
                          <strong>Purchase Id:</strong> {dt.orderId}
                        </Typography>
                        <Typography variant="h5" my={1}>
                          <strong>Purchase Date:</strong>{" "}
                          {new Date(dt.createdAt).toDateString()}
                        </Typography>
                        <br />
                        <Typography variant="h5" my={1}>
                          <strong>Amount Paid:</strong> $ {dt.pricePaid.toLocaleString("en-US") }
                        </Typography>
                        <Typography variant="h5" my={1}>
                          <strong>Rent Period:</strong>
                          <span style={{ textTransform: "capitalize" }}>
                            &nbsp;
                            {dt.order.products.find(
                              (pd) => pd.productId === dt.productId
                            ).rentalDateRange?.length > 0
                              ? new Date(
                                  dt.order.products.find(
                                    (pd) => pd.productId === dt.productId
                                  ).rentalDateRange[0]
                                ).toDateString() +
                                " - " +
                                new Date(
                                  dt.order.products.find(
                                    (pd) => pd.productId === dt.productId
                                  ).rentalDateRange[1]
                                ).toDateString()
                              : " - "}
                          </span>
                        </Typography>
                        <br />
                        <Typography variant="h5" my={1}>
                          <strong>Purchase type:</strong>
                          <span style={{ textTransform: "capitalize" }}>
                            &nbsp;
                            {dt.order.products.find(
                              (pd) => pd.productId === dt.productId
                            ).type
                              ? dt.order.products.find(
                                  (pd) => pd.productId === dt.productId
                                ).type
                              : "-"}
                          </span>
                        </Typography>
                        <br />

                        {dt.order.products.find(
                          (pd) => pd.productId === dt.productId
                        ).type &&
                        dt.order.products.find(
                          (pd) => pd.productId === dt.productId
                        ).type === "rent" &&
                        isLessThan72Hours(
                          dt.order.products.find(
                            (pd) => pd.productId === dt.productId
                          ).rentalDateRange[1]
                        ) ? (
                          <Button
                            onClick={() => {
                              setRentalExtReqModal(true);
                              setOrderExtension(dt.order._id);
                            }}
                            variant="contained"
                            sx={{
                              border: "1px solid var(--white-color)",
                              color: "var(--white-color)",
                              background: "var(--black-color)",
                              width: "100%",
                              "&:hover": {
                                border: "1px solid var(--grey-color)",
                                color: "var(--black-color)",
                                background: "var(--grey-color)",
                              },
                              marginTop: ".5rem",
                            }}
                          >
                            Request Rental Period Extension
                          </Button>
                        ) : (
                          <>
                            <br />
                            <br />
                          </>
                        )}

                        <a
                          href={`mailto:${dt.contact}`}
                          style={{ width: "100%" }}
                        >
                          <Button
                            variant="contained"
                            sx={{
                              border: "1px solid var(--white-color)",
                              color: "var(--white-color)",
                              background: "var(--black-color)",
                              width: "100%",
                              "&:hover": {
                                border: "1px solid var(--grey-color)",
                                color: "var(--black-color)",
                                background: "var(--grey-color)",
                              },
                              marginTop: ".5rem",
                            }}
                          >
                            Contact Asset Owner
                          </Button>
                        </a>
                      </Box>
                    </Stack>
                  </Box>
                ))}
            </Box>
            <Box
              margin={"auto"}
              sx={{ display: "flex", justifyContent: "center" }}
            >
              {/* <Pagination
              count={Math.ceil(products.length / 10)}
              // variant="outlined"
              shape="rounded"
              // variant="text"
              page={page}
              onChange={handlePageChange}
              color="primary"
              sx={{
                padding: "1rem",
                borderRadius: "5px",
                border: "1px solid var(--primary-color)",
                marginTop: "1rem",
              }}
              renderItem={(item) => (
                <PaginationItem
                  slots={{ previous: ArrowBack, next: ArrowForward }}
                  {...item}
                />
              )}
            /> */}
            </Box>
          </>
        )}
      </Box>
      <Modal
        aria-labelledby="rental-extension-request-modal-title"
        aria-describedby="rental-extension-request-modal-description"
        open={rentalExtReqModal}
        onClose={() => {
          setRentalExtReqModal(false);
          rentalExtMsgRef.current.value = "";
          setOrderExtension("");
        }}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 200,
          },
        }}
      >
        <Fade in={rentalExtReqModal}>
          <Box sx={modalStyle} position={"relative"}>
            <Typography
              id="rental-extension-request-modal-title"
              variant="h3"
              className="mont-title"
              marginLeft={0}
            >
              Rental Period Extension Request
            </Typography>
            <Box
              sx={{
                marginTop: "1rem",
                width: 50,
                height: 5,
                background: "var(--secondary-color)",
              }}
            >
              &nbsp;
            </Box>

            <Close
              onClick={() => {
                setRentalExtReqModal(false);
                rentalExtMsgRef.current.value = "";
                setOrderExtension("");
              }}
              sx={{
                position: "absolute",
                right: "2rem",
                top: "2rem",
                cursor: "pointer",
              }}
            />
            <Grid container>
              <Input
                inputRef={rentalExtMsgRef}
                multiline
                rows={5}
                fullWidth
                sx={{ marginTop: "1rem" }}
                type="text"
                color="secondary"
                placeholder=" Enter your message for rental period extension"
                // helperText="E.g. 2 / 5 / 10"
              />

              {cancelStatus === "loading" ? (
                <LoadingButton
                  fullWidth
                  sx={{ marginTop: "1rem" }}
                  loading
                  loadingPosition="end"
                  endIcon={<Save />}
                  variant="outlined"
                  disabled={true}
                >
                  <span>Please Wait</span>
                </LoadingButton>
              ) : (
                <Button
                  fullWidth
                  onClick={handleRentalExtRequest}
                  sx={{ marginTop: "1rem" }}
                  color="secondary"
                  variant="contained"
                >
                  Request
                </Button>
              )}
            </Grid>
          </Box>
        </Fade>
      </Modal>
    </>
  );
};

export default MyPurchases;
