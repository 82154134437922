import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import axios from "axios"

const initialState = {
  hangerHistoryStatus: "idle",
  hangerHistoryDT: [],
  error: null,
  hangerHistoryStatusMessage:null
}

export const getHangerHistory = createAsyncThunk(
  "user/hangerHistory",
  async ({token}, { rejectWithValue }) => {
    try {
      const { data } = await axios.get(
        `/api/users/hangerhistory`,
        {
            headers: {
                Authorization: `Bearer ${token}`,
            },
          }
      )
      return data
    } catch (err) {
      return rejectWithValue(err.response.data)
    }
  }
)

export const seenHangerHistory = createAsyncThunk(
  "user/seenHangerHistory",
  async ({token}, { rejectWithValue }) => {
    try {
      const { data } = await axios.get(
        `/api/users/seenhangerhistory`,
        {
            headers: {
                Authorization: `Bearer ${token}`,
            },
          }
      )
      return data
    } catch (err) {
      return rejectWithValue(err.response.data)
    }
  }
)

export const hangerHistorySlice = createSlice({
  name: "hangerHistory",
  initialState,
  reducers: {
    resethangerHistory(state,action){
        // state.hangerHistoryDT = []
        state.hangerHistoryStatus="idle"
        state.hangerHistoryStatusMessage=null
    }
  },
  extraReducers: {
    [getHangerHistory.pending]: (state, action) => {
      state.hangerHistoryStatus = "loading"
    },
    [getHangerHistory.fulfilled]: (state, action) => {
      state.hangerHistoryStatus = "succeeded"
      state.hangerHistoryDT = action.payload.hangerHistory
    },
    [getHangerHistory.rejected]: (state, action) => {
      state.hangerHistoryStatus = "failed"
      state.error = action.payload.message
    },
    [seenHangerHistory.pending]: (state, action) => {
      state.hangerHistoryStatus = "loading"
    },
    [seenHangerHistory.fulfilled]: (state, action) => {
      state.hangerHistoryStatus = "succeeded"
      state.hangerHistoryStatusMessage = action.payload.message
    },
    [seenHangerHistory.rejected]: (state, action) => {
      state.hangerHistoryStatus = "failed"
      state.error = action.payload.message
    },
  },
})

export const { resethangerHistory } = hangerHistorySlice.actions
export default hangerHistorySlice.reducer 