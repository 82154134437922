import * as React from "react";
import { FaFacebook, FaLinkedin } from "react-icons/fa6";
import { Box } from "@mui/material";

import "./Footer.css";

import { Link, useNavigate } from "react-router-dom";
import footerLogo from "../../assets/godizel-brand.png";
import { FaXTwitter } from "react-icons/fa6";
import { useDispatch, useSelector } from "react-redux";
import { TbMailFilled } from "react-icons/tb";
import { reset } from "../StateSlices/getuserSlice";

export default function Footer() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { userInfo, status } = useSelector((state) => state.user);
  const [isLoggedIn, setIsLoggedIn] = React.useState(false);

  React.useEffect(() => {
    console.log(userInfo);
    if (userInfo && userInfo.userDetailsId) {
      setIsLoggedIn(true);
    } else {
      setIsLoggedIn(false);
    }
  }, [userInfo]);

  const logout = () => {
    window.scrollTo(0, 0);
    localStorage.removeItem("cartItems");
    localStorage.removeItem("inktoken");
    dispatch(reset());
    // navigate("/");
    window.location.href = "/";
  };

  return (
    <>
      <footer className="footer-con footer-con-pos">
        <div className="footer-wrapper">
          <div className="footer-other-row-1">
            <div className="col">
              <h6>Dizel</h6>
              <ul>
                <li>
                  <Link to={"/faqs"}>How To Guide</Link>
                </li>
                <li>
                  <Link
                    to={"/about-dizel"}
                    onClick={() => {
                      window.scrollTo(0, 0);
                    }}
                  >
                    About Dizel
                  </Link>
                </li>
                <li>
                  <Link to={"/categories"}>Categories</Link>
                </li>
              </ul>
            </div>
            <div className="vertical-divider">&nbsp;</div>
            <div className="col">
              <h6>Rent / Buy</h6>
              <ul>
                <li>
                  <Link
                    to={"/shop"}
                    state={{ tab: "rent" }}
                    onClick={() => {
                      window.scrollTo(0, 0);
                    }}
                  >
                    Rent Equipment
                  </Link>
                </li>
                <li>
                  <Link
                    to={"/shop"}
                    state={{ tab: "buy" }}
                    onClick={() => {
                      window.scrollTo(0, 0);
                    }}
                  >
                    Buy Equipment
                  </Link>
                </li>
                <li>
                  <Link
                    // state={{ tab: "purchases" }}
                    to={"/user/transactions"}
                    onClick={() => {
                      window.scrollTo(0, 0);
                    }}
                  >
                    Transactions
                  </Link>
                </li>
              </ul>
            </div>
            <div className="vertical-divider">&nbsp;</div>
            <div className="col">
              <h6>Account</h6>
              <ul>
                <li>
                  <Link
                    to={"/user/equipment"}
                    // state={{ tab: "my equipment" }}
                    onClick={() => {
                      window.scrollTo(0, 0);
                    }}
                  >
                    My Equipment
                  </Link>
                </li>
                <li>
                  <Link
                    to={"/user/profile"}
                    onClick={() => {
                      window.scrollTo(0, 0);
                    }}
                  >
                    My Profile
                  </Link>
                </li>
                <li>
                  {isLoggedIn ? (
                    <Link to={"#"} onClick={() => logout()}>
                      Log Out
                    </Link>
                  ) : (
                    <>
                      <Link to={"/signup"}>Sign Up </Link>|{" "}
                      <Link to={"/login"}>Log In</Link>
                    </>
                  )}
                </li>
              </ul>
            </div>
          </div>
          <Box flex={"0 0 1"}>
            <div className="horizontal-divider"></div>
          </Box>
          <div className="footer-other-row-2">
            <div className="ftr-link-div">
              <a
                href="mailto:support@godizel.com"
                target="_blank"
                rel="noopener"
                style={{ color: "#999" }}
              >
                <TbMailFilled size={"1.2em"} style={{ margin: "0 1rem" }} />
              </a>
            </div>
            <div className="ftr-link-div">
              <a
                href="https://www.facebook.com/people/Dizel/61553175265762/"
                target="_blank"
                rel="noopener"
                style={{ color: "#999" }}
              >
                <FaFacebook size={"1.2em"} style={{ margin: "0 1rem" }} />
              </a>
            </div>
            <img
              src={footerLogo}
              width={"125px"}
              style={{ margin: "0 .5rem" }}
              className="footerLogo"
              alt="footer logo"
            />
            <div className="ftr-link-div">
              <a
                href="https://twitter.com/GoDizel"
                style={{ color: "#999" }}
                target="_blank"
                rel="noopener"
              >
                <FaXTwitter size={"1.2em"} style={{ margin: "0 1rem" }} />
              </a>
            </div>
            <div className="ftr-link-div">
              <a
                href="https://www.linkedin.com/company/dizel/"
                target="_blank"
                rel="noopener"
                style={{ color: "#999" }}
              >
                <FaLinkedin size={"1.2em"} style={{ margin: "0 1rem" }} />
              </a>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}
