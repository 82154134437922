import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { TableVirtuoso } from "react-virtuoso";
import { Button, Typography } from "@mui/material";
import { Link } from "react-router-dom";

const sample = [
  [
    "01-01-2024",
    "Purchase | Rental",
    "JD23123K",
    "John Doe",
    50000.0,
    "Contact",
    "View",
  ],
  [
    "01-01-2024",
    "Order | Rental",
    "BJ42123K",
    "Bob Johnson",
    78000.0,
    "Contact",
    "View",
  ],
  [
    "01-01-2024",
    "Purchase | Buy",
    "CC12345K",
    "Camila Cabello",
    340000.0,
    "Contact",
    "View",
  ],
  [
    "01-01-2024",
    "Order | Buy",
    "BM32456K",
    "Bruno Mars",
    123000.0,
    "Contact",
    "View",
  ],

  [
    "01-01-2024",
    "Order | Buy",
    "JB98098K",
    "James Bond",
    5120000.0,
    "Contact",
    "View",
  ],
];

function createData(
  date,
  transactionType,
  transactionId,
  name,
  amountPaid,
  contactBS,
  action
) {
  return {
    date,
    transactionType,
    transactionId,
    name,
    amountPaid,
    contactBS,
    action,
  };
}

const columns = [
  {
    width: 120,
    label: "Date",
    dataKey: "createdAt",
  },
  {
    width: 200,
    label: "Transaction Type",
    dataKey: "eventName",
  },
  {
    width: 150,
    label: "Transaction ID",
    dataKey: "orderId",
    // numeric: true,
  },
  {
    width: 200,
    label: "Name",
    dataKey: "productName",
  },
  {
    width: 150,
    label: "Amount Paid",
    dataKey: "pricePaid",
    numeric: true,
  },
  {
    width: 120,
    label: "Contact (Buyer/Seller)",
    dataKey: "contact",
  },
  {
    width: 120,
    label: "Action",
    dataKey: "action",
  },
];

const rows = Array.from({ length: 20 }, (_, index) => {
  const randomSelection = sample[Math.floor(Math.random() * sample.length)];
  return createData(...randomSelection);
});

const VirtuosoTableComponents = {
  Scroller: React.forwardRef((props, ref) => (
    <TableContainer
      component={"div"}
      {...props}
      ref={ref}
      sx={{ boxShadow: "none" }}
      className="no-box-shadow"
    />
  )),
  Table: (props) => (
    <Table
      {...props}
      sx={{
        borderCollapse: "separate",
        tableLayout: "fixed",
        boxShadow: "none",
      }}
    />
  ),
  TableHead,
  TableRow: ({ item: _item, ...props }) => <TableRow {...props} />,
  TableBody: React.forwardRef((props, ref) => (
    <TableBody {...props} ref={ref} sx={{ boxShadow: "none" }} />
  )),
};

function fixedHeaderContent() {
  return (
    <TableRow>
      {columns.map((column, ind) => (
        <TableCell
          key={column.dataKey}
          variant="head"
          align={column.numeric || false ? "center" : "center"}
          style={{ width: column.width }}
          sx={{
            backgroundColor: "var(--black-color)",
          }}
        >
          <Typography sx={{ fontWeight: 700, color: "var(--white-color)" }}>
            {column.label}
          </Typography>
        </TableCell>
      ))}
    </TableRow>
  );
}

function rowContent(_index, row) {
  console.log(row);
  return (
    <React.Fragment>
      {columns.map((column, ind) => (
        <>
          <TableCell
            key={column.dataKey}
            align={column.numeric ? "right" : "center"}
          >
            {ind === 5 ? (
              <a href={`mailto:${row[column.dataKey]}`} target="_blank">
                <Button
                  size="small"
                  variant="outlined"
                  sx={{
                    border: "1px solid var(--black-color)",
                    color: "var(--black-color)",
                    "&:hover": {
                      background: "var(--grey-color)",
                      color: "var(--white-color)",
                      border: "1px solid var(--grey-color)",
                    },
                  }}
                >
                  Contact
                </Button>
              </a>
            ) : ind === 6 ? (
              <Link to={`/order-details/${row.orderId}/${row.productId}`}>
                <Button
                  size="small"
                  variant="contained"
                  sx={{
                    border: "1px solid var(--white-color)",
                    color: "var(--white-color)",
                    background: "var(--black-color)",
                    "&:hover": {
                      background: "var(--grey-color)",
                      color: "var(--black-color)",
                      border: "1px solid var(--grey-color)",
                    },
                  }}
                >
                  View
                </Button>
              </Link>
            ) : ind === 0 ? (
              <>{new Date(row[column.dataKey]).toDateString()}</>
            ) : (
              <>{row[column.dataKey]}</>
            )}
          </TableCell>
        </>
      ))}
    </React.Fragment>
  );
}

export default function TransactionsTable({ data }) {
  return (
    <Paper elevation={0} style={{ width: "100%" }}>
      <TableVirtuoso
        data={data}
        components={VirtuosoTableComponents}
        fixedHeaderContent={fixedHeaderContent}
        itemContent={rowContent}
        style={{ height: 675 }}
      />
    </Paper>
  );
}
