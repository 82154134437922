import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Masonry from "@mui/lab/Masonry";
import { styled } from "@mui/material/styles";
import "./BrandsMasonary.css";
import { Stack, Typography } from "@mui/material";
// import p1 from "../../assets/masonry/1.jpg";
// import p2 from "../../assets/masonry/2.jpg";
// import p3 from "../../assets/masonry/3.jpg";
// import p4 from "../../assets/masonry/4.jpg";
// import p5 from "../../assets/masonry/5.jpg";
// import p6 from "../../assets/masonry/6.jpg";
// import p7 from "../../assets/masonry/7.jpg";
// import p8 from "../../assets/masonry/8.jpg";
// import p9 from "../../assets/masonry/9.jpg";
// import p10 from "../../assets/masonry/10.jpg";
// import p11 from "../../assets/masonry/11.jpg";
// import p12 from "../../assets/masonry/12.jpg";

import p1 from "../../assets/category-cards/1.png";
import p2 from "../../assets/category-cards/2.png";
import p3 from "../../assets/category-cards/3.png";
import p4 from "../../assets/category-cards/4.png";
import p5 from "../../assets/category-cards/5.png";
import p6 from "../../assets/category-cards/6.png";
import p7 from "../../assets/category-cards/7.png";
import p8 from "../../assets/category-cards/8.png";
import p9 from "../../assets/category-cards/9.png";
import { Link } from "react-router-dom";
import {
  categoryImagesOld,
  categoryNameImageMapping,
} from "../../pages/AddNewItem/data";

const BrandsMasonary = () => {
  const tempTypes = [
    "Skidsteer Auger Bit",
    "Skidsteer Boring Attachment",
    "Skidsteer Bucket",
    "Skidsteer Grapple Bucket",
    "Skidsteer Power Rake",
    "Skidsteer Leveler Attachment",
    "Skidsteer Multipurpose Tool",
    "Skidsteer Pallet Forks",
    "Skidsteer Soil Cultivator",
    "Skidsteer Trencher",
    "Skidsteer Vibratory Plow",
    "Skidsteer Rock Tip Auger",
    "Skidsteer Snow Pusher",
    "Skidsteer Tooth Bar",
    "Skidsteer Asphalt Planer",
    "Skidsteer Box Broom",
    "Skidsteer Brush Hog",
    "Skidsteer Dozer Blade",
    "Skidsteer Grader Blade",
    "Skidsteer Grapple Bucket",
    "Skidsteer Rake",
    "Skidsteer Snow Blade Attachment",
    "Skidsteer Sweeper Broom",
    "Skidsteer Tree Fork Attachment",
    "Skidsteer Hydraulic Hammer",
  ];
  const Label = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(0.5),
    textAlign: "center",
    color: theme.palette.text.secondary,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
  }));

  // Helper function to calculate the number of columns based on window width
  const getColumns = (width) => {
    // Adjust this logic based on your layout requirements
    if (width >= 1200) {
      return 3;
    } else if (width >= 900) {
      return 3;
    } else if (width >= 600) {
      return 2;
    } else {
      return 1;
    }
  };

  // Helper function to calculate the spacing based on window width
  const getSpacing = (width) => {
    // Adjust this logic based on your layout requirements
    if (width >= 1200) {
      return 6;
    } else {
      return 4;
    }
  };

  const [columns, setColumns] = useState(getColumns(window.innerWidth));
  const [spacing, setSpacing] = useState(getSpacing(window.innerWidth));

  useEffect(() => {
    // Update columns and spacing when the window is resized
    const handleResize = () => {
      setColumns(getColumns(window.innerWidth));
      setSpacing(getSpacing(window.innerWidth));
    };

    window.addEventListener("resize", handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      <section
        style={{
          backgroundSize: "cover",
          margin: "2rem auto",
          marginBottom: "2rem",
        }}
      >
        <Typography
          variant="h1"
          textAlign={"center"}
          marginTop={"4rem"}
          paddingTop={"2rem"}
          marginBottom={".5rem"}
          className="mont-title"
        >
          Explore Categories
        </Typography>
        <Typography
          variant="h4"
          color={"text.secondary"}
          sx={{ margin: ".5rem auto" }}
          textAlign={"center"}
        >
          Power up your projects with affordable rentals in your area.
        </Typography>

        <Stack
          marginTop={"2rem"}
          flexWrap={"wrap"}
          direction={"row"}
          sx={{ justifyContent: { xs: "center", lg: "space-between" } }}
        >
          {Object.keys(categoryNameImageMapping).map((cat, ind) => (
            <Link to={"/shop?goal=" + cat}>
              <Box
                key={ind}
                component={"div"}
                className="category-image-card category-pill "
              >
                <img src={categoryNameImageMapping[cat]} alt={cat} />
              </Box>
            </Link>
          ))}
        </Stack>
        <Stack
          margin={"2rem auto"}
          flexWrap={"wrap"}
          direction={"row"}
          sx={{ justifyContent: { xs: "center", lg: "space-between" } }}
        >
          {Object.keys(categoryImagesOld).map((item, index) => (
            <Link to={`/shop?subcategory=${tempTypes[index]}`}>
              {/* <Link to={"/shop?goal=" + item} key={index}> */}
              <Box
                component={"div"}
                maxWidth={"350px"}
                className="category-image-card"
                marginTop={0}
                width={350}
                height={350}
                margin={1}
                my={4}
                maxHeight={"350px"}
                flex={"40%"}
              >
                <img
                  src={categoryImagesOld[item]}
                  alt="type"
                  width={"100%"}
                  height={"100%"}
                  style={{ borderRadius: "20px" }}
                />
              </Box>
            </Link>
          ))}
        </Stack>
      </section>
    </>
  );
};

export default BrandsMasonary;
