import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/splide/dist/css/splide.min.css";
import { Grid } from "@splidejs/splide-extension-grid";

import React, { useState } from "react";
import { Box, Button, Chip, Typography, Stack } from "@mui/material";
import { brands } from "../../pages/AddNewItem/data";
import { FaHeart, FaRegHeart } from "react-icons/fa6";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import ProductCard from "../ProductCard/ProductCard";

const CarouselSale = ({ title, data, cartVal, setCartVal }) => {
  const [likes, setLikes] = useState(null);
  const toastOption = {
    position: "top-right",
    autoClose: 1500,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "dark",
  };
  const handleLike = (e, id) => {
    e.preventDefault();
    if (likes && likes.length > 0) {
      setLikes((prev) => [...prev, id]);
    } else {
      setLikes([id]);
    }
    toast.success("Equipment added to favorites", toastOption);
  };

  const handleDislike = (e, id) => {
    e.preventDefault();
    setLikes((prev) => prev.filter((item) => item !== id));
    toast.success("Equipment removed from favorites", toastOption);
  };
  return (
    <>
      <Typography
        variant="h1"
        fontWeight={700}
        textAlign={"center"}
        margin={"2rem auto"}
        className="title mont-title"
      >
        {title}
      </Typography>
      <Stack
        margin={"2rem auto"}
        flexWrap={"wrap"}
        direction={"row"}
        justifyContent={"space-between"}
      >
        {data.map((product, index) => (
          <div style={{ margin: "1rem auto" }}>
            <ProductCard
              shopPageCardWidth={"360px"}
              product={product}
              buttons={
                product.merchantFeed ? ["view details"] : ["view details"]
              }
              cartVal={cartVal}
              setCartVal={setCartVal}
            />
          </div>
        ))}
      </Stack>
    </>
  );
};

export default CarouselSale;
