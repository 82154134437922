import React, { useEffect, useMemo } from "react";
import TransactionsTable from "./TransactionsTable";
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { useState } from "react";
import {
  getNotification,
  resetNotification,
} from "../StateSlices/notificationSlice";
import Spinner from "../Spinner/Spinner";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";

const Transactions = () => {
  const location = useLocation();
  const dispatch = useDispatch();

  const { status, userInfo, profile } = useSelector((state) => state.user);
  const { notificationMessage, notificationInfo } = useSelector(
    (state) => state.notification
  );

  const [alignment, setAlignment] = React.useState("all");
  const [data, setData] = useState([]);
  const [spinner, setSpinner] = useState(false);

  const handleChange = (event, newAlignment) => {
    setAlignment(newAlignment);
  };

  const [paymentMode, setPaymentMode] = React.useState("");

  const handlePaymentModeChange = (event) => {
    setPaymentMode(event.target.value);
  };

  useEffect(() => {
    if (userInfo) {
      let token = localStorage.getItem("inktoken");
      dispatch(getNotification({ token }));
      setSpinner(true);
    }
  }, []);

  useEffect(() => {
    if (notificationInfo) {
      setData(notificationInfo);
      dispatch(resetNotification());
      setSpinner(false);
    }
  }, [notificationInfo]);

  const getDate = (params) => {
    const date = new Date(params.value);
    return (
      (date.getMonth() + 1).toString().padStart(2, "0") +
      "-" +
      date.getDate().toString().padStart(2, "0") +
      "-" +
      date.getFullYear()
    );
  };

  const defaultColDef = useMemo(() => ({
    filter: true,
    cellStyle: { fontFamily: "Helvetica, Arial, sans-serif" },
  }));

  const [colDefs, setColDefs] = useState([
    {
      headerName: "Date",
      field: "createdAt",
      valueFormatter: (params) => getDate(params),
    },
    { headerName: "Transaction Type", field: "eventName" },
    { headerName: "Transaction ID", field: "orderId" },
    { headerName: "Name", field: "productName" },
    {
      headerName: "Amount Paid",
      field: "pricePaid",
      valueFormatter: (params) => "$ " + params.value.toLocaleString("en-US") ,
    },
    { headerName: "Contact (Buyer/Asset Owner)", field: "contact" },
    {
      headerName: "Action",
      field: "",
      cellRenderer: (props) => (
        <a href={"mailto:" + props.data.contact} target="_blank">
          <Button
            size="small"
            variant="outlined"
            sx={{
              padding: "3px 6px",
              border: "1px solid var(--black-color)",
              color: "var(--black-color)",
              "&:hover": {
                background: "var(--grey-color)",
                color: "var(--white-color)",
                border: "1px solid var(--grey-color)",
              },
            }}
          >
            Contact
          </Button>
        </a>
      ),
    },
  ]);

  return (
    <Box component={"div"} marginTop={"2rem"}>
      <Typography
        variant="h2"
        mt={"4rem"}
        mb={"1rem"}
        className="title mont-title"
      >
        My Transactions
      </Typography>
      {/* <Box marginTop={"2rem"}> */}
      {spinner ? (
        <Spinner />
      ) : (
        <div className="ag-theme-quartz" style={{ height: "680px" }}>
          <AgGridReact
            defaultColDef={defaultColDef}
            rowData={data}
            columnDefs={colDefs}
          />
        </div>
      )}
      {/* </Box> */}
    </Box>
  );
};

export default Transactions;
