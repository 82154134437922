import { useEffect } from "react";
import { useLocation } from "react-router-dom";
const DynamicTitleUpdater = () => {
  const location = useLocation();

  useEffect(() => {
    const title = extractTitleFromPath(location.pathname);
    // Update the title in the <title> tag
    document.title = "GoDizel | " + title;
  }, [location]);

  const extractTitleFromPath = (pathname) => {
    const pathParts = pathname.split("/").filter((part) => part.trim() !== "");
    if (pathParts.length > 0) {
      return decodeURIComponent(
        pathParts[pathParts.length - 1]
          .replace(/-/g, " ")
          .replace(
            /\w\S*/g,
            (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
          )
      );
    }
    return "Home";
  };

  return null; // This component doesn't render anything
};

export default DynamicTitleUpdater;
