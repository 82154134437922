import React, { useState, useEffect } from "react";
import "./ComingSoon.css";
import { Telegram } from "@mui/icons-material";
import Spinner from "../Spinner/Spinner";
import { useDispatch, useSelector } from "react-redux";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import { toast } from "react-toastify";
import { resetWaitlist, waitlistSubscribe } from "../StateSlices/waitListSlice";
import goDizel from "../../assets/godizel-brand.png";

const ComingSoon = () => {
  const [email, setEmail] = useState("");
  const [spinner, setSpinner] = useState(false);

  const dispatch = useDispatch();

  const { waitStatus, waitInfo, waitError } = useSelector(
    (state) => state.waitlist
  );

  let toastOption = {
    position: "top-right",
    autoClose: 1500,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "dark",
  };

  const CountdownTimer = ({ endingDate }) => {
    const [timeRemaining, setTimeRemaining] = useState(
      calculateTimeRemaining()
    );
    useEffect(() => {
      const interval = setInterval(() => {
        setTimeRemaining(calculateTimeRemaining());
      }, 1000);

      return () => clearInterval(interval);
    }, []);

    function calculateTimeRemaining() {
      const current = new Date().getTime();
      const remainingTime = endingDate - current;

      const days = Math.floor(remainingTime / (1000 * 60 * 60 * 24));
      const hours = Math.floor(
        (remainingTime % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      const minutes = Math.floor(
        (remainingTime % (1000 * 60 * 60)) / (1000 * 60)
      );
      const seconds = Math.floor((remainingTime % (1000 * 60)) / 1000);

      return { days, hours, minutes, seconds };
    }

    return (
      <div id="countdown">
        {timeRemaining.days}
        <span>D</span> {timeRemaining.hours}
        <span>H</span> {timeRemaining.minutes}
        <span>M</span> {timeRemaining.seconds}
        <span>S</span>
      </div>
    );
  };

  useEffect(() => {
    if (waitInfo === "Succesfully waitlisted") {
      toast.success("Thanks for Joining our Waitlist!", toastOption);
      dispatch(resetWaitlist());
      setEmail("");
      setSpinner(false);
    }
  }, [waitInfo]);

  useEffect(() => {
    if (waitError) {
      toast.error(waitError, toastOption);
      dispatch(resetWaitlist());
      setSpinner(false);
    }
  }, [waitError]);

  const joinWaitlist = (e) => {
    e.preventDefault();
    dispatch(waitlistSubscribe(email));
    setSpinner(true);
  };

  return (
    <div className="main">
      <section className="hero is-transparent is-fullheight ">
        {/* {spinner?(<Spinner/>):""} */}
        <div className="hero-head">
          {/* <header className="navbar">
            <div className="container">
              <div className="navbar-brand">
                <a className="navbar-item">GoDizel</a>
              </div>
              <div className="navbar-menu">
                <div className="navbar-end">
                  <span className="navbar-item">
                    <a className="button is-primary is-outlined" href="#">
                      <span className="icon">
                        <i className="fa fa-twitter"></i>
                      </span>{" "}
                      &nbsp;&nbsp;Follow Us
                    </a>
                  </span>
                </div>
              </div>
            </div>
          </header> */}
          <br />
          <br />
          <br />
        </div>
        <div className="hero-body">
          <div className="container has-text-centered content">
            <h1 className="title mont-title">
              <span className="fade fadeOne">COMING &nbsp;</span>
              <span className="fade fadeTwo">SOON</span>
            </h1>
            <form
              className="subtitle fade fadeThree"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                position: "relative",
              }}
              onSubmit={(e) => {
                joinWaitlist(e);
              }}
            >
              <input
                type="email"
                maxlength="50"
                required
                placeholder="Join the Waitlist"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                disabled={spinner ? true : false}
                style={spinner ? { color: "#ccc" } : {}}
              />
              <button
                class="bt"
                type="submit"
                disabled={spinner ? true : false}
                style={spinner ? { color: "#bbb" } : {}}
              >
                {spinner ? (
                  <AutorenewIcon className="rotating" />
                ) : (
                  <Telegram />
                )}
              </button>
            </form>
            <h2 className="subtitle">
              {/* <p id="countdown" className="countdown fade fadeThree">
                <CountdownTimer
                  endingDate={new Date("Nov 12, 2023 13:37:00").getTime()}
                />
              </p> */}
              <img
                className="fade fadeThree"
                src={goDizel}
                style={{ marginTop: "6.5rem", maxWidth: "250px" }}
                height={"auto"}
              />
            </h2>
          </div>
        </div>
        <div className="hero-foot fade fadeThree">
          <div className="columns has-text-centered">
            <div className="column">
              <div className="container">
                <ul style={{ paddingInlineStart: 0 }}>
                  {/* <li>
                    <a href="#">Twitter</a>
                  </li> */}
                  <li>
                    <a
                      target="_blank"
                      href="https://www.facebook.com/profile.php?id=61553175265762&mibextid=LQQJ4d"
                    >
                      Facebook
                    </a>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      href="https://www.linkedin.com/company/dizel/"
                    >
                      LinkedIn
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default ComingSoon;
