import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import axios from "axios"

const initialState = {
  waitStatus: "idle",
  waitInfo: null,
  waitError: null,
}

export const waitlistSubscribe = createAsyncThunk(
  "add/waitlist",
  async (values, { rejectWithValue }) => {
    try {
      const { data } = await axios.post(
        "/api/users/waitlist",{
            email: values
        }
      )
      return data
    } catch (err) {
      return rejectWithValue(err.response.data)
    }
  }
)
export const waitListSlice = createSlice({
  name: "waitlist",
  initialState,
  reducers: {
    resetWaitlist(state,action){
      state.waitInfo = null
      state.waitStatus="idle"
      state.waitError= null
    }
  },
  extraReducers: {
    [waitlistSubscribe.pending]: (state, action) => {
      state.waitStatus = "loading"
    },
    [waitlistSubscribe.fulfilled]: (state, action) => {
      state.waitStatus = "succeeded"
      state.waitInfo = action.payload.message
    },
    [waitlistSubscribe.rejected]: (state, action) => {
      state.waitStatus = "failed";
      state.waitError = action.payload.message
    },
  },
})

export const { resetWaitlist } = waitListSlice.actions
export default waitListSlice.reducer 