import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import axios from "axios"

const initialState = {
  checkoutStatus: "idle",
  message: "",
  cherror: null,
  checkoutHistory:null
}

export const checkout = createAsyncThunk(
  "checkout/checkout",
  async ({checkoutData,token}, { rejectWithValue }) => {
    try {
      const { data } = await axios.post(
        `/api/users/checkout`, checkoutData,
        {
          headers: {
              Authorization: `Bearer ${token}`,
          },
        }
      )
      return data
    } catch (err) {
      return rejectWithValue(err.response.data)
    }
  }
)
export const CheckoutSlice = createSlice({
  name: "checkout",
  initialState,
  reducers: {
    resetCheckout(state,action){
        state.message = ""
        state.checkoutStatus="idle"
        state.cherror=null
      }
  },
  extraReducers: {
    [checkout.pending]: (state, action) => {
      state.checkoutStatus = "loading"
    },
    [checkout.fulfilled]: (state, action) => {
      state.checkoutStatus = "succeeded"
      state.message = action.payload.message
      state.checkoutHistory = action.payload.checkoutEntry
    },
    [checkout.rejected]: (state, action) => {
      state.checkoutStatus = "failed"
      state.cherror = action.payload.message
    },
  },
})

export const { resetCheckout } = CheckoutSlice.actions
export default CheckoutSlice.reducer 