import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/splide/dist/css/splide.min.css";
import { Grid } from "@splidejs/splide-extension-grid";
import React from "react";
import { Typography, Box, Stack } from "@mui/material";
import "./CarouselCategories.css";
import { Link } from "react-router-dom";
import {
  categoryImagesOld,
  categoryNameImageMapping,
} from "../../pages/AddNewItem/data";

const CarouselCategories = ({ title }) => {
  const tempTypes = [
    "Skidsteer Auger Bit",
    "Skidsteer Boring Attachment",
    "Skidsteer Bucket",
    "Skidsteer Grapple Bucket",
    "Skidsteer Power Rake",
    "Skidsteer Leveler Attachment",
    "Skidsteer Multipurpose Tool",
    "Skidsteer Pallet Forks",
    "Skidsteer Soil Cultivator",
    "Skidsteer Trencher",
    "Skidsteer Vibratory Plow",
    "Skidsteer Rock Tip Auger",
    "Skidsteer Snow Pusher",
    "Skidsteer Tooth Bar",
    "Skidsteer Asphalt Planer",
    "Skidsteer Box Broom",
    "Skidsteer Brush Hog",
    "Skidsteer Dozer Blade",
    "Skidsteer Grader Blade",
    "Skidsteer Grapple Bucket",
    "Skidsteer Rake",
    "Skidsteer Snow Blade Attachment",
    "Skidsteer Sweeper Broom",
    "Skidsteer Tree Fork Attachment",
    "Skidsteer Hydraulic Hammer",
  ];
  return (
    <>
      <Typography
        variant="h1"
        fontWeight={700}
        textAlign={"center"}
        margin={"2rem auto"}
        marginBottom={"3rem"}
        zIndex={0}
        className="title mont-title"
      >
        {title}
      </Typography>
      <Stack
        flexWrap={"wrap"}
        direction={"row"}
        sx={{ justifyContent: { xs: "center", lg: "space-between" }, margin: "0 .5rem" }}
      >
        {Object.keys(categoryNameImageMapping).map((cat, ind) => (
          <Link to={"/shop?goal=" + cat}>
            <Box
              key={ind}
              component={"div"}
              className="category-image-card category-pill "
              margin={"1rem 0"}
            >
              <img
                src={categoryNameImageMapping[cat]}
                alt={cat}
                // width={"auto"}
              />
            </Box>
          </Link>
        ))}
      </Stack>
      <Stack
        margin={"2rem auto"}
        flexWrap={"wrap"}
        direction={"row"}
        sx={{ justifyContent: { xs: "center", lg: "space-between" } }}
      >
        {Object.keys(categoryImagesOld).map((item, index) => (
          <Link to={`/shop?subcategory=${tempTypes[index]}`}>
            {/* <Link to={"/shop?goal=" + item} key={index}> */}
            <Box
              component={"div"}
              maxWidth={"360px"}
              className="category-image-card"
              marginTop={0}
              width={360}
              height={360}
              margin={1}
              // my={4}
              my={"1.5rem"}
              maxHeight={"360px"}
              flex={"40%"}
            >
              <img
                src={categoryImagesOld[item]}
                alt="type"
                width={"100%"}
                height={"100%"}
                style={{ borderRadius: "20px" }}
              />
            </Box>
          </Link>
        ))}
      </Stack>
    </>
  );
};

export default CarouselCategories;
