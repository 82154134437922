import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import axios from "axios"

const initialState = {
  brandStatus: "idle",
  brandInfo: null,
  categoryInfo:null,
  brandError: null,
  signature:null,
  timestamp:null,
  sizeInfo:null
}

export const addBrand = createAsyncThunk(
  "static/brand",
  async ({token,values,requestType}, { rejectWithValue }) => {
    try {
        if(requestType==="post"){
            const { data } = await axios.post(
                "/api/static/addbrand",values,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
              )
              return data
        }else{
            const { data } = await axios.get(
                "/api/static/getbrand",
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
              )
              return data
        }
      
    } catch (err) {
      return rejectWithValue(err.response.data)
    }
  }
)
export const addbrandSlice = createSlice({
  name: "brand",
  initialState,
  reducers: {
    resetBrand(state,action){
        state.brandError = null
        state.brandStatus="idle"
        state.brandInfo=null
        state.categoryInfo=null
        state.sizeInfo = null
        // state.signature=null
        // state.timestamp=null
      }
  },
  extraReducers: {
    [addBrand.pending]: (state, action) => {
      state.brandStatus = "loading"
    },
    [addBrand.fulfilled]: (state, action) => {
      state.brandStatus = "succeeded"
      state.brandInfo = action.payload.brands
      state.categoryInfo = action.payload.category
      state.signature = action.payload.signature
      state.timestamp = action.payload.timestamp
      state.sizeInfo = action.payload.sizeList
    },
    [addBrand.rejected]: (state, action) => {
      state.brandStatus = "failed";
      state.brandError = action.payload
    },
  },
})

export const { resetBrand } = addbrandSlice.actions
export default addbrandSlice.reducer 