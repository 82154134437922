import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import axios from "axios"

const initialState = {
  itemStatus: "idle",
  itemInfo: null,
  itemError: null,
  itemDelisted:null,
}

export const updateItem = createAsyncThunk(
  "update/updateItem",
  async ({token,values,id}, { rejectWithValue }) => {
    try {
      const { data } = await axios.post(
        `/api/product/edit/${id}`,values,
        {
        headers: {
                Authorization: `Bearer ${token}`,
            },
        }
      )
      return data
    } catch (err) {
      return rejectWithValue(err.response.data)
    }
  }
)

export const delistItem = createAsyncThunk(
  "update/delistItem",
  async ({token,id}, { rejectWithValue }) => {
    try {
      const { data } = await axios.delete(
        `/api/product/delist/${id}`,
        {
        headers: {
                Authorization: `Bearer ${token}`,
            },
        }
      )
      return data
    } catch (err) {
      return rejectWithValue(err.response.data)
    }
  }
)

export const enlistItem = createAsyncThunk(
  "update/enlistItem",
  async ({token,id}, { rejectWithValue }) => {
    try {
      const { data } = await axios.post(
        `/api/product/enlist/${id}`,
        {},
        {
        headers: {
                Authorization: `Bearer ${token}`,
            },
        }
      )
      return data
    } catch (err) {
      return rejectWithValue(err.response.data)
    }
  }
)

export const updateitemSlice = createSlice({
  name: "update",
  initialState,
  reducers: {
    resetItem(state,action){
        state.itemInfo = null
        state.itemStatus="idle"
        state.itemError = null
        state.itemDelisted = null
      }
  },
  extraReducers: {
    [updateItem.pending]: (state, action) => {
      state.itemStatus = "loading"
    },
    [updateItem.fulfilled]: (state, action) => {
      state.itemStatus = "succeeded"
      state.itemInfo = action.payload.product
    },
    [updateItem.rejected]: (state, action) => {
      state.itemStatus = "failed";
      state.itemError = action.payload
    },

    [delistItem.pending]: (state, action) => {
      state.itemStatus = "loading"
    },
    [delistItem.fulfilled]: (state, action) => {
      state.itemStatus = "succeeded"
      state.itemDelisted = action.payload.delist
    },
    [delistItem.rejected]: (state, action) => {
      state.itemStatus = "failed";
      state.itemError = action.payload
    },

    [enlistItem.pending]: (state, action) => {
      state.itemStatus = "loading"
    },
    [enlistItem.fulfilled]: (state, action) => {
      state.itemStatus = "succeeded"
      state.itemDelisted = action.payload.delist
    },
    [enlistItem.rejected]: (state, action) => {
      state.itemStatus = "failed";
      state.itemError = action.payload
    },
  },
})

export const { resetItem } = updateitemSlice.actions
export default updateitemSlice.reducer 