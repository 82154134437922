import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import Spinner from "../Spinner/Spinner";
import {
  Box,
  Chip,
  Container,
  Divider,
  Stack,
  Typography,
} from "@mui/material";
import TabsSorter from "../TabsSorter/TabsSorter";
import { useDispatch, useSelector } from "react-redux";
import { getallProducts, reset } from "../StateSlices/getproductsSlice";
import {
  brands,
  categoies,
  categoryImagesOld,
  categoryTitleOld,
} from "../../pages/AddNewItem/data";

const chipStyle = {
  padding: "1.25rem 1.5rem",
  color: "var(--white-color)",
  background: "var(--black-color)",
  cursor: "pointer",
  transition: ".3s ease",
  borderRadius: "30px",
  "&:hover": {
    background: "var(--dark-grey-color)",
    transform: "scale(1.05)",
    transition: ".3s ease",
  },
};

const IndividualCategory = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const [loading, setLoading] = useState(true);
  const [catImg, setCatImg] = useState(null);
  const [catTitle, setCatTitle] = useState(null);
  const [catId, setCatId] = useState(null);
  const [productDetails, setProductDetails] = useState(null);

  const { products, productCount, filters } = useSelector(
    (state) => state.product
  );

  useEffect(() => {
    if (location && location.state && location.state.products) {
      setLoading(false);
      setCatImg(location.state.catImg);
      setCatTitle(location.state.catTitle);
      setProductDetails(location?.state?.products);
    }
  }, [location?.state?.products]);

  useEffect(() => {
    if (!location?.state?.products) {
      const categoryVal = location.pathname.substring(
        location.pathname.lastIndexOf("/") + 1
      );
      console.log(categoryVal);
      if (categoryVal) {
        let categoryObj = categoies.filter((c) => c.value === categoryVal);
        console.log("ketul here", categoryObj);
        if (categoryObj && categoryObj.length > 0) {
          categoryObj = categoryObj[0];
          setCatId(categoryObj._id);
          setCatImg(categoryImagesOld[categoryObj._id]);
          setCatTitle(categoryTitleOld[categoryObj._id]);

          dispatch(
            getallProducts({
              tab: "",
              categories: categoryObj._id,
              brands: [],
              page: 1,
              date: [],
              priceFilterType: "",
              minRentPriceFilter: "",
              maxRentPriceFilter: "",
              minBuyPriceFilter: "",
              maxBuyPriceFilter: "",
              langLong: "",
              search: "",
              milesFilter: "",
              transportationFilter: "",
              sortFilter: "",
            })
          );
        }
      }
    }
  }, []);

  useEffect(() => {
    if (products.length > 0) {
      setProductDetails(products);
      setLoading(false);
      dispatch(reset());
    }
  }, [products]);
  return (
    <>
      {loading || !productDetails ? (
        <Spinner />
      ) : (
        <>
          {productDetails && productDetails.length === 0 ? (
            <Typography>No Equipment Found</Typography>
          ) : (
            <Container sx={{ margin: "4rem auto" }}>
              <Stack
                component={"div"}
                direction={"row"}
                alignItems={"flex-start"}
                gap={"20px"}
                data-aos="fade-in"
              >
                <Box
                  component={"div"}
                  maxWidth={"280px"}
                  className="category-image-card"
                  marginTop={0}
                  maxHeight={"280px"}
                  flex={"30%"}
                >
                  <img
                    src={catImg}
                    alt="category"
                    width={"100%"}
                    height={"100%"}
                    style={{ borderRadius: "20px" }}
                  />
                </Box>
                <Box component={"div"} flex={"70%"}>
                  <Stack
                    direction={"row"}
                    alignItems={"center"}
                    maxWidth={"100%"}
                    margin={"auto"}
                    flexWrap={"wrap"}
                    justifyContent={"space-between"}
                  >
                    <Typography variant="h4" fontWeight={700}>
                      {catTitle + " "}
                      equipment from $1000 to $50000
                    </Typography>
                    {/* <TabsSorter /> */}
                  </Stack>
                  {/* <Divider sx={{ mt: ".5rem", borderBottomWidth: "medium" }} /> */}
                  <Box
                    component={"div"}
                    marginTop={"2rem"}
                    className="other-products"
                  >
                    {productDetails &&
                      productDetails.map((item, ind) => (
                        <Stack
                          direction={"row"}
                          justifyContent={"space-between"}
                          alignItems={"center"}
                          margin={"1rem auto"}
                        >
                          <Typography variant="h5" maxWidth={"420px"}>
                            {item.itemName}
                          </Typography>
                          <Box component={"div"}>
                            <Chip
                              sx={{
                                ...chipStyle,
                                marginRight: "1rem",
                                minWidth: "250px",
                                "&:hover": {
                                  background: "var(--black-color)",
                                  transform: "none",
                                },
                              }}
                              label={
                                item.itemToSell
                                  ? item.itemWeeklyPrice &&
                                    item.itemWeeklyPrice !== 0
                                    ? "Rent for $" +
                                      item.itemWeeklyPrice.toLocaleString(
                                        "en-US"
                                      ) +
                                      " per week"
                                    : "Rent for $" +
                                      item.itemAmount.toLocaleString("en-US")
                                  : item.itemDailyPrice &&
                                    item.itemDailyPrice !== 0
                                  ? "Rent for $" +
                                    item.itemDailyPrice.toLocaleString(
                                      "en-US"
                                    ) +
                                    " per day"
                                  : "Rent for $" + item.itemMonthlyPrice &&
                                    item.itemMonthlyPrice.toLocaleString(
                                      "en-US"
                                    ) + " per month"
                              }
                            />
                            <Link
                              to={`/product/${item._id}`}
                              state={{ id: item._id }}
                              // style={{ textDecoration: "none", width: "100%" }}
                            >
                              {" "}
                              <Chip sx={chipStyle} label={"View"} />{" "}
                            </Link>
                          </Box>
                        </Stack>
                      ))}
                  </Box>
                </Box>
              </Stack>
            </Container>
          )}
        </>
      )}
    </>
  );
};

export default IndividualCategory;
