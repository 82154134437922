import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
  commentStatus: "idle",
  commentInfo: null,
  commentError: null,
  commentMessage: null,
};

export const addClosetComment = createAsyncThunk(
  "comment/addClosetComment",
  async ({ token, values, id }, { rejectWithValue }) => {
    try {
      const { data } = await axios.post(
        `/api/users/addclosetcomment/${id}`,
        values,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteClosetComment = createAsyncThunk(
  "comment/deleteClosetComment",
  async ({ token, id, commentId }, { rejectWithValue }) => {
    try {
      const { data } = await axios.post(
        `/api/users/deleteclosetcomment/${id}`,
        { commentId },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const updateClosetComment = createAsyncThunk(
  "comment/updateClosetComment",
  async ({ token, id, commentId, comment }, { rejectWithValue }) => {
    try {
      const { data } = await axios.post(
        `/api/users/updateclosetcomment/${id}`,
        { commentId, comment },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getClosetComments = createAsyncThunk(
  "comment/getClosetComment",
  async ({ token, id }, { rejectWithValue }) => {
    try {
      const { data } = await axios.get(`/api/users/getclosetcomments/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const commentSlice = createSlice({
  name: "closetcomment",
  initialState,
  reducers: {
    resetComment(state, action) {
      //state.commentInfo = null;
      state.commentStatus = "idle";
      state.commentError = null;
      state.commentMessage = null;
    },
  },
  extraReducers: {
    [addClosetComment.pending]: (state, action) => {
      state.commentStatus = "loading";
    },
    [addClosetComment.fulfilled]: (state, action) => {
      state.commentStatus = "succeeded";
      state.commentMessage = action.payload.message;
    },
    [addClosetComment.rejected]: (state, action) => {
      state.commentStatus = "failed";
      state.commentError = action.payload;
    },
    [deleteClosetComment.pending]: (state, action) => {
      state.commentStatus = "loading";
    },
    [deleteClosetComment.fulfilled]: (state, action) => {
      state.commentStatus = "succeeded";
      //state.commentInfo = action.payload;
      state.commentMessage = action.payload.message;
    },
    [deleteClosetComment.rejected]: (state, action) => {
      state.commentStatus = "failed";
      state.commentError = action.payload;
    },
    [updateClosetComment.pending]: (state, action) => {
      state.commentStatus = "loading";
    },
    [updateClosetComment.fulfilled]: (state, action) => {
      state.commentStatus = "succeeded";
      //   state.commentInfo = action.payload; // idhar be
      state.commentMessage = action.payload.message;
    },
    [updateClosetComment.rejected]: (state, action) => {
      state.commentStatus = "failed";
      state.commentError = action.payload;
    },
    [getClosetComments.pending]: (state, action) => {
      state.commentStatus = "loading";
    },
    [getClosetComments.fulfilled]: (state, action) => {
      state.commentStatus = "succeeded"; // save kar dal
      state.commentInfo = action.payload; // shane idhar nahi karna tha
      //   state.commentMessage = action.payload.message;
    },
    [getClosetComments.rejected]: (state, action) => {
      state.commentStatus = "failed";
      state.commentError = action.payload;
    },
  },
});

export const { resetComment } = commentSlice.actions;
export default commentSlice.reducer;
