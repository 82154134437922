import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
  userFavoritesStatus: "idle",
  userFavoritesMessage: "",
  userFavorites: null,
  userFavIds: null,
  userFavoritesError: null,
};

export const getUserFavorites = createAsyncThunk(
  "like/getFavorites",
  async ({ token }, { rejectWithValue }) => {
    try {
      const { data } = await axios.get(`/api/product/getUserFavorites`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);
export const getUserFavoritesSlice = createSlice({
  name: "userfavorites",
  initialState,
  reducers: {
    resetuserFavoritesMessage(state, action) {
      state.userFavoritesMessage = "";
      state.userFavoritesError = null;
      state.userFavorites = null;
      state.userFavIds = null;
      state.userFavoritesStatus = "idle";
    },
  },
  extraReducers: {
    [getUserFavorites.pending]: (state, action) => {
      state.userFavoritesStatus = "loading";
    },
    [getUserFavorites.fulfilled]: (state, action) => {
      state.userFavoritesStatus = "succeeded";
      state.userFavorites = action.payload.userFavorites;
      state.userFavIds = action.payload.userFavIds;
      state.userFavoritesMessage = action.payload.message;
    },
    [getUserFavorites.rejected]: (state, action) => {
      state.userFavoritesStatus = "failed";
      state.userFavoritesError = action.payload.message;
    },
  },
});

export const { resetuserFavoritesMessage } = getUserFavoritesSlice.actions;
export default getUserFavoritesSlice.reducer;
