import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
  closetProductStatus: "idle",
  closetProduct: null,
  closetProductError: null
};

export const getClosetProduct = createAsyncThunk(
  "closetproduct/getClosetProduct",
  async ({ pId }, { rejectWithValue }) => {
    try {
      const { data } = await axios.get(`/api/product/getclosetproducts/${pId}`);
      return data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const closetProductSlice = createSlice({
  name: "closetproduct",
  initialState,
  reducers: {
    resetClosetProduct(state, action) {
      state.closetProductStatus = "idle";
      state.closetProduct = null;
      state.closetProductError = null;
    },
  },
  extraReducers: {
    [getClosetProduct.pending]: (state, action) => {
      state.closetProductStatus = "loading";
    },
    [getClosetProduct.fulfilled]: (state, action) => {
      state.closetProductStatus = "succeeded";
      state.closetProduct = action.payload.products
    },
    [getClosetProduct.rejected]: (state, action) => {
      state.closetProductStatus = "failed";
      state.closetProductError = action.payload.message;
    },
  },
});

export const { resetClosetProduct } = closetProductSlice.actions;
export default closetProductSlice.reducer;
