import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  Typography,
  Container,
  Grid,
  Paper,
  Box,
  Stack,
  TextField,
  InputAdornment,
} from "@mui/material";
import GoDizel from "../../assets/Pictures/godizel.png";
import video from "../../assets/video.mp4";
import "./HeroSection.css";
import {
  ArrowLeft,
  East,
  KeyboardBackspace,
  LocationCity,
  PrecisionManufacturing,
  Search,
  West,
} from "@mui/icons-material";
import { Link, useNavigate } from "react-router-dom";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import { components } from "react-select";
import { useDispatch, useSelector } from "react-redux";
import { getElasticSearch } from "../StateSlices/elasticSearchSlice";
import ElasticAutocomplete from "./ElasticAutocomplete";

const SingleValue = ({ children, ...props }) => {
  return (
    <components.SingleValue {...props}>
      {props.data.value.structured_formatting.main_text}
    </components.SingleValue>
  );
};

const Input = (props) => <components.Input {...props} isHidden={false} />;

const HeroSection = () => {
  let navigate = useNavigate();

  const dispatch = useDispatch();

  const inputSearchRef = React.useRef();
  const locationRef = React.useRef();
  const handleSearchSubmit = (e) => {
    e.preventDefault();
    // debugger;
    const query = inputSearchRef.current.value;
    console.log(itemLocTemp);
    const query2 = itemLocTemp.label ? itemLocTemp.label : "";
    if (query !== "" || query2 !== "") {
      navigate(`/shop?search=${query}&location=${query2}`);
    }
  };

  const [inputValue, setInputValue] = useState("");
  const [itemLocTemp, setItemLocTemp] = useState("");

  const selectRef = useRef();

  const onFocus = () => itemLocTemp && selectRef.current.inputRef.select();

  const onInputChange = (inputValue, { action }) => {
    // onBlur => setInputValue to last selected value
    // if (action === "input-blur") {
    //   setInputValue(value ? value.label : "");
    // }
    console.log(action);
    // onInputChange => update inputValue
    if (action === "input-change") {
      setInputValue(inputValue);
    }
  };

  return (
    <section>
      <div className="hero_container">
        <Box>
          <Typography
            variant="h1"
            sx={{ fontSize: { xs: 32, md: 48 } }}
            fontWeight={900}
            color={"#f8f7f7"}
            textAlign={"center"}
            className="mont-title"
            // fontFamily={"Roboto"}
          >
            {/* Rent Equipment at a Lower Cost */} Search The Rental Equipment
            Industry
          </Typography>
          <Typography
            fontSize={22}
            color={"#ddd"}
            my={"1rem"}
            fontWeight={500}
            textAlign={"center"}
          >
            {/* Compare Pricing and Streamline Your Rental Search */}
            Discover A Perfect Rental for Your Next Project
          </Typography>
          <Box className="hero-input-con">
            {/* <Box>
              <TextField
                placeholder="Search makes, models or keywords"
                // label="Machine Type"
                sx={{
                  m: 1,
                  width: "350px",
                  color: "var(--grey-color)",
                  background: "var(--white-color)",
                  borderRadius: "5px",
                  "&:hover": {
                    border: "none", // Set the hover border color to black
                    outline: "none",
                  },
                }}
                inputRef={inputSearchRef}
                onChange={(e) => handleElasticChange(e.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <PrecisionManufacturing />
                    </InputAdornment>
                  ),
                }}
              />
              {res && res.length > 0 && (
                <ul className="elastic-search-res">
                  {res.map((i) => (
                    <Link to={`/product/${i._id}`}>
                      <li className="search-item" key={i._id}>
                        {i.name}
                      </li>
                    </Link>
                  ))}
                </ul>
              )}

            </Box> */}
            <ElasticAutocomplete inputSearchRef={inputSearchRef} />
            <Box>
              <GooglePlacesAutocomplete
                selectProps={{
                  ref: selectRef,
                  value: itemLocTemp,
                  onChange: (val) => {
                    console.log(val);
                    setItemLocTemp(val);
                    setInputValue(
                      val ? val.value.structured_formatting.main_text : ""
                    );
                    // setFieldValue("itemLocation", val.label);
                  },
                  className: "google-places-autocomplete",
                  styles: {
                    container: (provided) => ({
                      ...provided,
                      width: "350px",
                      color: "var(--grey-color)",
                      background: "var(--white-color)",
                      borderRadius: "5px",
                    }),
                    input: (provided) => ({
                      ...provided,
                      padding: "10px 0",
                      border: "none",
                      color: "var(--grey-color)",
                    }),
                    option: (provided) => ({
                      ...provided,
                      zIndex: 999,
                    }),
                    singleValue: (provided) => ({
                      ...provided,
                    }),
                    indicatorSeparator: (provided) => ({
                      ...provided,
                      display: "none",
                    }),
                    placeholder: (provided) => ({
                      ...provided,
                      fontSize: "1rem",
                      // color: "#a2a2a2",
                      fontFamily: "Helvetica,Arial,sans-serif",
                    }),
                  },

                  inputValue: inputValue,
                  onFocus: onFocus,
                  isClearable: true,
                  onInputChange: onInputChange,
                  controlShouldRenderValue: false,
                  components: { SingleValue, Input },
                  placeholder: "Enter Location",
                  noOptionsMessage: () =>
                    itemLocTemp
                      ? "No options available"
                      : "Enter zipcode or address",
                }}
                apiKey="AIzaSyAw_j2KY4CDtksQcd6JexS3J1xt4BdbnGQ"
                apiOptions={{ region: "US" }}
              />
            </Box>
            <Box>
              <Button
                sx={{
                  mx: ".5rem",
                  my: { xs: "1rem", md: "1rem" },
                  padding: "15.5px",
                  width: { xs: "320px", md: "100%" },
                }}
                variant="contained"
                onClick={handleSearchSubmit}
              >
                <Search />
              </Button>
            </Box>
          </Box>
          <Stack
            direction={"row"}
            justifyContent={"space-between"}
            alignItems={"center"}
            sx={{
              background: "var(--black-color)",
              borderRadius: {xs: "15px", md: "30px"},
              padding: {xs: "0 2rem", md: "0 2rem"},
              width: {xs: "100%", md:"80%"},
              margin: "auto",
              marginTop: "1.5rem",
            }}
          >
            <Typography
              variant="h4"
              // fontWeight={700}
              color={"var(--white-color)"}
            >
              Want to List Your Equipment?{" "}
            </Typography>
            <Link to={"/add-new-item"}>
              <Button
                variant="contained"
                // color="secondary"
                sx={{
                  margin: "1rem 0",
                  borderRadius: {xs:"15px",md: "30px"},
                  paddingLeft: {md: "20px"},
                  paddingRight: {md: "20px"},
                  minWidth: "100px"
                }}
              >
                Let's Go
              </Button>
            </Link>
          </Stack>
        </Box>
      </div>
    </section>
  );
};

export default HeroSection;
