import React from "react";
import Error4041 from "../../assets/Pictures/404-1.jpg";
import Error4042 from "../../assets/Pictures/404-2.jpg";
import Error4043 from "../../assets/Pictures/404-3.jpg";
import "./Error404.css";
import { Button, Typography } from "@mui/material";
import { Link } from "react-router-dom";
const Error404 = () => {
  return (
    <>
      <div className="error-main" data-aos="fade-in">
        <div className="error-body">
          <Typography variant="h1" className="error-title mont-title">
            <span className="fade fadeOne span-1">404</span>
            {/* <span className="fade fadeTwo span-2">Construction</span> */}
          </Typography>

          <Typography
            variant="h3"
            className="error-desc"
            style={{ color: "#ccc", fontWeight: "normal" }}
          >
            <span className="fade fadeTwo">&nbsp;:&nbsp;Invalid URL</span>
          </Typography>
        </div>
      </div>
    </>
  );
};

export default Error404;
