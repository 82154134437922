import { Stack, Typography } from "@mui/material";
import React from "react";
import AdminSearch from "../AdminSearch/AdminSearch";

const AnalyticsHeader = ({
  placeholder,
  label,
  searchQuery,
  hideSearchBar = false,
}) => {
  return (
    <Stack
      direction={"row"}
      flexWrap={"wrap"}
      margin={"1rem 0"}
      justifyContent={"space-between"}
      alignItems={"center"}
    >
      <Typography
        variant="h3"
        className="mont-title"
        textTransform={"capitalize"}
        fontWeight={700}
      >
        Manage {label}
      </Typography>
      {!hideSearchBar && (
        <AdminSearch
          placeholder={placeholder}
          label={label}
          searchQuery={searchQuery}
        />
      )}
    </Stack>
  );
};

export default AnalyticsHeader;
