import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import axios from "axios"

const initialState = {
  itemStatus: "idle",
  itemInfo: null,
  itemError: null,
  savedId:null
}

export const addItem = createAsyncThunk(
  "add/addItem",
  async ({token,values}, { rejectWithValue }) => {
    try {
      const { data } = await axios.post(
        "/api/product/add",values,
        {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        }
      )
      return data
    } catch (err) {
      return rejectWithValue(err.response.data)
    }
  }
)

export const saveItem = createAsyncThunk(
  "add/saveItem",
  async ({token,values}, { rejectWithValue }) => {
    try {
      const { data } = await axios.post(
        "/api/product/save",values,
        {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        }
      )
      return data
    } catch (err) {
      return rejectWithValue(err.response.data)
    }
  }
)

export const additemSlice = createSlice({
  name: "closet",
  initialState,
  reducers: {
    resetItem(state,action){
      state.itemInfo = null
      state.itemStatus="idle"
      state.itemError= null
      state.savedId = null
    },
    resetId(state,action){
      state.savedId = null
    }
  },
  extraReducers: {
    [addItem.pending]: (state, action) => {
      state.itemStatus = "loading"
    },
    [addItem.fulfilled]: (state, action) => {
      state.itemStatus = "succeeded"
      state.itemInfo = action.payload
    },
    [addItem.rejected]: (state, action) => {
      state.itemStatus = "failed";
      state.itemError = action.payload
    },

    [saveItem.fulfilled]: (state, action) => {
      state.itemStatus = "succeeded"
      state.savedId = action.payload.id
    },
  },
})

export const { resetItem, resetId } = additemSlice.actions
export default additemSlice.reducer 