import React, { useEffect, useState } from "react";
import "./OrderDetails.css";
import {
  Box,
  Button,
  Chip,
  Container,
  Divider,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { FaInfoCircle } from "react-icons/fa";
import { ShoppingCart } from "@mui/icons-material";
import ProductCard from "../../components/ProductCard/ProductCard";
import {
  Link,
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Spinner from "../../components/Spinner/Spinner";
import { getuserInfo } from "../../components/StateSlices/getuserSlice";
import {
  getOrderDetails,
  reseto,
} from "../../components/StateSlices/getOrderDetailsSlice";

const OrderDetails = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { orderDetailsInfo } = useSelector((state) => state.orderdetails);
  const { userInfo, error, profile } = useSelector((state) => state.user);

  const [loading, setLoading] = useState(true);
  const [orderDetails, setOrderDetails] = useState(null);

  const [buyProducts, setBuyProducts] = useState([]);
  const [rentProducts, setRentProducts] = useState([]);

  const { orderId, productId } = useParams();

  useEffect(() => {
    const token = localStorage.getItem("inktoken");
    console.log("aa gaya idhar", orderId, productId);
    if (orderId && token) {
      setLoading(true);
      dispatch(getOrderDetails({ token, orderId, productId }));
    }
  }, []);

  useEffect(() => {
    console.log("heyy", orderDetailsInfo);
    if (orderDetailsInfo && orderDetailsInfo.products) {
      let buyTypeProducts = orderDetailsInfo.products
        .filter((p) => p.type === "buy")
        .map((p) => p.productId);
      let rentTypeProducts = orderDetailsInfo.products
        .filter((p) => p.type === "rent")
        .map((p) => p.productId);
      console.log(buyTypeProducts, rentTypeProducts);
      if (buyTypeProducts && buyTypeProducts.length > 0)
        setBuyProducts(buyTypeProducts);
      if (rentTypeProducts && rentTypeProducts.length > 0)
        setRentProducts(rentTypeProducts);
      setOrderDetails(orderDetailsInfo);
      setLoading(false);
      dispatch(reseto());
    }
  }, [orderDetailsInfo]);

  return (
    <Container sx={{ mt: 3 }} data-aos="fade-in">
      {loading ? (
        <Spinner />
      ) : (
        <>
          {!orderDetailsInfo ? (
            <Typography>No Order Details Found</Typography>
          ) : (
            <div className="order-conf-con" data-aos="fade-up">
              <div className="left">
                <div className="checkmark-con">
                  <div display={"flex"}>
                    <FaInfoCircle color="var(--primary-color)" size={"4em"} />
                  </div>
                  <div className="checkmark-content">
                    <Typography variant="h6" mt={0} marginBottom={".2rem"}>
                      Order ID. {orderDetails.orderId}
                    </Typography>
                    <Typography variant="h3" fontWeight={700}>
                      Order Details
                    </Typography>
                  </div>
                </div>
                {/* <Divider /> */}
                <Paper
                  elevation={0}
                  style={{
                    padding: "1rem 2rem",
                    paddingBottom: "2rem",
                    marginTop: "1rem",
                    background: "var(--black-color)",
                    color: "var(--white-color)",
                    // minHeight: "100vh",
                    // border: "1px solid #ddd",
                    borderRadius: "10px",
                  }}
                >
                  <Typography variant="h3" fontWeight={700} margin={"1rem"}>
                    Order Summary
                  </Typography>
                  <TableContainer>
                    <Table size="small">
                      <TableHead>
                        <TableRow>
                          <TableCell
                            sx={{
                              fontWeight: 700,
                              fontSize: "12px",
                              textTransform: "uppercase",
                              letterSpacing: 1.5,
                              color: "var(--grey-color)",
                              borderBottomColor: "var(--grey-color)",
                            }}
                          >
                            Description
                          </TableCell>
                          <TableCell
                            align="right"
                            sx={{
                              fontWeight: 700,
                              fontSize: "12px",
                              textTransform: "uppercase",
                              letterSpacing: 1.5,
                              color: "var(--grey-color)",
                              borderBottomColor: "var(--grey-color)",
                            }}
                          >
                            Amount
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow>
                          <TableCell
                            sx={{
                              color: "var(--white-color)",
                              border: "none",
                              borderBottomColor: "var(--grey-color)",
                            }}
                          >
                            Checkout Date
                          </TableCell>
                          <TableCell
                            sx={{
                              color: "var(--white-color)",
                              border: "none",
                              borderBottomColor: "var(--grey-color)",
                            }}
                            align="right"
                          >
                            {new Date(
                              orderDetails.checkoutTime
                            ).toLocaleDateString("en-US", {
                              year: "numeric",
                              month: "2-digit",
                              day: "2-digit",
                            })}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell
                            sx={{
                              color: "var(--white-color)",
                              border: "none",
                              borderBottomColor: "var(--grey-color)",
                            }}
                          >
                            Checkout Time
                          </TableCell>
                          <TableCell
                            sx={{
                              color: "var(--white-color)",
                              border: "none",
                              borderBottomColor: "var(--grey-color)",
                            }}
                            align="right"
                          >
                            {new Date(
                              orderDetails.checkoutTime
                            ).toLocaleTimeString("en-US", {
                              hour: "numeric",
                              minute: "numeric",
                              hour12: true,
                            })}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell
                            sx={{
                              color: "var(--white-color)",
                              border: "none",
                              borderBottomColor: "var(--grey-color)",
                            }}
                          >
                            Total Tax
                          </TableCell>
                          <TableCell
                            sx={{
                              color: "var(--white-color)",
                              border: "none",
                              borderBottomColor: "var(--grey-color)",
                              minWidth: "max-content",
                            }}
                            align="right"
                          >
                            ${orderDetails.tax || 0}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell
                            sx={{
                              color: "var(--white-color)",
                              border: "none",
                              borderBottomColor: "var(--grey-color)",
                            }}
                          >
                            Total Order Price
                          </TableCell>
                          <TableCell
                            sx={{
                              color: "var(--white-color)",
                              border: "none",
                              borderBottomColor: "var(--grey-color)",
                              minWidth: "max-content",
                            }}
                            align="right"
                          >
                            ${orderDetails.fees || orderDetails.price || 0}
                          </TableCell>
                        </TableRow>

                        {/* <TableRow>
                          <TableCell
                            sx={{
                              fontWeight: "700",
                              textTransform: "uppercase",
                              letterSpacing: 1.5,
                              color: "var(--white-color)",
                              border: "none",
                              borderTop: "2px solid var(--grey-color)",
                              borderBottom: "none",
                            }}
                          >
                            Total
                          </TableCell>
                          <TableCell
                            sx={{
                              fontWeight: "700",
                              border: "none",
                              borderTop: "2px solid var(--grey-color)",
                              borderBottom: "none",
                              color: "var(--white-color)",
                            }}
                            align="right"
                          >
                            ${Number(checkoutHistory.totalPricePaid).toFixed(2)}
                          </TableCell>
                        </TableRow> */}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Paper>
                <Paper
                  elevation={0}
                  style={{
                    padding: "1rem 2rem",
                    paddingBottom: "2rem",
                    marginTop: "1rem",
                    background: "var(--black-color)",
                    color: "var(--white-color)",
                    // minHeight: "100vh",
                    // border: "1px solid #ddd",
                    borderRadius: "10px",
                  }}
                >
                  <Typography
                    variant="h3"
                    fontWeight={700}
                    margin={"1rem"}
                    marginTop={"2rem"}
                    marginBottom={"1.5rem"}
                  >
                    Shipping Details
                  </Typography>
                  <Typography variant="h6" margin={"1rem"}>
                    <strong>Asset Owner Name: </strong>
                    <span style={{ marginLeft: "1rem" }}>
                      {orderDetails.name}
                    </span>
                  </Typography>
                  <Typography variant="h6" margin={"1rem"} display={"flex"}>
                    <Box>
                      <strong>Street: </strong>
                    </Box>
                    <Box
                      marginLeft={"1rem"}
                      component={"div"}
                      id="addressFormat"
                    >
                      {orderDetails.shippingAddress}
                    </Box>
                  </Typography>
                  <Typography variant="h6" margin={"1rem"}>
                    <strong>City: </strong>
                    <span style={{ marginLeft: "1rem" }}>
                      {orderDetails.shippingCity}
                    </span>
                  </Typography>
                  <Typography variant="h6" margin={"1rem"}>
                    <strong>State: </strong>
                    <span style={{ marginLeft: "1rem" }}>
                      {orderDetails.shippingState}
                    </span>
                  </Typography>
                  <Typography variant="h6" margin={"1rem"}>
                    <strong>Zipcode: </strong>
                    <span style={{ marginLeft: "1rem" }}>
                      {orderDetails.shippingZipCode}
                    </span>
                  </Typography>
                </Paper>
                <div className="order-conf-footer">
                  <Box>
                    <Typography variant="h6">
                      Need any help ?{" "}
                      <strong
                        style={{ borderBottom: "1px solid var(--black-color)" }}
                      >
                        <a href="mailto:dizel@support.com" target="_blank">Contact Support</a>
                      </strong>
                    </Typography>
                  </Box>
                  <Box>
                    <Link to={"/shop"}>
                      <Button
                        sx={{
                          background: "var(--black-color)",
                          color: "var(--white-color)",
                          "&:hover": {
                            color: "var(-black-color)",
                            background: "var(--grey-color)",
                          },
                        }}
                      >
                        Continue Shopping
                      </Button>
                    </Link>
                  </Box>
                </div>
              </div>
              {/* <Box component={"div"} className="order-conf-vertical-divider">
      &nbsp;
    </Box> */}
              <div className="right">
                <div className="checkmark-con" style={{ marginTop: "1rem" }}>
                  <ShoppingCart />
                  <Typography variant="h3" mx={1.5} fontWeight={700}>
                    Your Equipment
                  </Typography>
                  <Chip
                    label={buyProducts.length + rentProducts.length}
                    sx={{
                      background: "var(--black-color)",
                      color: "Var(--white-color)",
                      fontWeight: 700,
                    }}
                  />
                </div>
                {/* <Divider /> */}
                <div className="order-conf-products-con">
                  {/* {products.map((product) => (
          <ProductCard product={product} />
        ))} */}
                  {buyProducts && buyProducts.length > 0 && (
                    <>
                      <Divider
                        sx={{
                          fontSize: "20px",
                          fontWeight: "700",
                          textTransform: "uppercase",
                          margin: "1rem 0",
                        }}
                        textAlign="left"
                      >
                        Equipment
                      </Divider>
                      {buyProducts &&
                        buyProducts.map((product) => (
                          <div style={{ margin: "1rem 0" }}>
                            <ProductCard
                              key={product._id}
                              product={product}
                              buttons={["view details"]}
                            />
                          </div>
                        ))}
                    </>
                  )}
                  {rentProducts && rentProducts.length > 0 && (
                    <>
                      <Divider
                        sx={{
                          fontSize: "20px",
                          fontWeight: "700",
                          textTransform: "uppercase",
                          margin: "1rem 0",
                        }}
                        textAlign="left"
                      >
                        Rental Equipment
                      </Divider>
                      {rentProducts &&
                        rentProducts.map((product) => (
                          <div style={{ margin: "1rem 0" }}>
                            <ProductCard
                              key={product._id}
                              product={product}
                              buttons={["view details"]}
                            />
                          </div>
                        ))}
                    </>
                  )}
                  {buyProducts.length === 0 && rentProducts.length === 0 && (
                    <Typography variant="h3">No Equipment</Typography>
                  )}
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </Container>
  );
};

export default OrderDetails;
