import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import axios from "axios"

const initialState = {
  deleteStatus: "idle",
  deleteInfo: null,
  deleteError: null,
}

export const deleteItem = createAsyncThunk(
  "delete/deleteItem",
  async ({token,id}, { rejectWithValue }) => {
    try {
      const { data } = await axios.delete(
        `/api/product/delete/${id}`,
        {
        headers: {
                Authorization: `Bearer ${token}`,
            },
        }
      )
      return data
    } catch (err) {
      return rejectWithValue(err.response.data)
    }
  }
)
export const deleteitemSlice = createSlice({
  name: "delete",
  initialState,
  reducers: {
    resetDelete(state,action){
        state.deleteInfo = null
        state.deleteStatus="idle"
      }
  },
  extraReducers: {
    [deleteItem.pending]: (state, action) => {
      state.deleteStatus = "loading"
    },
    [deleteItem.fulfilled]: (state, action) => {
      state.deleteStatus = "succeeded"
      state.deleteInfo = action.payload.message
    },
    [deleteItem.rejected]: (state, action) => {
      state.deleteStatus = "failed";
      state.deleteError = action.payload
    },
  },
})

export const { resetDelete } = deleteitemSlice.actions
export default deleteitemSlice.reducer 