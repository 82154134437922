import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import axios from "axios"

const initialState = {
  status: "idle",
  message: null,
  error: null,
}
export const forgotPassword = createAsyncThunk(
  "forgot/password",
  async (email, { rejectWithValue }) => {
    try {
      const { data } = await axios.post(
        "/api/users/forgotpassword",
        email
      )
      return data
    } catch (err) {
      return rejectWithValue(err.response.data)
    }
  }
)
export const forgotPasswordSlice = createSlice({
  name: "forgot",
  initialState,
  reducers: {
    reset(state,action){
      state.error=null
      state.message=null
    }
  },
  extraReducers: {
    [forgotPassword.pending]: (state, action) => {
      state.status = "loading"
    },
    [forgotPassword.fulfilled]: (state, action) => {
      state.status = "succeeded"
      state.message = action.payload.message
    },
    [forgotPassword.rejected]: (state, action) => {
      state.status = "failed"
      state.error = action.payload.message || "Something Went Wrong. Try Again Later!!"
    },
  },
})

export const { reset } = forgotPasswordSlice.actions

export default forgotPasswordSlice.reducer 