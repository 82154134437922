import React from "react";
import {
  ElementsConsumer,
  CardElement,
  CardNumberElement,
  Elements,
} from "@stripe/react-stripe-js";
import { Link } from "react-router-dom";
import CardSection from "./CardSection";
import { ToastContainer, toast } from "react-toastify";
import Button from "@mui/material/Button";

import "react-toastify/dist/ReactToastify.css";
import { Box } from "@mui/material";

class CheckoutCard extends React.Component {
  handleSubmit = async (event) => {
    event.preventDefault();

    const errorKeys = [];
    //console.log(errorKeys)
    if (errorKeys.length === 0) {
      this.props.setSpinner(true);
      const toastOption = {
        position: "top-right",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      };

      const { stripe, elements } = this.props;
      if (!stripe || !elements) {
        return;
      }

      const card = elements.getElement(CardNumberElement);
      console.log(card);
      const result = await stripe.createToken(card);
      if (result.error) {
        toast.error(result.error.message, toastOption);
        //console.log(result.error.message);
        this.props.setSpinner(false);
      } else {
        console.log(result.token);
        // this.props.setId(result.token);
        this.props.setToken(result.token);
        return;
      }
    } else {
      document.getElementsByName(errorKeys[0])[0].focus();
    }
  };

  render() {
    return (
      <div>
        <form>
          <CardSection
            setCardNumber={this.props.setCardNumber}
            setCardExpiry={this.props.setCardExpiry}
            setCardCvc={this.props.setCardCvc}
            cardNumber={this.props.cardNumber}
            cardExpiry={this.props.cardExpiry}
            cardCvc={this.props.cardCvc}
            setSaveCard={this.props.setSaveCard}
            checkBoxStatus={this.props.checkBoxStatus}
          />
          {/* <button
            style={{
              margin: "20px 0px",
              fontWeight: "bold",
              fontFamily: "LATO",
              fontSize: "16px",
            }}
            type="button"
            onClick={this.handleSubmit}
            title={
              this.props.addressDone === false
                ? "Please Update Address First"
                : ""
            }
            className={`btn dark-btn ${
              this.props.addressDone === false ? "dis-btn" : ""
            }`}
            disabled={
              !this.props.stripe ||
              this.props.spinner === true ||
              !this.props.addressDone
            }
          >
            {!this.props.stripe || this.props.spinner === true
              ? "Please Wait"
              : "Submit Order"}
          </button> */}
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              marginTop: "1rem",
            }}
          >
            <Button
              variant="contained"
              onClick={this.handleSubmit}
              // disabled={activeStep === 2}
              sx={{
                mr: 1,
                background: "var(--secondary-color)",
                "&: hover": {
                  background: "var(--grey-color)",
                  color: "var(--black-color)",
                },
              }}
            >
              Place Order
            </Button>
          </Box>
        </form>
      </div>
    );
  }
}

export default function InjectedCheckoutSection({
  //   submitForm,
  handleSubmit,
  spinner,
  setSpinner,
  setToken,
  setCardNumber,
  setCardExpiry,
  setCardCvc,
  cardNumber,
  cardExpiry,
  cardCvc,
  setSaveCard,
  checkBoxStatus,
}) {
  return (
    <ElementsConsumer>
      {({ stripe, elements }) => (
        <CheckoutCard
          stripe={stripe}
          elements={elements}
          handleSubmit={handleSubmit}
          //   submitForm={submitForm}
          //   formRef={formRef}
          //   setId={setId}
          spinner={spinner}
          setSpinner={setSpinner}
          setToken={setToken}
          setCardNumber={setCardNumber}
          setCardExpiry={setCardExpiry}
          setCardCvc={setCardCvc}
          cardNumber={cardNumber}
          cardExpiry={cardExpiry}
          cardCvc={cardCvc}
          setSaveCard={setSaveCard}
          checkBoxStatus={checkBoxStatus}
          //   errors={errors}
          //   addressDone={addressDone}
        />
      )}
    </ElementsConsumer>
  );
}
