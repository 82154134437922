import React from "react";

import "./LandingSection.css";

import { Link, useNavigate } from "react-router-dom";
import heroVideo from "../../assets/video.mp4";
import heroImg from "../../assets/Pictures/list-your-machine-info.png";
import { Typography, Button } from "@mui/material";

const LandingSection = () => {
  const navigate = useNavigate();

  const handleClick = () => {
    let token = localStorage.getItem("inktoken");
    if (!token) {
      navigate("/signup", { replace: true });
    } else {
      navigate("/add-items");
    }
  };
  return (
    <section
      style={{
        // background: `url(${heroBg})`,
        backgroundSize: "cover",
        margin: "2rem auto",
      }}
    >
      <div className="landing-section">
        <div className="landing-sec-left">
          <div className="img-con">
            {/* <video
              loop={true}
              width="100%"
              height="100%"
              preload="auto"
              // controls={true}
              autoPlay={true}
              muted={true}
              style={{ borderRadius: "10px" }}
              //   poster={posterImg}
            >
              <source src={heroVideo} type="video/mp4" />
            </video> */}
            <img
              src={heroImg}
              width={"100%"}
              style={{ borderRadius: "10px" }}
              alt="img"
            />
          </div>
        </div>
        <div className="landing-sec-right">
          {/* <img
            src={CClogo}
            className="landing-sec-logo"
            alt="Closest Closet Logo"
          /> */}
          <Typography
            variant="h1"
            className="large-text bold-text background-text gradient-text mont-title"
          >
            List your <span className="element mont-title">Machine</span>
            {/* <br /> */}
          </Typography>
          <Typography
            variant="h4"
            color={"text.secondary"}
            // fontSize={"20px"}
            margin={"1rem auto"}
            className="h4 secondary-text my-1"
          >
            Turn your idle machinery into profit
          </Typography>
          {/* <img src={repeatImg} style={{ width: "200px" }} /> */}
          {/* <br /> */}
          <div className="landing-sec-btn-con">
            {" "}
            <Button size="large" onClick={handleClick} variant="contained">
              Let's Go!
            </Button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default LandingSection;
