import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import axios from "axios"

const initialState = {
  commentStatus: "idle",
  commentInfo: null,
  commentError: null,
}

export const addComment = createAsyncThunk(
  "comment/addComment",
  async ({token,values,id}, { rejectWithValue }) => {
    try {
      const { data } = await axios.post(
        `/api/product/addcomment/${id}`,values,
        {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        }
      )
      return data
    } catch (err) {
      return rejectWithValue(err.response.data)
    }
  }
)

export const deleteComment = createAsyncThunk(
    "comment/deleteComment",
    async ({token,id,commentId}, { rejectWithValue }) => {
      try {
        const { data } = await axios.post(
          `/api/product/deletecomment/${id}`,{commentId},
          {
              headers: {
                  Authorization: `Bearer ${token}`,
              },
          }
        )
        return data
      } catch (err) {
        return rejectWithValue(err.response.data)
      }
    }
  )

  export const updateComment = createAsyncThunk(
    "comment/updateComment",
    async ({token,id,commentId,comment}, { rejectWithValue }) => {
      try {
        const { data } = await axios.post(
          `/api/product/updatecomment/${id}`,{commentId,comment},
          {
              headers: {
                  Authorization: `Bearer ${token}`,
              },
          }
        )
        return data
      } catch (err) {
        return rejectWithValue(err.response.data)
      }
    }
  )

export const commentSlice = createSlice({
  name: "comment",
  initialState,
  reducers: {
    resetComment(state,action){
      state.commentInfo = null
      state.commentStatus="idle"
      state.commentError= null
    }
  },
  extraReducers: {
    [addComment.pending]: (state, action) => {
      state.commentStatus = "loading"
    },
    [addComment.fulfilled]: (state, action) => {
      state.commentStatus = "succeeded"
      state.commentInfo = action.payload
    },
    [addComment.rejected]: (state, action) => {
      state.commentStatus = "failed";
      state.commentError = action.payload
    },
    [deleteComment.pending]: (state, action) => {
      state.commentStatus = "loading"
    },
    [deleteComment.fulfilled]: (state, action) => {
      state.commentStatus = "succeeded"
      state.commentInfo = action.payload
    },
    [deleteComment.rejected]: (state, action) => {
      state.commentStatus = "failed";
      state.commentError = action.payload
    },
    [updateComment.pending]: (state, action) => {
      state.commentStatus = "loading"
    },
    [updateComment.fulfilled]: (state, action) => {
      state.commentStatus = "succeeded"
      state.commentInfo = action.payload
    },
    [updateComment.rejected]: (state, action) => {
      state.commentStatus = "failed";
      state.commentError = action.payload
    },
  },
})

export const { resetComment } = commentSlice.actions
export default commentSlice.reducer 