import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
  purchasestatus: "idle",
  purchaseInfo: {},
  error: null,
};

export const getSoldedProduct = createAsyncThunk(
  "product/getsoldproduct",
  async ({ id, token }, { rejectWithValue }) => {
    try {
      const { data } = await axios.get(`/api/checkout/getsoldproduct/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);
export const SoldedSlice = createSlice({
  name: "solded",
  initialState,
  reducers: {
    resetp(state, action) {
      state.purchaseInfo = {};
      state.purchasestatus = "idle";
    },
  },
  extraReducers: {
    [getSoldedProduct.pending]: (state, action) => {
      state.purchasestatus = "loading";
    },
    [getSoldedProduct.fulfilled]: (state, action) => {
      state.purchasestatus = "succeeded";
      state.purchaseInfo = action.payload.checkoutInfo;
    },
    [getSoldedProduct.rejected]: (state, action) => {
      state.purchasestatus = "failed";
      state.error = action.payload.message;
    },
  },
});

export const { resetp } = SoldedSlice.actions;
export default SoldedSlice.reducer;
