import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import axios from "axios"

const initialState = {
  status: "idle",
  message: null,
  error: null,
  info:null
}

export const verifyAccount = createAsyncThunk(
  "verify/user",
  async ({token,values,id}, { rejectWithValue }) => {
    try {
      const { data } = await axios.post(
        `/api/users/verify/${id}`,
      )
      return data
    } catch (err) {
      return rejectWithValue(err.response.data)
    }
  }
)
export const verifyAccountSlice = createSlice({
  name: "verify",
  initialState,
  reducers: {
    reset(state,action){
        state.message = null
        state.status="idle"
        state.info = null
      }
  },
  extraReducers: {
    [verifyAccount.pending]: (state, action) => {
      state.status = "loading"
    },
    [verifyAccount.fulfilled]: (state, action) => {
      state.status = "succeeded"
      state.message = action.payload.message
      state.info = action.payload.userinfo
      localStorage.setItem('inktoken', action.payload.token)
    },
    [verifyAccount.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.payload
    },
  },
})

export const { reset } = verifyAccountSlice.actions
export default verifyAccountSlice.reducer 