import React from "react";
import "./DocusignSuccess.css";
const DocuSignSucces = () => {
  return (
    <div class="thank-you-wrapper">
      <div class="check-mark-block">
        <div class="check-mark-wrapper">
          <span></span>
          <span></span>
        </div>
      </div>
      <h3>Document Signed Successfully</h3>
      <p>Have a great day!</p>
    </div>
  );
};

export default DocuSignSucces;
