import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import axios from "axios"

const initialState = {
  deleteItemStatus: "idle",
  deleteItemInfo: null,
  deleteItemError: null,
}

export const deleteCheckoutItem = createAsyncThunk(
  "delete/checkoutItem",
  async ({token,id}, { rejectWithValue }) => {
    try {
      const { data } = await axios.delete(
        `/api/checkout/delete/${id}`,
        {
        headers: {
                Authorization: `Bearer ${token}`,
            },
        }
      )
      return data
    } catch (err) {
      return rejectWithValue(err.response.data)
    }
  }
)
export const deleteCheckoutItemSlice = createSlice({
  name: "checkoutdelete",
  initialState,
  reducers: {
    resetCheckoutDelete(state,action){
        state.deleteItemInfo = null
        state.deleteItemStatus="idle"
      }
  },
  extraReducers: {
    [deleteCheckoutItem.pending]: (state, action) => {
      state.deleteItemStatus = "loading"
    },
    [deleteCheckoutItem.fulfilled]: (state, action) => {
      state.deleteItemStatus = "succeeded"
      state.deleteItemInfo = action.payload.message
    },
    [deleteCheckoutItem.rejected]: (state, action) => {
      state.deleteItemStatus = "failed";
      state.deleteItemError = action.payload
    },
  },
})

export const { resetCheckoutDelete } = deleteCheckoutItemSlice.actions
export default deleteCheckoutItemSlice.reducer 