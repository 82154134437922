import { Box, Button, Grid, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import ProductCard from "../ProductCard/ProductCard";
import { useDispatch, useSelector } from "react-redux";
import Spinner from "../Spinner/Spinner";
import { getuserInfo } from "../StateSlices/getuserSlice";
import swal from "sweetalert";
import { enlistItem, resetItem } from "../StateSlices/updateitemSlice";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { sortDataProducts } from "../../utils/utils";
import noitem from '../../assets/no-item.png'

const EquipmentTab = ({ type, sort = "newest" }) => {
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const { userInfo } = useSelector((state) => state.user);
  const { itemStatus, itemInfo, itemError, itemDelisted } = useSelector(
    (state) => state.update
  );
  const [userProducts, setUserProducts] = useState([]);
  const [loading, setLoading] = useState(false);

  let toastOption = {
    position: "top-right",
    autoClose: 1500,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "dark",
  };

  useEffect(() => {
    setUserProducts([]);
    setLoading(true);

    // console.log("TYPE", type);

    if (userInfo && userInfo.userProducts) {
      let products = userInfo?.userProducts?.filter((p) =>
        type === "buy"
          ? p.itemToSell === true
          : type === "rent"
          ? p.itemForRent === true
          : type === "delisted"
          ? p.delisted === true
          : type === "hold"
          ? p.itemToHold === true
          : type === "draft"
          ? p.draft === true
          : p.itemForRent === true ||
            p.itemToSell === true ||
            p.itemToHold === true ||
            p.delisted === true ||
            p.draft === true
      );

      if (products) {
        console.log(sort);
        let sortedProducts = products;
        if (products.length > 1) {
          sortedProducts = sortDataProducts(sortedProducts, sort, type);
        }
        console.log(sortedProducts);
        setUserProducts(sortedProducts);
        setTimeout(() => {
          setLoading(false);
        }, 400);
        products = [];
      }
      //   setSpinner(false);
    }
  }, [type, userInfo, sort]);

  const enlistHandler = (id) => {
    swal({
      title: "EnList Item",
      text: "Are you sure you want to Enlist this item, Once Enlisted it will be available for rent or sell",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willReactive) => {
      // console.log(willReactive);
      if (willReactive) {
        let token = localStorage.getItem("inktoken");
        setLoading(true);
        dispatch(enlistItem({ token, id: id }));
        // if (token) {
        //   setSpinner(true)
        //   dispatch(
        //     deleteProduct({
        //       token,
        //       id,
        //     })
        //   );
        // } else {
        //   // navigate("/404", { replace: true });
        // }
      }
    });
  };

  useEffect(() => {
    if (itemDelisted) {
      toast.success("Item Enlisted Successfully", toastOption);
      dispatch(resetItem());
      navigate("/user/equipment", { replace: true });
      dispatch(getuserInfo({ token: localStorage.getItem("inktoken") }));
      setLoading(false);
    }
  }, [itemDelisted]);

  return (
    <Box component={"div"} marginTop={"2rem"} data-aos="fade-in">
      {loading ? (
        <Spinner height="60vh" />
      ) : (
        <Grid
          container
          justifyContent={"space-between"}
          marginTop={"1rem"}
          gap={4}
        >
          {" "}
          {userProducts && userProducts.length > 0 ? (
            <>
              {userProducts.map((prod, ind) => (
                <ProductCard
                  hideLikeIcon={true}
                  shopPageCardWidth={"400px"}
                  product={prod}
                  userInfo={userInfo}
                  buttons={["Edit Listing"]}
                  enlistHandler={enlistHandler}
                ></ProductCard>
              ))}
            </>
          ) : (
            <Stack
              direction={"column"}
              justifyContent={"center"}
              alignItems={"center"}
              minHeight={"60vh"}
              width={"100%"}
              sx={{
                background:`url(${noitem})`,
                backgroundSize:"cover",

              }}
            >
              <Typography variant="h3" color={"var(--black-color)"}>
                You haven't added any {type === "both" ? "" : type} equipment
              </Typography>
              <Button
                variant="contained"
                color="secondary"
                onClick={() => navigate("/add-new-item")}
                sx={{ marginTop: "1rem" }}
              >
                Add Equipment
              </Button>
            </Stack>
          )}
        </Grid>
      )}
    </Box>
  );
};

export default EquipmentTab;
