import React from "react";
import dizelLogo from "../../assets/godizel-logo.png";
import { categoryImagesOld, categoryTitleOld } from "../AddNewItem/data";
import { Link } from "react-router-dom";
import { Box } from "@mui/material";

const CustomCategoryTab = ({ catMappings, categorySelected, productInfo }) => {
  return (
    <>
      {categorySelected.map((catId, ind) => (
        <Link
          to={
            "/category/" +
            categoryTitleOld[categorySelected[ind]].replace(" ", "-")
          }
          state={{
            products: productInfo,
            catImg: categoryImagesOld[categorySelected[ind]],
            catTitle: categoryTitleOld[categorySelected[ind]],
          }}
        >
          <Box
            component={"div"}
            width="160px"
            height="80px"
            className="category-image-card"
            marginTop={0}
            margin={0}
          >
            <img
              src={categoryImagesOld[catId]}
              alt="category"
              // width={"100%"}
              // height={"100%"}
              style={{
                borderRadius: "20px",
                backgroundSize: "cover",
                backgroundPosition: "center",
              }}
            />
          </Box>
        </Link>
      ))}
    </>
  );
};

export default CustomCategoryTab;
