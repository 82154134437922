import * as React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import SpeedDial from "@mui/material/SpeedDial";
import SpeedDialIcon from "@mui/material/SpeedDialIcon";
import SpeedDialAction from "@mui/material/SpeedDialAction";
import {
  AddBusiness,
  AddCircle,
  CalendarMonth,
  Notifications,
  Share,
} from "@mui/icons-material";
import { Link } from "react-router-dom";

const StyledSpeedDial = styled(SpeedDial)(({ theme }) => ({
  position: "absolute",
  "&.MuiSpeedDial-directionUp, &.MuiSpeedDial-directionLeft": {
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
  "&.MuiSpeedDial-directionDown, &.MuiSpeedDial-directionRight": {
    top: theme.spacing(2),
    left: theme.spacing(2),
  },
}));

const actions = [
  { icon: <AddCircle />, name: "Add Items", key: "add-new-item" },
  { icon: <Notifications />, name: "Notifications" },
  { icon: <AddBusiness />, name: "Advertise" },
  { icon: <CalendarMonth />, name: "Calendar" },
  { icon: <Share />, name: "Refer Friend" },
];

export default function QuickAccess() {
  const [direction, setDirection] = React.useState("up");
  const [hidden, setHidden] = React.useState(false);

  return (
    <Box sx={{ transform: "translateZ(0px)" }}>
      <Box
        sx={{ position: "absolute", mt: "-2rem", right: "2rem", height: 320 }}
      >
        <StyledSpeedDial
          ariaLabel="SpeedDial playground example"
          hidden={hidden}
          icon={<SpeedDialIcon />}
          direction={"down"}
        >
          {actions.map((action) => (
            <SpeedDialAction
              key={action.name}
              icon={action.icon}
              tooltipTitle={action.name}
            />
          ))}
        </StyledSpeedDial>
      </Box>
    </Box>
  );
}
