import React, { useState, useEffect } from "react";
import { Formik, useFormik } from "formik";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  resendVerification,
  reset,
} from "../../components/StateSlices/resendVerificationSlice";
import { ToastContainer, toast } from "react-toastify";
import * as Yup from "yup";
import Spinner from "../../components/Spinner/Spinner";
import { Link } from "react-router-dom";
import { Button, Grid, TextField, Typography } from "@mui/material";

const Confirmation = () => {
  let location = useLocation();
  let navigate = useNavigate();
  const [spinner, setSpinner] = useState(false);
  const dispatch = useDispatch();
  const [message, setMessage] = useState("");
  const [resend, setResend] = useState(false);

  const { status, rmessage, error } = useSelector((state) => state.resend);

  const userDetailsState = location.state;

  const toastOption = {
    position: "top-right",
    autoClose: 1500,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "dark",
  };

  useEffect(() => {
    // if (!userDetailsState || userDetailsState === null) {
    //   navigate("/login");
    // }
    let search = location.search;
    if (search) {
      let qry = new URLSearchParams(search);
      let error = qry.get("error");
      if (error === "AccountNotVerified") {
        setMessage("Account Not Verified");
      }
    }
  }, []);

  if (rmessage) {
    dispatch(reset());
    toast.success("Email Sent Successfully", toastOption);
    setSpinner(false);
    setResend(false);
  }

  if (error) {
    toast.error(error, toastOption);
    dispatch(reset());
    setSpinner(false);
  }

  return (
    <div
      className="closest_setup_container"
      style={{ position: "relative", height: "39vh", padding: "2rem 4rem" }}
    >
      {spinner ? <Spinner /> : ""}

      {/* <ToastContainer
        position="bottom-left"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      /> */}

      <h1 className="section_title mont-title">
        {resend ? "Resend Verification Email" : "Welcome to GoDizel"}
      </h1>

      {resend ? (
        <div className="closest_setup">
          {/* <p className="paragraph_title">Resend Verification Email</p> */}
          <Formik
            initialValues={{
              email: "",
            }}
            validationSchema={Yup.object().shape({
              email: Yup.string().required("Please enter your Email Address"),
            })}
            onSubmit={(values) => {
              console.log(values);
              dispatch(resendVerification(values));
              setSpinner(true);
              return false;
            }}
          >
            {(props) => {
              return (
                <form onSubmit={props.handleSubmit}>
                  <div className="closest_setup_form">
                    <Grid item xs={6}>
                      <TextField
                        error={
                          props.touched.email && Boolean(props.errors.email)
                        }
                        required
                        name="email"
                        id="email"
                        label="Email"
                        defaultValue=""
                        onBlur={props.handleBlur}
                        onChange={props.handleChange}
                        value={props.values.email}
                        helperText={props.touched.email && props.errors.email} //required in case of error msg
                        sx={{ width: "100%" }}
                      />
                    </Grid>
                    <Button
                      variant="contained"
                      style={{ marginTop: "16px" }}
                      type="submit"
                      disabled={status === "loading" ? true : false}
                    >
                      {status === "loading" ? "Please Wait" : "Resend Email"}
                    </Button>
                  </div>
                </form>
              );
            }}
          </Formik>
        </div>
      ) : (
        <>
          {message ? (
            <p className="paragraph_title" style={{ color: "red" }}>
              {message}
            </p>
          ) : (
            ""
          )}
          <p className="paragraph_title">Verify Your Email Address</p>
          <div style={{ textAlign: "center" }}>
            Please check your email and verify your account to acess all
            features of GoDizel.
          </div>
          <div>
            <p className="paragraph_title">
              Didn't Receive Verification Email ? <br /> Please check your spam
              folder or{" "}
              <Button
                style={{ color: "#F26654" }}
                onClick={() => setResend(true)}
              >
                Click Here
              </Button>{" "}
              to resend
            </p>{" "}
          </div>
        </>
      )}
    </div>
  );
};

export default Confirmation;
