import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
  notificationStatus: "idle",
  notificationError: null,
  notificationMessage:null,
  notificationInfo:null,
};

export const notificationSeen = createAsyncThunk(
  "notification/seen",
  async ({ token}, { rejectWithValue }) => {
    try {
      const { data } = await axios.get(`/api/users/notificationseen`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getNotification = createAsyncThunk(
  "notification/get",
  async ({ token, filter="all"}, { rejectWithValue }) => {
    try {
      const { data } = await axios.get(`/api/users/notificationget/${filter}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);


export const notificationSlice = createSlice({
  name: "notification",
  initialState,
  reducers: {
    resetNotification(state, action) {
      state.notificationError = null;
      state.notificationMessage = null;
      state.notificationStatus = "idle";
      state.notificationInfo = null;
    },
  },
  extraReducers: {
    [notificationSeen.pending]: (state, action) => {
      state.notificationStatus = "loading";
      state.notificationError = null;
    },
    [notificationSeen.fulfilled]: (state, action) => {
      state.notificationStatus = "succeeded";
      state.notificationMessage = action.payload.message;
      // state.profile = action.payload.user.userDetailsId.profileStatus;
      // state.userProductCount = action.payload.user.userProductsCount;
    },
    [notificationSeen.rejected]: (state, action) => {
      state.notificationStatus = "failed";
      state.notificationError = action.payload.message;
    },

    [getNotification.pending]: (state, action) => {
      state.notificationStatus = "loading";
      state.notificationError = null;
    },
    [getNotification.fulfilled]: (state, action) => {
      state.notificationStatus = "succeeded";
      state.notificationMessage = action.payload.message;
      state.notificationInfo = action.payload.notification;
      // state.profile = action.payload.user.userDetailsId.profileStatus;
      // state.userProductCount = action.payload.user.userProductsCount;
    },
    [getNotification.rejected]: (state, action) => {
      state.notificationStatus = "failed";
      state.notificationError = action.payload.message;
    },

  },
});

export const { resetNotification } = notificationSlice.actions;
export default notificationSlice.reducer;
