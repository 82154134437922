import React, { useEffect } from "react";
import "../ConstructionInfo/ConstructionInfo.css";
import "./GoDizelInfo.css";
import ConstructionIcon from "@mui/icons-material/Construction";
import EngineeringIcon from "@mui/icons-material/Engineering";
import FoundationIcon from "@mui/icons-material/Foundation";
import LocationCityIcon from "@mui/icons-material/LocationCity";
import infoBg from "../../assets/Pictures/hero_bg.png";
import aboutDizelVideo from "../../assets/about-dizel.mp4";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Paper,
  Typography,
} from "@mui/material";
import { HearingTwoTone, Margin } from "@mui/icons-material";
const GoDizelInfo = () => {
  useEffect(() => {
    const videoElement = document.getElementById("video-landing");
    if (videoElement) {
      videoElement.addEventListener("canplay", () => {
        videoElement.play();
      });
    }
  }, []);
  return (
    <section className="future-of-fashion-con" style={{ margin: "2rem auto" }}>
      <div className="info_container">
        <div className="info_text">
          <Typography
            variant="h1"
            className="mont-title"
            fontWeight={700}
            letterSpacing={2}
          >
            Dizel is the Leading Equipment Search Engine.
          </Typography>
          <Typography margin={"2rem 0rem"} fontSize={18}>
            Dizel scans hundreds of third-party equipment and brand websites and
            gathers the best pricing available in real time. Additionally, Dizel
            shows you a list of rental pricing and purchase options for every
            equipment from dozens of individual equipment operator websites,
            including other popular rental equipment platforms.
          </Typography>
          <Typography fontSize={18}>
            To find the best equipment deal available, select what type of
            equipment you want to purchase or rent, and insert your required
            dates and location.
          </Typography>
        </div>
        <div className="info_img">
          {/* <img src={infoBg} alt="Dizel Engine" /> */}
          <video
            id="video-landing"
            autoPlay
            preload={"metadata"}
            width="100%"
            height="100%"
            loop={true}
            playsInline
            muted
          >
            <source src={aboutDizelVideo}></source>
          </video>
        </div>
      </div>

      <div className="repeat-icon">{/* <img src={repeatImg} /> */}</div>
    </section>
  );
};

export default GoDizelInfo;
