import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
  makeoptionsstatus: "idle",
  makeoptions: {},
  makeoptionserror: null,
};

export const getMakeFieldOptions = createAsyncThunk(
  "product/getMakeFieldOptions",
  async ({ token }, { rejectWithValue }) => {
    try {
      const { data } = await axios.get(`/api/product/getmakefieldoptions`);
      return data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);
export const getMakeFieldOptionsSlice = createSlice({
  name: "getmakefieldoptions",
  initialState,
  reducers: {
    resetmakefieldoptions(state, action) {
      state.makeoptions = {};
      state.makeoptionsstatus = "idle";
    },
  },
  extraReducers: {
    [getMakeFieldOptions.pending]: (state, action) => {
      state.makeoptionsstatus = "loading";
    },
    [getMakeFieldOptions.fulfilled]: (state, action) => {
      state.makeoptionsstatus = "succeeded";
      state.makeoptions = action.payload.makeFieldOptions;
    },
    [getMakeFieldOptions.rejected]: (state, action) => {
      state.makeoptionsstatus = "failed";
      state.makeoptionserror = action.payload.message;
    },
  },
});

export const { resetmakefieldoptions } = getMakeFieldOptionsSlice.actions;
export default getMakeFieldOptionsSlice.reducer;
