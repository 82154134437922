import React from "react";
import "./ToggleSidebar.css";
import { RxHamburgerMenu } from "react-icons/rx";
const ToggleSidebar = (props) => {
  return (
    <div className="toggle-sidebar">
      <button onClick={props.onToggle}>
        <RxHamburgerMenu size={"1.8em"} />
      </button>
    </div>
  );
};

export default ToggleSidebar;
