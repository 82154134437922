import React, { useState, useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Spinner from "../components/Spinner/Spinner";
import { verifyAccount, reset } from "../components/StateSlices/verifySlice";
import { toast } from "react-toastify";

const Verify = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const { id } = useParams();
  const [spinner, setSpinner] = useState(true);
  const { status, info, message, error } = useSelector((state) => state.verify);
  const [requested, setRequested] = useState(false);

  let toastOption = {
    position: "top-right",
    autoClose: 1500,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "dark",
  };

  useEffect(() => {
    setRequested(true);
    if (status === "idle") dispatch(verifyAccount({ id: id }));
    console.log(status);
    // if(productInfo !== {}){
    //     productInfo.productImage.map((val)=>{
    //         setImages(prevState => {
    //             return [...prevState,{original:val,thumbnail:val}]
    //         })
    //     })
    // }
  }, []);

  useEffect(() => {
    if (message) {
      console.log(message);

      if (message === "success") {
        if (info && info.verified !== true) {
          toast.success("Account Verified Succesfully", toastOption);
        } else {
          toast.error("User Already Verified", toastOption);
        }
      } else {
        toast.error("Something Went Wrong", toastOption);
      }

      console.log(message);
      if (message === "success") {
        console.log("HERE");
        dispatch(reset());
        setTimeout(() => {
          navigate("/setup-profile");
        }, 5000);
        return;
      } else {
        dispatch(reset());
        setTimeout(() => {
          navigate("/signup");
        }, 5000);
      }
    }
  }, [message]);

  useEffect(() => {
    if (error) {
      toast.error("Verification Link Expired or Invalid", toastOption);
      dispatch(reset());
      navigate("/verify-email?error=AccountNotVerified", { state: "Verified" });
    }
  }, [error]);

  return (
    <div style={{ height: "43vh" }}>
      <Spinner />
    </div>
  );
};

export default Verify;
