import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import "./Marquee.css";
import b1 from "../../assets/brands/1.png";
import b2 from "../../assets/brands/2.png";
import b3 from "../../assets/brands/3.png";
import b4 from "../../assets/brands/4.png";
import b5 from "../../assets/brands/5.png";
import b6 from "../../assets/brands/6.svg";
import b7 from "../../assets/brands/7.jpg";
import b8 from "../../assets/brands/8.png";
import b9 from "../../assets/brands/9.png";
import b10 from "../../assets/brands/10.png";
import { Typography } from "@mui/material";
const Marquee = () => {
  const brands = [
    { brandName: "Brand 1", logo: b1 },
    { brandName: "Brand 2", logo: b2 },
    { brandName: "Brand 3", logo: b3 },
    { brandName: "Brand 4", logo: b4 },
    { brandName: "Brand 5", logo: b5 },
    { brandName: "Brand 6", logo: b6 },
    { brandName: "Brand 7", logo: b7 },
    { brandName: "Brand 8", logo: b8 },
    { brandName: "Brand 9", logo: b9 },
    { brandName: "Brand 10", logo: b10 },
  ];

  return (
    // <div class="Marquee">
    //   <div class="Marquee-content">
    //     {brandImages.map((category, index) => (
    //       <Link to={"/shop"} key={index}>
    //         <div class="Marquee-tag">
    //           <img style={{ width: "100%" }} src={brandImages[index]} />
    //         </div>
    //       </Link>
    //     ))}
    //   </div>
    // </div>
    <>
      <section style={{ margin: "4rem auto" }}>
        <Typography
          variant="h1"
          className="mont-title"
          textAlign={"center"}
          fontWeight={700}
        >
          Brands to Choose
        </Typography>
        <Typography
          textAlign={"center"}
          variant="h4"
          color={"text.secondary"}
          sx={{ margin: ".5rem auto" }}
        >
          Experience best equipment around the world
        </Typography>
        <div className="marquee__con">
          <div class="marquee">
            <div class="marquee__group">
              {brands.map((category, index) => (
                <Link
                  to={`/shop`}
                  state={{ brandName: category.brandName }}
                  key={index}
                >
                  <div class="Marquee-tag">
                    <img style={{ width: "100%" }} src={category.logo} />
                  </div>
                </Link>
              ))}
            </div>

            <div aria-hidden="true" class="marquee__group">
              {brands.map((category, index) => (
                <Link
                  to={`/shop`}
                  state={{ brandName: category.brandName }}
                  key={index}
                >
                  <div class="Marquee-tag">
                    <img style={{ width: "100%" }} src={category.logo} />
                  </div>
                </Link>
              ))}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Marquee;
