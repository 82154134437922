import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
  cstatus: "idle",
  cproducts: null,
  error: null,
};

export const fetchProducts = createAsyncThunk(
  "cart/checkoutProducts",
  async ({ token, userId }, { rejectWithValue }) => {
    try {
      const { data } = await axios.get(`/api/product/getCheckoutProducts`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);
export const getCheckoutProductsSlice = createSlice({
  name: "checkoutproducts",
  initialState,
  reducers: {
    // resetCartMessage(state, action) {
    //   (state.cproducts = null), (state.cstatus = "idle");
    // },
  },
  extraReducers: {
    [fetchProducts.pending]: (state, action) => {
      state.cstatus = "loading";
    },
    [fetchProducts.fulfilled]: (state, action) => {
      state.cstatus = "succeeded";
      state.cproducts = action.payload.products;
    },
    [fetchProducts.rejected]: (state, action) => {
      state.cstatus = "failed";
      state.error = action.payload.message;
    },
  },
});

export const { resetCartMessage } = getCheckoutProductsSlice.actions;
export default getCheckoutProductsSlice.reducer;
