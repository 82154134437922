import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import axios from "axios"

const initialState = {
  cmsStatus: "idle",
  cmsInfo: null,
  cmsError: null,
  cmsFetchStatus:"idle",
  cmsMessage:null,
}

export const addCMSContent = createAsyncThunk(
  "cms/addCMSContent",
  async ({token,values}, { rejectWithValue }) => {
    try {
      const { data } = await axios.post(
        "/api/cms/add",values,
        {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        }
      )
      return data
    } catch (err) {
      return rejectWithValue(err.response.data)
    }
  }
)

export const updateCMSContent = createAsyncThunk(
    "cms/updateCMSContent",
    async ({token,values,id}, { rejectWithValue }) => {
      try {
        const { data } = await axios.post(
          `/api/cms/update/${id}`,values,
          {
              headers: {
                  Authorization: `Bearer ${token}`,
              },
          }
        )
        return data
      } catch (err) {
        return rejectWithValue(err.response.data)
      }
    }
  )

export const getAllCMSContent = createAsyncThunk(
    "cms/getAllCMSContent",
    async ({token}, { rejectWithValue }) => {
      try {
        const { data } = await axios.get(
          "/api/cms/getall",
          {
              headers: {
                  Authorization: `Bearer ${token}`,
              },
          }
        )
        return data
      } catch (err) {
        return rejectWithValue(err.response.data)
      }
    }
  )


  export const deleteCMSContent = createAsyncThunk(
    "cms/deleteCMSContent",
    async ({token,id}, { rejectWithValue }) => {
      try {
        const { data } = await axios.delete(
          `/api/cms/delete/${id}`,
          {
              headers: {
                  Authorization: `Bearer ${token}`,
              },
          }
        )
        return data
      } catch (err) {
        return rejectWithValue(err.response.data)
      }
    }
  )

  export const getCMSContent = createAsyncThunk(
    "cms/getCMSContent",
    async ({token,id}, { rejectWithValue }) => {
      try {
        const { data } = await axios.get(
          `/api/cms/get/${id}`,
          {
              headers: {
                  Authorization: `Bearer ${token}`,
              },
          }
        )
        return data
      } catch (err) {
        return rejectWithValue(err.response.data)
      }
    }
  )

export const cmsSlice = createSlice({
  name: "cms",
  initialState,
  reducers: {
    resetCMS(state,action){
      state.cmsInfo = null
      state.cmsStatus="idle"
      state.cmsError= null
      state.cmsMessage=null
      state.cmsFetchStatus = null
    }
  },
  extraReducers: {
    [addCMSContent.pending]: (state, action) => {
      state.cmsStatus = "loading"
    },
    [addCMSContent.fulfilled]: (state, action) => {
      state.cmsStatus = "succeeded"
      state.cmsInfo = action.payload.message
    },
    [addCMSContent.rejected]: (state, action) => {
      state.cmsStatus = "failed";
      state.cmsError = action.payload
    },
    [getAllCMSContent.pending]: (state, action) => {
      state.cmsFetchStatus = "loading"
    },
    [getAllCMSContent.fulfilled]: (state, action) => {
      state.cmsFetchStatus = "succeeded"
      state.cmsInfo = action.payload.cmsList
    },
    [getAllCMSContent.rejected]: (state, action) => {
      state.cmsFetchStatus = "failed";
      state.cmsError = action.payload
    },
    [deleteCMSContent.pending]: (state, action) => {
      state.cmsStatus = "loading"
    },
    [deleteCMSContent.fulfilled]: (state, action) => {
      state.cmsStatus = "succeeded"
      state.cmsMessage = action.payload.message
    },
    [deleteCMSContent.rejected]: (state, action) => {
      state.cmsStatus = "failed";
      state.cmsError = action.payload
    },
    [getCMSContent.pending]: (state, action) => {
      state.cmsStatus = "loading"
    },
    [getCMSContent.fulfilled]: (state, action) => {
      state.cmsStatus = "succeeded"
      state.cmsInfo = action.payload.cmsInfo
    },
    [getCMSContent.rejected]: (state, action) => {
      state.cmsStatus = "failed";
      state.cmsError = action.payload
    },
    [updateCMSContent.pending]: (state, action) => {
      state.cmsStatus = "loading"
    },
    [updateCMSContent.fulfilled]: (state, action) => {
      state.cmsStatus = "succeeded"
      state.cmsMessage = action.payload.message
    },
    [updateCMSContent.rejected]: (state, action) => {
      state.cmsStatus = "failed";
      state.cmsError = action.payload
    },
  },
})

export const { resetCMS } = cmsSlice.actions
export default cmsSlice.reducer 