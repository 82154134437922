import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
  userLikesStatus: "idle",
  userLikesMessage: "",
  userLikes: null,
  userLikesError: null,
};

export const getUserLikes = createAsyncThunk(
  "like/getUserLikes",
  async ({ token }, { rejectWithValue }) => {
    try {
      const { data } = await axios.get(`/api/product/getUserLikes`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);
export const getUserLikesSlice = createSlice({
  name: "userlikes",
  initialState,
  reducers: {
    resetuserLikesMessage(state, action) {
      state.userLikesMessage = "";
      state.userLikesError = null;
      state.userLikes = null;
      state.userLikesStatus = "idle";
    },
  },
  extraReducers: {
    [getUserLikes.pending]: (state, action) => {
      state.userLikesStatus = "loading";
    },
    [getUserLikes.fulfilled]: (state, action) => {
      state.userLikesStatus = "succeeded";
      state.userLikes = action.payload.userLikes;
      state.userLikesMessage = action.payload.message;
    },
    [getUserLikes.rejected]: (state, action) => {
      state.userLikesStatus = "failed";
      state.userLikesError = action.payload.message;
    },
  },
});

export const { resetuserLikesMessage } = getUserLikesSlice.actions;
export default getUserLikesSlice.reducer;
