import React from "react";
import spinner from "../../assets/Pictures/spinner.gif";
import "./Spinner.css";
import { CircularProgress } from "@mui/material";
import { LoadingButton } from "@mui/lab";

const Spinner = ({
  pos,
  spinnerColor = null,
  spinnerBgTranparent = null,
  height = null,
}) => {
  return (
    <div
      className="spinner_container"
      data-aos="fade-out"
      data-aos-duration="250"
      style={{
        minHeight: height ? 0 : "calc(100% - 60px)",
        height: height ? height : "100vh",
        position: pos ? pos : "relative",
        background: "#fff",
        width: "100%",
        marginTop: ".5rem",
      }}
    >
      {/* <img src={spinner} alt="spinner"/> */}
      <CircularProgress
        sx={{
          // display: "flex",
          // justifyContent: "center",
          // margin: "auto",
          color: spinnerColor ? spinnerColor : "var(--secondary-color)",
        }}
      />
    </div>
  );
};

export default Spinner;
