import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import axios from "axios"

const initialState = {
  membershipHistoryStatus: "idle",
  membershipHistoryInfo: null,
  membershipHistoryError: null
}

export const getmembershipHistory = createAsyncThunk(
  "user/getmembershipHistory",
  async (token, { rejectWithValue }) => {
    try {
      const { data } = await axios.get(
        "/api/users/membershiphistory",
        {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        }
      )
      return data
    } catch (err) {
      return rejectWithValue(err.response.data)
    }
  }
)
export const membershiphistorySlice = createSlice({
  name: "membershiphistory",
  initialState,
  reducers: {
    
  },
  extraReducers: {
    [getmembershipHistory.pending]: (state, action) => {
      state.membershipHistoryStatus = "loading"
    },
    [getmembershipHistory.fulfilled]: (state, action) => {
      state.membershipHistoryStatus = "succeeded"
      state.membershipHistoryInfo = action.payload.membershipHistory.membershipHistory.reverse()
    },
    [getmembershipHistory.rejected]: (state, action) => {
      state.membershipHistoryStatus = "failed"
      state.membershipHistoryError = action.payload.message
    },
  },
})

// export const { reset } = membershiphistorySlice.actions
export default membershiphistorySlice.reducer 