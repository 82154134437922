import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import axios from "axios"

const initialState = {
  cardUpgradeStatus: "idle",
  cardUpgradeInfo: null,
  cardUpgradeError: null,
}
export const cardUpgrade = createAsyncThunk(
  "card/upgrade",
  async (payload, { rejectWithValue }) => {
    try {
      const { data } = await axios.post(
        "/api/users/cardupgrade",
        payload
      )
      return data
    } catch (err) {
      return rejectWithValue(err.response.data)
    }
  }
)
export const cardUpgradeSlice = createSlice({
  name: "card",
  initialState,
  reducers: {
    cardUpgradeReset(state,action){
      state.cardUpgradeError=null
      state.cardUpgradeInfo=null
    }
  },
  extraReducers: {
    [cardUpgrade.pending]: (state, action) => {
      state.cardUpgradeStatus = "loading"
    },
    [cardUpgrade.fulfilled]: (state, action) => {
      state.cardUpgradeStatus = "succeeded"
      state.cardUpgradeInfo = action.payload.message
    },
    [cardUpgrade.rejected]: (state, action) => {
      state.cardUpgradeStatus = "failed"
      state.cardUpgradeError = action.payload.message || "Something Went Wrong. Try Again Later!!"
    },
  },
})

export const { cardUpgradeReset } = cardUpgradeSlice.actions

export default cardUpgradeSlice.reducer 