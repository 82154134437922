import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
  allUserStatus: "idle",
  allUsersInfo: null,
  allUserError: null,
  updateUserStatus: "idle",
  totalCount: 0,
  allProductsInfo: null,
  allOrdersInfo: null,
  allBrandInfo:null,
  brandErrorDisplay:null,
  allCategoryInfo:null,
  allSizeInfo:null,
  allReferInfo:null,
  allReferStatus:"idle",
  allReferError:null,
};

export const referData = createAsyncThunk(
  "admin/refer",
  async ({ token}, { rejectWithValue }) => {
    
    try {
      const { data } = await axios.get(
        `/api/admin/refer`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getUsers = createAsyncThunk(
  "admin/getUsers",
  async ({ token, p = 0, search, status = "active" }, { rejectWithValue }) => {
    let searchString = "";
    if (search) {
      searchString = "&search=" + encodeURIComponent(search);
    }
    try {
      const { data } = await axios.get(
        `/api/admin/getusers?page=${p}${searchString}&status=${status}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const updateUser = createAsyncThunk(
  "admin/updateUser",
  async (
    { token, id, payload, p, search },
    { rejectWithValue, thunkAPI, dispatch }
  ) => {
    try {
      const { data } = await axios.post(
        `/api/admin/updateuser/${id}`,
        { payload },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      await dispatch(getUsers({ token, p, search }));
      return data;
    } catch (err) {
      console.log(err);
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteUser = createAsyncThunk(
  "admin/deleteUser",
  async ({ token, id, p, search }, { rejectWithValue, thunkAPI, dispatch }) => {
    try {
      const { data } = await axios.delete(`/api/admin/deleteuser/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      await dispatch(getUsers({ token, p, search }));
      return data;
    } catch (err) {
      console.log(err);
      return rejectWithValue(err.response.data);
    }
  }
);

export const cancelSubscription = createAsyncThunk(
  "admin/cancelSubscription",
  async ({ token, id, p, search }, { rejectWithValue, thunkAPI, dispatch }) => {
    try {
      const { data } = await axios.delete(
        `/api/admin/cancelsubscription/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      await dispatch(getUsers({ token, p, search }));
      return data;
    } catch (err) {
      console.log(err);
      return rejectWithValue(err.response.data);
    }
  }
);

export const pauseSubscription = createAsyncThunk(
  "admin/pauseSubscription",
  async (
    { token, id, p, search, month },
    { rejectWithValue, thunkAPI, dispatch }
  ) => {
    try {
      const { data } = await axios.post(
        `/api/admin/pausesubscription/${id}`,
        {
          month,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      await dispatch(getUsers({ token, p, search }));
      return data;
    } catch (err) {
      console.log(err);
      return rejectWithValue(err.response.data);
    }
  }
);

export const unbanUser = createAsyncThunk(
  "admin/unbanUser",
  async (
    { token, id, p, search, status = "active" },
    { rejectWithValue, thunkAPI, dispatch }
  ) => {
    try {
      const { data } = await axios.post(
        `/api/admin/unbanuser/${id}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      await dispatch(getUsers({ token, p, search, status }));
      return data;
    } catch (err) {
      console.log(err);
      return rejectWithValue(err.response.data);
    }
  }
);

export const getProducts = createAsyncThunk(
  "admin/getProducts",
  async ({ token, p = 0, search, status = false, sort,report }, { rejectWithValue }) => {
    let searchString = "";
    if (search) {
      searchString = "&search=" + search;
    }
    try {
      const { data } = await axios.get(
        `/api/admin/getproducts?page=${p}${searchString}&status=${status}&sort=${sort}&report=${report}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteProduct = createAsyncThunk(
  "admin/deleteProduct",
  async (
    { token, id, p, search, reason, status = false, sort,report },
    { rejectWithValue, thunkAPI, dispatch }
  ) => {
    let reasonString = "";
    console.log(reason);
    if (reason) {
      reasonString = "?reason=" + reason;
    }
    try {
      const { data } = await axios.delete(
        `/api/admin/deleteproduct/${id}${reasonString}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      await dispatch(getProducts({ token, p, search, status, sort,report }));
      return data;
    } catch (err) {
      console.log(err);
      return rejectWithValue(err.response.data);
    }
  }
);

export const unbanProduct = createAsyncThunk(
  "admin/unbanProduct",
  async (
    { token, id, p, search, status = false, sort,report },
    { rejectWithValue, thunkAPI, dispatch }
  ) => {
    try {
      const { data } = await axios.post(
        `/api/admin/unbanproduct/${id}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      await dispatch(getProducts({ token, p, search, status, sort,report }));
      return data;
    } catch (err) {
      console.log(err);
      return rejectWithValue(err.response.data);
    }
  }
);

export const getCheckoutOrders = createAsyncThunk(
  "admin/getCheckoutOrders",
  async ({ token, p = 0, search }, { rejectWithValue }) => {
    let searchString = "";
    if (search) {
      searchString = "&search=" + search;
    }
    try {
      const { data } = await axios.get(
        `/api/admin/getcheckoutorders?page=${p}${searchString}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getBrands = createAsyncThunk(
  "admin/getBrands",
  async ({ token, p = 0, search}, { rejectWithValue }) => {
    let searchString = "";
    if (search) {
      searchString = "&search=" + encodeURIComponent(search);
    }
    try {
      const { data } = await axios.get(
        `/api/admin/getbrands?page=${p}${searchString}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const updateBrand = createAsyncThunk(
  "admin/updateBrand",
  async (
    { token, id, payload, p, search },
    { rejectWithValue, thunkAPI, dispatch }
  ) => {
    try {
      const { data } = await axios.post(
        `/api/admin/updatebrand/${id}`,
        { payload },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      await dispatch(getBrands({ token, p, search }));
      return data;
    } catch (err) {
      console.log(err);
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteBrand = createAsyncThunk(
  "admin/deleteBrand",
  async (
    { token, id, p, search },
    { rejectWithValue, thunkAPI, dispatch }
  ) => {
    try {
      const { data } = await axios.delete(
        `/api/admin/deletebrand/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      await dispatch(getBrands({ token, p, search }));
      return data;
    } catch (err) {
      console.log(err);
      return rejectWithValue(err.response.data);
    }
  }
);

export const addBrand = createAsyncThunk(
  "admin/addBrand",
  async (
    { token,payload, p, search },
    { rejectWithValue, thunkAPI, dispatch }
  ) => {
    try {
      const { data } = await axios.post(
        `/api/admin/addbrand`,
        {brandName:payload.brandName},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      await dispatch(getBrands({ token, p, search }));
      return data;
    } catch (err) {
      console.log(err);
      return rejectWithValue(err.response.data);
    }
  }
);

export const getCategory = createAsyncThunk(
  "admin/getCategory",
  async ({ token, p = 0, search}, { rejectWithValue }) => {
    let searchString = "";
    if (search) {
      searchString = "&search=" + encodeURIComponent(search);
    }
    try {
      const { data } = await axios.get(
        `/api/admin/getcategory?page=${p}${searchString}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const updateCategory = createAsyncThunk(
  "admin/updateCategory",
  async (
    { token, id, payload, p, search },
    { rejectWithValue, thunkAPI, dispatch }
  ) => {
    try {
      const { data } = await axios.post(
        `/api/admin/updatecategory/${id}`,
        { payload },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      await dispatch(getCategory({ token, p, search }));
      return data;
    } catch (err) {
      console.log(err);
      return rejectWithValue(err.response.data);
    }
  }
);

export const addCategory = createAsyncThunk(
  "admin/addCategory",
  async (
    { token,payload, p, search },
    { rejectWithValue, thunkAPI, dispatch }
  ) => {
    try {
      const { data } = await axios.post(
        `/api/admin/addcategory`,
        {payload},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      await dispatch(getCategory({ token, p, search }));
      return data;
    } catch (err) {
      console.log(err);
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteCategory = createAsyncThunk(
  "admin/deleteCategory",
  async (
    { token, id, p, search },
    { rejectWithValue, thunkAPI, dispatch }
  ) => {
    try {
      const { data } = await axios.delete(
        `/api/admin/deletecategory/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      await dispatch(getCategory({ token, p, search }));
      return data;
    } catch (err) {
      console.log(err);
      return rejectWithValue(err.response.data);
    }
  }
);

export const getSizes = createAsyncThunk(
  "admin/getSizes",
  async ({ token, p = 0, search}, { rejectWithValue }) => {
    let searchString = "";
    if (search) {
      searchString = "&search=" + encodeURIComponent(search);
    }
    try {
      const { data } = await axios.get(
        `/api/admin/getsizes?page=${p}${searchString}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const updateSize = createAsyncThunk(
  "admin/updateSize",
  async (
    { token, id, payload, p, search },
    { rejectWithValue, thunkAPI, dispatch }
  ) => {
    try {
      const { data } = await axios.post(
        `/api/admin/updatesize/${id}`,
        { payload },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      await dispatch(getSizes({ token, p, search }));
      return data;
    } catch (err) {
      console.log(err);
      return rejectWithValue(err.response.data);
    }
  }
);

export const addSize = createAsyncThunk(
  "admin/addSize",
  async (
    { token,payload, p, search },
    { rejectWithValue, thunkAPI, dispatch }
  ) => {
    try {
      const { data } = await axios.post(
        `/api/admin/addsize`,
        {payload},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      await dispatch(getSizes({ token, p, search }));
      return data;
    } catch (err) {
      console.log(err);
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteSize = createAsyncThunk(
  "admin/deleteSize",
  async (
    { token, id, p, search },
    { rejectWithValue, thunkAPI, dispatch }
  ) => {
    try {
      const { data } = await axios.delete(
        `/api/admin/deletesize/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      await dispatch(getSizes({ token, p, search }));
      return data;
    } catch (err) {
      console.log(err);
      return rejectWithValue(err.response.data);
    }
  }
);

export const allUsersInfoSlice = createSlice({
  name: "admin",
  initialState,
  reducers: {
    resetusersInfo(state, action) {
      state.allUserError = null;
      state.allUsersInfo = null;
    },
    resetUpdateStatus(state, action) {
      state.updateUserStatus = null;
      state.allUserError = null;
    },
    resetBrandError(state,action){
      state.brandErrorDisplay = null
      state.allBrandMessage = null
    }
  },
  extraReducers: {
    [referData.pending]: (state, action) => {
      state.allReferStatus = "loading";
    },
    [referData.fulfilled]: (state, action) => {
      state.allReferStatus = "succeeded";
      state.allReferInfo = action.payload.referalData;
      // state.totalCount = action.payload.totalCount;
    },
    [referData.rejected]: (state, action) => {
      state.allReferStatus = "failed";
      state.allReferError = action.payload.message;
    },

    [getUsers.pending]: (state, action) => {
      state.allUserStatus = "loading";
    },
    [getUsers.fulfilled]: (state, action) => {
      state.allUserStatus = "succeeded";
      state.allUsersInfo = action.payload.usersInfo;
      state.totalCount = action.payload.totalCount;
    },
    [getUsers.rejected]: (state, action) => {
      state.allUserStatus = "failed";
      state.allUserError = action.payload.message;
    },
    [updateUser.pending]: (state, action) => {
      state.allUserStatus = "loading";
    },
    [updateUser.fulfilled]: (state, action) => {
      state.updateUserStatus = "succeeded";
      // after this I want to run getUsers actio
    },
    [updateUser.rejected]: (state, action) => {
      state.allUserStatus = "failed";
      state.allUserError = action.payload
        ? action.payload.message
        : "Something Went Wrong";
    },
    [deleteUser.pending]: (state, action) => {
      state.allUserStatus = "loading";
    },
    [deleteUser.fulfilled]: (state, action) => {
      state.updateUserStatus = "succeeded";
      // after this I want to run getUsers actio
    },
    [deleteUser.rejected]: (state, action) => {
      state.updateUserStatus = "failed";
      state.allUserStatus = "failed";
      state.allUserError = action.payload
        ? action.payload.stack
        : "Something Went Wrong";
    },
    [cancelSubscription.pending]: (state, action) => {
      state.allUserStatus = "loading";
    },
    [cancelSubscription.fulfilled]: (state, action) => {
      state.updateUserStatus = "succeeded";
      // after this I want to run getUsers actio
    },
    [cancelSubscription.rejected]: (state, action) => {
      state.updateUserStatus = "failed";
      state.allUserStatus = "failed";
      state.allUserError = action.payload
        ? action.payload.stack
        : "Something Went Wrong";
    },
    [pauseSubscription.pending]: (state, action) => {
      state.allUserStatus = "loading";
    },
    [pauseSubscription.fulfilled]: (state, action) => {
      state.updateUserStatus = "succeeded";
      // after this I want to run getUsers actio
    },
    [pauseSubscription.rejected]: (state, action) => {
      state.allUserStatus = "failed";
      state.allUserError = action.payload
        ? action.payload.message
        : "Something Went Wrong";
    },
    [unbanUser.pending]: (state, action) => {
      state.allUserStatus = "loading";
    },
    [unbanUser.fulfilled]: (state, action) => {
      state.updateUserStatus = "succeeded";
      // after this I want to run getUsers actio
    },
    [unbanUser.rejected]: (state, action) => {
      state.allUserStatus = "failed";
      state.allUserError = action.payload
        ? action.payload.message
        : "Something Went Wrong";
    },
    [getProducts.pending]: (state, action) => {
      state.allUserStatus = "loading";
    },
    [getProducts.fulfilled]: (state, action) => {
      state.allUserStatus = "succeeded";
      state.allProductsInfo = action.payload.productsInfo;
      state.totalCount = action.payload.totalCount;
    },
    [getProducts.rejected]: (state, action) => {
      state.allUserStatus = "failed";
      state.allUserError = action.payload
        ? action.payload.message
        : "Something Went Wrong";
    },
    [deleteProduct.pending]: (state, action) => {
      state.allUserStatus = "loading";
    },
    [deleteProduct.fulfilled]: (state, action) => {
      state.updateUserStatus = "succeeded";
      // after this I want to run getUsers actio
    },
    [deleteProduct.rejected]: (state, action) => {
      state.allUserStatus = "failed";
      state.allUserError = action.payload
        ? action.payload.message
        : "Something Went Wrong";
    },
    [unbanProduct.pending]: (state, action) => {
      state.allUserStatus = "loading";
    },
    [unbanProduct.fulfilled]: (state, action) => {
      state.updateUserStatus = "succeeded";
      // after this I want to run getUsers actio
    },
    [unbanProduct.rejected]: (state, action) => {
      state.allUserStatus = "failed";
      state.allUserError = action.payload
        ? action.payload.message
        : "Something Went Wrong";
    },
    [getCheckoutOrders.pending]: (state, action) => {
      state.allOrdersStatus = "loading";
    },
    [getCheckoutOrders.fulfilled]: (state, action) => {
      state.allOrdersStatus = "succeeded";
      state.allOrdersInfo = action.payload.orders;
      state.totalCount = action.payload.totalCount;
    },
    [getCheckoutOrders.rejected]: (state, action) => {
      state.allOrdersStatus = "failed";
      state.allOrdersError = action.payload.message;
    },
    [getBrands.pending]: (state, action) => {
      state.allUserStatus = "loading";
    },
    [getBrands.fulfilled]: (state, action) => {
      state.allUserStatus = "succeeded";
      state.allBrandInfo = action.payload.brandInfo;
      state.totalCount = action.payload.totalCount;
    },
    [getBrands.rejected]: (state, action) => {
      state.allUserStatus = "failed";
      state.brandErrorDisplay = action.payload.message;
    },
    [updateBrand.pending]: (state, action) => {
      state.allUserStatus = "loading";
    },
    [updateBrand.fulfilled]: (state, action) => {
      state.updateUserStatus = "succeeded";
      state.allBrandMessage = action.payload.message;
      state.totalCount = action.payload.totalCount;
    },
    [updateBrand.rejected]: (state, action) => {
      state.allUserStatus = "failed";
      state.brandErrorDisplay = action.payload.message;
    },
    [deleteBrand.pending]: (state, action) => {
      state.allUserStatus = "loading";
    },
    [deleteBrand.fulfilled]: (state, action) => {
      state.updateUserStatus = "succeeded";
      state.allBrandMessage = action.payload.message;
      state.totalCount = action.payload.totalCount;
    },
    [deleteBrand.rejected]: (state, action) => {
      state.allUserStatus = "failed";
      state.brandErrorDisplay = action.payload.message;
    },
    [addBrand.pending]: (state, action) => {
      state.allUserStatus = "loading";
    },
    [addBrand.fulfilled]: (state, action) => {
      state.updateUserStatus = "succeeded";
      state.allBrandMessage = action.payload.message;
      state.totalCount = action.payload.totalCount;
    },
    [addBrand.rejected]: (state, action) => {
      state.allUserStatus = "failed";
      state.brandErrorDisplay = action.payload.message;
    },
    [getCategory.pending]: (state, action) => {
      state.allUserStatus = "loading";
    },
    [getCategory.fulfilled]: (state, action) => {
      state.allUserStatus = "succeeded";
      state.allCategoryInfo = action.payload.categoryInfo;
      state.totalCount = action.payload.totalCount;
    },
    [getCategory.rejected]: (state, action) => {
      state.allUserStatus = "failed";
      state.brandErrorDisplay = action.payload.message;
    },
    [updateCategory.pending]: (state, action) => {
      state.allUserStatus = "loading";
    },
    [updateCategory.fulfilled]: (state, action) => {
      state.updateUserStatus = "succeeded";
      state.allBrandMessage = action.payload.message;
      state.totalCount = action.payload.totalCount;
    },
    [updateCategory.rejected]: (state, action) => {
      state.allUserStatus = "failed";
      state.brandErrorDisplay = action.payload.message;
    },
    [deleteCategory.pending]: (state, action) => {
      state.allUserStatus = "loading";
    },
    [deleteCategory.fulfilled]: (state, action) => {
      state.updateUserStatus = "succeeded";
      state.allBrandMessage = action.payload.message;
      state.totalCount = action.payload.totalCount;
    },
    [deleteCategory.rejected]: (state, action) => {
      state.allUserStatus = "failed";
      state.brandErrorDisplay = action.payload.message;
    },
    [addCategory.pending]: (state, action) => {
      state.allUserStatus = "loading";
    },
    [addCategory.fulfilled]: (state, action) => {
      state.updateUserStatus = "succeeded";
      state.allBrandMessage = action.payload.message;
      state.totalCount = action.payload.totalCount;
    },
    [addCategory.rejected]: (state, action) => {
      state.allUserStatus = "failed";
      state.brandErrorDisplay = action.payload.message;
    },
    [getSizes.pending]: (state, action) => {
      state.allUserStatus = "loading";
    },
    [getSizes.fulfilled]: (state, action) => {
      state.allUserStatus = "succeeded";
      state.allSizeInfo = action.payload.sizeInfo;
      state.totalCount = action.payload.totalCount;
    },
    [getSizes.rejected]: (state, action) => {
      state.allUserStatus = "failed";
      state.brandErrorDisplay = action.payload.message;
    },
    [updateSize.pending]: (state, action) => {
      state.allUserStatus = "loading";
    },
    [updateSize.fulfilled]: (state, action) => {
      state.updateUserStatus = "succeeded";
      state.allBrandMessage = action.payload.message;
    },
    [updateSize.rejected]: (state, action) => {
      state.allUserStatus = "failed";
      state.brandErrorDisplay = action.payload.message;
    },
    [deleteSize.pending]: (state, action) => {
      state.allUserStatus = "loading";
    },
    [deleteSize.fulfilled]: (state, action) => {
      state.updateUserStatus = "succeeded";
      state.allBrandMessage = action.payload.message;
      state.totalCount = action.payload.totalCount;
    },
    [deleteSize.rejected]: (state, action) => {
      state.allUserStatus = "failed";
      state.brandErrorDisplay = action.payload.message;
    },
    [addSize.pending]: (state, action) => {
      state.allUserStatus = "loading";
    },
    [addSize.fulfilled]: (state, action) => {
      state.updateUserStatus = "succeeded";
      state.allBrandMessage = action.payload.message;
      state.totalCount = action.payload.totalCount;
    },
    [addSize.rejected]: (state, action) => {
      state.allUserStatus = "failed";
      state.brandErrorDisplay = action.payload.message;
    },
  },
});

export const { resetusersInfo, resetUpdateStatus,resetBrandError } = allUsersInfoSlice.actions;
export default allUsersInfoSlice.reducer;
