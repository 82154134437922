import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
  reportStatus: "idle",
  reportInfo: null,
  reportError: null,
};

export const productreport = createAsyncThunk(
  "product/report",
  async ({ id, token }, { rejectWithValue }) => {
    try {
      const { data } = await axios.get(`/api/product/report/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);
export const productreportslice = createSlice({
  name: "productReport",
  initialState,
  reducers: {
    resetReport(state, action) {
      state.reportInfo = null;
      state.reportStatus = "idle";
      state.reportError = null;
    },
  },
  extraReducers: {
    [productreport.pending]: (state, action) => {
      state.reportStatus = "loading";
    },
    [productreport.fulfilled]: (state, action) => {
      state.reportStatus = "succeeded";
      state.reportInfo = action.payload.message;
    },
    [productreport.rejected]: (state, action) => {
      state.reportStatus = "failed";
      state.reportError = action.payload.message;
    },
  },
});

export const { resetReport } = productreportslice.actions;
export default productreportslice.reducer;
