import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import axios from "axios"

const initialState = {
  referStatus: "idle",
  referInfo: null,
  referError: null,
}

export const referLink = createAsyncThunk(
  "refer/referLink",
  async ({token,values}, { rejectWithValue }) => {
    try {
      const { data } = await axios.get(
        "/api/users/referlink",
        {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        }
      )
      return data
    } catch (err) {
      return rejectWithValue(err.response.data)
    }
  }
)
export const referLinkSlice = createSlice({
  name: "closet",
  initialState,
  reducers: {
    resetItem(state,action){
      state.referInfo = null
      state.referStatus="idle"
      state.referError= null
    }
  },
  extraReducers: {
    [referLink.pending]: (state, action) => {
      state.referStatus = "loading"
    },
    [referLink.fulfilled]: (state, action) => {
      state.referStatus = "succeeded"
      state.referInfo = action.payload.referId
    },
    [referLink.rejected]: (state, action) => {
      state.referStatus = "failed";
      state.referError = action.payload
    },
  },
})

export const { resetItem } = referLinkSlice.actions
export default referLinkSlice.reducer 