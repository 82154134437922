import { Box, Container, Stack, Typography } from "@mui/material";
import React from "react";
import "./Categories.css";
import { Link } from "react-router-dom";
import { categoies, categoryImagesOld } from "../../pages/AddNewItem/data";

const Categories = () => {
  return (
    <Container sx={{ minHeight: "100vh", margin: "4rem auto" }}>
      <Typography
        variant="h1"
        className="mont-title"
        textAlign={"center"}
        textTransform={"uppercase"}
      >
        Categories
      </Typography>
      <Stack
        margin={"2rem auto"}
        flexWrap={"wrap"}
        direction={"row"}
        justifyContent={"center"}
      >
        {categoies.map((cat, ind) => (
          <Link to={`/category/${cat.value}`}>
            <div class="category-image-card" key={ind}>
              <img
                src={categoryImagesOld[cat._id]}
                alt="Card Image of Product"
              />
              {/* <h3>Your Heading</h3> */}
            </div>
          </Link>
        ))}
      </Stack>
    </Container>
  );
};

export default Categories;
