import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
  ratingStatus: "idle",
  ratingInfo: null,
  ratingError: null,
};

export const productrating = createAsyncThunk(
  "product/rating",
  async ({ id, token, rating }, { rejectWithValue }) => {
    try {
      const { data } = await axios.get(`/api/product/rating/${id}/${rating}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);
export const productratingSlice = createSlice({
  name: "productRating",
  initialState,
  reducers: {
    resetItem(state, action) {
      state.ratingInfo = null;
      state.ratingStatus = "idle";
      state.ratingError = null;
    },
  },
  extraReducers: {
    [productrating.pending]: (state, action) => {
      state.ratingStatus = "loading";
    },
    [productrating.fulfilled]: (state, action) => {
      state.ratingStatus = "succeeded";
      state.ratingInfo = action.payload.ratingInfo;
    },
    [productrating.rejected]: (state, action) => {
      state.ratingStatus = "failed";
      state.ratingError = action.payload.message;
    },
  },
});

export const { resetItem } = productratingSlice.actions;
export default productratingSlice.reducer;
