import React, { useState } from "react";
import HeroSection from "../../components/HeroSection/HeroSection";
import CarouselCategories from "../../components/CarouselCategories/CarouselCategories";
import CarouselTrending from "../../components/CarouselTrending/CarouselTrending";
import CarouselSale from "../../components/CarouselSale/CarouselSale";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Spinner from "../../components/Spinner/Spinner";
import { useEffect } from "react";
import {
  gethomeInfo,
  resetHome,
} from "../../components/StateSlices/getHomeSlice";
import { Box, Container } from "@mui/material";
import { categoies } from "../AddNewItem/data";

const LoginHome = ({ setCartVal, cartVal, setAddedtoCart }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [homeProduct, setHomeProduct] = useState({});
  const [spinner, setSpinner] = useState(false);

  const { homeStatus, homeInfo, homeError } = useSelector(
    (state) => state.homeInfo
  );

  useEffect(() => {
    setSpinner(true);
    dispatch(gethomeInfo({ token: localStorage.getItem("inktoken") }));
  }, []);

  useEffect(() => {
    if (homeInfo) {
      console.log(homeInfo);
      setHomeProduct(homeInfo);
      dispatch(resetHome());
      setSpinner(false);
    }
  }, [homeInfo]);

  return (
    <>
      {spinner ? (
        <Spinner />
      ) : (
        <>
          <Box
            component={"section"}
            data-aos="fade-in"
            style={{ width: "100%" }}
            data-aos-duration="1000"
          >
            <HeroSection />
          </Box>
          <Container>
            <br />
            <div data-aos="fade-up" data-aos-duration="1000">
              <CarouselCategories title={"Search by Category"} />
            </div>
            {homeProduct && homeProduct.newestProduct ? (
              <div data-aos="fade-up" data-aos-duration="1000">
                <CarouselTrending
                  cartVal={cartVal}
                  setCartVal={setCartVal}
                  title={"Trending Equipment"}
                  data={homeProduct.newestProduct}
                  setAddedtoCart={setAddedtoCart}
                />
              </div>
            ) : (
              ""
            )}

            {homeProduct && homeProduct.saleProduct ? (
              <div data-aos="fade-up" data-aos-duration="1000">
                <CarouselSale
                  cartVal={cartVal}
                  setCartVal={setCartVal}
                  title={"Equipment for Sale"}
                  data={homeProduct.saleProduct}
                />
              </div>
            ) : (
              ""
            )}
            {homeProduct &&
            homeProduct.rentProduct &&
            homeProduct.rentProduct.length > 0 ? (
              <div data-aos="fade-up" data-aos-duration="1000">
                <CarouselSale
                  cartVal={cartVal}
                  setCartVal={setCartVal}
                  title={"Equipment for Rent"}
                  data={homeProduct.rentProduct}
                />
              </div>
            ) : (
              ""
            )}
            <br />
            <br />
          </Container>
        </>
      )}
    </>
  );
};

export default LoginHome;
