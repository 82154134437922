import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
  orderstatus: "idle",
  orderDetailsInfo: null,
  error: null,
};

export const getOrderDetails = createAsyncThunk(
  "checkout/getorderdetails",
  async ({ token, orderId, productId }, { rejectWithValue }) => {
    try {
      const { data } = await axios.get(
        `/api/checkout/order-details/${orderId}/${productId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);
export const getOrderDetailsSlice = createSlice({
  name: "orderdetails",
  initialState,
  reducers: {
    reseto(state, action) {
      state.orderDetailsInfo = [];
      state.orderstatus = "idle";
    },
  },
  extraReducers: {
    [getOrderDetails.pending]: (state, action) => {
      state.orderstatus = "loading";
    },
    [getOrderDetails.fulfilled]: (state, action) => {
      state.orderstatus = "succeeded";
      state.orderDetailsInfo = action.payload.orderDetails;
    },
    [getOrderDetails.rejected]: (state, action) => {
      state.orderstatus = "failed";
      state.error = action.payload.message;
    },
  },
});

export const { reseto } = getOrderDetailsSlice.actions;
export default getOrderDetailsSlice.reducer;
