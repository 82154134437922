import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
  closetStatus: "idle",
  closetInfo: null,
  closetError: null,
  closetProductCount: 0,
};

export const getclosetInfo = createAsyncThunk(
  "closet/getclosetInfo",
  async ({ id, p }, { rejectWithValue }) => {
    try {
      const { data } = await axios.get(`/api/users/closetinfo/${id}?p=${p}`);
      return data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);
export const closetInfoSlice = createSlice({
  name: "closetInfo",
  initialState,
  reducers: {
    resetcloset(state, action) {
      state.closetError = null;
      state.closetInfo = null;
      state.closetProductCount = 0;
    },
  },
  extraReducers: {
    [getclosetInfo.pending]: (state, action) => {
      state.closetStatus = "loading";
    },
    [getclosetInfo.fulfilled]: (state, action) => {
      state.closetStatus = "succeeded";
      state.closetInfo = action.payload.user;
      state.closetProductCount = action.payload.userProductsCount;
    },
    [getclosetInfo.rejected]: (state, action) => {
      state.closetStatus = "failed";
      state.closetError = action.payload.message;
    },
  },
});

export const { resetcloset } = closetInfoSlice.actions;
export default closetInfoSlice.reducer;
