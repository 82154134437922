import {
  Box,
  Grid,
  Tab,
  Tabs,
  Tooltip,
  Typography,
  styled,
  tooltipClasses,
} from "@mui/material";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { reset } from "../StateSlices/getuserSlice";
import { InfoOutlined } from "@mui/icons-material";

const StyledTab = styled(Tab)(({ theme, isActive }) => ({
  fontWeight: isActive ? 700 : "inherit",
  color: "var(--grey-color)",
  alignItems: "flex-start",
  padding: "0 10px",
  transition: isActive && "2.5s ease",
}));

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip arrow {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "var(--white-color)",
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 14,
    // fontWeight: 700,
    padding: "8px 20px",
    borderRadius: 30,
    border: "2px solid var(--white-color)",
  },
  [`& .${tooltipClasses.arrow}`]: {
    // boxShadow: theme.shadows[1],
    // borderRadius: 30,
    color: "var(--white-color)",
  },
}));

const TabsComponent = ({ value, setValue }) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { status, userInfo, profile } = useSelector((state) => state.user);

  const logout = () => {
    window.scrollTo(0, 0);
    localStorage.removeItem("inktoken");
    dispatch(reset());
    navigate("/");
  };

  return (
    <Box
      sx={{
        // background: "#fef8f1",
        // height: "min-content",
        borderRadius: "5px",
        position: "relative",
        flexBasis: "1",
        margin: { xs: "5rem 2rem", md: "2rem 0" },
        marginBottom: "1.35rem",
        background: "var(--black-color)",
        padding: "1rem",
        borderTop: "10px solid var(--secondary-color)",
        position: { xs: "static", lg: "sticky" },
        top: "5rem",
      }}
    >
      <Typography
        variant="h2"
        color={"var(--white-color)"}
        textAlign={"left"}
        padding={" 10px"}
        fontWeight={700}
        marginBottom={"1rem"}
        className="mont-title"
      >
        {userInfo && userInfo.userDetailsId
          ? userInfo.userDetailsId.userFName +
            " " +
            userInfo.userDetailsId.userLName
          : "Hello User"}
      </Typography>
      <Box>
        <Tabs
          orientation="vertical"
          value={value}
          onChange={(e, newValue) => setValue(newValue)}
          aria-label="Profile tabs"
          textColor="secondary"
          indicatorColor="secondary"
        >
          {/* IF STYLED TAB IS NOT SUITABLE THEN USE THIS */}
          {/* <Tab
                      sx={{
                        color: "var(--grey-color)",
                        fontWeight: "normal",
                        alignItems: "flex-start",
                        padding: "0 10px",
                      }}
                      label="My Profile"
                      {...a11yProps(1)}
                    /> */}
          <StyledTab
            label="My Profile"
            value={0}
            isActive={value === 0}
            component={Link}
            to="profile"
          />
          <StyledTab
            label="Add Equipment"
            isActive={value === 8}
            value={8}
            component={Link}
            to="/add-new-item/#/0"
          />
          <StyledTab
            value={1}
            label="My Equipment"
            isActive={value === 1}
            component={Link}
            to="equipment"
          />
          {/* <StyledTab
            value={2}
            label={
              <Typography
                variant="body2"
                display={"flex"}
                fontSize={"1rem"}
                alignItems={"center"}
                fontWeight={value === 2 ? 700 : "normal"}
              >
                My Purchases &nbsp;{" "}
                <LightTooltip
                  title="See equipment you have bought/rented from others"
                  placement="right"
                >
                  <InfoOutlined
                    sx={{ color: "var(--grey-color)" }}
                    fontSize="10px"
                  />
                </LightTooltip>
              </Typography>
            }
            isActive={value === 2}
            component={Link}
            to="purchases"
          /> */}
          <StyledTab
            value={4}
            label={
              <Typography
                variant="body2"
                fontSize={"1rem"}
                display={"flex"}
                alignItems={"center"}
                fontWeight={value === 3 ? 700 : "normal"}
              >
                My Transactions &nbsp;{" "}
                <LightTooltip
                  title="See equipment you have bought/rented or sold/rented to others"
                  placement="right"
                >
                  <InfoOutlined
                    sx={{ color: "var(--grey-color)" }}
                    fontSize="10px"
                  />
                </LightTooltip>
              </Typography>
            }
            isActive={value === 3}
            component={Link}
            to="transactions"
          />
          {/* <StyledTab
            value={4}
            label="All Transactions"
            isActive={value === 4}
            component={Link}
            to="transactions"
          /> */}
          <StyledTab
            label="My Favorites"
            isActive={value === 5}
            component={Link}
            value={5}
            to="favorites"
          />
          <StyledTab
            label="My Notifications"
            isActive={value === 6}
            component={Link}
            value={6}
            to="notifications"
          />
          <StyledTab
            label="My Referrals"
            isActive={value === 7}
            value={7}
            component={Link}
            to="referrals"
          />
        </Tabs>
      </Box>

      <Typography
        variant="h5"
        fontWeight={700}
        mt={1}
        color={"var(--secondary-color)"}
        sx={{ cursor: "pointer" }}
        padding={"10px"}
        onClick={() => logout()}
      >
        Logout
      </Typography>
    </Box>
  );
};

export default TabsComponent;
