import {
    Box,
    Button,
    Divider,
    Modal,
    TextField,
    Typography,
  } from "@mui/material";
  import React, { useEffect } from "react";
  import verifyIllustrator from "../../assets/Pictures/verify-mail-illustrate.svg";
  import { useState } from "react";
  import { useLocation, useNavigate } from "react-router-dom";
  import { useDispatch, useSelector } from "react-redux";
  import { ToastContainer, toast } from "react-toastify";
  import Spinner from "../../components/Spinner/Spinner";
  import { forgotPassword,reset } from "../../components/StateSlices/forgotpasswordSlice";
  
  const ForgotPassword = () => {
    const style = {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      width: 400,
      bgcolor: "background.paper",
      boxShadow: 24,
      p: 4,
      borderRadius: "10px",
    };
    const [openModal, setOpenModal] = useState(false);
    const [newEmail, setNewEmail] = useState("");
    let location = useLocation();
    let navigate = useNavigate();
    const [spinner, setSpinner] = useState(false);
    const dispatch = useDispatch();
    // const [message, setMessage] = useState("");
    const [oldEmail, setOldEmail] = useState("");
    const [sent, setSent] = useState(false)
    const { status, message, error } = useSelector((state) => state.forgot);
  
    const userDetailsState = location.state;
  
    const toastOption = {
      position: "top-right",
      autoClose: 1500,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    };
  
    useEffect(() => {
    //   if (!userDetailsState || userDetailsState === null) {
    //     navigate("/signup");
    //   }
    //   let search = location.search;
    //   if (search) {
    //     let qry = new URLSearchParams(search);
    //     let error = qry.get("error");
    //     if (error === "AccountNotVerified") {
    //       setMessage("Account Not Verified");
    //     }
    //   }
    //   console.log(userDetailsState);
    //   if (userDetailsState) {
    //     setNewEmail(userDetailsState.userEmail);
    //     setOldEmail(userDetailsState.userEmail);
    //   }
    }, []);
  
    const ResendEmail = (e) => {
      e.preventDefault();
      dispatch(forgotPassword({ email: newEmail}));
      setSpinner(true);
    };
  
    if (message) {
      dispatch(reset());
      toast.success("Email Sent Successfully", toastOption);
      setOldEmail(newEmail);
      setSpinner(false);
      setOpenModal(false);
      setSent(true)
    }
  
    if (error) {
      toast.error(error, toastOption);
      dispatch(reset());
      setSpinner(false);
    }
  
    return (
      <>
        <Modal
          open={openModal}
          onClose={() => setOpenModal(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <form onSubmit={(e) => ResendEmail(e)}>
              <Typography
                id="modal-modal-title"
                variant="h4"
                component="h2"
                fontWeight={700}
              >
                Email Information
              </Typography>
              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                <TextField
                  fullWidth
                  type="email"
                  value={newEmail}
                  onChange={(e) => setNewEmail(e.target.value)}
                  placeholder="Email Address"
                ></TextField>
              </Typography>
              <Button
                type="submit"
                variant="contained"
                sx={{ marginTop: "1rem", float: "right" }}
              >
                Resend Email
              </Button>
            </form>
          </Box>
        </Modal>
        <Box component={"div"} minHeight={"100vh"} minWidth={"100%"}>
          {spinner ? <Spinner /> : ""}
          <Box
            component={"div"}
            padding={".5rem 2rem"}
            textAlign={"center"}
            marginTop={"4rem"}
          >
            {/* {message ? (
              <p className="paragraph_title" style={{ color: "red" }}>
                {message}
              </p>
            ) : (
              ""
            )} */}
            <Typography variant="h1 mont-title" fontWeight={700}>
              Forgot Password
            </Typography>
            <Divider
                        sx={{ margin: "1rem auto", width: "50%", maxWidth: "550px" }}
                        />
            {
                sent ?(
                    <>
                        
                        <Typography variant="h4" color={"text.secondary"}>
                        Check your email and click the link to reset your password{" "}
                        </Typography>
                        <img
                        src={verifyIllustrator}
                        style={{ width: "100%", maxWidth: "300px", margin: "2rem auto" }}
                        alt="verify-mail-illustrate"
                        />
                    </>
                ):(
                    <>
                    <Typography variant="h4" color={"text.secondary"}>
                        Enter your email address to reset your password
                        </Typography>
                    </>
                )
            }
            
            <Box component={"div"}>
              <Button
                variant="contained"
                sx={{ margin: ".5rem", color: "var(--white-color)" }}
                onClick={() => setOpenModal(true)}
                color="primary"
              >
                Send Reset Link
              </Button>
              <Button variant="outlined" sx={{ margin: ".5rem" }}>
                <a href="mailto:support@godizel.com" target="_blank">Contact Support</a>
              </Button>
            </Box>
          </Box>
        </Box>
      </>
    );
  };
  
  export default ForgotPassword;
  