import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import axios from "axios"

const initialState = {
  checkoutNStatus: "idle",
  nmessage: "",
  ncherror: null,
  checkoutNHistory:null
}

export const nonUserCheckout = createAsyncThunk(
  "nonusercheckout/checkout",
  async ({checkoutData,token}, { rejectWithValue }) => {
    try {
      const { data } = await axios.post(
        `/api/users/nonusercheckout`, checkoutData,
        {
          headers: {
              Authorization: `Bearer ${token}`,
          },
        }
      )
      return data
    } catch (err) {
      return rejectWithValue(err.response.data)
    }
  }
)
export const NonUserCheckoutSlice = createSlice({
  name: "nonusercheckout",
  initialState,
  reducers: {
    resetNCheckout(state,action){
        state.nmessage = ""
        state.checkoutNStatus="idle"
        state.ncherror=null
      }
  },
  extraReducers: {
    [nonUserCheckout.pending]: (state, action) => {
      state.checkoutNStatus = "loading"
    },
    [nonUserCheckout.fulfilled]: (state, action) => {
      state.checkoutNStatus = "succeeded"
      state.nmessage = action.payload.message
      state.checkoutNHistory = action.payload.checkoutEntry
    },
    [nonUserCheckout.rejected]: (state, action) => {
      state.checkoutNStatus = "failed"
      state.ncherror = action.payload.message
    },
  },
})

export const { resetNCheckout } = NonUserCheckoutSlice.actions
export default NonUserCheckoutSlice.reducer 