import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import axios from "axios"

const initialState = {
  pstatus: "idle",
  productInfo: null,
  closetInfo:{},
  images:[],
  error: null
}

export const getProduct = createAsyncThunk(
  "product/getproduct",
  async (id, { rejectWithValue }) => {
    try {
      const { data } = await axios.get(
        `/api/product/getproduct/${id}`,
      )
      return data
    } catch (err) {
      return rejectWithValue(err.response.data)
    }
  }
)
export const ProductSlice = createSlice({
  name: "product",
  initialState,
  reducers: {
    reset(state,action){
        state.productInfo = null
        state.pstatus="idle"
        state.error=null
      }
  },
  extraReducers: {
    [getProduct.pending]: (state, action) => {
      state.pstatus = "loading"
    },
    [getProduct.fulfilled]: (state, action) => {
      state.pstatus = "succeeded"
      state.productInfo = action.payload.product
      state.closetInfo = action.payload.closetInfo
      state.images = action.payload.images;
    },
    [getProduct.rejected]: (state, action) => {
      state.pstatus = "failed"
      state.error = action.payload.message
    },
  },
})

export const { reset } = ProductSlice.actions
export default ProductSlice.reducer 